export const environment = {
  production: true,
  occBaseUrl: 'https://api.c9guxrh1t0-osbornedi1-p1-public.model-t.cc.commerce.ondemand.com',
  paypal: {
    id: 'AQm2p3BTLUOz9o4iAcD_CoYouFEmFLMf4N1nXQrgf26OlKRqCiNaVxFMvHuUUE9fvkfxZo-OqIvKF_Ey',
    src: 'https://www.paypal.com/sdk/js',
    installments: {
      active: true,
      threshold: 60.0,
    },
  },
  gtmId: 'GTM-T549BT5',
  reCaptcha: {
    enabled: true,
    scriptSrc: 'https://www.google.com/recaptcha/api.js',
    onloadCallbackName: 'recaptchaCallback',
    v2: {
      siteKey: '6LfDaf8eAAAAAAFtczB04T0abSBTzDV3Q-0fybJE',
    },
    v3: {
      siteKey: '',
    },
  },
  cookiepro: {
    enabled: true,
    src: 'https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js',
    id: '019072b1-85ab-7fcf-aef6-9de55345f264',
    charset: 'UTF-8',
  },
  bugherd: {
    enabled: false,
    src: '',
    apikey: '',
  },
  emarsysConfig: {
    merchantId: '16715D27DACAC2F7',
    testMode: false,
  },
};
