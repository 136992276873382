import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, provideConfig, UrlModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CjMiniCartComponent } from './mini-cart.component';

@NgModule({
  imports: [CommonModule, RouterModule, UrlModule, IconModule, I18nModule],

  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        MiniCartComponent: {
          component: () => import('./mini-cart.component').then((m) => m.CjMiniCartComponent),
        },
      },
    }),
  ],
  declarations: [CjMiniCartComponent],
  exports: [CjMiniCartComponent],
})
export class CjMiniCartModule {}
