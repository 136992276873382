import { NgModule } from '@angular/core';
import { CjOccCartModule } from './adapters/cart/cart-occ.module';
import { CjCheckoutOccModule } from './adapters/checkout/checkout-occ.module';
import { CjCmsOccModule } from './adapters/cms/cms-occ.module';
import { CjOccOrderModule } from './adapters/order/order-occ.module';
import { CjProductOccModule } from './adapters/product/product-occ.module';
import { CjSiteContextOccModule } from './adapters/site-context/site-context-occ.module';
import { CjOccUserModule } from './adapters/user/user-occ.module';

@NgModule({
  imports: [
    CjProductOccModule,
    CjCheckoutOccModule,
    CjOccCartModule,
    CjCmsOccModule,
    CjOccOrderModule,
    CjSiteContextOccModule,
    CjOccUserModule,
  ],
})
export class CjOccModule {}
