import { Injectable } from '@angular/core';
import { ActiveCartFacade, Cart, MultiCartFacade } from '@spartacus/cart/base/root';
import { Command, CommandService, CommandStrategy, UserIdService } from '@spartacus/core';
import { Observable, combineLatest, map, switchMap, take } from 'rxjs';
import { CjModifyOrderConnector } from '../connectors/modify-order.connector';

@Injectable()
export class CjModifyOrderService {
  protected modifyCommand: Command<{ orderId: string }, Cart> = this.commandService.create<{ orderId: string }, Cart>(
    ({ orderId }) =>
      this.modifyPreconditions().pipe(
        switchMap((userId: string) => this.modifyOrderConnector.modify(orderId, userId)),
        map((cart) => cart as Cart),
      ),
    {
      strategy: CommandStrategy.CancelPrevious,
    },
  );

  constructor(
    protected commandService: CommandService,
    protected modifyOrderConnector: CjModifyOrderConnector,
    protected userIdService: UserIdService,
    protected activeCartFacade: ActiveCartFacade,
    protected multiCartFacade: MultiCartFacade,
  ) {}

  /**
   * Cancel order and create cart from an existing order
   */
  modify(orderId: string): Observable<Cart> {
    return this.modifyCommand.execute({
      orderId,
    });
  }

  protected modifyPreconditions(): Observable<string> {
    return combineLatest([this.userIdService.takeUserId(), this.activeCartFacade.getActiveCartId()]).pipe(
      take(1),
      map(([userId, cartId]) => {
        if (cartId) {
          this.multiCartFacade.deleteCart(cartId, userId);
        }

        return userId;
      }),
    );
  }
}
