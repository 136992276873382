<div *ngIf="data$ | async as data" class="cx-modal-container cj-popup-dialog" (esc)="dismissModal('Escape pressed')">
  <!-- Modal Header -->
  <div class="cx-dialog-header modal-header">
    <button type="button" class="close" attr.aria-label="{{ 'addToCart.closeModal' | cxTranslate }}" (click)="dismissModal('Cross click')">
      <span aria-hidden="true">
        <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
      </span>
    </button>
  </div>

  <div class="cx-dialog-body modal-body">
    <cx-media *ngIf="data.icon" [container]="data.icon"></cx-media>
    <div class="cj-popup-dialog__info">
      <p *ngIf="data.title" class="cj-popup-dialog__title">{{ data.title }}</p>
      <p *ngIf="data.text" class="cj-popup-dialog__text" [innerHTML]="data.text"></p>
      <ng-container
        *ngIf="textComponent$ | async as paragraph"
        [cxComponentWrapper]="{
          flexType: paragraph.typeCode,
          typeCode: paragraph.typeCode,
          uid: paragraph.uid,
        }"
      >
      </ng-container>
    </div>
  </div>
</div>
