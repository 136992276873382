import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { FormErrorsModule } from '@spartacus/storefront';
import { CjCheckoutLoginComponent } from './checkout-login.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    FormErrorsModule,
    RouterModule,
    UrlModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        GuestCheckoutLoginComponent: {
          component: () => import('./checkout-login.component').then((m) => m.CjCheckoutLoginComponent),
          guards: [],
        },
      },
    }),
  ],
  declarations: [CjCheckoutLoginComponent],
  exports: [CjCheckoutLoginComponent],
})
export class CjCheckoutLoginModule {}
