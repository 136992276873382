import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { OutletModule } from '@spartacus/storefront';
import { CjProductSummaryComponent } from './product-summary.component';

@NgModule({
  imports: [CommonModule, OutletModule, I18nModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ProductSummaryComponent: {
          component: () => import('./product-summary.component').then((m) => m.CjProductSummaryComponent),
        },
      },
    }),
  ],
  declarations: [CjProductSummaryComponent],
  exports: [CjProductSummaryComponent],
})
export class CjProductSummaryModule {}
