import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cjCapitalize',
})
export class CjCapitalizePipe implements PipeTransform {
  transform(value: string): string {
    return value.length ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() : value;
  }
}
