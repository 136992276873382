import { Component, Input } from '@angular/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { ORDER_REGISTER_FORM_DIALOG } from './order-guest-register-form-dialog/order-guest-register-form-dialog.config';

@Component({
  selector: 'cj-guest-register-form',
  templateUrl: './order-guest-register-form.component.html',
})
export class CjOrderGuestRegisterFormComponent {
  @Input() guid?: string;
  @Input() email?: string;

  constructor(private readonly dialogService: LaunchDialogService) {}

  openDialog() {
    this.dialogService.openDialogAndSubscribe(ORDER_REGISTER_FORM_DIALOG, undefined, { guid: this.guid, email: this.email });
  }
}
