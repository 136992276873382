/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const payment = {
  paymentOptions: {
    title: 'Options de paiement',
    paymentText: 'Payez en 3 fois, sans intérêts. <br/> {{total}} 0 % TAEG.',
    subTitle: 'Modes de paiement',
  },
};
