import { Component } from '@angular/core';
import { ICON_TYPE, LaunchDialogService } from '@spartacus/storefront';
import { Observable, map } from 'rxjs';
import { CjCmsOsborneModalComponent, CjCmsOsborneModalItemComponent } from 'src/app/shared/models/modal.model';
import { CjCmsUtilsService } from 'src/app/shared/utils/cms-utils.service';

@Component({
  selector: 'cj-delivery-banner-dialog',
  templateUrl: './delivery-banner-dialog.component.html',
  styleUrl: './delivery-banner-dialog.component.scss',
})
export class CjDeliveryBannerDialogComponent {
  iconTypes = ICON_TYPE;

  data$: Observable<CjCmsOsborneModalComponent> = this.launchDialogService.data$;
  items$: Observable<Observable<CjCmsOsborneModalItemComponent>[]> = this.data$.pipe(
    map((data) => {
      if (!data.items) return [];
      return this.cmsUtils.getComponentDataList<CjCmsOsborneModalItemComponent>(data.items);
    }),
  );

  constructor(
    private readonly launchDialogService: LaunchDialogService,
    protected cmsUtils: CjCmsUtilsService,
  ) {}

  dismissModal(reason: string) {
    this.launchDialogService.closeDialog(reason);
  }
}
