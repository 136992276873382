import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { Country, DeliveryCountryEntities, StateWithUser } from '@spartacus/core';
import { CJ_USER_FEATURE, CjBaseSiteCountriesState, CjUserState } from '../user-state';

export const getCjUserState: MemoizedSelector<StateWithUser, CjUserState> = createFeatureSelector<CjUserState>(CJ_USER_FEATURE);

export const getBaseSiteCountriesState: MemoizedSelector<StateWithUser, CjBaseSiteCountriesState> = createSelector(
  getCjUserState,
  (state: CjUserState) => state.baseSiteCountries,
);

export const getBaseSiteCountriesEntites: MemoizedSelector<StateWithUser, DeliveryCountryEntities> = createSelector(
  getBaseSiteCountriesState,
  (state: CjBaseSiteCountriesState) => state.entities,
);

export const getAllBaseSiteCountries: MemoizedSelector<StateWithUser, Country[]> = createSelector(getBaseSiteCountriesEntites, (entites) =>
  Object.keys(entites).map((isocode) => entites[isocode]),
);

export const countrySelectorFactory = (isocode: string): MemoizedSelector<StateWithUser, Country | null> =>
  createSelector(getBaseSiteCountriesEntites, (entities) => (Object.keys(entities).length !== 0 ? entities[isocode] : null));
