import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { CjSiteContextSelectorDialogComponent } from './site-context-selector-dialog.component';

export const SITE_CONTEXT_SELECTOR_DIALOG = 'SITE_CONTEXT_SELECTOR';

export const cjSiteContextSelectorConfig: LayoutConfig = {
  launch: {
    SITE_CONTEXT_SELECTOR: {
      inlineRoot: true,
      component: CjSiteContextSelectorDialogComponent,
      dialogType: DIALOG_TYPE.POPOVER_CENTER,
    },
  },
};
