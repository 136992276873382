import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { Cart } from '@spartacus/cart/base/root';
import { createFrom, EventService, StateEventService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CleanEcommerceEvent } from '../../common/common.events';
import { EcommerceType } from '../../tag-manager-feature.model';
import { PLACE_ORDER } from '../checkout-steps/store/actions/custom-checkout.action';
import { getProductItems } from '../common/checkout.utils';
import { OrderPlacedEvent } from './checkout.events';

@Injectable({
  providedIn: 'root',
})
export class CheckoutEventBuilder {
  constructor(
    protected actions: ActionsSubject,
    protected stateEventService: StateEventService,
    protected eventService: EventService,
  ) {
    this.register();
  }

  /**
   * Registers checkout events
   */
  protected register(): void {
    this.eventService.register(OrderPlacedEvent, this.buildPurchaseEvent());
  }

  /**
   * Builds the purchase events
   */
  protected buildPurchaseEvent(): Observable<OrderPlacedEvent> {
    return this.actions.pipe(
      ofType(PLACE_ORDER),
      tap(() => this.eventService.dispatch(<CleanEcommerceEvent>{ ecommerce: null }, CleanEcommerceEvent)),
      map((action: any) => {
        const order = action.payload;
        return createFrom(OrderPlacedEvent, {
          event: EcommerceType.PURCHASE,
          ecommerce: {
            transaction_id: order.code,
            value: order.totalPrice.value.toString(),
            tax: order.totalTax.value.toString(),
            shipping: order.deliveryCost.value.toString(),
            currency: order.totalPrice.currencyIso,
            coupon: this.getOrderPromotionName(order) || '',
            items: getProductItems(order.entries, order.giftCardProduct),
          },
        });
      }),
    );
  }

  private getOrderPromotionName(cart: Cart): string | undefined {
    if (cart && cart.appliedOrderPromotions && cart.appliedOrderPromotions.length) {
      return cart.appliedOrderPromotions[0].promotion?.code;
    }
    return undefined;
  }
}
