import { Injectable } from '@angular/core';
import { CmsService, ProductSearchPage, RoutingService } from '@spartacus/core';
import { ProductListComponentService, SchemaBuilder } from '@spartacus/storefront';
import { Observable, distinctUntilChanged, filter, map, of, scan, switchMap } from 'rxjs';
import { CjPage } from 'src/app/core/cms/model/page.model';
import { CjStructuredDataUtilsService } from '../../structured-data-utils-service';

@Injectable({
  providedIn: 'root',
})
export class CjOfferCatalogSchemaBuilder implements SchemaBuilder {
  constructor(
    private readonly utils: CjStructuredDataUtilsService,
    private readonly productListComponentService: ProductListComponentService,
    private readonly cmsService: CmsService,
    private readonly routingService: RoutingService,
  ) {}

  build(): Observable<any> {
    // Using productListComponentService ensures we are in a CategoryPage
    return this.cmsService.getCurrentPage().pipe(
      map((page: CjPage) => page?.plpCategoryCode || ''),
      distinctUntilChanged(),
      switchMap((categoryCode) =>
        !categoryCode
          ? of({})
          : this.productListComponentService.model$.pipe(
              filter((model: ProductSearchPage) => categoryCode === model.currentQuery?.query?.value?.split(':').reverse()[0]),
              map((model: ProductSearchPage) => model.products),
              scan((acc, curr) => [...(acc || []), ...(curr || [])]),
              map((products) =>
                products
                  ? {
                      '@type': 'OfferCatalog',
                      '@id': this.utils.getCurrentUrl('#gallery'),
                      mainEntityOfPage: { '@id': this.utils.getCurrentUrl('#webpage') },
                      itemListElement: products.map((product, i) => ({
                        '@type': 'ListItem',
                        position: i + 1,
                        url: this.utils.getFullUrl('', product.url),
                      })),
                    }
                  : {},
              ),
            ),
      ),
    );
  }
}
