import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BASE_SITE_NORMALIZER } from '@spartacus/core';
import { CjBaseStoreAdapter } from 'src/app/core/site-context/connectors/base-store.adapter';
import { CjOccBaseStoreAdapter } from './occ-base-store.adapter';
import { CjBaseSiteNormalizer } from './converters/base-site-normalizer';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: BASE_SITE_NORMALIZER,
      useExisting: CjBaseSiteNormalizer,
      multi: true,
    },
    {
      provide: CjBaseStoreAdapter,
      useClass: CjOccBaseStoreAdapter,
    },
  ],
})
export class CjSiteContextOccModule {}
