import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OrderConfirmationTotalsComponent } from '@spartacus/order/components';
import { OrderFacade, OrderHistoryFacade } from '@spartacus/order/root';
import { Observable, map } from 'rxjs';
import { CjOrder } from '../../../order.model';

@Component({
  selector: 'cj-order-confirmation-totals',
  templateUrl: './order-confirmation-totals.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjOrderConfirmationTotalsComponent extends OrderConfirmationTotalsComponent {
  override order$: Observable<CjOrder | undefined> = this.orderHistoryFacade.getOrderDetails().pipe(map((o) => o as CjOrder));

  constructor(
    protected override orderFacade: OrderFacade,
    private readonly orderHistoryFacade: OrderHistoryFacade,
  ) {
    super(orderFacade);
  }

  override ngOnDestroy(): void {
    this.orderHistoryFacade.clearOrderDetails();
  }
}
