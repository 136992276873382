import { Injectable } from '@angular/core';
import { LanguageService } from '@spartacus/core';
import { CurrentProductService, SchemaBuilder } from '@spartacus/storefront';
import { Observable, map, switchMap } from 'rxjs';
import { CjProduct } from 'src/app/shared/models/product.model';
import { CjStructuredDataUtilsService } from '../../structured-data-utils-service';

@Injectable({
  providedIn: 'root',
})
export class CjItemPageSchemaBuilder implements SchemaBuilder {
  product?: CjProduct;
  getFullProductUrl(append: string = '') {
    return this.utils.getFullUrl('', this.product?.url) + append;
  }

  constructor(
    private readonly currentProduct: CurrentProductService,
    private readonly utils: CjStructuredDataUtilsService,
    private readonly languageService: LanguageService,
  ) {}

  build(): Observable<any> {
    // Using currentProductService ensures we are in a ProductPage
    return this.languageService.getActive().pipe(
      switchMap(() =>
        this.currentProduct.getProduct().pipe(
          map((p: CjProduct | null) => {
            if (p) {
              this.product = p;

              let schema: any = {
                '@type': 'ItemPage',
                '@id': this.getFullProductUrl('#webpage'),
                url: this.getFullProductUrl(),
                breadcrumb: { '@id': this.getFullProductUrl('#breadcrumb') },
                hasPart: { '@id': this.getFullProductUrl('#faq') },
                speakable: {
                  '@type': 'SpeakableSpecification',
                  cssSelector: ['speakable-title', 'speakable-description'],
                },
                publisher: { '@id': this.utils.getFullUrl('#organization') },
                isPartOf: { '@id': this.utils.getFullUrl('#website') },
              };

              if (p.images) schema.image = { '@id': this.getFullProductUrl('#primaryimage') };

              return schema;
            }
            return {};
          }),
        ),
      ),
    );
  }
}
