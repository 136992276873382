import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { OrderConfirmationOrderEntriesContext, OrderDetailsService } from '@spartacus/order/components';
import { OrderConfirmationOrderEntriesContextToken, OrderFacade } from '@spartacus/order/root';
import { FormErrorsModule, PasswordVisibilityToggleModule } from '@spartacus/storefront';
import { CjCartSharedModule } from 'src/app/cms-components/cart/base/components/cart-shared/cart-shared.module';
import { CjOrderConfirmationGuard } from '../guards/order-confirmation.guard';
import { CjOrderConfirmationActionsComponent } from './order-confirmation-actions/order-confirmation-actions.component';
import { CjOrderConfirmationItemsComponent } from './order-confirmation-items/order-confirmation-items.component';
import { CjOrderConfirmationShippingComponent } from './order-confirmation-shipping/order-confirmation-shipping.component';
import { CjOrderConfirmationThankYouMessageComponent } from './order-confirmation-thank-you-message/order-confirmation-thank-you-message.component';
import { CjOrderConfirmationTotalsComponent } from './order-confirmation-totals/order-confirmation-totals.component';
import { CjOrderGuestRegisterDialogModule } from './order-guest-register-form/order-guest-register-form-dialog/order-guest-register-form-dialog.module';
import { CjOrderGuestRegisterFormComponent } from './order-guest-register-form/order-guest-register-form.component';

const orderConfirmationComponents = [
  CjOrderConfirmationItemsComponent,
  CjOrderConfirmationThankYouMessageComponent,
  CjOrderConfirmationShippingComponent,
  CjOrderConfirmationTotalsComponent,
  CjOrderGuestRegisterFormComponent,
  CjOrderConfirmationActionsComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    I18nModule,
    ReactiveFormsModule,
    FormErrorsModule,
    PasswordVisibilityToggleModule,
    CjCartSharedModule,
    CjOrderGuestRegisterDialogModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OrderConfirmationThankMessageComponent: {
          component: () =>
            import('./order-confirmation-thank-you-message/order-confirmation-thank-you-message.component').then(
              (m) => m.CjOrderConfirmationThankYouMessageComponent,
            ),
          guards: [CjOrderConfirmationGuard],
        },
        ReplenishmentConfirmationMessageComponent: {
          component: () =>
            import('./order-confirmation-thank-you-message/order-confirmation-thank-you-message.component').then(
              (m) => m.CjOrderConfirmationThankYouMessageComponent,
            ),
          guards: [CjOrderConfirmationGuard],
        },

        OrderConfirmationItemsComponent: {
          component: () =>
            import('./order-confirmation-items/order-confirmation-items.component').then((m) => m.CjOrderConfirmationItemsComponent),
          guards: [CjOrderConfirmationGuard],
        },
        ReplenishmentConfirmationItemsComponent: {
          component: () =>
            import('./order-confirmation-items/order-confirmation-items.component').then((m) => m.CjOrderConfirmationItemsComponent),
          guards: [CjOrderConfirmationGuard],
        },

        OrderConfirmationShippingComponent: {
          component: () =>
            import('./order-confirmation-shipping/order-confirmation-shipping.component').then(
              (m) => m.CjOrderConfirmationShippingComponent,
            ),
          guards: [CjOrderConfirmationGuard],
        },

        OrderConfirmationTotalsComponent: {
          component: () =>
            import('./order-confirmation-totals/order-confirmation-totals.component').then((m) => m.CjOrderConfirmationTotalsComponent),
          guards: [CjOrderConfirmationGuard],
        },
        ReplenishmentConfirmationTotalsComponent: {
          component: () =>
            import('./order-confirmation-totals/order-confirmation-totals.component').then((m) => m.CjOrderConfirmationTotalsComponent),
          guards: [CjOrderConfirmationGuard],
        },

        OrderConfirmationOverviewComponent: {
          component: () => import('../order-details/order-overview/order-overview.component').then((m) => m.CjOrderOverviewComponent),
          providers: [{ provide: OrderDetailsService, useExisting: OrderFacade }],
          guards: [CjOrderConfirmationGuard],
        },
        ReplenishmentConfirmationOverviewComponent: {
          component: () => import('../order-details/order-overview/order-overview.component').then((m) => m.CjOrderOverviewComponent),
          providers: [{ provide: OrderDetailsService, useExisting: OrderFacade }],
          guards: [CjOrderConfirmationGuard],
        },

        OrderConfirmationContinueButtonComponent: {
          component: () =>
            import('./order-confirmation-actions/order-confirmation-actions.component').then((m) => m.CjOrderConfirmationActionsComponent),
          guards: [CjOrderConfirmationGuard],
        },
      },
    }),
    { provide: OrderConfirmationOrderEntriesContextToken, useExisting: OrderConfirmationOrderEntriesContext },
  ],
  declarations: [...orderConfirmationComponents],
  exports: [...orderConfirmationComponents],
})
export class CjOrderConfirmationModule {}
