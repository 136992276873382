import { Action } from '@ngrx/store';
import { Address } from '@spartacus/core';

export const CREATE_BILLING_ADDRESS = '[CjCheckout] Create Billing Address';
export const CREATE_BILLING_ADDRESS_FAIL = '[CjCheckout] Create Billing Address Fail';
export const CREATE_BILLING_ADDRESS_SUCCESS = '[CjCheckout] Create Billing Address Success';

export const SET_BILLING_ADDRESS = '[CjCheckout] Set Billing Address';
export const SET_BILLING_ADDRESS_FAIL = '[CjCheckout] Set Billing Address Fail';
export const SET_BILLING_ADDRESS_SUCCESS = '[CjCheckout] Set Billing Address Success';

export const UNSET_BILLING_ADDRESS = '[CjCheckout] Unset Billing Address';
export const UNSET_BILLING_ADDRESS_FAIL = '[CjCheckout] Unset Billing Address Fail';
export const UNSET_BILLING_ADDRESS_SUCCESS = '[CjCheckout] Unset Billing Address Success';

export class CreateBillingAddress implements Action {
  readonly type = CREATE_BILLING_ADDRESS;
  constructor(
    public payload: {
      userId: string;
      cartId: string;
      address: Address;
    },
  ) {}
}
export class CreateBillingAddressFail implements Action {
  readonly type = CREATE_BILLING_ADDRESS_FAIL;
  constructor(public payload: any) {}
}
export class CreateBillingAddressSuccess implements Action {
  readonly type = CREATE_BILLING_ADDRESS_SUCCESS;
  constructor(public payload: Address) {}
}

export class SetBillingAddress implements Action {
  readonly type = SET_BILLING_ADDRESS;
  constructor(
    public payload: {
      userId: string;
      cartId: string;
      addressId: string;
    },
  ) {}
}
export class SetBillingAddressFail implements Action {
  readonly type = SET_BILLING_ADDRESS_FAIL;
  constructor(public payload: any) {}
}
export class SetBillingAddressSuccess implements Action {
  readonly type = SET_BILLING_ADDRESS_SUCCESS;
  constructor(public payload: Address) {}
}

export class UnsetBillingAddress implements Action {
  readonly type = UNSET_BILLING_ADDRESS;
  constructor(
    public payload: {
      userId: string;
      cartId: string;
    },
  ) {}
}
export class UnsetBillingAddressFail implements Action {
  readonly type = UNSET_BILLING_ADDRESS_FAIL;
  constructor(public payload: any) {}
}
export class UnsetBillingAddressSuccess implements Action {
  readonly type = UNSET_BILLING_ADDRESS_SUCCESS;
  constructor() {}
}

export type CustomCheckoutAction =
  | CreateBillingAddress
  | CreateBillingAddressSuccess
  | CreateBillingAddressFail
  | SetBillingAddress
  | SetBillingAddressSuccess
  | SetBillingAddressFail
  | UnsetBillingAddress
  | UnsetBillingAddressSuccess
  | UnsetBillingAddressFail;
