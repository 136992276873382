import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import {
  MediaModule,
  PageComponentModule
} from '@spartacus/storefront';
import { CjRestaurantBannerComponent } from './restaurant-banner.component';

@NgModule({
  imports: [CommonModule, PageComponentModule, MediaModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsborneRestaurantComponent: {
          component: () =>
            import('./restaurant-banner.component').then(
              (m) => m.CjRestaurantBannerComponent,
            ),
        },
      },
    }),
  ],
  declarations: [CjRestaurantBannerComponent],
  exports: [CjRestaurantBannerComponent],
})
export class CjRestaurantBannerModule {}
