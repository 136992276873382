import { CxEvent } from '@spartacus/core';
import { EcommerceViewItemList } from './category-page.model';

export class CategoryPageEvent extends CxEvent {
  /**
   * Event's type
   */
  static override readonly type = 'CategoryPageEvent';
  event!: string;
  ecommerce!: EcommerceViewItemList;
}
