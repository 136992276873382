import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { createFrom, EventService, StateEventService, UserActions } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CleanEcommerceEvent } from '../common/common.events';
import { EcommerceType } from '../tag-manager-feature.model';
import { UserAddProductInterestEvent, UserRegisterEvent } from './user.events';

@Injectable({
  providedIn: 'root',
})
export class UserEventBuilder {
  protected currentUid?: string;

  constructor(
    protected actionsSubject: ActionsSubject,
    protected eventService: EventService,
    protected stateEventService: StateEventService,
  ) {
    this.register();
  }

  protected register(): void {
    this.registerAddProductInterestEvents();
    this.registerUserRegisterEvents();
  }

  protected registerAddProductInterestEvents(): void {
    this.eventService.register(UserAddProductInterestEvent, this.buildAddProductInterestEvent());
  }

  protected registerUserRegisterEvents(): void {
    this.eventService.register(UserRegisterEvent, this.registerUserRegister());
  }

  protected registerUserRegister(): Observable<UserRegisterEvent> {
    return this.actionsSubject.pipe(
      ofType(UserActions.REGISTER_USER_SUCCESS),
      tap(() => this.eventService.dispatch(<CleanEcommerceEvent>{ ecommerce: null }, CleanEcommerceEvent)),
      map(() =>
        createFrom(UserRegisterEvent, {
          event: EcommerceType.SIGN_UP,
          method: 'email',
          uid: this.currentUid!,
        }),
      ),
    );
  }

  protected buildAddProductInterestEvent(): Observable<UserAddProductInterestEvent> {
    return this.actionsSubject.pipe(
      ofType(UserActions.ADD_PRODUCT_INTEREST_SUCCESS),
      switchMap((action: any) => of(action.payload.product)),
      map((product) => {
        return createFrom(UserAddProductInterestEvent, {
          event: EcommerceType.OUT_OF_STOCK,
          item_name: product.name,
        });
      }),
    );
  }

  setCurrentUid(uid: string) {
    this.currentUid = uid;
  }
}
