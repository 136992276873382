import { Injectable } from '@angular/core';
import { BaseSite, Converter, Occ } from '@spartacus/core';
import { CjBaseStoreService } from 'src/app/cms-components/content/misc/site-context-selector/facades/base-store.service';

@Injectable({ providedIn: 'root' })
export class CjBaseSiteNormalizer implements Converter<Occ.BaseSite, BaseSite> {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private baseStoreService: CjBaseStoreService) {}

  convert(source: Occ.BaseSite, target?: BaseSite): BaseSite {
    if (target === undefined) {
      target = { ...(source as any) } as BaseSite;
    }

    this.baseStoreService
      .getActive()
      .subscribe((store) => {
        if (target === undefined) {
          target = { ...(source as any) } as BaseSite;
        }
        if (store && target) {
          target.baseStore = store;
        } else {
          target.baseStore = this.firstStore(source);
        }
      })
      .unsubscribe();

    delete target.stores;
    return target;
  }

  protected firstStore(source: Occ.BaseSite) {
    // group all languages of stores
    const allLanguages = new Map<string, Occ.Language>();

    source.stores?.forEach((store) => {
      store.languages?.forEach((lang) => {
        if (lang.isocode) {
          allLanguages.set(lang.isocode, lang);
        }
      });
    });

    const firstStore = {
      ...source.stores?.[0],
      languages: Array.from(allLanguages.values()),
    };

    return firstStore;
  }
}
