import { TranslationResources } from '@spartacus/core';
import { en } from './en';
import { es } from './es';
import { fr } from './fr';

export const cjTranslations: TranslationResources = {
  en,
  es,
  fr,
};
