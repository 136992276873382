import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CjProductScrollToTopComponent } from './product-scroll-to-top.component';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { CjAddToCartModule } from 'src/app/cms-components/cart/base/components/add-to-cart/add-to-cart.module';

@NgModule({
  imports: [CommonModule, CjAddToCartModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        scrollToTopComponent: {
          component: () => import('./product-scroll-to-top.component').then((m) => m.CjProductScrollToTopComponent),
        },
      },
    }),
  ],
  declarations: [CjProductScrollToTopComponent],
  exports: [CjProductScrollToTopComponent],
})
export class CjProductScrollToTopModule {}
