import { CheckoutAdapter } from '@spartacus/checkout/base/core';
import { Address } from '@spartacus/core';
import { Observable } from 'rxjs';

export abstract class CjCheckoutAdapter extends CheckoutAdapter {
  /**
   * Abstract method used to create billing address and set in cart
   *
   * @param userId
   * @param cartId
   * @param billingAddress
   */
  abstract createBillingAddress(userId: string, cartId: string, billingAddress: Address): Observable<any>;

  /**
   * Abstract method used to set billing address in cart
   *
   * @param userId
   * @param cartId
   * @param billingAddressId
   */
  abstract setBillingAddress(userId: string, cartId: string, billingAddressId: string): Observable<any>;

  /**
   * Abstract method used to unset billing address from cart
   *
   * @param userId
   * @param cartId
   */
  abstract unsetBillingAddress(userId: string, cartId: string): Observable<any>;
}
