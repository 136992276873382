import { registerLocaleData } from '@angular/common';
import { Component } from '@angular/core';

import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import { CmsService, Config } from '@spartacus/core';
import { filter, map } from 'rxjs';

registerLocaleData(localeEs);
registerLocaleData(localeEn);

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    protected readonly config: Config,
    protected readonly cmsService: CmsService,
  ) {
    const componentGuards = Object.keys(config.cmsComponents!).reduce((acc, key) => {
      const guards = config.cmsComponents![key]?.guards;
      if (guards) acc[key] = guards.map((guard) => guard.name);
      return acc;
    }, {} as any);

    const allGuards = this.filterDuplicateAndUndefined(Object.values(componentGuards).flat());

    const currentComponents = this.cmsService.getCurrentPage().pipe(
      filter((p) => !!p),
      map((page) =>
        this.filterDuplicateAndUndefined(
          (page.slots &&
            Object.values(page.slots)
              .map((s) => s.components!)
              .flat()) ||
            [],
        ),
      ),
      map((components) => components),
    );

    const currentGuards$ = currentComponents.pipe(
      map((components) => components.map((component) => component.flexType || component.typeCode)),
      map((components) => {
        const currentComponentGuards: any = {};
        components.forEach((component) => {
          const guards = componentGuards[component!];
          if (component && guards) currentComponentGuards[component] = guards.join(', ');
        });
        return currentComponentGuards;
      }),
    );

    const allCurrentGuards$ = currentGuards$.pipe(
      map((currentGuards) => this.filterDuplicateAndUndefined(Object.values(currentGuards).flat()).join(', ')),
    );

    currentGuards$.subscribe(console.log);
    allCurrentGuards$.subscribe((g) => console.log('Guardas: ' + g));
  }

  filterDuplicateAndUndefined<T>(elements: T[]): T[] {
    return [...new Set(elements)].filter((e) => !!e);
  }
}
