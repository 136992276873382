import { StateUtils } from '@spartacus/core';
import { CsTicketCause } from '../contact-form.model';

export const CONTACT_FORM_FEATURE = 'customContactForm';
export const SEND_CONTACT_FORM_PROCESS_ID = 'customSendContactForm';

export interface StateContactForm {
  causes: StateUtils.LoaderState<CsTicketCause[]>;
}

export interface StateWithContactForm {
  [CONTACT_FORM_FEATURE]: StateContactForm;
}
