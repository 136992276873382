import { Injectable } from '@angular/core';
import { EventService, createFrom } from '@spartacus/core';
import { NavigationEvent } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, map, pairwise, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { CjActiveCartService } from 'src/app/cms-components/cart/base/core/facade/active-cart.service';
import { CleanEcommerceEvent } from '../../common/common.events';
import { EcommerceType, ProductItem } from '../../tag-manager-feature.model';
import { NavigateToCheckoutEvent } from './checkout-navigation.events';

@Injectable({ providedIn: 'root' })
export class CheckoutNavigationEventBuilder {
  constructor(
    protected eventService: EventService,
    protected activeCartService: CjActiveCartService,
  ) {
    this.register();
  }

  protected register(): void {
    this.registerNavigateToCheckoutEvents();
  }

  protected registerNavigateToCheckoutEvents(): void {
    this.eventService.register(NavigateToCheckoutEvent, this.registerNavigateToCheckout());
  }

  protected registerNavigateToCheckout(): Observable<NavigateToCheckoutEvent> {
    return this.eventService.get(NavigationEvent).pipe(
      filter((navigationEvent) => navigationEvent.semanticRoute === 'checkoutDeliveryAddress'),
      switchMap(() =>
        this.activeCartService.getActive().pipe(
          filter((cart) => Boolean(cart)),
          take(1),
          tap(() => this.eventService.dispatch(<CleanEcommerceEvent>{ ecommerce: null }, CleanEcommerceEvent)),
          map((cart: any) => {
            return createFrom(NavigateToCheckoutEvent, {
              event: EcommerceType.BEGIN_CHECKOUT,
              ecommerce: {
                items: this.getCartProductItems(cart) || [],
              },
            });
          }),
        ),
      ),
    );
  }

  private getCartProductItems(cart: any): ProductItem[] {
    return cart.entries.map((entry: any): ProductItem => {
      return {
        item_name: entry.product.name || entry.product.code,
        item_id: entry.product.code,
        price: entry.basePrice.value.toString(),
        item_brand: entry.product.brands ? entry.product.brands[0].name : '',
        item_category: entry.product.categories ? entry.product.categories[0]?.name || '' : '',
        item_category2: entry.product.categories ? entry.product.categories[1]?.name || '' : '',
        item_category3: entry.product.categories ? entry.product.categories[2]?.name || '' : '',
        item_variant: entry.unit?.name ? entry.unit.name : '',
        quantity: entry.quantity,
      };
    });
  }
}
