<div class="cx-modal-container cj-guest-register-form-dialog" (esc)="dismissModal('Escape pressed')">
  <!-- Modal Header -->
  <div class="cx-dialog-header modal-header">
    <button type="button" class="close" (click)="dismissModal('Cross click')">
      <span aria-hidden="true">
        <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
      </span>
    </button>
  </div>

  <!-- Modal Body -->
  <div class="cx-dialog-body modal-body">
    <div class="register-guest" *ngIf="data$ | async">
      <h3 class="register-guest__title">{{ 'checkoutOrderConfirmation.createAccount' | cxTranslate }}</h3>
      <p class="register-guest__text" [innerHTML]="'checkoutOrderConfirmation.createAccountForNext' | cxTranslate: { email: email }"></p>

      <form (ngSubmit)="submit()" [formGroup]="guestRegisterForm">
        <div class="form-group">
          <label>
            <input
              required="true"
              class="form-control"
              type="password"
              name="password"
              placeholder="{{ 'register.password.placeholder' | cxTranslate }}"
              formControlName="password"
              [attr.aria-label]="'register.password.placeholder' | cxTranslate"
              cxPasswordVisibilitySwitch
            />
            <cx-form-errors [control]="guestRegisterForm.get('password')"></cx-form-errors>
          </label>
        </div>

        <!-- TODO: Enable option to subscribe to newsletter -->
        <!-- <div class="form-group">
          <label>
            <span class="label-content">{{ 'register.newsletter' | cxTranslate }}</span>
            <input
              class="form-control"
              type="checkbox"
              name="newsletter"
              formControlName="newsletter"
              [attr.aria-label]="'register.newsletter' | cxTranslate"
            />
          </label>
        </div> -->

        <button type="submit" class="btn btn-block btn-primary">
          {{ 'checkoutOrderConfirmation.createAccount' | cxTranslate }}
        </button>
      </form>
    </div>
  </div>
</div>
