import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CartValidationGuard } from '@spartacus/cart/base/core';
import { CartNotEmptyGuard } from '@spartacus/checkout/base/components';
import { CmsConfig, FeaturesConfigModule, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { CardModule, SpinnerModule } from '@spartacus/storefront';
import { CjAddressFormModule } from '../../myaccount/address-book/address-form/address-form.module';
import { CjCheckoutLoginModule } from '../checkout-login/checkout-login.module';
import { CjCheckoutBillingAddressComponent } from './checkout-billing-address.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CjAddressFormModule,
    CardModule,
    SpinnerModule,
    I18nModule,
    FeaturesConfigModule,
    UrlModule,
    FormsModule,
    ReactiveFormsModule,
    CjCheckoutLoginModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutDeliveryAddress: {
          component: () => import('./checkout-billing-address.component').then((m) => m.CjCheckoutBillingAddressComponent),
          guards: [CartNotEmptyGuard, CartValidationGuard],
        },
      },
    }),
  ],
  declarations: [CjCheckoutBillingAddressComponent],
  exports: [CjCheckoutBillingAddressComponent],
})
export class CjCheckoutBillingAddressModule {}
