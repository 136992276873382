import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorModel, GlobalMessageType, HttpErrorModel } from '@spartacus/core';
import { ResetPasswordComponentService } from '@spartacus/user/profile/components';
import { CustomValidators } from 'src/app/shared/utils/validators/custom-validators';

@Injectable()
export class CjResetPasswordComponentService extends ResetPasswordComponentService {
  override form: FormGroup = new FormGroup(
    {
      password: new FormControl('', [Validators.required, CustomValidators.passwordValidator]),
      passwordConfirm: new FormControl('', Validators.required),
    },
    {
      validators: CustomValidators.passwordsMustMatch('password', 'passwordConfirm'),
    },
  );

  protected override onError(error: Error): void {
    this.busy$.next(false);
    ((error as HttpErrorModel)?.details ?? []).forEach((err: ErrorModel) => {
      if (err.type) {
        this.globalMessage.add({ key: `forgottenPassword.${err.type}` }, GlobalMessageType.MSG_TYPE_ERROR);
      }
    });
  }
}
