import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { CjCartPaymentMethodsComponent } from './cart-payment-methods.component';
import { MediaModule } from '@spartacus/storefront';

@NgModule({
  imports: [CommonModule, MediaModule, I18nModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsbornePaymentMethodsCartComponent: {
          component: () =>
            import('./cart-payment-methods.component').then(
              (m) => m.CjCartPaymentMethodsComponent,
            ),
        },
      },
    }),
  ],
  declarations: [CjCartPaymentMethodsComponent],
  exports: [CjCartPaymentMethodsComponent],
})
export class CjCartPaymentMethodsModule {}
