import { Component } from '@angular/core';
import { OrderDetailsService } from '@spartacus/order/components';
import { Observable, map } from 'rxjs';
import { CjOrder } from '../../../order.model';

@Component({
  selector: 'cj-order-confirmation-shipping',
  templateUrl: './order-confirmation-shipping.component.html',
  styleUrl: './order-confirmation-shipping.component.scss',
})
export class CjOrderConfirmationShippingComponent {
  order$?: Observable<CjOrder>;

  constructor(protected orderDetailsService: OrderDetailsService) {}

  ngOnInit() {
    this.order$ = this.orderDetailsService.getOrderDetails().pipe(map((o) => o as CjOrder));
  }
}
