import { Injectable } from '@angular/core';
import { Cart } from '@spartacus/cart/base/root';
import { Observable } from 'rxjs';
import { CjReorderOrderAdapter } from './reorder-order.adapter';

@Injectable()
export class CjReorderOrderConnector {
  constructor(protected adapter: CjReorderOrderAdapter) {}

  public reorder(orderId: string, userId: string): Observable<Cart> {
    return this.adapter.reorder(orderId, userId);
  }
}
