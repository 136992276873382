import { Address, StateUtils, USER_ADDRESSES } from '@spartacus/core';

export const GET_ALL_BASESTORE_ADDRESSESS = '[User] Get All Basestore Addresses';

export const UPDATE_DEFAULT_USER_ADDRESS = '[User] Update Default User Address';
export const UPDATE_DEFAULT_USER_ADDRESS_FAIL = '[User] Update Default User Address Fail';
export const UPDATE_DEFAULT_USER_ADDRESS_SUCCESS = '[User] Update Default User Address Success';

// Get all addresses of basestore
export class GetAllBasestoreAddresses extends StateUtils.LoaderLoadAction {
  override readonly type = GET_ALL_BASESTORE_ADDRESSESS;
  constructor(public payload: { userId: string; basestore: string }) {
    super(USER_ADDRESSES);
  }
}

// Updating address actions
export class UpdateDefaultUserAddress extends StateUtils.LoaderLoadAction {
  override readonly type = UPDATE_DEFAULT_USER_ADDRESS;
  constructor(public payload: { userId: string; addressId: string; address: Address }) {
    super(USER_ADDRESSES);
  }
}

export class UpdateDefaultUserAddressFail extends StateUtils.LoaderFailAction {
  override readonly type = UPDATE_DEFAULT_USER_ADDRESS_FAIL;
  constructor(public payload: any) {
    super(USER_ADDRESSES, payload);
  }
}

export class UpdateDefaultUserAddressSuccess extends StateUtils.LoaderSuccessAction {
  override readonly type = UPDATE_DEFAULT_USER_ADDRESS_SUCCESS;
  constructor(public payload: any) {
    super(USER_ADDRESSES);
  }
}

// action types
export type CjUserAddressesAction =
  | UpdateDefaultUserAddress
  | UpdateDefaultUserAddressFail
  | UpdateDefaultUserAddressSuccess
  | GetAllBasestoreAddresses;
