import { Order } from '@spartacus/order/root';
import { EmarsysCartItem, EmarsysOrder, EmarsysPurchase } from './emarsys.model';
import { CjOrderEntry } from 'src/app/cms-components/order/order.model';
import { OrderEntry } from '@spartacus/cart/base/root';

export function mapAvailabilityZone(store?: string): string {
  switch (store) {
    case 'cincojotasCanarias':
      return 'cjc';
    case 'cincojotasEuropa':
      return 'cjeu';
    case 'cincojotasFrancia':
      return 'cjf';
    default:
      return 'cje';
  }
}

export function getExperienceName(experienceRaw: string) {
  // Remove anything before experiencia- or visitas (In the case of visitas it returns it)
  // Then remove anything after ? or # (like a query or a fragment)
  // Also lowercase the string just in case.
  const experience = experienceRaw
    .toLowerCase()
    .replace(/.*?experiencias?-/g, '')
    .replace(/.*(visitas)/, '$1')
    .replace(/[?#].*/, '');
  // Then replace the - or _ to spaces
  return experience.replace(/[-_]/g, ' ');
}

export function getBrandOrCategoryName(value: string, isCategory: boolean) {
  // Remove anything before nav_ (if category is true) marca_ or brand_
  // and anything after ? or # (like a query or a fragment)
  // Also lowercase the string just in case.
  const regex = isCategory ? /.*?(nav[_-]|marca[_-]|brand[_-])/g : /.*?(marca[_-]|brand[_-])/g;
  const finalValue = value
    .toLowerCase()
    .replace(regex, '')
    .replace(/[?#].*/, '');

  // Replace - _ to spaces
  return finalValue.replace(/[-_]/g, ' ');
}

export function mapPurchase(order: Order): EmarsysPurchase | undefined {
  if (!order?.entries) {
    return;
  }
  return { orderId: order.code, items: mapPurchaseItems(order) };
}

// Return the list of discount codes separated by ;
export function discountList(order: Order): string {
  const discountList: (string | undefined)[] = [];
  order?.appliedOrderPromotions?.forEach((promotion) => {
    discountList.push(promotion.promotion?.code);
  });
  order?.appliedProductPromotions?.forEach((promotion) => {
    discountList.push(promotion.promotion?.code);
  });
  order.appliedVouchers?.forEach((voucher) => {
    discountList.push(voucher.code);
  });

  return discountList.join(';');
}

function mapPurchaseItems(order: EmarsysOrder): EmarsysCartItem[] {
  const cartDiscountPercentage: number = getCartDiscountPercentageForEntry(order);

  const items: any[] = order.entries?.map((entry) => {
    return {
      item: entry?.product?.code ?? '',
      price: getPriceWithDiscounts(entry, cartDiscountPercentage),
      quantity: entry?.quantity,
    };
  }) ?? [];

  // Add delivery cost as a dummy product
  const deliveryItem = {
    item: getDeliveryType(order.deliveryMode?.code),
    price: order.deliveryMode?.deliveryCost?.value,
    quantity: 1,
  }

  // Add giftCard as a dummy product
  if(order.giftCardProduct) {
    const corporateCards = {
      item: order.giftCardProduct.code,
      price: order.giftCardProduct.price?.value,
      quantity: 1,
    }
    items.push(corporateCards);
  }

  items.push(deliveryItem);

  return items;
}

function getCartDiscountPercentageForEntry(order: Order): number {
  const totalCartDiscounts: number = (order?.totalDiscounts?.value ?? 0) - (order?.productDiscounts?.value ?? 0);
  const totalOrderWithoutCartDiscounts: number = (order.subTotal?.value ?? 0) + totalCartDiscounts;
  return (totalCartDiscounts * 100) / totalOrderWithoutCartDiscounts;
}

function getPriceWithDiscounts(entry: OrderEntry, discountPercentage: number = 0): number {
  const entryTotalPrice: number = entry?.totalPrice?.value ?? 0;
  const discountedPrice: number = entryTotalPrice - (entryTotalPrice * (discountPercentage / 100));
  return parseFloat(discountedPrice.toFixed(2));
}

function getDeliveryType(deliveryMode?: string) {
  if (deliveryMode?.includes('express')) {
    return 'GEexpress'
  }
  return 'GEestandar'
}

export function mapCartItems(entries: CjOrderEntry[]): EmarsysCartItem[] {
  return entries.map((entry) => {
    return {
      item: entry?.product?.code ?? '',
      price: entry?.totalPrice?.value ?? 0,
      quantity: entry?.quantity,
    };
  });
}

export function clearQueriesAndFragments(value: string): string {
  return value.replace(/[?#].*$/, '')
}

export function shouldTriggerPurchase(name: string, orderCode: string) {
  const orderCodeLS = localStorage.getItem(name);

  return !orderCodeLS || orderCodeLS !== orderCode;
}
