export const order = {
  orderDetails: {
    actions: {
      cancellationAndReturn: {
        cancelTitle: 'Annuler la commande',
        cancelAction: 'Annuler la commande',
        cancelParagraph:
          'Êtes-vous sûr de vouloir annuler votre commande ? Votre compte sera crédité dans les prochains jours si vous avez payé par carte de crédit.',
        cancellationSuccess: 'La commande {{orderCode}} a été annulée avec succès',
        cancellationFail: "Une erreur s'est produite lors de l'annulation de la commande {{CodeCommande}}",
      },
      modify: {
        modifyTitle: 'Modifier la commande',
        modifyAction: 'Modifier la commande',
        modifyParagraph:
          'Pour modifier la commande, la commande actuelle devra être annulée. En cas de paiement par carte bancaire, le remboursement sera réalisé dans les prochains jours sur votre compte. Toute la commande précédente sera automatiquement chargée pour que vous puissiez la modifier. Êtes-vous d’accord ? ',
        modifySuccess: 'Commande {{orderCode}} restaurée avec succès ',
        modifyFail: 'Une erreur s’est produite lors de la restauration de la commande {{orderCode}} ',
      },
      reorder: {
        reorderTitle: 'Réassortir',
        reorderAction: 'Réassortir',
        reorderParagraph: 'Êtes-vous sûr que vouloir réaliser de nouveau cette commande? ',
        reorderSuccess: 'Produits de la commande {{orderCode}} ajoutés au panier avec succès ',
        reorderFail: 'Une erreur s’est produite en commandant de nouveau la commande {{orderCode}} ',
      },
      downloadInvoice: {
        invoiceAction: 'Télécharger la facture',
      },
    },
    orderInfo: {
      shippingAddress: 'Adresse de livraison',
      shippingMethod: 'Mode de livraison',
      paymentMethod: 'Procédé de paiement',
    },
  },
  orderHistory: {
    orderHistory: 'Mes commandes',
    orderId: 'N° de commande',
    emptyPurchaseOrderId: 'Aucune',
    sendTo: 'Envoyé à',
    date: 'Date de création',
    status: 'Statut',
    total: 'Montant',
    noOrders: "Nous n'avons aucun enregistrement de commande pour ce compte.",
    noReplenishmentOrders: "Nous n'avons aucun enregistrement de commande de réapprovisionnement pour ce compte.",
    startShopping: 'Commencer les achats',
    replenishmentOrderHistory: 'Historique des commandes de réapprovisionnement',
    replenishmentOrderId: 'Réapprovisionnement #',
    purchaseOrderNumber: 'PO #',
    costCenter: 'Centre de coût',
    startOn: 'Commence à',
    frequency: 'Fréquence',
    nextOrderDate: 'Date de la prochaine commande',
    cancel: 'Annulation',
    cancelled: 'Annulé',
    replenishmentHistory: 'Historique des réapprovisionnements',
    notFound: 'Aucune commande trouvée',
    actions: 'Actions',
    noOrdersFound: "Vous n'avez pas de commandes en cours.",
    reorder: 'Répéter la demande',
  },
};
