import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MediaModule, MediaService } from '@spartacus/storefront';
import { CjMediaComponent } from './media.component';
import { CjMediaService } from './media.service';

@NgModule({
  imports: [CommonModule, MediaModule],
  declarations: [CjMediaComponent],
  exports: [CjMediaComponent],
  providers: [{ provide: MediaService, useClass: CjMediaService }],
})
export class CjMediaModule {}
