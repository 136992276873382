import { Country, UserActions } from '@spartacus/core';
import { CjUserActions } from '../actions';
import { CjBaseSiteCountriesState } from '../user-state';

export const initialState: CjBaseSiteCountriesState = {
  entities: {},
};

export function reducer(
  state = initialState,
  action: CjUserActions.CjDeliveryCountriesAction | UserActions.ClearUserMiscsData,
): CjBaseSiteCountriesState {
  switch (action.type) {
    case CjUserActions.LOAD_BASE_SITE_COUNTRIES_SUCCESS: {
      const deliveryCountries = action.payload;
      const entities = deliveryCountries.reduce(
        (countryEntities: { [isocode: string]: Country }, country: Country) => {
          return {
            ...countryEntities,
            [country.isocode ?? '']: country,
          };
        },
        {
          ...state.entities,
        },
      );

      return {
        ...state,
        entities,
      };
    }

    case UserActions.CLEAR_USER_MISCS_DATA: {
      return initialState;
    }
  }

  return state;
}
