import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config, WindowRef, getLastValueSync } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CjBaseStoreService } from 'src/app/cms-components/content/misc/site-context-selector/facades/base-store.service';

@Injectable()
export class CjBaseStoreInterceptor implements HttpInterceptor {
  constructor(
    private readonly winRef: WindowRef,
    private readonly config: Config,
    private readonly baseStoreService: CjBaseStoreService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let newRequest = request;
    if (
      this.config.routing?.routes &&
      [...(this.config.routing?.routes['baseStores']?.paths || []), ...(this.config.routing?.routes['baseStores']?.paths || [])].find(
        (route) => route.includes(request.url),
      )
    ) {
      return next.handle(request);
    }

    const baseStore = getLastValueSync(this.baseStoreService.getActive());
    if (baseStore?.code) {
      const headers = request.headers.set('currentbasestore', baseStore.code);
      newRequest = request.clone({ headers });
    }
    return next.handle(newRequest);
  }
}
