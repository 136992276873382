import { CjProduct } from 'src/app/shared/models/product.model';

export function getProductDetailSelectedUnit(product: CjProduct): string {
  let result: string = '';
  if (product && product.unitPrices?.length) {
    product.unitPrices.map((unit: { price: { value: number | undefined }; unit: { name: string } }) => {
      if (unit.price.value === product?.price?.value) {
        result = unit.unit.name;
      }
    });
  }
  return result;
}
