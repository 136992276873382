import { Injectable } from '@angular/core';
import { StatePersistenceService } from '@spartacus/core';
import { Observable, ReplaySubject } from 'rxjs';
import { CjBaseStoreService } from 'src/app/cms-components/content/misc/site-context-selector/facades/base-store.service';
import { CjBaseStore } from '../../model/misc.mode';
import { BASE_STORE_ID } from './providers/context-ids';

@Injectable({ providedIn: 'root' })
export class BaseStoreStatePersistenceService {
  constructor(
    protected statePersistenceService: StatePersistenceService,
    protected baseStoreService: CjBaseStoreService,
  ) {}

  protected initialized$ = new ReplaySubject<unknown>(1);

  /**
   * Initializes the synchronization of the active language with the local storage.
   *
   * @returns Observable that emits and completes when the value is read from the storage.
   */
  public initSync(): Observable<unknown> {
    this.statePersistenceService.syncWithStorage({
      key: BASE_STORE_ID,
      state$: this.baseStoreService.getActive(),
      onRead: (state) => this.onRead(state),
    });
    return this.initialized$;
  }

  protected onRead(valueFromStorage?: CjBaseStore): void {
    if (!this.baseStoreService.isInitialized() && valueFromStorage) {
      this.baseStoreService.setActive(valueFromStorage);
    }
    if (!this.initialized$.closed) {
      this.initialized$.next(0);
      this.initialized$.complete();
    }
  }
}
