import { Injectable } from '@angular/core';
import { ActiveCartService } from '@spartacus/cart/base/core';
import { User, UserIdService, WindowRef } from '@spartacus/core';
import { Observable, switchMap, take, withLatestFrom } from 'rxjs';
import { CjGiftCard } from 'src/app/cms-components/checkout/checkout-gift-card/model/gift-card.model';
import { CjCart } from '../../models/cart.model';
import { CjMultiCartService } from './multi-cart.service';

@Injectable({
  providedIn: 'root',
})
export class CjActiveCartService extends ActiveCartService {
  constructor(
    protected override multiCartFacade: CjMultiCartService,
    protected override userIdService: UserIdService,
    protected override winRef: WindowRef,
  ) {
    super(multiCartFacade, userIdService, winRef);
  }

  override getActive(): Observable<CjCart> {
    return super.getActive() as Observable<CjCart>;
  }

  /**
   * Set document identifier to customer
   *
   * @param user
   */
  setDocumentIdentifier(user: User): Observable<any> {
    return this.getActiveCartId().pipe(
      withLatestFrom(this.userIdService.getUserId()),
      take(1),
      switchMap(([cartId, userId]) => {
        return this.multiCartFacade.assignDocumentIdentifier(cartId, userId, user);
      }),
    );
  }

  getAvailableGiftCards(): Observable<CjGiftCard[]> {
    return this.activeCartId$.pipe(
      withLatestFrom(this.userIdService.getUserId()),
      switchMap(([cartId, userId]) => this.multiCartFacade.getAvailableGiftCards(userId, cartId)),
    );
  }

  getGiftCard(): Observable<CjGiftCard | undefined> {
    return this.getActiveCartId().pipe(switchMap((cartId) => this.multiCartFacade.getGiftCard(cartId)));
  }
  setGiftCard(code: string, message = ''): void {
    this.getActiveCartId()
      .pipe(withLatestFrom(this.userIdService.getUserId()))
      .subscribe(([cartId, userId]) => this.multiCartFacade.setGiftCard(userId, cartId, code, message));
  }
  removeGiftCard(): void {
    this.getActiveCartId()
      .pipe(withLatestFrom(this.userIdService.getUserId()))
      .subscribe(([cartId, userId]) => this.multiCartFacade.removeGiftCard(userId, cartId));
  }
}
