<div class="cj-product-healthy-dialog">
  <div *ngIf="data$ | async as data" class="cx-modal-container" (esc)="dismissModal('Escape pressed')">
    <div class="cx-modal-content">
      <!-- Modal Header -->
      <div class="cx-dialog-header modal-header">
        <div *ngIf="data.title" class="cx-dialog-title modal-title">
          {{ data.title }}
        </div>
        <button type="button" class="close" (click)="dismissModal('Cross click')">
          <span aria-hidden="true">
            <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
          </span>
        </button>
      </div>

      <!-- Modal Body -->
      <div class="cx-dialog-body modal-body">
        <div *ngIf="items$ | async as items" class="cj-product-healthy-dialog__list">
          <div *ngFor="let item$ of items" class="cj-product-healthy-dialog__item">
            <ng-container *ngIf="item$ | async as item">
              <p *ngIf="item.title" class="cj-product-healthy-dialog__text">
                {{ item.title }}
              </p>
              <p *ngIf="item.firstDescription" class="cj-product-healthy-dialog__data">
                {{ item.firstDescription }}
                <span *ngIf="item.secondDescription">
                  {{ item.secondDescription }}
                </span>
              </p>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
