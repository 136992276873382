import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { CjOrderDetailCancelDialogComponent } from './order-detail-cancel-dialog.component';

export const ORDER_DETAIL_CANCEL_DIALOG = 'ORDER_DETAIL_CANCEL';

export const cjOrderDetailCancelLayoutConfig: LayoutConfig = {
  launch: {
    ORDER_DETAIL_CANCEL: {
      inlineRoot: true,
      component: CjOrderDetailCancelDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};
