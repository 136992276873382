import { ChangeDetectionStrategy, Component, Optional } from '@angular/core';
import { PromotionLocation } from '@spartacus/cart/base/root';
import { OrderConfirmationItemsComponent } from '@spartacus/order/components';
import { Order, OrderFacade, OrderHistoryFacade } from '@spartacus/order/root';
import { OutletContextData } from '@spartacus/storefront';
import { Observable, map } from 'rxjs';
import { CjCartItemDisplayType } from 'src/app/cms-components/cart/base/components/cart-shared/cart.model';

@Component({
  selector: 'cj-order-confirmation-items',
  templateUrl: './order-confirmation-items.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjOrderConfirmationItemsComponent extends OrderConfirmationItemsComponent {
  override order$: Observable<Order> = this.orderHistoryFacade.getOrderDetails();
  promotionLocation$ = this.outlet?.context$.pipe(
    map((context) => (context.promotionLocation !== undefined ? context.promotionLocation : PromotionLocation.ActiveCart)),
  );
  cartItemDisplayTypes = CjCartItemDisplayType;

  constructor(
    protected override orderFacade: OrderFacade,
    private readonly orderHistoryFacade: OrderHistoryFacade,
    @Optional() private readonly outlet?: OutletContextData<any>,
  ) {
    super(orderFacade);
  }

  override ngOnDestroy(): void {
    this.orderHistoryFacade.clearOrderDetails();
  }
}
