import { NgModule } from '@angular/core';
import { CjCheckoutBillingAddressModule } from './checkout-billing-address/checkout-billing-address.module';
import { CjCheckoutDeliveryAddressModule } from './checkout-delivery-address/checkout-delivery-address.module';
import { CjCheckoutDeliveryModeModule } from './checkout-delivery-mode/checkout-delivery-mode.module';
import { CjCheckoutOrchestratorModule } from './checkout-orchestrator/checkout-orchestrator.module';
import { CjCheckoutPaymentTypeModule } from './checkout-payment-type/checkout-payment-type.module';
import { CjCheckoutPlaceOrderModule } from './checkout-place-order/checkout-place-order.module';
import { CjCheckoutProgressMobileTopModule } from './checkout-progress/checkout-progress-mobile-top/checkout-progress-mobile-top.module';
import { CjCheckoutGuardsModule } from './guards/checkout-guards.module';

@NgModule({
  imports: [
    CjCheckoutOrchestratorModule,
    CjCheckoutDeliveryAddressModule,
    CjCheckoutDeliveryModeModule,
    CjCheckoutBillingAddressModule,
    CjCheckoutPaymentTypeModule,
    CjCheckoutPlaceOrderModule,
    CjCheckoutProgressMobileTopModule,

    CjCheckoutGuardsModule
  ],
})
export class CjCheckoutComponentsModule {}
