import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CjProductGridCategoryState,
  CjProductGridElements,
  CjProductGridState,
  PRODUCT_GRID_FEATURE,
  StateWithProductGrid,
} from '../product-grid-state';

export const getProductGridState: MemoizedSelector<StateWithProductGrid, CjProductGridState> =
  createFeatureSelector<CjProductGridState>(PRODUCT_GRID_FEATURE);

export const getProductGridCategory = (category: string): MemoizedSelector<StateWithProductGrid, CjProductGridCategoryState> =>
  createSelector(getProductGridState, (state: CjProductGridState) => state[category] || {});

export const getFilterProductList = (category: string): MemoizedSelector<StateWithProductGrid, string[]> =>
  createSelector(getProductGridCategory(category), (state: CjProductGridCategoryState) => state.filteredProductsList || []);

export const getOneColumnBannerList = (category: string): MemoizedSelector<StateWithProductGrid, string[]> =>
  createSelector(getProductGridCategory(category), (state: CjProductGridCategoryState) => (state.oneColumnBannerList || '').split(' '));

export const getThreeColumnBannerList = (category: string): MemoizedSelector<StateWithProductGrid, string[]> =>
  createSelector(getProductGridCategory(category), (state: CjProductGridCategoryState) => (state.threeColumnBannerList || '').split(' '));

export const getElements = (category: string): MemoizedSelector<StateWithProductGrid, CjProductGridElements> =>
  createSelector(
    getProductGridCategory(category),
    (state: CjProductGridCategoryState) => state.elements || productGridCategoryInitialState,
  );
export const getLastPage = (category: string): MemoizedSelector<StateWithProductGrid, number> =>
  createSelector(getProductGridCategory(category), (state: CjProductGridCategoryState) => state.elements?.lastPage || 0);

const productGridCategoryInitialState: CjProductGridElements = {
  lastPage: undefined,
  nextRow: 0,
  nextOneColumnBanner: 0,
  nextThreeColumnBanner: 0,
  lastIteration: 1,
  extraRowProducts: 0,
  entities: [],
};
