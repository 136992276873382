import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CjFilteredProductsService } from '../facade/filtered-products.service';
import { CjProductGridService } from '../facade/product-grid.service';

/**
 * Extract product codes from the components of the current catagoryPage and save them as filtered products.
 * Only extracts the products from the defined component types
 */
@Injectable({
  providedIn: 'root',
})
export class CjFilteredProductsGuard {
  constructor(
    private readonly productGridService: CjProductGridService,
    private readonly filteredProductsService: CjFilteredProductsService,
  ) {}

  canActivate(activatedRoute: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const componentTypes = ['OsborneProductBannerComponent', 'OsborneProductTwoColumnsComponent', 'OsborneTwoProductComponent'];
    const categoryCode = activatedRoute.params['categoryCode'];

    return this.filteredProductsService.getFilteredProductsFromCategoryPage(categoryCode, componentTypes).pipe(
      tap((products) => this.productGridService.addProductToFilter(categoryCode, ...products)),
      map(() => true),
    );
  }
}
