import { Action } from '@ngrx/store';
import { CjBaseStore } from 'src/app/core/model/misc.mode';

export const LOAD_BASE_STORES = '[Base Store] Load Base Stores';
export const LOAD_BASE_STORES_FAIL = '[Base Store] Load Base Stores Fail';
export const LOAD_BASE_STORES_SUCCESS = '[Base Store] Load Base Stores Success';
export const CLEAR_BASE_STORES = '[Base Store] Clear Base Stores';
export const SET_ACTIVE_BASE_STORE = '[Base Store] Set active Base Store';
export const CLEAR_ACTIVE_BASE_STORE = '[Base Store] Clear active Base Store';

export class LoadBaseStores implements Action {
  readonly type = LOAD_BASE_STORES;
  constructor(public payload: { userId: string }) {}
}

export class LoadBaseStoresFail implements Action {
  readonly type = LOAD_BASE_STORES_FAIL;
  constructor(public payload: any) {}
}

export class LoadBaseStoresSuccess implements Action {
  readonly type = LOAD_BASE_STORES_SUCCESS;
  constructor(public payload: CjBaseStore[]) {}
}

export class ClearBaseStores implements Action {
  readonly type = CLEAR_BASE_STORES;
}

export class SetActiveBaseStore implements Action {
  readonly type = SET_ACTIVE_BASE_STORE;
  constructor(public payload: CjBaseStore) {}
}

export class ClearActiveBaseStore implements Action {
  readonly type = CLEAR_ACTIVE_BASE_STORE;
}

export type CjBaseStoreAction =
  | LoadBaseStores
  | LoadBaseStoresFail
  | LoadBaseStoresSuccess
  | ClearBaseStores
  | SetActiveBaseStore
  | ClearActiveBaseStore;
