import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { CsTicketCause } from '../../contact-form.model';
import { ContactFormActions } from '../actions';
import { CONTACT_FORM } from '../actions/contact-form.action';
import { StateContactForm } from '../contact-form-state';

export const initialState: CsTicketCause[] = [];

export function reducer(state = initialState, action: any): CsTicketCause[] {
  switch (action.type) {
    case ContactFormActions.LOAD_CONTACT_FORM_SUCCESS: {
      return action.payload.causes ? action.payload.causes : initialState;
    }
    case ContactFormActions.LOAD_CONTACT_FORM_FAIL: {
      return initialState;
    }
  }

  return state;
}

export function getReducers(): ActionReducerMap<StateContactForm> {
  return {
    causes: StateUtils.loaderReducer<CsTicketCause[]>(CONTACT_FORM, reducer),
  };
}

export const reducerContactFormToken: InjectionToken<ActionReducerMap<StateContactForm>> = new InjectionToken<
  ActionReducerMap<StateContactForm>
>('ContactFormReducers');

export const reducerContactFormProvider = {
  provide: reducerContactFormToken,
  useFactory: getReducers,
};
