import { PaymentDetails, StateUtils } from '@spartacus/core';

export const REDSYS_FEATURE = 'redsys';

export interface StateWithRedsys {
  [REDSYS_FEATURE]: RedsysState;
}

export interface RedsysState {
  cards: StateUtils.LoaderState<PaymentDetails[]>;
}
