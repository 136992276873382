import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard, CmsConfig, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { FormErrorsModule, NgSelectA11yModule, SpinnerModule } from '@spartacus/storefront';
import { CjUpdateProfileComponent } from './update-profile.component';
import { UpdateProfileComponentService } from '@spartacus/user/profile/components';
import { CjUpdateProfileComponentService } from './update-profile-component.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SpinnerModule,
    I18nModule,
    FormErrorsModule,
    RouterModule,
    UrlModule,
    NgSelectModule,
    NgSelectA11yModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        UpdateProfileComponent: {
          component: () => import('./update-profile.component').then((m) => m.CjUpdateProfileComponent),
          guards: [AuthGuard],
          providers: [
            {
              provide: CjUpdateProfileComponentService,
            },
          ],
        },
      },
    }),
  ],
  declarations: [CjUpdateProfileComponent],
})
export class CjUpdateProfileModule {}
