<ng-container *ngIf="container">
  <ng-container *ngIf="isVideo(container); else imageTemplate">
    <video
      #videoPlayer
      *ngIf="media && media.src"
      [attr.alt]="media.alt"
      loop
      playsinline
      [muted]="muted"
    >
      <source [attr.src]="media.src" />
    </video>
  </ng-container>

  <ng-template #imageTemplate>
    <img
      *ngIf="media && media.src"
      [attr.src]="media.src"
      [attr.srcset]="media.srcset"
      [attr.alt]="media.alt"
      [attr.role]="media.role"
      [attr.loading]="loading"
      (load)="loadHandler()"
      (error)="errorHandler()"
    />
  </ng-template>
</ng-container>
