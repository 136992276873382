import { Cart } from '@spartacus/cart/base/root';
import { Observable } from 'rxjs';

export abstract class CjModifyOrderAdapter {
  /**
   * Abstract method used to modify an order.
   *
   * @param orderId The `orderId` of an existing order to update the cart
   * @param userId The `userId` for given user
   */
  abstract modify(orderId: string, userId: string): Observable<Cart>;
}
