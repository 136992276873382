<div class="cx-modal-container cj-site-context-selector-dialog" (esc)="dismissModal('Escape pressed')">
  <div class="cx-modal-content">
    <!-- Modal Header -->
    <div class="cx-dialog-header modal-header">
      <div class="cx-dialog-title modal-title">{{ 'contextSelector.title' | cxTranslate }}</div>
      <button
        type="button"
        class="close"
        attr.aria-label="{{ 'addToCart.closeModal' | cxTranslate }}"
        (click)="dismissModal('Cross click')"
      >
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </button>
    </div>

    <!-- Modal Body -->
    <div class="cx-dialog-body modal-body">
      <form (ngSubmit)="onSubmit()" [formGroup]="siteContextForm">
        <div class="form-group">
          <label>
            {{ 'contextSelector.country' | cxTranslate }}
            <ng-select
              [searchable]="false"
              [clearable]="false"
              [items]="availableBaseStores$ | async"
              bindLabel="name"
              bindValue="code"
              [placeholder]="'contextSelector.placeholders.countries' | cxTranslate"
              formControlName="baseStore"
              (change)="selectBaseStore()"
            >
              <ng-template ng-option-tmp ng-label-tmp let-item="item">
                <div class="d-flex align-items-center">
                  <div *ngIf="item.flag" class="mr-2 country-flag"><cx-media [container]="item.flag"></cx-media></div>
                  <div>{{ item.name }}</div>
                </div>
              </ng-template>
            </ng-select>
          </label>
        </div>
        <div class="form-group">
          <label>
            {{ 'contextSelector.language' | cxTranslate }}
            <ng-select
              [searchable]="false"
              [clearable]="false"
              [items]="availableLanguages$ | async"
              bindLabel="name"
              bindValue="isocode"
              [placeholder]="'contextSelector.placeholders.languages' | cxTranslate"
              formControlName="language"
            >
            </ng-select>
          </label>
        </div>

        <button class="btn btn-primary" type="submit">{{ 'common.continue' | cxTranslate }}</button>
      </form>
    </div>
  </div>
</div>
