import { address } from './address.i18n';
import { cart } from './cart.i18n';
import { checkout } from './checkout.i18n';
import { common } from './common.i18n';
import { myAccount } from './my-account.i18n';
import { order } from './order.i18n';
import { payment } from './payment';
import { product } from './product.i18n';
import { userAccount } from './user-account.i18n';
import { userProfile } from './user-profile.i18n';
import { user } from './user.i18n';

export const es = {
  address,
  cart,
  checkout,
  common,
  product,
  userAccount,
  userProfile,
  user,
  order,
  myAccount,
  payment,
};
