import { Injectable } from '@angular/core';
import { CurrentProductService, SchemaBuilder } from '@spartacus/storefront';
import { Observable, map } from 'rxjs';
import { CjProduct } from 'src/app/shared/models/product.model';
import { CjStructuredDataUtilsService } from '../../structured-data-utils-service';

@Injectable({
  providedIn: 'root',
})
export class CjProductImageSchemaBuilder implements SchemaBuilder {
  product?: CjProduct;
  getFullProductUrl(append: string = '') {
    return this.utils.getFullUrl('', this.product?.url) + append;
  }

  constructor(
    private readonly currentProduct: CurrentProductService,
    private readonly utils: CjStructuredDataUtilsService,
  ) {}

  build(): Observable<any> {
    // Using currentProductService ensures we are in a ProductPage
    return this.currentProduct.getProduct().pipe(
      map((p: CjProduct | null) => {
        const imageUrl = (<any>p?.images?.['PRIMARY'])?.zoom?.url;
        if (p && imageUrl) {
          this.product = p;
          return {
            '@type': 'ImageObject',
            '@id': this.getFullProductUrl('#primaryimage'),
            url: this.utils.getFullMediaUrl(imageUrl),
          };
        }
        return {};
      }),
    );
  }
}
