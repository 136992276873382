<ng-container *ngFor="let item of items">
  <div class="cj-cart-item-list">
    <ng-container *ngIf="getControl(item) | async as control" [class.is-changed]="control.get('quantity')?.disabled">
      <ng-container *ngIf="control.get('quantity') as qty">
        <cj-cart-item
          role="row"
          class="cx-item-list-row"
          [item]="item"
          [quantityControl]="$any(qty)"
          [readonly]="readonly"
          [promotionLocation]="promotionLocation"
          [options]="options"
          [displayType]="displayType"
        ></cj-cart-item>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
