import { Component, ViewChild } from '@angular/core';
import { Address } from '@spartacus/core';
import { ICON_TYPE, LaunchDialogService } from '@spartacus/storefront';
import { CjAddressFormComponent } from '../address-form.component';

@Component({
  selector: 'cj-address-form-dialog',
  templateUrl: './address-form-dialog.component.html',
  styleUrl: './address-form-dialog.component.scss',
})
export class CjAddressFormDialogComponent {
  iconTypes = ICON_TYPE;
  @ViewChild('addressForm') addressForm?: CjAddressFormComponent;

  constructor(private readonly dialogService: LaunchDialogService) {}

  forceSubmit() {
    this.addressForm?.verifyAddress();
  }

  dismissModal(data: Address | string | undefined) {
    this.dialogService.closeDialog(data);
  }
}
