import { Injectable } from '@angular/core';
import { Occ, Product, ProductImageNormalizer } from '@spartacus/core';
import { CjProduct } from 'src/app/shared/models/product.model';

@Injectable({ providedIn: 'root' })
export class CjProductImageNormalizer extends ProductImageNormalizer {
  override convert(source: Occ.Product, target?: CjProduct): CjProduct {
    if (target === undefined) {
      target = { ...(source as any) } as Product;
    }
    if (source.images) {
      target.images = this.normalize(this.normalizeFormatName(source.images));

      if (target.images['PRIMARY']) {
        target.images['PRIMARY_SQUARE'] = target.images['PRIMARY'];
      }
      if (target.images['GALLERY']) {
        target.images['GALLERY_SQUARE'] = target.images['GALLERY'];
      }
      if (target.images['RECTANGULAR']) {
        target.images['PRIMARY'] = target.images['RECTANGULAR'];
        delete target.images['RECTANGULAR'];
      }
      if (target.images['RECTANGULAR_GALLERY']) {
        target.images['GALLERY'] = target.images['RECTANGULAR_GALLERY'];
        delete target.images['RECTANGULAR_GALLERY'];
      }
    }
    return target;
  }

  private normalizeFormatName(images: Occ.Image[]): Occ.Image[] {
    return images.map((image) => {
      image.format = image.format?.replace(/(.*)Rec$/, '$1');
      return image;
    });
  }
}
