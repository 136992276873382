import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsParagraphComponent, CmsService } from '@spartacus/core';
import { ICON_TYPE, LaunchDialogService } from '@spartacus/storefront';
import { Observable, filter, switchMap } from 'rxjs';
import { CjCmsPopupComponent } from '../popup.model';
@Component({
  selector: 'cj-popup-dialog',
  templateUrl: './popup-dialog.component.html',
  styleUrls: ['./popup-dialog.component.html'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjPopupDialogComponent {
  data$: Observable<CjCmsPopupComponent> = this.dialogService.data$;
  textComponent$: Observable<CmsParagraphComponent> = this.dialogService.data$.pipe(
    filter((data) => !!data.component),
    switchMap((data) => this.cmsService.getComponentData<CmsParagraphComponent>(data.component)),
  );
  iconTypes = ICON_TYPE;

  constructor(
    private readonly dialogService: LaunchDialogService,
    private readonly cmsService: CmsService,
  ) {}

  dismissModal(reason: string) {
    this.dialogService.closeDialog(reason);
  }
}
