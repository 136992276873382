export const product = {
  productDetail: {
    common: {
      showMore: 'Ver más',
      showLess: 'Ver menos',
    },
    specification: {
      slider: {
        title: 'Tan delicioso como saludable',
      }
    }
  },
  productCard: {
    stock: {
      inStock: 'Disponible',
      lowStock: 'Últimas unidades disponibles',
      lowStock_other: 'Últimas unidades disponibles',
      outOfStock: 'No disponible',
    },
  },
  addToCart: {
    title: 'Cesta',
    subtitle: {
      products: 'Tus productos guardados en la cesta.',
      empty: 'No tienes ningún artículo en tu carrito.',
    },
    checkoutButton: 'Finalizar pedido',
    cartButton: 'Ver y editar carrito',
    total: 'Total estimado: ',
    stock: {
      lowStock: 'Últimas unidades disponibles',
      outOfStock: 'No disponible',
    },
    quantity: 'Cantidad: ',
    addToCart: 'Añadir a la cesta',
    buyItAgain: 'Cómpralo de nuevo',
    notifyMe: 'Avisadme cuando esté disponible',
    inStock: 'Disponible',
    lowStock: 'Últimas unidades disponibles',
    lowStock_other: 'Últimas unidades disponibles',
    outOfStock: 'No disponible',
  },
  cartItems: {
    itemRemoved: 'El artículo seleccionado ha sido eliminado. Se ha actualizado el total del carrito',
  },
  webAvailability: {
    WEB_NOT_AVAILABLE: 'No disponible',
    WEB_AVAILABLE: 'Disponible',
    WEB_LAST_UNITS: 'Últimas unidades disponibles',
    WEB_ONLINE_ONLY: 'Solo online',
    WEB_TEMPORARILY_OUT_OF_STOCK: 'Agotado temporalmente',
  },
  stockNotification: {
    notifyMe: 'NOTIFÍCAME',
    stopNotify: 'CANCELAR NOTIFICACIÓN',
    getNotify: 'Recibe una notificación cuando este producto esté disponible.',
    getNotifySuffix: 'para recibir una notificación cuando este producto esté disponible.',
    activateChannelsPrefix: 'Para recibir notificaciones debes activar los ',
    channelsLink: 'Canales de Notificación',
    activateChannelsSuffix: '.',
    notified: 'Serás notificado cuando este producto esté disponible de nuevo.',
    getNotified: 'Recibe una notificación cuando este producto esté disponible de nuevo.',
    unsubscribeSuccess: 'Ya no recibirás una notificación de disponibilidad para este producto.',
    subscriptionDialog: {
      header: 'Suscripción fuera de stock',
      notifiedPrefix: 'Se le notificará en:',
      notifiedSuffix: 'tan pronto como este producto vuelva a estar disponible.',
      manageChannelsPrefix: 'Gestione sus canales de notificación preferidos en ',
      manageChannelsLink: 'Preferencias de notificación',
      manageChannelsSuffix: ' de notificaciones.',
      manageSubscriptionsPrefix: 'Puede gestionar sus suscripciones en ',
      manageSubscriptionsLink: 'Mis intereses',
      manageSubscriptionsSuffix: ' página.',
      okBtn: 'OK',
      subscribing: 'Suscripción a las notificaciones de disponiblidad de existencias de este producto',
    },
  },
};
