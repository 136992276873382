import { Country, CountryType } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CjBaseStore } from '../../model/misc.mode';

export abstract class CjBaseStoreAdapter {
  /**
   * Abstract method used to load site base stores
   */
  abstract getBaseStores(userId: string): Observable<CjBaseStore[]>;

  /**
   * Abstract method used to get base store countries with optional type.
   */
  abstract loadBaseStoreCountries(type?: CountryType): Observable<Country[]>;
}
