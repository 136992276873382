import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { IconModule, MediaModule, PageComponentModule } from '@spartacus/storefront';
import { CjWarrantyBannerDialogComponent } from './warranty-banner-dialog/warranty-banner-dialog.component';
import { cjWarrantyBanneLayoutConfig } from './warranty-banner-dialog/warranty-banner-dialog.config';
import { CjWarrantyBannerComponent } from './warranty-banner.component';

@NgModule({
  imports: [CommonModule, PageComponentModule, MediaModule, IconModule, I18nModule],
  providers: [
    provideConfig(cjWarrantyBanneLayoutConfig),
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsborneWarrantyComponent: {
          component: () => import('./warranty-banner.component').then((m) => m.CjWarrantyBannerComponent),
        },
      },
    }),
  ],
  declarations: [CjWarrantyBannerComponent, CjWarrantyBannerDialogComponent],
  exports: [CjWarrantyBannerComponent, CjWarrantyBannerDialogComponent],
})
export class CjWarrantyBannerModule {}
