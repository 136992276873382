import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StateConfig, StateTransferType, provideConfigFactory } from '@spartacus/core';
import { CjRedsysEffect } from './effects/redsys.effect';
import { REDSYS_FEATURE } from './redsys-state';
import { cjRedsysReducerProvider, cjRedsysReducerToken } from './reducers/redsys.reducer';

export function productStoreConfigFactory(): StateConfig {
  // if we want to reuse REDSYS_FEATURE const in config, we have to use factory instead of plain object
  const config: StateConfig = {
    state: {
      ssrTransfer: {
        keys: { [REDSYS_FEATURE]: StateTransferType.TRANSFER_STATE },
      },
    },
  };
  return config;
}

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(REDSYS_FEATURE, cjRedsysReducerToken), EffectsModule.forFeature([CjRedsysEffect])],
  providers: [provideConfigFactory(productStoreConfigFactory), cjRedsysReducerProvider],
})
export class CjRedsysStoreModule {}
