/* eslint-disable @typescript-eslint/no-explicit-any */
import { InjectionToken, Provider } from '@angular/core';
import { ActionReducer } from '@ngrx/store';
import { CjProductGridState } from '../product-grid-state';
import * as fromProductGrid from './product-grid.reducer';

export function getReducers(): ActionReducer<CjProductGridState, any> {
  return fromProductGrid.reducer;
}

export const reducerToken: InjectionToken<ActionReducer<CjProductGridState>> = new InjectionToken<ActionReducer<CjProductGridState>>(
  'ProductGridReducers',
);

export const reducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getReducers,
};
