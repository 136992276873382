import { Component } from '@angular/core';
import { ICON_TYPE, LaunchDialogService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CjCmsUtilsService } from 'src/app/shared/utils/cms-utils.service';
import { CjCmsProductHealthyItemCardComponent, CjCmsProductHealthyItemModalComponent } from '../product-healthy-slider.model';

@Component({
  selector: 'cj-product-healthy-dialog',
  templateUrl: './product-healthy-dialog.component.html',
  styleUrls: ['./product-healthy-dialog.component.scss'],
})
export class CjProductHealthyDialogComponent {
  iconTypes = ICON_TYPE;

  data$: Observable<CjCmsProductHealthyItemModalComponent> = this.launchDialogService.data$;

  constructor(
    private readonly launchDialogService: LaunchDialogService,
    protected cmsUtils: CjCmsUtilsService,
  ) {}

  items$: Observable<Observable<CjCmsProductHealthyItemCardComponent>[]> = this.data$.pipe(
    map((data) => data.items),
    filter((items) => !!items),
    map((items) => {
      if (!items) {
        return [];
      }
      return this.cmsUtils.getComponentDataList<CjCmsProductHealthyItemCardComponent>(items);
    }),
  );

  dismissModal(reason: string) {
    this.launchDialogService.closeDialog(reason);
  }
}
