import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthGuard, CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { CjLogoutComponent } from './logout.component';

@NgModule({
  declarations: [CjLogoutComponent],
  imports: [CommonModule, I18nModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        MyAccountLogoutComponent: {
          component: CjLogoutComponent,
        },
      },
    }),
  ],
})
export class CjLogoutModule {}
