/**
 * Base cart event. Most cart events should have these properties.
 */
import { CartEvent } from '@spartacus/cart/base/root';
import { CxEvent } from '@spartacus/core';
import { EcommerceAddProduct, EcommerceRemoveProduct, EcommerceViewCart } from './cart-events.model';

export class CartAddEntrySuccessEvent extends CxEvent {
  /**
   * Event's type
   */
  static override readonly type = 'CartAddEntryEvent';
  event!: string;
  ecommerce!: EcommerceAddProduct;
}

export class CartRemoveEntrySuccessEvent extends CxEvent {
  /**
   * Event's type
   */
  static override readonly type = 'CartRemoveEntrySuccessEvent';
  event!: string;
  ecommerce!: EcommerceRemoveProduct;
}

export class CartUpdateEntrySuccessEvent extends CxEvent {
  /**
   * Event's type
   */
  static override readonly type = 'CartUpdateEntrySuccessEvent';
  event!: string;
  ecommerce!: null | EcommerceAddProduct | EcommerceRemoveProduct;
}

export class CustomDeleteCartSuccessEvent extends CartEvent {
  /**
   * Event's type
   */
  static override readonly type = 'CustomDeleteCartSuccessEvent';
}

export class CustomDeleteCartFailEvent extends CartEvent {
  /**
   * Event's type
   */
  static override readonly type = 'CustomDeleteCartFailEvent';
}

export class CustomSetDocumentIdentifierSuccessEvent extends CxEvent {
  /**
   * Event's type
   */
  static override readonly type = 'CustomSetDocumentIdentifierSuccessEvent';
}

export class CustomSetDocumentIdentifierFailEvent extends CxEvent {
  /**
   * Event's type
   */
  static override readonly type = 'CustomSetDocumentIdentifierFailEvent';
}

export class CustomLoadCartSuccessEvent extends CartEvent {
  /**
   * Event's type
   */
  static override readonly type = 'CustomLoadCartSuccessEvent';
}

export class CustomLoadCartFailEvent extends CartEvent {
  /**
   * Event's type
   */
  static override readonly type = 'CustomLoadCartFailEvent';
}

export class NavigateToCartEvent extends CxEvent {
  static override readonly type = 'NavigateToCartEvent';
  event!: string;
  ecommerce!: EcommerceViewCart;
}
