import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { GenericLinkModule, MediaModule } from '@spartacus/storefront';
import { CjGsapAnimationDirectiveModule } from 'src/app/shared/directives/directives.module';
import { CjProductCardComponent } from './product-card.component';
import { CjProductCardWrapperComponent } from './wrapper/product-card-wrapper.component';
import { CjMediaModule } from 'src/app/shared/components/media/media.module';

@NgModule({
  imports: [CommonModule, I18nModule, MediaModule, GenericLinkModule, RouterModule, UrlModule, CjGsapAnimationDirectiveModule, CjMediaModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsborneProductCardComponent: {
          component: () => import('./wrapper/product-card-wrapper.component').then((m) => m.CjProductCardWrapperComponent),
        },
      },
    }),
  ],
  declarations: [CjProductCardComponent, CjProductCardWrapperComponent],
  exports: [CjProductCardComponent, CjProductCardWrapperComponent],
})
export class CjProductCardModule {}
