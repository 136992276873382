import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { MediaModule, PageComponentModule } from '@spartacus/storefront';
import { CjExperiencesCardSliderComponent } from './experiences-card-slider.component';

@NgModule({
  imports: [CommonModule, PageComponentModule, MediaModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsborneExperienceCardSliderComponent: {
          component: () =>
            import('./experiences-card-slider.component').then(
              (m) => m.CjExperiencesCardSliderComponent,
            ),
        },
      },
    }),
  ],
  declarations: [CjExperiencesCardSliderComponent],
  exports: [CjExperiencesCardSliderComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CjExperiencesCardSliderModule {}
