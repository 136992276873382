import { Injectable } from '@angular/core';
import { Country, CountryType } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CjBaseStoreAdapter } from './base-store.adapter';
import { CjBaseStore } from '../../model/misc.mode';

@Injectable({
  providedIn: 'root',
})
export class CjBaseStoreConnector {
  constructor(protected adapter: CjBaseStoreAdapter) {}

  public getBaseStores(userId: string): Observable<CjBaseStore[]> {
    return this.adapter.getBaseStores(userId);
  }

  getBaseStoreCountries(type?: CountryType): Observable<Country[]> {
    return this.adapter.loadBaseStoreCountries(type);
  }
}
