import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { createFrom, EventService, Product, ProductActions } from '@spartacus/core';
import { CategoryPageResultsEvent, PageEvent } from '@spartacus/storefront';
import { Observable, zip } from 'rxjs';
import { distinctUntilChanged, map, pairwise, startWith, tap, withLatestFrom } from 'rxjs/operators';
import { CjProduct } from 'src/app/shared/models/product.model';
import { CleanEcommerceEvent } from '../common/common.events';
import { EcommerceType } from '../tag-manager-feature.model';
import { CategoryPageEvent } from './category-page.events';
import { ProductListItem } from './category-page.model';

@Injectable({
  providedIn: 'root',
})
export class CategoryPageEventBuilder {
  constructor(
    protected eventService: EventService,
    protected actionsSubject: ActionsSubject,
  ) {
    this.register();
  }

  register() {
    this.registerNavigateToCategoryPageEvents();
  }

  protected registerNavigateToCategoryPageEvents(): void {
    this.eventService.register(CategoryPageEvent, this.registerNavigateToCategoryPage());
  }

  protected registerNavigateToCategoryPage(): Observable<CategoryPageEvent> {
    return zip(
      this.eventService
        .get(CategoryPageResultsEvent)
        .pipe(withLatestFrom(this.eventService.get(PageEvent).pipe(startWith(<PageEvent>(<unknown>null)), pairwise()))),
      this.actionsSubject.pipe(
        ofType(ProductActions.SEARCH_PRODUCTS_SUCCESS),
        map(<ProductSearchPage>(action: { payload: any }) => action.payload),
      ),
    ).pipe(
      distinctUntilChanged(([[prevPage], prevSearch], [[currPage, [prevNav, currNav]], currSearch]) => {
        return (
          prevPage.categoryCode === currPage.categoryCode &&
          prevNav.navigation.semanticRoute === currNav.navigation.semanticRoute &&
          prevSearch.pagination.currentPage === currSearch.pagination.currentPage
        );
      }),
      tap(() => this.eventService.dispatch(<CleanEcommerceEvent>{ ecommerce: null }, CleanEcommerceEvent)),
      map(([[page], search]) =>
        createFrom(CategoryPageEvent, {
          event: EcommerceType.VIEW_ITEM_LIST,
          ecommerce: {
            items: this.getEcommerceItemsList(search.products, page.categoryName!),
          },
        }),
      ),
    );
  }

  private getEcommerceItemsList(products: Product[], categoryName: string): ProductListItem[] {
    return products.map((product: CjProduct, index: number): ProductListItem => {
      return {
        item_id: product.code!,
        item_name: product.name! || product.code!,
        item_brand: product.brands ? product.brands[0].name : '',
        price: product.price?.value?.toString() || '',
        item_category: product.categories ? product.categories[0]?.name || '' : '',
        item_category2: product.categories ? product.categories[1]?.name || '' : '',
        item_category3: product.categories ? product.categories[2]?.name || '' : '',
        item_variant: this.getItemListVariants(product),
        item_list_name: categoryName || '',
        index: index + 1,
        quantity: 1,
      };
    });
  }

  private getItemListVariants(product: CjProduct): string {
    let result: string = '';
    product.unitPrices?.map((unit: { unit: { name: string } }, index: number) => {
      if (index > 0) {
        result += `,${unit.unit.name}`;
      } else {
        result = unit.unit.name;
      }
    });

    return result;
  }
}
