<div class="cx-order-items container" *ngIf="order$ | async as order">
  <h4 class="cx-order-items-header">
    {{ 'checkoutOrderConfirmation.orderItems' | cxTranslate }}
  </h4>

  <cj-cart-item-list
    *ngIf="order.entries"
    [items]="order.entries"
    [promotionLocation]="promotionLocation$ | async"
    [displayType]="cartItemDisplayTypes.ORDER_SUMMARY"
  ></cj-cart-item-list>
</div>
