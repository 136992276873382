<div class="cx-modal-container cj-order-detail-cancel-dialog" (esc)="dismissModal('Escape pressed')">
  <div class="cx-modal-content">
    <!-- Modal Header -->
    <div class="cx-dialog-header modal-header">
      <div class="cx-dialog-title modal-title">
        {{ 'orderDetails.actions.reorder.reorderTitle' | cxTranslate }}
      </div>
      <button
        type="button"
        class="close"
        attr.aria-label="{{ 'addToCart.closeModal' | cxTranslate }}"
        (click)="dismissModal('Cross click')"
      >
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </button>
    </div>

    <!-- Modal Body -->
    <div class="cx-dialog-body modal-body">
      <p>
        {{ 'orderDetails.actions.reorder.reorderParagraph' | cxTranslate }}
      </p>
      <div class="d-flex justify-content-around mt-4">
        <button class="btn btn-outline-primary" (click)="dismissModal('Cross click')">
          {{ 'common.back' | cxTranslate }}
        </button>
        <button (click)="confirmReorder()" class="btn btn-outline-primary">
          {{ 'orderDetails.actions.reorder.reorderAction' | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
</div>
