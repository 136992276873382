import { Injectable } from '@angular/core';
import { select } from '@ngrx/store';
import { LoaderState } from '@spartacus/core/src/state/utils/loader';
import { OrderHistoryService, OrderSelectors } from '@spartacus/order/core';
import { Order } from '@spartacus/order/root';
import { Observable } from 'rxjs';

@Injectable()
export class CjOrderHistoryService extends OrderHistoryService {
  /**
   * Returns an order's detail with loading state
   */
  getOrderDetailsState(): Observable<LoaderState<Order>> {
    return this.store.pipe(select(OrderSelectors.getOrderDetailState));
  }
}
