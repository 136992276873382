import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { StructuredDataModule } from '@spartacus/storefront';
import { CjCapitalizePipeModule } from 'src/app/shared/pipes/capitalize/capitalize-pipe.module';
import { CjContactInfoComponent } from './contact-info.component';

@NgModule({
  imports: [CommonModule, I18nModule, CjCapitalizePipeModule, StructuredDataModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsborneContactGroupComponent: {
          component: () => import('./contact-info.component').then((m) => m.CjContactInfoComponent),
        },
      },
    }),
  ],
  declarations: [CjContactInfoComponent],
  exports: [CjContactInfoComponent],
})
export class CjContactInfoModule {}
