export const common = {
  common: {
    send: 'Enviar',
    discover: 'Descubrir',
    knowMore: 'Saber más',
    remove: 'Eliminar',
    delete: 'Borrar',
    edit: 'Editar',
    save: 'Guardar',
    continue: 'Continuar',
    free: 'Gratis',
    logout: 'Cerrar sesión',
    cancel: 'Cancelar',
    submit: 'Enviar',
    back: 'Volver',
    update: 'Actualizar',
    home: 'Cinco Jotas',
    reserve: 'Reservar',
    daysOfWeek: {
      0: 'lunes',
      1: 'martes',
      2: 'miércoles',
      3: 'jueves',
      4: 'viernes',
      5: 'sábado',
      6: 'domingo',
    },
    daysOfWeek_plural: {
      0: 'lunes',
      1: 'martes',
      2: 'miércoles',
      3: 'jueves',
      4: 'viernes',
      5: 'sábados',
      6: 'domingos',
    },
  },
  httpHandlers: {
    badRequest: {
      bad_credentials: 'Credenciales incorrectas. Por favor, inicie sesión de nuevo.',
      el_usuario_está_deshabilitado: 'Usuario deshabilitado. Por favor, inicie sesión con otro correo.',
      user_is_disabled: 'Usuario deshabilitado. Por favor, inicie sesión con otro correo.',
      le_compte_utilisateur_est_désactivé: 'Usuario deshabilitado. Por favor, inicie sesión con otro correo.',
      usuário_desabilitado: 'Usuario deshabilitado. Por favor, inicie sesión con otro correo.',
    },
    badGateway: 'Se ha producido un error en el servidor. Por favor, inténtelo de nuevo más tarde.',
    badRequestPleaseLoginAgain: 'Credenciales incorrectas. Por favor, inicie sesión de nuevo.',
    badRequestOldPasswordIncorrect: 'Contraseña antigua incorrecta.',
    badRequestGuestDuplicateEmail:
      'El correo electrónico ya existe. Por favor, inicie sesión o introduzca un correo electrónico diferente.',
    conflict: 'Ya existe.',
    forbidden:
      'No está autorizado para realizar esta acción. Por favor, póngase en contacto con su administrador si cree que esto es un error.',
    gatewayTimeout: 'El servidor no responde, por favor, intentelo más tarde.',
    internalServerError: 'Se ha producido un error interno del servidor. Por favor, inténtelo de nuevo más tarde.',
    sessionExpired: 'Su sesión ha caducado. Por favor, entra en la cuenta de nuevo.',
    unknownError: 'Se produjo un error desconocido',
    unauthorized: {
      common: 'Se produjo un error de autorización desconocido',
      invalid_client: 'Credenciales incorrectas',
    },
    validationErrors: {
      missing: {
        bankAccountNumber: 'El campo IBAN es obligatorio',
        card_cardType: 'La tarjeta de crédito seleccionada no es compatible. Por favor, seleccione otra.',
        card_accountNumber: 'El número de tarjeta de crédito introducido no es válido.',
        card_cvNumber: 'El código de seguridad introducido no es válido.',
        card_expirationMonth: 'La fecha de caducidad de la tarjeta de crédito introducida no es válida.',
        card_expirationYear: 'La fecha de caducidad de la tarjeta de crédito introducida no es válida.',
        billTo_firstName: 'El nombre introducido no es válido.',
        billTo_lastName: 'El apellido introducido no es válido.',
        billTo_street1: 'La dirección introducida no es válida.',
        billTo_street2: 'La dirección introducida no es válida.',
        billTo_city: 'La ciudad introducida no es válida para esta tarjeta de crédito.',
        billTo_state: 'El estado/provincia introducido no es válido para esta tarjeta de crédito.',
        billTo_country: 'El país introducido no es válido para esta tarjeta de crédito.',
        billTo_postalCode: 'El código postal no es válido para esta tarjeta de crédito.',
        country: {
          isocode: 'País faltante',
        },
        firstName: 'El nombre es obligatorio',
        lastName: 'El apellido es obligatorio',
        password: 'La contraseña es obligatoria',
        firstNameLastName: 'El nombre y apellido es obligatorio',
        uid: 'El email es obligatorio',
        code: 'El código es obligatorio',
        email: 'El email es obligatorio',
        iban: 'EL campo IBAN es obligatorio',
        documentIdentifier: 'El campo NIF es obligatorio',
        postalCode: 'El campo código postal es obligatorio',
        defaultPaymentAddress: {
          phone: 'El campo código postal es obligatorio',
          firstName: 'El campo Nombre es obligatorio',
          lastName: 'El campo Apellido es obligatorio',
          password: 'El campo password es obligatorio',
          line1: 'El campo dirección es obligatorio',
          town: 'El campo ciudad es obligatorio',
          postalCode: 'El campo código postal es obligatorio',
          country: {
            isocode: 'El campo país es obligatorio',
          },
          toCustomersUid: 'Missing customers email address',
        },
        newUid: 'Verifique la información del usuario',
        message: 'El mensaje es obligatorio',
        subject: 'El asunto es obligatorio',
        orderCode: 'El número de pedido es obligatorio',
        productsAffected: 'El campo Productos y Cantidades afectadas es obligatorio',
        qualityProblem: 'El campo Problema de calidad es obligatorio',
        requiredAction: 'El campo Acción requerida es obligatorio',
      },
      invalid: {
        subject: 'El asunto introducido no es válido.',
        message: 'El mensaje introducido no es válido.',
        bankAccountNumber: 'El campo IBAN no es válido',
        card_expirationMonth: 'La fecha de caducidad de la tarjeta de crédito introducida no es válida.',
        country: {
          isocode: 'El país no es válido.',
        },
        firstName: 'El nombre introducido no es válido.',
        lastName: 'El apellido introducido no es válido.',
        firstNameLastName: 'El nombre y apellido introducido no es válido',
        password:
          'La contraseña introducida no es válida, debe tener un mínimo de seis caracteres, con una letra mayúscula, un número, un símbolo',
        uid: 'El email no es válido.',
        code: 'El código no es válido.',
        email: 'El email no es válido.',
        iban: 'EL campo IBAN no es válido',
        sessionCart: 'No se ha configurado la información de pago.',
        documentIdentifier: 'El NIF no es válido.',
        postalCode: 'El código postal no es válido',
        defaultPaymentAddress: {
          phone: 'El campo código postal no es válido',
          firstName: 'El campo Nombre no es válido',
          lastName: 'El campo Apellido no es válido',
          password: 'El campo password no es válido',
          line1: 'El campo dirección no es válido',
          town: 'El campo ciudad no es válido',
          postalCode: 'El campo código no es válido',
          country: {
            isocode: 'El campo país no es válido',
          },
        },
      },
    },
    cartModification: {
      giftBoxError: {
        label: 'No se ha alcanzado el mínimo de productos para completar la caja regalo.',
        entries: '',
      },
      lowStock: {
        label: 'Se han modificado los siguientes artículos de tu carro: ',
        entries: 'La cantidad del producto {{product}} ha sido reducida de {{oldQuantity}} a {{quantity}} por falta de stock.',
      },
      noStock: {
        label: 'Se han modificado los siguientes artículos de tu carro: ',
        entries: 'Se ha eliminado el producto {{product}} por falta de stock.',
      },
      unavailable: {
        label: 'Se han eliminado los artículos que no están disponibles para este destino: ',
        entries: '{{product}}.',
      },
    },
    cartNotFound: 'Carrito no encontrado.',
    invalidCodeProvided: 'Código no válido.',
    notActiveCodeProvided: 'Código no válido actualmente.',
    voucherExceeded: '{{voucherCode}}',
    cartSimulationError: 'Error al simular la orden',
  },
  contextSelector: {
    title: 'Seleccionar país e idioma',
    country: 'País',
    language: 'Idioma',
  },
  newsletter: {
    emailPlaceholder: 'Tu correo electrónico',
    acceptThe: 'Acepto la ',
    termsAndConditions: 'política de privacidad',
    success: 'Muchas gracias por formar parte de Cinco Jotas',
    error: 'Se ha producido un error en la suscripcion a la newsletter',
  },
  formErrors: {
    cjEmailMustNotExist: 'Este correo ya está registrado en Cinco Jotas.',
    nifError: 'Nombre y Apellidos no coinciden con su NIF.',
    globalMessage: 'El formulario que está intentando enviar contiene errores.',
    required: 'Este campo es obligatorio',
    cxInvalidEmail: 'El formato del email es incorrecto',
    cxInvalidPassword:
      'La contraseña debe tener un mínimo de seis caracteres, una letra mayúscula, una letra minúscula, un número y un símbolo',
    cxPasswordsMustMatch: 'Los campos de la contraseña deben coincidir',
    cxEmailsMustMatch: 'Los campos del email deben coincidir',
    cxStarRatingEmpty: 'El campo de calificación es obligatorio',
    cxNoSelectedItemToCancel: 'Seleccione al menos un elemento',
    cxNegativeAmount: 'El importe debe ser igual o superior a cero',
    cxContainsSpecialCharacters: 'El campo no puede contener caracteres especiales',
    cxIban: 'El campo IBAN es incorrecto',
    cxNif: 'El campo NIF es incorrecto para el país seleccionado',
    cxCif: 'El campo NIF es incorrecto para el país seleccionado. Solo se puede introducir un NIF de persona física',
    cxPostalCode: 'El código postal es incorrecto para la zona - país seleccionado',
    email: 'El formato del email es incorrecto',
    maxlength: 'Has introducido más caracteres de los permitidos',
    maxlengthWithNumber: 'Este campo debe contener como máximo {{maxLength}} caracteres',
    combinedMaxlength:
      '{{ firstControl }} y {{ secondControl }} combinados deben tener como máximo {{maxLength}} caracteres (actualmente {{actualLength}})',
    date: {
      required: 'Este campo es obligatorio',
      min: 'La fecha no puede ser anterior a {{min}}',
      max: 'La fecha no puede ser posterior a {{max}}',
      pattern: 'Utilice el formato de fecha yyyy-mm-dd',
      invalid: 'Utilice una fecha válida',
    },
    minlength: 'Este campo debe contener al menos {{minLength}} caracteres',
    min: 'No llega al mínimo',
  },
  contact: {
    address: '{{ street }}<br>{{locality}} ({{region}})',
    scheduleDay: '{{ startDay }} laborables de {{ opening }} a {{ closing}}',
    schedulePeriod: 'De {{ startDay }} a {{ endDay }} laborables de {{ opening }} a {{ closing}}',
    email: '{{ email }}',
    phone: 'T: {{ phone }}',
  },
  contactForm: {
    labels: {
      email: 'Email',
      message: 'Comentario sobre el caso',
      subject: 'Asunto',
      orderCode: 'Número de pedido',
      accept: 'Acepto el tratamiento de los datos personales para las actividades que a continuación se indican.',
      requiredAction: 'Acción requerida',
      productsAffected: {
        label: 'Productos y cantidades afectadas',
        productDescription: 'Descripción del producto',
        quantity: 'Cantidad',
        add: 'PRODUCTO',
        remove: 'Quitar producto',
      },
      qualityProblem: 'Problema de calidad',
      qualityProblems: {
        excessFat: 'Exceso de grasa',
        smellFlavorColorProblems: 'Problemas de olor/sabor/color',
        vacuumLoss: 'Pérdida de vacío',
        excessLackCuring: 'Exceso/falta de curación',
        other: 'Otros motivos',
      },
    },
    placeholders: {
      ticketCauses: 'Selecciona un motivo',
      ticketTemplates: 'Selecciona un caso',
      qualityProblems: 'Selecciona un problema',
    },
    formTemplateTitle: 'Formulario de contacto para {{formTitle}}',
    submit: 'Enviar',
    updateSuccess: 'Solicitud enviada correctamente',
    updateError: 'Ha habido un error al intentar enviar la solicitud. Por favor, inténtelo de nuevo más tarde.',
    modelNotFoundError: 'No coincide el email para el número de pedido. Por favor, inténtelo de nuevo tras corregir los datos.',
  },
  preferenceCenter: {
    form: {
      title: 'MI INFORMACIÓN PERSONAL',
      name: {
        label: 'Nombre',
        placeholder: 'Nombre',
      },
      surnames: {
        label: 'Apellido',
        placeholder: 'Apellido',
      },
      email: {
        label: 'Email',
        placeholder: 'Email',
        contact: 'Para modificaciones del email, por favor ponte en contacto con sac_web@osborne.es',
      },
      birthDate: {
        label: 'Fecha de nacimiento',
        placeholder: 'dd/mm/aaaa',
      },
      province: {
        label: 'Provincia',
        placeholder: 'Provincia',
      },
      phoneNumber: {
        label: 'Teléfono móvil',
        placeholder: 'Teléfono móvil',
      },
      language: {
        label: 'Idioma',
        placeholder: 'Idioma',
      },
      unsubscribe: {
        title: 'ANULAR SUSCRIPCIÓN',
        placeholder: 'No deseo recibir más comunicaciones comerciales.',
      },
      update: 'ACTUALIZAR',
      preferenceUpdateSuccess: 'Datos personales actualizados con éxito',
      preferenceUpdateError: 'No se ha podido actualizar los datos',
    },
    languages: {
      en: 'Inglés',
      fr: 'Francés',
      gt: 'Gallego',
      de: 'Alemán',
      it: 'Italiano',
      pt: 'Portugués',
      es: 'Español',
    },
  },
};
