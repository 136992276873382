import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { CartActions } from '@spartacus/cart/base/core';
import { EventService, StateEventService, createFrom } from '@spartacus/core';
import { Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CjActiveCartService } from 'src/app/cms-components/cart/base/core/facade/active-cart.service';
import { CleanEcommerceEvent } from '../common/common.events';
import { EcommerceType } from '../tag-manager-feature.model';
import { TagManagerFeatureService } from '../tag-manager-feature.service';
import { CartAddEntrySuccessEvent, CartRemoveEntrySuccessEvent, CartUpdateEntrySuccessEvent, NavigateToCartEvent } from './cart.events';
import { CartRemoveEntry, CartUpdateEntrySuccess } from '@spartacus/cart/base/core/store/actions/cart-entry.action';
import { NavigationEvent } from '@spartacus/storefront';
import { Cart } from '@spartacus/cart/base/root';
import { ProductCartItem, ProductCartItemPromotionData } from './cart-events.model';
import { CjOrderEntry } from 'src/app/cms-components/order/order.model';

/**
 * Registers events for the active cart
 */
@Injectable({ providedIn: 'root' })
export class CustomCartEventBuilder {
  constructor(
    protected actionsSubject: ActionsSubject,
    protected event: EventService,
    protected activeCartService: CjActiveCartService,
    protected tagManagerFeatureService: TagManagerFeatureService,
    protected stateEventService: StateEventService,
  ) {
    this.register();
  }

  /**
   * Registers events for the active cart
   */
  protected register(): void {
    this.registerNavigateToCartEvents();
    this.registerAddCartEntryEvents();
    this.registerRemoveCartEntryEvents();
    this.registerUpdateCartEntryEvents();
  }

  protected registerNavigateToCartEvents(): void {
    this.event.register(NavigateToCartEvent, this.registerNavigateToCart());
  }
  protected registerAddCartEntryEvents(): void {
    this.event.register(CartAddEntrySuccessEvent, this.registerCustomAddEntry());
  }
  protected registerRemoveCartEntryEvents(): void {
    this.event.register(CartUpdateEntrySuccessEvent, this.registerCustomRemoveEntry());
  }
  protected registerUpdateCartEntryEvents(): void {
    this.event.register(CartAddEntrySuccessEvent, this.registerCustomUpdateEntry());
  }

  /**
   * Register events for adding entry to the active cart
   */
  protected registerCustomAddEntry(): Observable<CartAddEntrySuccessEvent> {
    return this.actionsSubject.pipe(
      ofType(CartActions.CART_ADD_ENTRY_SUCCESS),
      map((action: any) => action.payload),
      tap(() => this.event.dispatch(<CleanEcommerceEvent>{ ecommerce: null }, CleanEcommerceEvent)),
      switchMap(({ entry, quantityAdded }) => {
        return of(
          createFrom(CartAddEntrySuccessEvent, {
            event: EcommerceType.ADD_TO_CART,
            ecommerce: {
              items: [this.tagManagerFeatureService.cartActions(entry, quantityAdded)],
            },
          }),
        );
      }),
    );
  }

  /**
   * Register events for navigate to cart
   */
  protected registerNavigateToCart(): Observable<NavigateToCartEvent> {
    return combineLatest([
      this.event.get(NavigationEvent),
      this.actionsSubject.pipe(
        ofType(CartActions.LOAD_CART_SUCCESS),
        switchMap((action) => this.activeCartService.getActive()),
      ),
    ]).pipe(
      distinctUntilChanged(([prev], [curr]) => prev.url === curr.url),
      filter(([navigate]) => navigate.url.includes('cart')),
      tap(() => this.event.dispatch(<CleanEcommerceEvent>{ ecommerce: null }, CleanEcommerceEvent)),
      map(([, cart]) =>
        createFrom(NavigateToCartEvent, {
          event: EcommerceType.VIEW_CART,
          ecommerce: {
            currency: cart.totalPrice!.currencyIso!.toUpperCase(),
            value: cart.totalPrice!.value!.toString(),
            items: this.getCartProductItems(cart) || [],
          },
        }),
      ),
    );
  }

  /**
   * Register events for removing entry to the active cart
   */
  protected registerCustomRemoveEntry(): Observable<CartRemoveEntrySuccessEvent> {
    return this.actionsSubject.pipe(
      ofType(CartActions.CART_REMOVE_ENTRY_SUCCESS),
      switchMap((action: CartRemoveEntry) => {
        return of(action).pipe(withLatestFrom(this.activeCartService.getActive(), this.activeCartService.getActiveCartId()));
      }),
      // tslint:disable-next-line:variable-name
      filter(([action, _activeCart, activeCartId]) => action.payload.cartId === activeCartId),
      tap(() => this.event.dispatch(<CleanEcommerceEvent>{ ecommerce: null }, CleanEcommerceEvent)),
      map(([action, activeCart]) => {
        const removedEntryNumber = parseInt(action.payload.entryNumber, 10);
        const removedProduct = activeCart?.entries?.find((entry) => entry.entryNumber === removedEntryNumber);
        return createFrom(CartRemoveEntrySuccessEvent, {
          event: EcommerceType.REMOVE_FROM_CART,
          ecommerce: {
            items: [this.tagManagerFeatureService.cartActions(removedProduct, removedProduct?.quantity)],
          },
        });
      }),
    );
  }

  /**
   * Register events for update entry to the active cart
   */
  protected registerCustomUpdateEntry(): Observable<CartUpdateEntrySuccessEvent> {
    return this.actionsSubject.pipe(
      ofType(CartActions.CART_UPDATE_ENTRY_SUCCESS),
      switchMap((action: CartUpdateEntrySuccess) => {
        return of(action).pipe(withLatestFrom(this.activeCartService.getActive(), this.activeCartService.getActiveCartId()));
      }),
      // tslint:disable-next-line:variable-name
      filter(([action, _activeCart, activeCartId]) => action.payload.cartId === activeCartId),
      tap(() => this.event.dispatch(<CleanEcommerceEvent>{ ecommerce: null }, CleanEcommerceEvent)),
      map(([action, activeCart]) => {
        const modifiedActionData = action.payload;
        const modifiedEntryNumber = parseInt(modifiedActionData.entryNumber, 10);
        const modifiedProduct = activeCart.entries?.find((entry) => entry.entryNumber === modifiedEntryNumber);
        const modifiedQuantity = modifiedActionData.quantity! - modifiedProduct?.quantity!;
        const eventType = modifiedQuantity > 0 ? EcommerceType.ADD_TO_CART : EcommerceType.REMOVE_FROM_CART;
        const products = [this.tagManagerFeatureService.cartActions(modifiedProduct, Math.abs(modifiedQuantity))];

        return createFrom(CartUpdateEntrySuccessEvent, {
          event: eventType,
          ecommerce: {
            items: products,
          },
        });
      }),
    );
  }

  private getCartProductItems(cart: Cart): ProductCartItem[] {
    return cart.entries!.map((entry: CjOrderEntry, index: number): ProductCartItem => {
      const promotionData: ProductCartItemPromotionData | undefined = this.getCartProductItemPromotionData(cart, entry);
      return {
        item_id: entry.product!.code!,
        item_name: entry.product!.name! || entry.product!.code!,
        coupon: promotionData?.coupon || '',
        currency: entry.basePrice!.currencyIso!.toUpperCase(),
        discount: promotionData?.discount || 0,
        index: index + 1,
        item_brand: entry.product!.brands ? entry.product!.brands[0].name : '',
        price: entry.basePrice!.value!.toString(),
        item_category: entry.product!.categories ? entry.product!.categories[0]?.name || '' : '',
        item_category2: entry.product!.categories ? entry.product!.categories[1]?.name || '' : '',
        item_category3: entry.product!.categories ? entry.product!.categories[2]?.name || '' : '',
        item_variant: entry.unit?.name ? entry.unit.name : '',
        quantity: entry.quantity!,
      };
    });
  }

  private getCartProductItemPromotionData(cart: Cart, orderEntry: CjOrderEntry): ProductCartItemPromotionData | undefined {
    if (cart && cart.appliedProductPromotions && cart.appliedProductPromotions.length) {
      return cart.appliedProductPromotions
        .filter((entry) => entry.consumedEntries![0].orderEntryNumber === orderEntry.entryNumber)
        .map(
          (promotion) =>
            <ProductCartItemPromotionData>{
              coupon: promotion.promotion!.code,
              discount: orderEntry.basePrice!.value! - promotion.consumedEntries![0].adjustedUnitPrice!,
            },
        )[0];
    }
    return undefined;
  }
}
