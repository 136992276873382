import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  COUNTRY_NORMALIZER,
  ConverterService,
  Country,
  CountryType,
  OCC_USER_ID_ANONYMOUS,
  Occ,
  OccEndpointsService,
} from '@spartacus/core';
import { Observable, map } from 'rxjs';
import { CjBaseStore } from 'src/app/core/model/misc.mode';
import { CjOcc } from 'src/app/core/occ/occ-models/occ.models';
import { CjBaseStoreAdapter } from 'src/app/core/site-context/connectors/base-store.adapter';

@Injectable()
export class CjOccBaseStoreAdapter implements CjBaseStoreAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converterService: ConverterService,
  ) {}

  public loadBaseStoreCountries(type?: CountryType): Observable<Country[]> {
    return this.http
      .get<Occ.CountryList>(
        this.occEndpoints.buildUrl('baseStoreCountries', {
          queryParams: type ? { type } : undefined,
        }),
      )
      .pipe(
        map((countryList) => countryList.countries ?? []),
        this.converterService.pipeableMany(COUNTRY_NORMALIZER),
      );
  }

  public getBaseStores(userId: string): Observable<CjBaseStore[]> {
    let url: string;
    if (!userId || userId === OCC_USER_ID_ANONYMOUS) {
      url = this.occEndpoints.buildUrl('baseStores');
    } else {
      url = this.occEndpoints.buildUrl('userBaseStores', { urlParams: { userId } });
    }
    return this.http.get<CjOcc.BaseStoreList>(url).pipe(map((list) => list.baseStores || []));
  }
}
