import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService, OccEndpointsService, UserIdService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CjGiftCard, CjOccGiftCard } from '../model/gift-card.model';
import { CjGiftCardAdapter } from './gift-card.adapters';

@Injectable()
export class CjOccGiftCardAdapter implements CjGiftCardAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected userIdService: UserIdService,
  ) {}

  loadAll(userId: string, cartId: string): Observable<CjGiftCard[]> {
    const url = this.occEndpoints.buildUrl('giftCards', { urlParams: { userId, cartId } });
    return this.http.get<CjOccGiftCard>(url).pipe(map((giftCards) => giftCards.products));
  }

  set(userId: string, cartId: string, giftCardCode: string, giftCardsMessage: string): Observable<any> {
    const url = this.occEndpoints.buildUrl('giftCard', { urlParams: { userId, cartId } });
    return this.http.put<CjGiftCard>(url, { code: giftCardCode, message: giftCardsMessage });
  }

  delete(userId: string, cartId: string): Observable<{}> {
    const url = this.occEndpoints.buildUrl('giftCard', { urlParams: { userId, cartId } });
    return this.http.delete(url);
  }
}
