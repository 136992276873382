import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule, provideConfig } from '@spartacus/core';
import { FormErrorsModule, IconModule, PasswordVisibilityToggleModule } from '@spartacus/storefront';
import { CjOrderGuestRegisterFormDialogComponent } from './order-guest-register-form-dialog.component';
import { cjOrderRegisterFormLayoutConfig } from './order-guest-register-form-dialog.config';

@NgModule({
  imports: [CommonModule, I18nModule, ReactiveFormsModule, FormErrorsModule, PasswordVisibilityToggleModule, IconModule],
  declarations: [CjOrderGuestRegisterFormDialogComponent],
  exports: [CjOrderGuestRegisterFormDialogComponent],
  providers: [provideConfig(cjOrderRegisterFormLayoutConfig)],
})
export class CjOrderGuestRegisterDialogModule {}
