import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { CjMediaModule } from 'src/app/shared/components/media/media.module';
import { CjGsapAnimationDirectiveModule } from 'src/app/shared/directives/directives.module';
import { CjProductCardModule } from '../../product/card/product-card.module';
import { CjProductTwoColumnsComponent } from './product-two-columns.component';

@NgModule({
  imports: [CommonModule, CjMediaModule, CjProductCardModule, CjGsapAnimationDirectiveModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsborneProductTwoColumnsComponent: {
          component: () => import('./product-two-columns.component').then((m) => m.CjProductTwoColumnsComponent),
        },
      },
    }),
  ],
  declarations: [CjProductTwoColumnsComponent],
  exports: [CjProductTwoColumnsComponent],
})
export class CjProductTwoColumnsModule {}
