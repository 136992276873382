export const product = {
  productDetail: {
    common: {
      showMore: 'Show more',
      showLess: 'Show less',
    },
    specification: {
      slider: {
        title: 'As delicious as it is healthy',
      }
    },
  },
  productCard: {
    stock: {
      inStock: 'Available',
      lowStock: 'Only {{ count }} available',
      lowStock_other: 'Only {{ count }} available',
      outOfStock: 'Not available',
    },
  },
  addToCart: {
    title: 'Cart',
    subtitle: {
      products: 'Your saved products in cart.',
      empty: 'You have no items in your shopping cart.',
    },
    checkoutButton: 'Checkout',
    cartButton: 'View and edit cart',
    total: 'Estimated total: ',
    stock: {
      lowStock: 'Last available units',
      outOfStock: 'Not available',
    },
    quantity: 'Quantity: ',
    addToCart: 'Add to cart',
    buyItAgain: 'Buy It Again',
    notifyMe: 'Notify me when it´s available',
    inStock: 'Available',
    lowStock: 'Only {{ count }} available',
    lowStock_other: 'Only {{ count }} available',
    outOfStock: 'Not available',
  },
  cartItems: {
    itemRemoved: 'Selected item has been removed. Cart total has been updated.',
  },
  webAvailability: {
    WEB_NOT_AVAILABLE: 'Not available',
    WEB_AVAILABLE: 'Available',
    WEB_LAST_UNITS: 'Last available units',
    WEB_ONLINE_ONLY: 'Online only',
    WEB_TEMPORARILY_OUT_OF_STOCK: 'Temporarily Out',
  },
  stockNotification: {
    notifyMe: 'NOTIFY ME',
    stopNotify: 'STOP NOTIFICATION',
    getNotify: 'Get notified when this product is available.',
    getNotifySuffix: 'to get notified when this product is available.',
    activateChannelsPrefix: 'To be notified you need to activate the ',
    channelsLink: 'Notification Channels',
    activateChannelsSuffix: '.',
    notified: 'You will be notified when this product is back in stock.',
    getNotified: 'Get notified when this product is back in stock.',
    unsubscribeSuccess: 'You will not receive back-in-stock notification for this product.',
    subscriptionDialog: {
      header: 'Out of stock subscription',
      notifiedPrefix: 'You will be notified on:',
      notifiedSuffix: 'as soons as this product is back in stock.',
      manageChannelsPrefix: 'Manage your prefered notification channels at the ',
      manageChannelsLink: 'Notification Preference',
      manageChannelsSuffix: ' page.',
      manageSubscriptionsPrefix: 'You can manage your subscriptions at ',
      manageSubscriptionsLink: 'My Interests',
      manageSubscriptionsSuffix: ' page.',
      okBtn: 'OK',
      subscribing: 'Subscribing you to Out of Stock notifications for this product',
    },
  },
};
