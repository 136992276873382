<ng-container *ngIf="order$ | async as order">
  <div class="cj-order-confirmation-thank-you-message">
    <h2
      class="cj-order-confirmation-thank-you-message__title"
      *ngIf="clientName"
      innerHTML="{{ 'checkoutOrderConfirmation.thankYou' | cxTranslate: { name: clientName } }}"
    ></h2>

    <!-- TODO: Heart animated img -->

    <p class="cj-order-confirmation-thank-you-message__text">{{ order.deliveryMode?.confirmationPageDescription || ('checkoutOrderConfirmation.invoiceArriveTime' | cxTranslate) }}</p>
    <p class="cj-order-confirmation-thank-you-message__text" *ngIf="!order.guestCustomer">{{ 'checkoutOrderConfirmation.invoiceCancelTime' | cxTranslate }}</p>
    <p class="cj-order-confirmation-thank-you-message__code" *ngIf="orderCode">
      {{ 'checkoutOrderConfirmation.orderNumber' | cxTranslate: { code: orderCode } }}
    </p>

    <div class="guest-checkout-info" *ngIf="isGuestCustomer && orderGuid && clientEmail">
      <cj-guest-register-form [guid]="orderGuid" [email]="clientEmail"></cj-guest-register-form>
    </div>
  </div>
</ng-container>
