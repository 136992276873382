import { Injectable } from '@angular/core';
import { CartConnector } from '@spartacus/cart/base/core';
import { User } from '@spartacus/user/account/root';
import { Observable } from 'rxjs';
import { CjCartAdapter } from './cart.adapter';

@Injectable({
  providedIn: 'root',
})
export class CjCartConnector extends CartConnector {
  constructor(protected override adapter: CjCartAdapter) {
    super(adapter);
  }

  public setDocumentIdentifier(userId: string, cartId: string, user: User): Observable<any> {
    return this.adapter.setDocumentIdentifier(userId, cartId, user);
  }
}
