export const user = {
  checkoutLogin: {
    signIn: 'Iniciar sesión',
    emailAddress: {
      label: '¿Estás registrado?',
      placeholder: 'Correo electrónico',
    },
    emailIsRequired: 'Formato de email inválido',
  },
  authMessages: {
    signedOutSuccessfully: 'Sesión cerrada correctamente',
  },
};
