import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { FormErrorsModule, PasswordVisibilityToggleModule, SpinnerModule } from '@spartacus/storefront';
import { CjLoginFormComponent } from './login-form.component';
import { CjLoginFormComponentService } from './login-form-component.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    FormErrorsModule,
    SpinnerModule,
    PasswordVisibilityToggleModule,
  ],
  providers: [
    provideConfig({
      cmsComponents: {
        ReturningCustomerLoginComponent: {
          component: () => import('./login-form.component').then((m) => m.CjLoginFormComponent),
        },
      },
    }),
    CjLoginFormComponentService,
  ],
  declarations: [CjLoginFormComponent],
  exports: [CjLoginFormComponent],
})
export class CjLoginFormModule {}
