export const cart = {
  cartDetails: {
    title: 'Ma sélection',
    continueShopping: 'Poursuivre mes achats',
    proceedToCheckout: 'Finaliser la commande',
  },
  validation: {
    cartEntriesChangeDuringCheckout: 'Lors du paiement, nous avons constaté des problèmes avec vos entrées. Veuillez revoir votre panier',
    cartEntryRemoved: "{{name}} a été retiré du panier parce qu'il n'était plus en stock",
    productOutOfStock: "{{name}} a été retiré du panier en raison d'un stock insuffisant",
    lowStock: "La quantité a été réduite à {{quantity}} en raison d'un stock insuffisant",
    inProgress: 'Traitement',
  },
  orderCost: {
    orderSummary: 'Résumé de la commande',
    subtotal: 'Subtotal',
    shipping: 'Expédition',
    giftCard: 'Carte cadeau',
    estimatedShipping: 'Expédition',
    discount: 'Remise',
    salesTax: 'Taxe de vente',
    grossTax: 'TVA (non incl.)',
    grossIncludeTax: 'TVA (incl.)',
    taxMessage:
      'La taxe est estimée au moment de la commande et peut être légèrement modifiée. La taxe finale (si elle est applicable dans votre pays) est indiquée dans le récapitulatif de la commande.',
    total: 'Total',
    toBeDetermined: 'À déterminer',
  },
  voucher: {
    coupon: 'Code promotionnel',
    coupon_other: 'Codes promotionnels',
    couponLabel: 'Entrez un code promo ici',
    apply: 'Ajouter',
    placeholder: 'Code promo',
    applyVoucherSuccess: '{{voucherCode}} a été appliqué.',
    removeVoucherSuccess: '{{voucherCode}} a été supprimé.',
    anchorLabel: 'Saisissez ou supprimez votre code promo',
    vouchersApplied: 'Coupons appliqués',
    availableCoupons: 'Coupons disponibles',
    availableCouponsLabel: 'Vous pouvez ajouter ces coupons à cette commande.',
  },
};
