import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { IconModule, MediaModule, PageComponentModule } from '@spartacus/storefront';
import { cjPopupLayoutConfig } from './popup-dialog/popup-dialog-layout.config';
import { CjPopupDialogComponent } from './popup-dialog/popup-dialog.component';
import { CjPopupComponent } from './popup.component';

@NgModule({
  imports: [CommonModule, MediaModule, IconModule, I18nModule, PageComponentModule],
  providers: [
    provideConfig(cjPopupLayoutConfig),
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsbornePopupComponent: {
          component: () => import('./popup.component').then((m) => m.CjPopupComponent),
        },
      },
    }),
  ],
  declarations: [CjPopupComponent, CjPopupDialogComponent],
  exports: [CjPopupComponent, CjPopupDialogComponent],
})
export class CjPopupModule {}
