import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CartActions } from '@spartacus/cart/base/core';
import { LoggerService, SiteContextActions, normalizeHttpError, withdrawOn } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { CjCheckoutConnector } from '../../connectors/checkout.connector';
import { CjCheckoutActions } from '../actions';

@Injectable()
export class CjCheckoutEffect {
  constructor(
    private readonly actions$: Actions,
    private readonly checkoutConnector: CjCheckoutConnector,
    private readonly logger: LoggerService,
  ) {}

  private contextChange$ = this.actions$.pipe(ofType(SiteContextActions.CURRENCY_CHANGE, SiteContextActions.LANGUAGE_CHANGE));

  createBillingAddress$: Observable<
    CjCheckoutActions.CreateBillingAddressSuccess | CjCheckoutActions.CreateBillingAddressFail | CartActions.LoadCart
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(CjCheckoutActions.CREATE_BILLING_ADDRESS),
      map((action: any) => action.payload),
      mergeMap((payload) => {
        return this.checkoutConnector.createBillingAddress(payload.userId, payload.cartId, payload.address).pipe(
          switchMap(() => [
            new CjCheckoutActions.CreateBillingAddressSuccess(payload.address),
            new CartActions.LoadCart({
              userId: payload.userId,
              cartId: payload.cartId,
            }),
          ]),
          catchError((error) => of(new CjCheckoutActions.CreateBillingAddressFail(normalizeHttpError(error, this.logger)))),
        );
      }),
      withdrawOn(this.contextChange$),
    ),
  );

  setBillingAddress$: Observable<
    CjCheckoutActions.SetBillingAddressSuccess | CjCheckoutActions.SetBillingAddressFail | CartActions.LoadCart
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(CjCheckoutActions.SET_BILLING_ADDRESS),
      map((action: CjCheckoutActions.SetBillingAddress) => action.payload),
      mergeMap(({ userId, cartId, addressId }) => {
        return this.checkoutConnector.setBillingAddress(userId, cartId, addressId).pipe(
          switchMap((address) => [new CjCheckoutActions.SetBillingAddressSuccess(address), new CartActions.LoadCart({ userId, cartId })]),
          catchError((error) => of(new CjCheckoutActions.SetBillingAddressFail(normalizeHttpError(error, this.logger)))),
        );
      }),
      withdrawOn(this.contextChange$),
    ),
  );

  unsetBillingAddress$: Observable<
    CjCheckoutActions.UnsetBillingAddressSuccess | CjCheckoutActions.UnsetBillingAddressFail | CartActions.LoadCart
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(CjCheckoutActions.UNSET_BILLING_ADDRESS),
      map((action: any) => action.payload),
      mergeMap((payload) => {
        return this.checkoutConnector.unsetBillingAddress(payload.userId, payload.cartId).pipe(
          switchMap(() => [
            new CjCheckoutActions.UnsetBillingAddressSuccess(),
            new CartActions.LoadCart({
              userId: payload.userId,
              cartId: payload.cartId,
            }),
          ]),
          catchError((error) => of(new CjCheckoutActions.UnsetBillingAddressFail(normalizeHttpError(error, this.logger)))),
        );
      }),
      withdrawOn(this.contextChange$),
    ),
  );
}
