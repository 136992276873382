import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { CjBaseStore } from 'src/app/core/model/misc.mode';
import { BASE_STORES_FEATURE, BaseStoreState, StateWithBaseStores } from '../base-store-state';

export const getBaseStoreState: MemoizedSelector<StateWithBaseStores, BaseStoreState> =
  createFeatureSelector<BaseStoreState>(BASE_STORES_FEATURE);

export const getBaseStores: MemoizedSelector<StateWithBaseStores, CjBaseStore[] | undefined> = createSelector(
  getBaseStoreState,
  (state: BaseStoreState) => state.baseStores,
);

export const getActiveBaseStore: MemoizedSelector<StateWithBaseStores, CjBaseStore | undefined> = createSelector(
  getBaseStoreState,
  (state: BaseStoreState) => state.selected,
);
