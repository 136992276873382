import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { CheckoutDeliveryModesFacade } from '@spartacus/checkout/base/root';
import { createFrom, EventService } from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CjActiveCartService } from 'src/app/cms-components/cart/base/core/facade/active-cart.service';
import { CjCheckoutPaymentTypeService } from 'src/app/cms-components/checkout/checkout-payment-type/facade/checkout-payment-type.service';
import { CleanEcommerceEvent } from '../../common/common.events';
import { EcommerceType } from '../../tag-manager-feature.model';
import { TagManagerFeatureService } from '../../tag-manager-feature.service';
import { getProductItems } from '../common/checkout.utils';
import { CheckoutDeliveryModeEvent, CheckoutPaymentModeCompleteEvent, CheckoutStepsEvent } from './checkout-steps.events';
import { LOAD_CHECKOUT_STEP_SUCCESS, SET_DELIVERY_MODE_SUCCESS } from './store/actions/custom-checkout.action';

@Injectable({
  providedIn: 'root',
})
export class CheckoutStepsEventBuilder {
  constructor(
    protected actions: ActionsSubject,
    protected eventService: EventService,
    protected tagManagerFeatureService: TagManagerFeatureService,
    protected checkoutDeliveryService: CheckoutDeliveryModesFacade,
    protected paymentTypeService: CjCheckoutPaymentTypeService,
    protected activeCartService: CjActiveCartService,
  ) {
    this.register();
  }

  /**
   * Registers checkout events
   */
  protected register(): void {
    this.eventService.register(CheckoutDeliveryModeEvent, this.buildDeliveryModeEvent());
  }

  /**
   * Builds the delivery mode events
   */
  protected buildDeliveryModeEvent(): Observable<CheckoutDeliveryModeEvent> {
    return this.actions.pipe(
      ofType(SET_DELIVERY_MODE_SUCCESS),
      map((action: any) => {
        return action.payload;
      }),
      map((selectedModeId: string) => {
        return createFrom(CheckoutDeliveryModeEvent, {
          event: EcommerceType.ADD_SHIPPING_INFO,
          ecommerce: {
            checkout: {
              actionField: {
                step: 2,
                option: selectedModeId?.length ? selectedModeId : '',
              },
            },
          },
        });
      }),
    );
  }

  public dispatchPaymentModeCompleteEvent(): Observable<any> {
    return combineLatest([this.activeCartService.takeActive()]).pipe(
      tap(([cart]: any) => {
        this.eventService.dispatch(<CleanEcommerceEvent>{ ecommerce: null }, CleanEcommerceEvent);
        this.eventService.dispatch(
          <CheckoutPaymentModeCompleteEvent>{
            event: EcommerceType.ADD_PAYMENT_INFO,
            ecommerce: {
              payment_type: cart?.paymentMode,
              items: getProductItems(cart.entries, cart.giftCardProduct),
            },
          },
          CheckoutPaymentModeCompleteEvent,
        );
      }),
    );
  }
}
