import { NgModule } from '@angular/core';
import { CjOccRedsysAdapter } from './connectors/occ-redsys.adapter';
import { CjRedsysAdapter } from './connectors/redsys.adapter';
import { CjRedsysStoreModule } from './store/redsys-store.module';

@NgModule({
  imports: [CjRedsysStoreModule],
  providers: [{ provide: CjRedsysAdapter, useClass: CjOccRedsysAdapter }],
})
export class CjRedsysModule {}
