import { Component, Input } from '@angular/core';
import { CartItemComponent, CartItemContextSource } from '@spartacus/cart/base/components';
import { CartItemContext } from '@spartacus/cart/base/root';
import { CjOrderEntry } from 'src/app/cms-components/order/order.model';
import { CjCartItemDisplayType } from '../cart.model';

@Component({
  selector: 'cj-cart-item',
  templateUrl: './cart-item.component.html',
  providers: [CartItemContextSource, { provide: CartItemContext, useExisting: CartItemContextSource }],
})
export class CjCartItemComponent extends CartItemComponent {
  @Input() displayType: CjCartItemDisplayType = CjCartItemDisplayType.CART;
  cartItemDisplayTypes = CjCartItemDisplayType;

  getItem(): CjOrderEntry {
    return this.item as CjOrderEntry;
  }
}
