export const order = {
  orderDetails: {
    actions: {
      cancellationAndReturn: {
        cancelTitle: 'Cancel Order',
        cancelAction: 'Cancel Order',
        cancelParagraph:
          'Are you sure you want to cancel the order? Refund will be processed to your account in the following days if paid by Credit Card',
        cancellationSuccess: 'Order {{orderCode}} cancelled successfully ',
        cancellationFail: 'There has been an error trying to cancel the order {{orderCode}} ',
      },
      modify: {
        modifyTitle: 'Modify order',
        modifyAction: 'Modify order',
        modifyParagraph:
          'In order to modify the order the current one will be cancelled. Your account will be credited within a few days if you have paid with bank card. The entire previous order will automatically be loaded so that you can modify it. Do you agree to this? ',
        modifySuccess: 'Order {{orderCode}} restored successfully ',
        modifyFail: 'There has been an error trying to restore the order {{orderCode}} ',
      },
      reorder: {
        reorderTitle: 'Reorder',
        reorderAction: 'Reorder',
        reorderParagraph: 'Are you sure you want to place this order again? ',
        reorderSuccess: 'Products from order {{orderCode}} added to cart successfully ',
        reorderFail: 'There has been an error trying to add to cart the order {{orderCode}} ',
      },
      downloadInvoice: {
        invoiceAction: 'Download invoice',
      },
    },
    orderInfo: {
      shippingAddress: 'Shipping Address',
      shippingMethod: 'Shipping Method',
      paymentMethod: 'Payment method',
    },
  },
  orderHistory: {
    orderHistory: 'My orders',
    orderId: 'Order number',
    emptyPurchaseOrderId: 'None',
    sendTo: 'Send to',
    date: 'Creation date',
    status: 'Status',
    total: 'Amount',
    noOrders: 'We have no order records for this account.',
    noReplenishmentOrders: 'We have no replenishment order records for this account.',
    startShopping: 'Start Shopping',
    replenishmentOrderHistory: 'Replenishment Order History',
    replenishmentOrderId: 'Replenishment #',
    purchaseOrderNumber: 'PO #',
    costCenter: 'Cost Center',
    startOn: 'Start On',
    frequency: 'Frequency',
    nextOrderDate: 'Next Order Date',
    cancel: 'Cancel',
    cancelled: 'Cancelled',
    replenishmentHistory: 'Replenishment History',
    notFound: 'No Orders Found',
    actions: 'Actions',
    noOrdersFound: 'You have no pending orders.',
    reorder: 'Reorder',
  },
};
