import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, InjectionToken, NgModule } from '@angular/core';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { IconModule, MediaModule } from '@spartacus/storefront';
import { CjProductAttributesComponent } from './product-attributes.component';
import { CjProductAttributesItemComponent } from './product-attributes-item/product-attributes-item.component';
import { cjProductAttributesLayoutConfig } from './product-attributes-dialog/product-attributes-dialog.config';
import { CjProductAttributesDialogComponent } from './product-attributes-dialog/product-attributes-dialog.component';
import { CjHtmlSanitizerPipeModule } from 'src/app/shared/pipes/html-sanitizer/html-sanitizer-pipe.module';
import { PRODUCT_ATTRIBUTES_CONFIG } from './product-attributes.config';


@NgModule({
  declarations: [
    CjProductAttributesComponent,
    CjProductAttributesItemComponent,
    CjProductAttributesDialogComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
    MediaModule,
    IconModule,
    CjHtmlSanitizerPipeModule,
  ],
  providers: [
    provideConfig(cjProductAttributesLayoutConfig),
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CincojotasProductAttributesComponent: {
          component: () => import('./product-attributes.component').then((m) => m.CjProductAttributesComponent),
        },
      },
    }),
    {
      provide: PRODUCT_ATTRIBUTES_CONFIG,
      useValue: [
        {
          code: 'nutritional',
          displayOrder: 1,
          contentAsModal: true,
        },
        {
          code: 'conservation',
          displayOrder: 2,
          contentAsModal: false,
        },
        {
          code: 'ham_parts',
          displayOrder: 3,
          contentAsModal: true,
        },
        {
          code: 'health_benefits',
          displayOrder: 4,
          contentAsModal: true,
        }
      ]
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CjProductAttributesModule { }
