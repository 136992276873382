import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { IconModule, MediaModule, PageComponentModule } from '@spartacus/storefront';
import { CjExperiencesBannerComponent } from './experiences-banner.component';

@NgModule({
  imports: [CommonModule, PageComponentModule, MediaModule, IconModule, I18nModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ExperiencesBannerComponent: {
          component: () =>
            import('./experiences-banner.component').then(
              (m) => m.CjExperiencesBannerComponent,
            ),
        },
      },
    }),
  ],
  declarations: [CjExperiencesBannerComponent],
  exports: [CjExperiencesBannerComponent],
})
export class CjExperiencesBannerModule {}
