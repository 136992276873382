export const user = {
  checkoutLogin: {
    signIn: 'Log in',
    emailAddress: {
      label: 'Are you registerd?',
      placeholder: 'Email',
    },
    emailIsRequired: 'Email format invalid',
  },
  authMessages: {
    signedOutSuccessfully: 'Logged out succesfully',
  },
};
