import { Product } from '@spartacus/core';

export interface CjProducGridBanner {
  type: CjProductGridBannerType;
  banner: string | undefined;
}

export type CjProductGridElement = Product | CjProducGridBanner;

export enum CjProductGridBannerType {
  ONE_COLUMN = 'ONE_COLUMN',
  THREE_COLUMN = 'THREE_COLUMN',
}
