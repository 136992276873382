import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Language, LanguageService, RoutingService, WindowRef } from '@spartacus/core';
import { ICON_TYPE, LaunchDialogService } from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { CjBaseStore } from 'src/app/core/model/misc.mode';
import { CjBaseStoreService } from '../../facades/base-store.service';
import { Router } from '@angular/router';

@Component({
  selector: 'cj-site-context-selector-dialog',
  templateUrl: './site-context-selector-dialog.component.html',
  styleUrls: ['./site-context-selector-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CjSiteContextSelectorDialogComponent implements OnInit {
  iconTypes = ICON_TYPE;

  siteContextForm = this.fb.group({
    baseStore: ['', Validators.required],
    language: ['', Validators.required],
  });

  stores: CjBaseStore[] = [];

  selectedBaseStore$: BehaviorSubject<string> = new BehaviorSubject('');
  activeLang$ = this.languageService.getActive();

  availableBaseStores$ = this.baseStoreService.getBaseStores().pipe(
    filter((baseStores) => !!baseStores.length),
    withLatestFrom(this.activeLang$),
    map(([baseStores, activeLang]) => {
      // Select active country and language
      if (!this.selectedBaseStore$.value) {
        this.baseStoreService
          .getActive()
          .pipe(take(1))
          .subscribe((store) => {
            if (store) {
              this.selectedBaseStore$.next(store.code);
              this.siteContextForm.patchValue({ baseStore: store.code, language: activeLang });
            } else {
              const activeBaseStore = baseStores.find((s) => !!s.languages?.find((lang) => lang.isocode?.toLowerCase() == activeLang));

              if (!this.selectedBaseStore$.value && activeBaseStore?.code) {
                this.selectedBaseStore$.next(activeBaseStore.code);
                this.siteContextForm.patchValue({ baseStore: activeBaseStore.code, language: activeLang });
              }
            }
          });
      }
      this.stores = baseStores;
      return baseStores;
    }),
    distinctUntilChanged(),
  );
  availableLanguages$?: Observable<Language[]> = combineLatest([
    this.availableBaseStores$,
    this.selectedBaseStore$.pipe(filter(Boolean)),
  ]).pipe(
    map(([stores, selectedStore]) => stores.find((store) => store.code?.toLowerCase() === selectedStore.toLowerCase())),
    map((store) => store?.selectorLanguages || []),
    tap((languages => {
      if (languages.length && !this.siteContextForm.get('language')?.value) {
        this.siteContextForm.patchValue({ language: languages[0].isocode });
      }
    })),
  );

  subscription = new Subscription();

  constructor(
    private readonly fb: FormBuilder,
    private readonly languageService: LanguageService,
    private readonly launchDialogService: LaunchDialogService,
    private readonly routingService: RoutingService,
    private readonly baseStoreService: CjBaseStoreService,
    private readonly winRef: WindowRef,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.routingService
        .getRouterState()
        .pipe(filter((state) => !!state.nextState))
        .subscribe(() => this.dismissModal('dismiss')),
    );
  }

  dismissModal(reason: string) {
    this.launchDialogService.closeDialog(reason);
  }

  selectBaseStore() {
    const store = this.siteContextForm.value.baseStore;
    if (store) {
      this.siteContextForm.patchValue({ language: '' });
      this.selectedBaseStore$.next(store);
    }
  }

  onSubmit() {
    if (this.siteContextForm.valid) {
      this.setBaseStore();
      this.setLanguage();
      this.dismissModal('success');
      if (this.winRef.isBrowser()) {
        this.router.navigate(['/']);
      }
    }
  }

  setBaseStore() {
    const baseStoreCode = this.siteContextForm.value.baseStore;
    const store = this.stores.find((item) => item.code === baseStoreCode);
    if (store) {
      this.baseStoreService.setActive(store);
    }
  }

  setLanguage() {
    const lang = this.siteContextForm.value.language;
    if (lang) {
      this.languageService.setActive(lang);
    }
  }
}
