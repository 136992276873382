import { InjectionToken } from '@angular/core';
import { ActionReducer, MetaReducer } from '@ngrx/store';
import { CjBaseStoreActions } from '../actions';
import { BaseStoreState } from '../base-store-state';
import { AuthActions } from '@spartacus/core';

export const initialState: BaseStoreState = {
  baseStores: undefined,
  selected: undefined,
};

export function baseStoreReducer(state = initialState, action: CjBaseStoreActions.CjBaseStoreAction): BaseStoreState {
  switch (action.type) {
    case CjBaseStoreActions.LOAD_BASE_STORES_SUCCESS: {
      if (!state.selected && action.payload && action.payload.length > 0) {
        return {
          ...state,
          baseStores: action.payload,
        };
      }
      return {
        ...state,
        baseStores: action.payload,
      };
    }
    case CjBaseStoreActions.LOAD_BASE_STORES_FAIL: {
      return initialState;
    }
    case CjBaseStoreActions.SET_ACTIVE_BASE_STORE: {
      return {
        ...state,
        selected: action.payload,
      };
    }
    default:
      return state;
  }
}

export function clearBaseStoreState(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === AuthActions.LOGOUT) {
      state = [];
    }

    return reducer(state, action);
  };
}

export const reducerBaseStoreToken: InjectionToken<ActionReducer<BaseStoreState>> = new InjectionToken<ActionReducer<BaseStoreState>>(
  'BaseStoreReducers',
);

export const reducerBaseStoreProvider = {
  provide: reducerBaseStoreToken,
  useValue: baseStoreReducer,
};

export const metaReducers: MetaReducer<any>[] = [clearBaseStoreState];
