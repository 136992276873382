import { Injectable } from '@angular/core';
import { BaseSiteService, LanguageService } from '@spartacus/core';
import { SchemaBuilder } from '@spartacus/storefront';
import { combineLatest, map, Observable, switchMap } from 'rxjs';
import { CjBaseSite } from 'src/app/core/occ/occ-models/occ.models';
import { CjStructuredDataUtilsService } from '../../structured-data-utils-service';

@Injectable({
  providedIn: 'root',
})
export class CjOrganizationSchemaBuilder implements SchemaBuilder {
  constructor(
    private readonly utils: CjStructuredDataUtilsService,
    private readonly languageService: LanguageService,
    private readonly baseSiteService: BaseSiteService,
  ) {}

  build(): Observable<any> {
    // Common to all pages
    // this.languageService.getActive() is used to ensure urls contain the site language
    return combineLatest([
      this.baseSiteService.getActive().pipe(
        switchMap((active) => this.baseSiteService.get(active)),
        map((baseSite) => (baseSite as CjBaseSite).logo.url),
      ),
      this.languageService.getActive(),
    ]).pipe(
      map(([logoUrl]) => ({
        '@type': 'Organization',
        name: this.utils.siteName,
        '@id': this.utils.getFullUrl('#organization'),
        url: this.utils.getFullUrl(),
        logo: {
          '@type': 'ImageObject',
          url: logoUrl ? this.utils.getFullMediaUrl(logoUrl) : '',
        },
        sameAs: this.utils.socialNetworkUrls,
      })),
    );
  }
}
