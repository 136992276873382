export const cart = {
  cartDetails: {
    title: 'Mi selección',
    continueShopping: 'Seguir comprando',
    proceedToCheckout: 'Finalizar pedido',
  },
  validation: {
    cartEntriesChangeDuringCheckout: 'Durante el pago hemos encontrado problemas con los productos. Por favor, revise su carrito',
    cartEntryRemoved: '{{name}} ha sido eliminado del carrito por falta de stock.',
    productOutOfStock: '{{name}} ha sido eliminado del carrito por falta de stock.',
    lowStock: 'La cantidad se ha reducido a {{quantity}} debido a falta de existencias.',
    inProgress: 'Procesando',
  },
  orderCost: {
    orderSummary: 'Resumen del pedido',
    subtotal: 'Subtotal',
    shipping: 'Envío',
    giftCard: 'Tarjeta de regalo',
    estimatedShipping: 'Envío',
    discount: 'Descuento',
    salesTax: 'Impuesto de venta',
    grossTax: 'IVA (no incluido)',
    grossIncludeTax: 'IVA (incluido)',
    taxMessage:
      'El impuesto se estima al finalizar la compra y está sujeto a ligeros cambios. El impuesto final (si corresponde en su estado) se muestra en el Resumen del pedido.',
    total: 'Total',
    toBeDetermined: 'Por determinar',
  },
  voucher: {
    coupon: 'Código promocional',
    coupon_other: 'Códigos promocionales',
    couponLabel: 'Introduzca aquí un código promocional',
    apply: 'Añadir',
    placeholder: 'Código promocional',
    applyVoucherSuccess: '{{voucherCode}} ha sido aplicado.',
    removeVoucherSuccess: '{{voucherCode}} ha sido eliminado.',
    anchorLabel: 'Introduzca o elimine su código de cupón',
    vouchersApplied: 'Cupones aplicados',
    availableCoupons: 'Cupones disponibles',
    availableCouponsLabel: 'Puede añadir estos cupones a este pedido.',
  },
};
