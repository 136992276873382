import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { IconModule, MediaModule, PageComponentModule } from '@spartacus/storefront';
import { CjMediaModule } from 'src/app/shared/components/media/media.module';
import { CjAnimationSliderComponent } from './animation-slider.component';
import { CjHtmlSanitizerPipeModule } from 'src/app/shared/pipes/html-sanitizer/html-sanitizer-pipe.module';
import { cjAnimationSliderDialogLayoutConfig } from './animation-slider-dialog/animation-slider-dialog.config';
import { CjAnimationSliderDialogComponent } from './animation-slider-dialog/animation-slider-dialog.component';

@NgModule({
  imports: [CommonModule, MediaModule, CjMediaModule, PageComponentModule, CjHtmlSanitizerPipeModule, IconModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsborneAnimationSliderComponent: {
          component: () => import('./animation-slider.component').then((m) => m.CjAnimationSliderComponent),
        },
      },
    }),
    provideConfig(cjAnimationSliderDialogLayoutConfig),
  ],
  declarations: [CjAnimationSliderComponent, CjAnimationSliderDialogComponent],
  exports: [CjAnimationSliderComponent, CjAnimationSliderDialogComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CjAnimationSliderModule { }
