import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { FormErrorsModule, IconModule } from '@spartacus/storefront';
import { CjCartApplyCouponComponent } from './cart-apply-coupon.component';
import { CjAppliedCouponsComponent } from './applied-coupons/applied-coupons.component';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    I18nModule,
    IconModule,
    FormErrorsModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CartApplyCouponComponent: {
          component: () =>
            import('./cart-apply-coupon.component').then(
              (m) => m.CjCartApplyCouponComponent,
            ),
        },
      },
    }),
  ],
  declarations: [CjCartApplyCouponComponent, CjAppliedCouponsComponent],
  exports: [CjCartApplyCouponComponent, CjAppliedCouponsComponent],
})
export class CjCartApplyCouponModule {}
