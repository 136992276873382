import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CmsConfig, GlobalMessageService, I18nModule, NotAuthGuard, RoutingService, provideConfig } from '@spartacus/core';
import { FormErrorsModule, PasswordVisibilityToggleModule, SpinnerModule } from '@spartacus/storefront';
import { ResetPasswordComponentService } from '@spartacus/user/profile/components';
import { UserPasswordFacade } from '@spartacus/user/profile/root';
import { CjResetPasswordComponentService } from './reset-password-component.service';
import { CjResetPasswordComponent } from './reset-password.component';

@NgModule({
  declarations: [CjResetPasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    I18nModule,
    FormErrorsModule,
    SpinnerModule,
    PasswordVisibilityToggleModule,
  ],
  exports: [CjResetPasswordComponent],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ResetPasswordComponent: {
          component: () => import('./reset-password.component').then((m) => m.CjResetPasswordComponent),
          guards: [NotAuthGuard],
          providers: [
            {
              provide: ResetPasswordComponentService,
              useClass: CjResetPasswordComponentService,
              deps: [UserPasswordFacade, RoutingService, GlobalMessageService],
            },
          ],
        },
      },
    }),
  ],
})
export class CjResetPasswordModule {}
