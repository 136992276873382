import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { LoaderState } from '@spartacus/core/src/state/utils/loader';
import { CONTACT_FORM_FEATURE, StateContactForm, StateWithContactForm } from '../contact-form-state';
import { CsTicketCause } from '../../contact-form.model';

export const getContactFormState: MemoizedSelector<StateWithContactForm, StateContactForm> =
  createFeatureSelector<StateContactForm>(CONTACT_FORM_FEATURE);

export const getContactFormLoaderState: MemoizedSelector<StateWithContactForm, LoaderState<CsTicketCause[]>> = createSelector(
  getContactFormState,
  (state: StateContactForm) => state.causes,
);

export const getContactForm: MemoizedSelector<StateWithContactForm, CsTicketCause[]> = createSelector(
  getContactFormLoaderState,
  (state: LoaderState<CsTicketCause[]>) => StateUtils.loaderValueSelector(state),
);

export const getContactFormLoading: MemoizedSelector<StateWithContactForm, boolean> = createSelector(
  getContactFormLoaderState,
  (state: LoaderState<CsTicketCause[]>) => StateUtils.loaderLoadingSelector(state),
);

export const getContactFormLoaded: MemoizedSelector<StateWithContactForm, boolean> = createSelector(
  getContactFormLoaderState,
  (state: LoaderState<CsTicketCause[]>) => StateUtils.loaderSuccessSelector(state),
);
