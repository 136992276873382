export const userProfile = {
  register: {
    registerTitle: 'I do not have an account',
    createAccount: 'Create an account',
    termsAndConditions: 'Terms & Conditions',
    confirmThatRead: 'I am confirming that I have read and agreed with the',
    newsletter: 'I want to receive the newsletter',
    firstName: {
      label: 'First name',
      placeholder: 'First name',
    },
    lastName: {
      label: 'Last name',
      placeholder: 'Last name',
    },
    emailAddress: {
      label: 'Email address',
      placeholder: 'Email address',
    },
    password: {
      label: 'Password',
      placeholder: 'Password',
    },
    confirmPassword: {
      action: 'Confirm password',
      label: 'Confirm password',
      placeholder: 'Confirm Password',
    },
    postRegisterMessage: 'Please log in with the provided credentials.',
    newPassword: 'New password',
    passwordMinRequirements:
      'The password must be a minimum of six characters, one uppercase letter, one lowercase letter, one number, and one symbol.',
    resetPassword: 'Change password',
  },
  forgottenPassword: {
    resetPassword: 'Reset Password',
    resetButton: 'Reset',
    sendResetPassword: 'Change Password',
    enterEmailAddressAssociatedWithYourAccount: 'Enter the email address associated with your account',
    emailAddress: {
      label: 'Email',
      placeholder: 'Email',
    },
    enterValidEmail: 'Please enter a valid email.',
    passwordResetEmailSent: 'If there is an account with the email provided, you will receive an email to reset your password.',
    passwordResetSuccess: 'Success! You can log in with your new password.',
    TokenInvalidatedError:
      'The link used is not correct. If you have made multiple attempts, make sure to use the link from the latest email received. Otherwise, please request it again.',
    IllegalArgumentError: 'The link has expired',
  },
};
