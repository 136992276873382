import { DIALOG_TYPE, LAUNCH_CALLER, LayoutConfig } from '@spartacus/storefront';
import { CjAddedToCartDialogComponent } from './added-to-cart-dialog.component';

export const ADDED_TO_CART_DIALOG = LAUNCH_CALLER.ADDED_TO_CART;

export const cjAddedToCartLayoutConfig: LayoutConfig = {
  launch: {
    ADDED_TO_CART: {
      inlineRoot: true,
      component: CjAddedToCartDialogComponent,
      dialogType: DIALOG_TYPE.SIDEBAR_END,
    },
  },
};
