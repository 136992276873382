import { Injectable } from '@angular/core';
import {
  BasePageMetaResolver,
  BaseSiteService,
  BreadcrumbMeta,
  isNotUndefined,
  LanguageService,
  PageLinkService,
  ProductPageMetaResolver,
  ProductScope,
  ProductService,
  RoutingService,
  SemanticPathService,
  TranslationService,
} from '@spartacus/core';
import { combineLatest, filter, map, Observable, of, switchMap } from 'rxjs';
import { CjBaseStoreService } from 'src/app/cms-components/content/misc/site-context-selector/facades/base-store.service';
import { CjHreflangMetas } from 'src/app/core/cms/model/page.model';
import { CjProduct } from 'src/app/shared/models/product.model';
import { CjStructuredDataUtilsService } from '../../structured-data/structured-data-utils-service';

@Injectable({
  providedIn: 'root',
})
export class CjProductPageMetaResolver extends ProductPageMetaResolver {
  protected override product$: Observable<CjProduct> = this.routingService.getRouterState().pipe(
    map((state) => state.state.params['productCode']),
    filter((code) => !!code),
    switchMap((code) => this.productService.get(code, ['default', ProductScope.DETAILS])),
    filter(isNotUndefined),
  );

  constructor(
    protected override routingService: RoutingService,
    protected override productService: ProductService,
    protected override translation: TranslationService,
    protected override basePageMetaResolver: BasePageMetaResolver,
    protected override pageLinkService: PageLinkService,
    private readonly utils: CjStructuredDataUtilsService,
    private readonly semanticPathService: SemanticPathService,
    private readonly languageService: LanguageService,
    private readonly basesiteService: BaseSiteService,
    private readonly basestoreService: CjBaseStoreService,
  ) {
    super(routingService, productService, translation, basePageMetaResolver, pageLinkService);
  }

  // resolveHeading is defined by the component CjProductSummaryComponent that uses product.name

  override resolveTitle(): Observable<string> {
    return this.product$.pipe(
      switchMap((product: CjProduct) =>
        product.metaTitle5j
          ? of(product.metaTitle5j)
          : this.translation.translate('pageMetaResolver.product.title', { title: product.name }),
      ),
    );
  }

  override resolveDescription(): Observable<string> {
    return this.product$.pipe(
      switchMap((product: CjProduct) =>
        product.metaDescription5j
          ? of(product.metaDescription5j)
          : this.translation.translate('pageMetaResolver.product.description', { description: product.shortDescription }),
      ),
    );
  }

  // resolveImage uses product.image.PRIMARY.zoom

  override resolveBreadcrumbs(): Observable<BreadcrumbMeta[]> {
    return combineLatest([this.product$.pipe(), this.translation.translate('common.home')]).pipe(
      map(([product, label]) => {
        const breadcrumbs = [];

        breadcrumbs.push({ label, link: '/' });
        const category = product.categories?.slice(-1)[0];
        if (category) {
          breadcrumbs.push({
            label: category.name || category.code,
            link: category.url,
          } as BreadcrumbMeta);
        }
        breadcrumbs.push({ label: product.name, link: product.url } as BreadcrumbMeta);

        return breadcrumbs;
      }),
    );
  }

  // resolveCanonicalUrl uses this.basePageMetaResolver.resolveCanonicalUrl (base product url)

  override resolveCanonicalUrl(): Observable<string> {
    return this.product$.pipe(
      switchMap((product) => this.findBaseProduct(product)),
      map((product) => {
        const url = this.utils.getFullUrl('', product.url || { cxRoute: 'product', params: product });
        return this.pageLinkService.getCanonicalUrl({}, url);
      }),
    );
  }

  // resolveRobots uses this.basePageMetaResolver.resolveRobots (page.robots)

  resolveHreflang(): Observable<CjHreflangMetas | undefined> {
    return this.product$.pipe(
      switchMap((product) =>
        combineLatest([
          this.languageService.getActive(),
          this.basestoreService.getActive(),
          this.basesiteService.getActive().pipe(switchMap((active) => this.basesiteService.get(active))),
        ]).pipe(
          map(([activeLang, activeBasestore, activeBasesite]) => {
            const hreflangs: CjHreflangMetas = {};

            if (activeBasesite?.defaultLanguage?.isocode) {
              hreflangs['x-default'] = this.getHreflangCommand(activeLang, activeBasesite?.defaultLanguage?.isocode, product);
            }

            activeBasestore?.languages?.forEach((lang) => {
              if (lang.isocode) hreflangs[lang.isocode] = this.getHreflangCommand(activeLang, lang.isocode, product);
            });

            return hreflangs;
          }),
        ),
      ),
    );
  }

  getHreflangCommand(activeLang: string, lang: string, product: CjProduct) {
    const commands = this.semanticPathService.transform({
      cxRoute: 'product',
      params: { code: product.code, slug: (product.names && product.names[lang])?.slug },
    });
    return `${this.utils.getHost()}/${commands.map((val) => (val == activeLang || val == '/' ? lang : val)).join('/')}`;
  }
}
