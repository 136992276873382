<form (ngSubmit)="verifyAddress()" [formGroup]="addressForm" class="cj-address-form">
  <div class="row justify-content-md-between cj-address-form__row">
    <div [ngClass]="formLayout === layouts.SHIPPING ? 'col-12 col-sm' : 'col-sm-12'">
      <!-- Alias -->
      <div class="form-group" *ngIf="formLayout === layouts.SHIPPING">
        <label>
          <span class="label-content">{{ 'addressForm.alias' | cxTranslate }}</span>
          <input class="form-control" type="text" formControlName="companyName" placeholder="{{ 'addressForm.alias' | cxTranslate }}" />
          <cx-form-errors [control]="addressForm.get('companyName')"></cx-form-errors>
        </label>
      </div>
      <div class="row">
        <div class="col-sm-6 form-group-item">
          <!-- Nombre -->
          <div class="form-group">
            <label>
              <span class="label-content required">{{ 'addressForm.firstName.label' | cxTranslate }}</span>
              <input
                required="true"
                class="form-control"
                type="text"
                placeholder="{{ 'addressForm.firstName.placeholder' | cxTranslate }}"
                formControlName="firstName"
              />
              <cx-form-errors [control]="addressForm.get('firstName')"></cx-form-errors>
            </label>
          </div>
        </div>
        <div class="col-sm-6 form-group-item">
          <!-- Apellidos -->
          <div class="form-group">
            <label>
              <span class="label-content required">{{ 'addressForm.lastName.label' | cxTranslate }}</span>
              <input
                required="true"
                type="text"
                class="form-control"
                placeholder="{{ 'addressForm.lastName.placeholder' | cxTranslate }}"
                formControlName="lastName"
              />
              <cx-form-errors [control]="addressForm.get('lastName')"></cx-form-errors>
            </label>
          </div>
        </div>
      </div>
      <!-- Email -->
      <div class="form-group" *ngIf="formLayout === layouts.SHIPPING">
        <label>
          <span class="label-content required">
            {{ 'addressForm.email.label' | cxTranslate }}
          </span>
          <input
            required="true"
            type="email"
            class="form-control"
            placeholder="{{ 'addressForm.email.placeholder' | cxTranslate }}"
            formControlName="email"
            maxlength="60"
          />
          <cx-form-errors [control]="addressForm.get('email')"></cx-form-errors>
        </label>
      </div>
      <!-- Teléfono -->
      <div class="form-group" *ngIf="formLayout !== layouts.BILLING">
        <label>
          <span class="label-content required">{{ 'addressForm.phoneNumber.label' | cxTranslate }}</span>
          <input
            required="true"
            type="tel"
            class="form-control"
            placeholder="{{ 'addressForm.phoneNumber.placeholder' | cxTranslate }}"
            formControlName="phone"
          />
          <cx-form-errors [control]="addressForm.get('phone')"></cx-form-errors>
        </label>
      </div>
    </div>
    <div [ngClass]="formLayout === layouts.SHIPPING ? 'col-12 col-sm' : 'col-sm-12'">
      <!-- Dirección 1 -->
      <div class="form-group">
        <label>
          <span class="label-content required">{{ 'addressForm.streetAddress' | cxTranslate }}</span>
          <input
            required="true"
            type="text"
            class="form-control"
            placeholder="{{ 'addressForm.address1' | cxTranslate }}"
            formControlName="line1"
          />
          <cx-form-errors [control]="addressForm.get('line1')"></cx-form-errors>
        </label>
        <label>
          <input type="text" class="form-control" placeholder="{{ 'addressForm.address2' | cxTranslate }}" formControlName="line2" />
          <cx-form-errors [control]="addressForm.get('line2')"></cx-form-errors>
        </label>
      </div>

      <div class="row">
        <!-- Código Postal -->
        <div class="col-sm-6 form-group-item">
          <div class="form-group">
            <label>
              <span class="label-content required">{{ 'addressForm.zipCode.label' | cxTranslate }}</span>
              <input
                required="true"
                type="text"
                class="form-control"
                placeholder="{{ 'addressForm.zipCode.placeholder' | cxTranslate }}"
                formControlName="postalCode"
              />
              <cx-form-errors [control]="addressForm.get('postalCode')"></cx-form-errors>
            </label>
          </div>
        </div>
        <!-- Ciudad -->
        <div class="col-sm-6 form-group-item">
          <div class="form-group">
            <label>
              <span class="label-content required">{{ 'addressForm.city.label' | cxTranslate }}</span>
              <input
                required="true"
                type="text"
                class="form-control"
                placeholder="{{ 'addressForm.city.placeholder' | cxTranslate }}"
                formControlName="town"
              />
              <cx-form-errors [control]="addressForm.get('town')"></cx-form-errors>
            </label>
          </div>
        </div>
        <!-- País -->
        <div class="col-sm-6 form-group-item">
          <div class="form-group" formGroupName="country">
            <ng-container *ngIf="countries$ | async as countries">
              <div *ngIf="countries.length !== 0">
                <label>
                  <span class="label-content required">{{ 'addressForm.country.label' | cxTranslate }}</span>
                  <ng-select
                    [inputAttrs]="{ required: 'true' }"
                    class="country-select"
                    id="country-select"
                    formControlName="isocode"
                    [searchable]="true"
                    [clearable]="false"
                    [items]="countries"
                    bindLabel="name"
                    bindValue="isocode"
                    placeholder="{{ 'addressForm.country.placeholder' | cxTranslate }}"
                    (change)="countrySelected($event)"
                    [cxNgSelectA11y]="{
                      ariaLabel: 'addressForm.country.label' | cxTranslate,
                    }"
                  >
                  </ng-select>
                  <cx-form-errors [control]="addressForm.get('country.isocode')"></cx-form-errors>
                </label>
              </div>
            </ng-container>
          </div>
        </div>
        <!-- Provincia -->
        <div class="col-sm-6 form-group-item">
          <ng-container *ngIf="regions$ | async as regions" formGroupName="region">
            <ng-container *ngIf="regions.length !== 0">
              <div class="form-group">
                <label>
                  <span class="label-content required">{{ 'addressForm.region.label' | cxTranslate }}</span>
                  <ng-select
                    [inputAttrs]="{ required: 'true' }"
                    class="region-select"
                    formControlName="isocode"
                    [searchable]="true"
                    [clearable]="false"
                    [items]="regions"
                    bindLabel="{{ regions[0].name ? 'name' : 'isocode' }}"
                    bindValue="{{ regions[0].isocode ? 'isocode' : 'region' }}"
                    placeholder="{{ 'addressForm.region.placeholder' | cxTranslate }}"
                    id="region-select"
                    [cxNgSelectA11y]="{
                      ariaLabel: 'addressForm.state' | cxTranslate,
                    }"
                  >
                  </ng-select>
                  <cx-form-errors [control]="addressForm.get('region.isocode')"></cx-form-errors>
                </label>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <!-- Teléfono -->
        <div class="form-group col-sm-12" *ngIf="formLayout === layouts.BILLING">
          <label>
            <span class="label-content required">{{ 'addressForm.phoneNumber.label' | cxTranslate }}</span>
            <input
              required="true"
              type="tel"
              class="form-control"
              placeholder="{{ 'addressForm.phoneNumber.placeholder' | cxTranslate }}"
              formControlName="phone"
            />
            <cx-form-errors [control]="addressForm.get('phone')"></cx-form-errors>
          </label>
        </div>
        <!-- Email -->
        <div class="form-group col-sm-12" *ngIf="formLayout === layouts.BILLING">
          <label>
            <span class="label-content required">
              {{ 'addressForm.email.label' | cxTranslate }}
            </span>
            <input
              required="true"
              type="email"
              class="form-control"
              placeholder="{{ 'addressForm.email.placeholder' | cxTranslate }}"
              formControlName="email"
              maxlength="60"
            />
            <cx-form-errors [control]="addressForm.get('email')"></cx-form-errors>
          </label>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showActionBtns" class="cx-address-form-btns">
    <div class="row justify-content-lg-around">
      <div class="col-12 col-md-5" *ngIf="showCancelBtn">
        <button class="btn btn-block btn-outline-primary" (click)="back()">
          {{ cancelBtnLabel || ('addressForm.chooseAddress' | cxTranslate) }}
        </button>
      </div>
      <div class="col-12 col-md-5 mt-4 mt-md-0">
        <button #submit class="btn btn-block btn-primary" type="submit">
          {{ actionBtnLabel || ('common.continue' | cxTranslate) }}
        </button>
      </div>
    </div>
  </div>
</form>
