import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CountryType, LoggerService, UserActions, normalizeHttpError } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CjBaseStoreConnector } from 'src/app/core/site-context/connectors/base-store-connector';
import { CjUserActions } from '../actions';

@Injectable()
export class CjBaseSiteCountriesEffects {
  protected logger = inject(LoggerService);

  loadBaseSiteCountries$: Observable<CjUserActions.CjDeliveryCountriesAction | UserActions.ClearUserMiscsData> = createEffect(() =>
    this.actions$.pipe(
      ofType(CjUserActions.LOAD_BASE_SITE_COUNTRIES),
      switchMap(() => {
        return this.connector.getBaseStoreCountries(CountryType.SHIPPING).pipe(
          switchMap((countries) => [new UserActions.ClearUserMiscsData(), new CjUserActions.LoadBaseSiteCountriesSuccess(countries)]),
          catchError((error) => of(new CjUserActions.LoadBaseSiteCountriesFail(normalizeHttpError(error, this.logger)))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private connector: CjBaseStoreConnector,
  ) {}
}
