<div class="cx-modal-container cj-added-to-cart-dialog" (esc)="dismissModal('Escape pressed')">
  <div *ngIf="cart$ | async as cart" class="cx-modal-content">
    <!-- Modal Header -->
    <div class="cx-dialog-header modal-header">
      <ng-container *ngIf="cart.entries && cart.entries.length">
        <div class="cx-dialog-promotions" *ngIf="orderPromotions$ | async as orderPromotions">
          <cj-promotions [promotions]="orderPromotions"></cj-promotions>
        </div>
        <div class="cx-dialog-promotions" *ngIf="orderAppliedPromotions$ | async as orderPromotions">
          <cj-promotions [promotions]="orderPromotions"></cj-promotions>
        </div>
      </ng-container>
      <p class="cx-dialog-title modal-title">{{ 'addToCart.title' | cxTranslate }}</p>
      <p class="cx-dialog-subtitle modal-subtitle">
        {{ cart.entries && cart.entries.length ? ('addToCart.subtitle.products' | cxTranslate) : ('addToCart.subtitle.empty' | cxTranslate) }}
      </p>
      <button
        type="button"
        class="close"
        attr.aria-label="{{ 'addToCart.closeModal' | cxTranslate }}"
        (click)="dismissModal('Cross click')"
      >
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </button>
    </div>

    <!-- Modal Body -->
    <div class="cx-dialog-body modal-body" *ngIf="cart.entries && cart.entries.length">
      <div class="cj-cart-item-list-container">
        <cj-cart-item-list
          [items]="cart.entries"
          [promotionLocation]="promotionLocation"
          [displayType]="cartItemDisplayTypes.MINI_CART"
        ></cj-cart-item-list>
      </div>
      <div class="cj-cart-item-list-totals position-sticky bg-white" style="bottom: 0">
        <p class="cart-total row mx-0" *ngIf="cart.totalPrice?.formattedValue">
          <span class="col pl-0">{{ 'addToCart.total' | cxTranslate }}</span>
          <span class="col-auto pr-0">{{ cart.totalPrice?.formattedValue }}</span>
        </p>
        <a class="btn btn-block btn-primary" [routerLink]="{ cxRoute: 'checkout' } | cxUrl">
          {{ 'addToCart.checkoutButton' | cxTranslate }}
        </a>
        <a class="btn btn-block btn-outline-primary" [routerLink]="{ cxRoute: 'cart' } | cxUrl">
          {{ 'addToCart.cartButton' | cxTranslate }}
        </a>
      </div>
    </div>
  </div>
</div>
