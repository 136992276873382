import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { CjHtmlSanitizerPipeModule } from 'src/app/shared/pipes/html-sanitizer/html-sanitizer-pipe.module';
import { CjProductDescriptionBannerComponent } from './product-description-banner.component';

@NgModule({
  imports: [CommonModule, CjHtmlSanitizerPipeModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsbornePDPBannerComponent: {
          component: () => import('./product-description-banner.component').then((m) => m.CjProductDescriptionBannerComponent),
        },
      },
    }),
  ],
  declarations: [CjProductDescriptionBannerComponent],
  exports: [CjProductDescriptionBannerComponent],
})
export class CjProductDescriptionBannerModule {}
