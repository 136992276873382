/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const payment = {
  paymentOptions: {
    title: 'Payment options',
    paymentText: 'Pay in 3 installments, without interest. <br/> {{ total }} 0% APR.',
    subTitle: 'Payment methods',
  },
};
