import { Injectable } from '@angular/core';
import { Product, ProductNameNormalizer } from '@spartacus/core';
import { CjOccProduct, CjOccProductLocalizedNames, CjProduct, CjProductLocalizedNames } from 'src/app/shared/models/product.model';

@Injectable({ providedIn: 'root' })
export class CjProductNameNormalizer extends ProductNameNormalizer {
  override convert(source: CjOccProduct, target?: CjProduct): CjProduct {
    target = target ?? { ...(source as unknown as Partial<CjProduct>) };

    if (source.name) {
      target.name = this.normalize(source.name);
      target.slug = this.normalizeSlug(source.name);
      target.nameHtml = source.name;
    }

    if (source.names) {
      target.names = this.normalizeLocalizedNames(source.names.entry);
    }
    return target as Product;
  }

  protected normalizeLocalizedNames(names: CjOccProductLocalizedNames[]): CjProductLocalizedNames {
    return names.reduce((acc, name) => {
      acc[name.key] = {
        name: this.normalize(name.value),
        slug: this.normalizeSlug(name.value),
      };

      return acc;
    }, {} as CjProductLocalizedNames);
  }

  protected override normalizeSlug(name: string): string {
    // Remove accents
    return super
      .normalizeSlug(name)
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }
}
