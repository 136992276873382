import { Injectable } from '@angular/core';
import { CmsComponent, CmsService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CjCmsUtilsService {
  constructor(private cmsService: CmsService) {}

  getComponentDataList<T extends CmsComponent>(uids: string): Observable<T>[] {
    return uids ? uids.split(' ').map((component: string) => this.cmsService.getComponentData<T>(component)) : [];
  }
}
