import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, PageMetaModule, provideConfig } from '@spartacus/core';
import { CjBreadcrumbComponent } from './breadcrumb.component';

@NgModule({
  imports: [CommonModule, RouterModule, PageMetaModule, I18nModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        BreadcrumbComponent: {
          component: CjBreadcrumbComponent,
        },
      },
    }),
  ],
  declarations: [CjBreadcrumbComponent],
  exports: [CjBreadcrumbComponent],
})
export class CjBreadcrumbModule {}
