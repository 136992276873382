export const address = {
  addressForm: {
    alias: 'Alias',
    firstName: {
      label: 'Nom',
      placeholder: 'Nom',
    },
    lastName: {
      label: 'Nom de famille',
      placeholder: 'Nom de famille',
    },
    email: {
      label: 'Courrier électronique (notification d’envoi)',
      placeholder: 'Courrier électronique',
    },
    phoneNumber: {
      label: 'Numéro de téléphone (optionnel)',
      placeholder: '(555) 555 - 0123',
    },
    address1: 'Rue et numéro',
    address2: 'Appt/Escalier (En option)',
    zipCode: {
      label: 'Code postal',
      placeholder: 'Code postal',
    },
    city: {
      label: 'Ville',
      placeholder: 'Ville',
    },
    nif: {
      label: 'NIF',
      placeholder: 'NIF',
    },
    country: {
      label: 'Pays',
      placeholder: 'Pays',
    },
    region: {
      label: 'Région',
      placeholder: 'Région',
    },
    saveAsDefault: 'Save as default',
    chooseAddress: 'Choose address',
    streetAddress: 'Street Address',
    aptSuite: 'Apt, Suite',
    setAsDefault: 'Définir l’adresse de livraison par défaut',
    titleRequired: 'Un titre est requis.',
    requestBilling: 'Demande de facture complète ?',
    sameAsDelivery: "Identique à l'adresse de livraison",
    userAddressAddSuccess: 'La nouvelle adresse a été ajoutée avec succès !',
    userAddressUpdateSuccess: 'L’adresse a été mise à jour avec succès !',
    userAddressDeleteSuccess: 'Adresse supprimée avec succès !',
    invalidAddress: 'Adresse invalide',
  },
  addressBook: {
    addNewDeliveryAddress: 'Ajouter une nouvelle adresse de livraison',
    editDeliveryAddress: 'Modifier l’adresse de livraison',
    areYouSureToDeleteAddress: 'Êtes-vous sûr de vouloir supprimer cette adresse ?',
    addNewAddress: 'Ajouter une nouvelle adresse',
    addAddress: 'Ajouter une adresse',
    updateAddress: 'Mise à jour de l’adresse',
    backToAddressList: 'Retour à la liste d’adresses',
    defaultDeliveryAddress: 'Adresse de livraison par défaut',
    additionalDeliveryAddress: 'Adresse de livraison supplémentaire {{number}}',
  },
  addressCard: {
    default: 'Adresse de livraison par défaut',
    selected: 'Choisi',
    setAsDefault: 'Définir par défaut',
  },
};
