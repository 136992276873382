import { DIALOG_TYPE, LAUNCH_CALLER, LayoutConfig } from '@spartacus/storefront';
import { CjOrderDetailReorderDialogComponent } from './order-detail-reorder-dialog.component';

export const ORDER_DETAIL_REORDER_DIALOG = LAUNCH_CALLER.REORDER;

export const cjOrderDetailReorderLayoutConfig: LayoutConfig = {
  launch: {
    REORDER: {
      inlineRoot: true,
      component: CjOrderDetailReorderDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};
