export enum EcommerceType {
  ADD_TO_CART = 'add_to_cart',
  REMOVE_FROM_CART = 'remove_from_cart',
  VIEW_CART = 'view_cart',
  VIEW_ITEM_LIST = 'view_item_list',
  VIEW_ITEM = 'view_item',
  SELECT_ITEM = 'select_item',
  BEGIN_CHECKOUT = 'begin_checkout',
  ADD_SHIPPING_INFO = 'add_shipping_info',
  ADD_PAYMENT_INFO = 'add_payment_info',
  PURCHASE = 'purchase',
  REFUND = 'refund',
  LOGIN = 'login',
  SIGN_UP = 'sign_up',
  GUEST_USER = 'guest_user',
  REORDER = 'reorder',
  FORM = 'form',
  OUT_OF_STOCK = 'out_of_stock',
  NEWSLETTER = 'newsletter',
  VIEW_PROMOTION = 'view_promotion',
  SELECT_PROMOTION = 'select_promotion',
}

export interface ProductItem {
  item_name: string;
  item_id: string;
  price: string;
  item_brand: string;
  item_category: string;
  item_category2?: string;
  item_category3?: string;
  item_variant: string;
  quantity: number;
}
