import { ViewConfig } from '@spartacus/storefront';

export const cjViewConfig: ViewConfig = {
  view: {
    defaultPageSize: 12,
    infiniteScroll: {
      active: true,
      productLimit: 0,
      showMoreButton: false,
    },
  },
};
