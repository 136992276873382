import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { HamburgerMenuService } from '@spartacus/storefront';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CjHamburgerMenuService extends HamburgerMenuService {
  private renderer: Renderer2;

  constructor(router: Router, rendererFactory: RendererFactory2) {
    super(router);
    this.renderer = rendererFactory.createRenderer(null, null);

    // Escucha eventos de navegación para forzar la expansión del menú.
    router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => this.toggle(true));
  }

  /**
   * Alterna la clase 'overflow-hidden' en el body según el estado de expansión.
   */
  override toggle(forceCollapse?: boolean): void {
    const body = document.body;
    const shouldBeExpanded = forceCollapse ? false : !this.isExpanded.value;
    this.isExpanded.next(shouldBeExpanded);

    if (this.isExpanded.value) {
      this.renderer.addClass(body, 'overflow-hidden');
    } else {
      this.renderer.removeClass(body, 'overflow-hidden');
    }
  }
}
