import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { CheckoutPaymentConnector, CheckoutPaymentService } from '@spartacus/checkout/base/core';
import { CheckoutQueryFacade } from '@spartacus/checkout/base/root';
import { Address, CommandService, EventService, OCC_USER_ID_ANONYMOUS, QueryService, UserIdService } from '@spartacus/core';
import { Observable, combineLatest, filter, map, take } from 'rxjs';
import { CjCheckoutActions } from '../store/actions';

@Injectable({
  providedIn: 'root',
})
export class CjCheckoutPaymentService extends CheckoutPaymentService {
  actionAllowed$: Observable<boolean> = combineLatest([this.userIdService.getUserId(), this.activeCartFacade.isGuestCart()]).pipe(
    map(([userId, isGuestCart]) => (userId && userId !== OCC_USER_ID_ANONYMOUS) || isGuestCart),
  );

  constructor(
    activeCartFacade: ActiveCartFacade,
    userIdService: UserIdService,
    queryService: QueryService,
    commandService: CommandService,
    eventService: EventService,
    checkoutPaymentConnector: CheckoutPaymentConnector,
    checkoutQueryFacade: CheckoutQueryFacade,
    private readonly store: Store<unknown>,
  ) {
    super(activeCartFacade, userIdService, queryService, commandService, eventService, checkoutPaymentConnector, checkoutQueryFacade);
  }

  /**
   * Create and set billing address in active cart
   * @param address billing address to be set
   */
  createAndSetBillingAddress(address: Address): void {
    combineLatest([this.actionAllowed$, this.userIdService.getUserId(), this.activeCartFacade.getActiveCartId()])
      .pipe(
        take(1),
        filter(([allowed, userId, cartId]) => allowed && !!userId && !!cartId),
      )
      .subscribe(([_, userId, cartId]) =>
        this.store.dispatch(
          new CjCheckoutActions.CreateBillingAddress({
            userId,
            cartId,
            address: address,
          }),
        ),
      );
  }

  /**
   * Set billing address in active cart
   * @param addressId Id of billing address to be set
   */
  setBillingAddress(addressId: string): void {
    combineLatest([this.actionAllowed$, this.userIdService.getUserId(), this.activeCartFacade.getActiveCartId()])
      .pipe(
        take(1),
        filter(([allowed, userId, cartId]) => allowed && !!userId && !!cartId),
      )
      .subscribe(([_, userId, cartId]) =>
        this.store.dispatch(
          new CjCheckoutActions.SetBillingAddress({
            userId,
            cartId,
            addressId: addressId,
          }),
        ),
      );
  }

  /**
   * Unset billing address from active cart
   */
  unsetBillingAddress(): void {
    combineLatest([this.actionAllowed$, this.userIdService.getUserId(), this.activeCartFacade.getActiveCartId()])
      .pipe(
        take(1),
        filter(([allowed, userId, cartId]) => allowed && !!userId && !!cartId),
      )
      .subscribe(([_, userId, cartId]) =>
        this.store.dispatch(
          new CjCheckoutActions.UnsetBillingAddress({
            userId,
            cartId,
          }),
        ),
      );
  }
}
