import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BreadcrumbComponent } from '@spartacus/storefront';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cj-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjBreadcrumbComponent extends BreadcrumbComponent {
  override ngOnInit(): void {
    super.ngOnInit();
    this.cjSetCrumbs();
  }

  private cjSetCrumbs(): void {
    this.crumbs$ = this.pageMetaService
      .getMeta()
      .pipe(map((meta) => (meta?.breadcrumbs?.length && meta?.breadcrumbs?.length >= 2 && meta?.breadcrumbs?.slice(-2, -1)) || []));
  }
}
