import { Injectable } from '@angular/core';
import { JsonLdScriptFactory } from '@spartacus/storefront';

@Injectable({
  providedIn: 'root',
})
export class CjJsonLdScriptFactory extends JsonLdScriptFactory {
  /**
   * Secure the given json-ld schema by encoding html characters (aka escaping), eg: <script> becomes &lt;script&gt;
   *
   * The given schema is not trusted, as malicious code could be injected (XSS)
   * into the json-ld script.
   */
  override escapeHtml(schema: {}[]): string {
    const div: HTMLScriptElement = this.renderer.createElement('div');
    div.textContent = JSON.stringify(
      Array.isArray(schema)
        ? {
            '@context': 'https://schema.org',
            '@graph': schema.filter((s) => !!s && Object.keys(s).length),
          }
        : schema,
    );
    return div.innerHTML;
  }
}
