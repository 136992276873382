import { Injectable, inject } from '@angular/core';
import { CmsService, getLastValueSync } from '@spartacus/core';
import { ProductListComponentService, ProductListRouteParams } from '@spartacus/storefront';
import { CjPage } from 'src/app/core/cms/model/page.model';

@Injectable({ providedIn: 'root' })
export class CjProductListComponentService extends ProductListComponentService {
  private cmsService: CmsService = inject(CmsService);

  /**
   * Resolves the search query from the given `ProductListRouteParams`.
   */
  protected override getQueryFromRouteParams({ query, categoryCode, brandCode }: ProductListRouteParams) {
    if (categoryCode) {
      const plpCategoryCode = (getLastValueSync(this.cmsService.getCurrentPage()) as CjPage)?.plpCategoryCode;
      return this.RELEVANCE_ALLCATEGORIES + (plpCategoryCode || categoryCode);
    }
    return super.getQueryFromRouteParams({ query, categoryCode, brandCode });
  }
}
