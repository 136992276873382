import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CmsComponent, CmsService, ContentSlotComponentData, ContentSlotData, Page, PageType } from '@spartacus/core';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { CjCmsProductBanner } from 'src/app/cms-components/content/product-banner/product-banner.model';
import { CjCmsProductTwoColumnsComponent } from 'src/app/cms-components/content/product-two-columns/product-two-columns.model';
import { StateWithProductGrid } from '../store/product-grid-state';

@Injectable({
  providedIn: 'root',
})
export class CjFilteredProductsService {
  constructor(
    protected store: Store<StateWithProductGrid>,
    private readonly cmsService: CmsService,
  ) {}

  getFilteredProductsFromCategoryPage(categoryCode: string, componentTypes: string[]) {
    return this.cmsService.getPageState({ type: PageType.CATEGORY_PAGE, id: categoryCode }).pipe(
      filter((p: Page) => !!p),
      map((page) => page.slots),
      switchMap((slots: { [key: string]: ContentSlotData } | undefined) => {
        if (!slots) return of([]);
        const components = this.getComponentsWithProducts(slots, componentTypes);
        return this.getProductsFromComponents(components);
      }),
    );
  }

  getComponentsWithProducts(slots: { [key: string]: ContentSlotData }, componentTypes: string[]) {
    return Object.values(slots)
      .reduce((acc: ContentSlotComponentData[], slot) => {
        acc.push(
          ...(slot?.components || []).filter(
            (component: ContentSlotComponentData) => component?.typeCode && component?.uid && componentTypes.includes(component?.typeCode),
          ),
        );
        return acc;
      }, [])
      .map((component: ContentSlotComponentData) => component.uid || '');
  }

  getProductsFromComponents(uids: string[]): Observable<string[]> {
    return uids.length
      ? combineLatest(
          uids.map((uid) =>
            this.cmsService.getComponentData(uid).pipe(
              map((component: CmsComponent | null) => {
                switch (component?.typeCode) {
                  case 'OsborneProductBannerComponent':
                  case 'OsborneProductTwoColumnsComponent':
                    return (component as CjCmsProductBanner | CjCmsProductTwoColumnsComponent)?.productCard?.product || '';
                  case 'OsborneTwoProductComponent':
                    return '';
                  default:
                    return '';
                }
              }),
            ),
          ),
        ).pipe(
          map((productCodes: (string | string[])[]) =>
            productCodes.reduce((acc: string[], codes: string | string[]) => {
              typeof codes === 'string' ? acc.push(codes) : acc.push(...codes);
              return acc;
            }, []),
          ),
        )
      : of([]);
  }
}
