import { NgModule } from '@angular/core';
import { CjCartModule } from './cart/cart.module';
import { CjCheckoutModule } from './checkout/checkout.module';
import { CjOccModule } from './occ/occ.module';
import { CjOrderModule } from './order/order.module';

@NgModule({
  imports: [CjCartModule, CjCheckoutModule, CjOccModule, CjOrderModule],
})
export class CjCoreModule {}
