import { Injectable } from '@angular/core';
import { CmsService, LanguageService } from '@spartacus/core';
import { SchemaBuilder } from '@spartacus/storefront';
import { combineLatest, map, Observable } from 'rxjs';
import { CjStructuredDataUtilsService } from '../../../structured-data-utils-service';

@Injectable({
  providedIn: 'root',
})
export class CjContactPointSchemaBuilder implements SchemaBuilder {
  constructor(
    private readonly utils: CjStructuredDataUtilsService,
    private readonly languageService: LanguageService,
    private readonly cmsService: CmsService,
  ) {}

  build(): Observable<any> {
    // Only in contact page
    // this.languageService.getActive() is used to ensure urls contain the site language
    return combineLatest([this.cmsService.getCurrentPage(), this.languageService.getActive()]).pipe(
      map(([page]) =>
        page?.pageId == 'contact'
          ? {
              '@type': 'ContactPage',
              '@id': this.utils.getCurrentUrl('#webpage'),
              url: this.utils.getCurrentUrl(),
              breadcrumb: { '@id': this.utils.getCurrentUrl('#breadcrumb') },
              image: { '@id': this.utils.getCurrentUrl('#primaryimage') },
              publisher: { '@id': this.utils.getFullUrl('#organization') },
              isPartOf: { '@id': this.utils.getFullUrl('#website') },
            }
          : {},
      ),
    );
  }
}
