<cx-spinner class="overlay" *ngIf="isUpdating$ | async"></cx-spinner>

<form *ngIf="data$ | async as data" (ngSubmit)="onSubmit()" [formGroup]="form">
  <h2>{{ 'preferenceCenter.form.title' | cxTranslate }}</h2>
  
  <label>
    <span class="label-content">{{ 'preferenceCenter.form.name.label' | cxTranslate }}</span>
    <input
      type="text"
      class="form-control"
      name="name"
      placeholder="{{ 'preferenceCenter.form.name.placeholder' | cxTranslate }}"
      formControlName="name"
    />
    <cx-form-errors [control]="form.get('name')"></cx-form-errors>
  </label>

  <label>
    <span class="label-content">{{ 'preferenceCenter.form.surnames.label' | cxTranslate }}</span>
    <input
      type="text"
      class="form-control"
      name="surnames"
      placeholder="{{ 'preferenceCenter.form.surnames.placeholder' | cxTranslate }}"
      formControlName="surnames"
    />
    <cx-form-errors [control]="form.get('surnames')"></cx-form-errors>
  </label>
  
  <label class="email-label">
    <span class="label-content label-content-email">
      {{ 'preferenceCenter.form.email.label' | cxTranslate }}
      <cx-icon [type]="iconTypes.INFO"></cx-icon>
    </span>

    <div class="email-contact">
      <p>{{ 'preferenceCenter.form.email.contact' | cxTranslate }}</p>
    </div>

    <input
      type="email"
      class="form-control"
      formControlName="email"
      placeholder="{{ 'preferenceCenter.form.email.placeholder' | cxTranslate }}"
      readonly
    />
    <cx-form-errors [control]="form.get('email')"></cx-form-errors>
  </label>

  <label>
    <span class="label-content">{{ 'preferenceCenter.form.birthDate.label' | cxTranslate }}</span>
    <input
      type="date"
      class="form-control"
      name="birthDate"
      placeholder="{{ 'preferenceCenter.form.birthDate.placeholder' | cxTranslate }}"
      formControlName="birthDate"
    />
    <cx-form-errors [control]="form.get('birthDate')"></cx-form-errors>
  </label>

  <label>
    <span class="label-content">{{ 'preferenceCenter.form.province.label' | cxTranslate }}</span>
    <ng-select
      formControlName="province"
      [searchable]="true"
      [clearable]="false"
      [items]="provinces"
      bindLabel="name"
      bindValue="isocode"
      placeholder="{{ 'preferenceCenter.form.province.placeholder' | cxTranslate }}"
    >
    </ng-select>
    <cx-form-errors [control]="form.get('province')"></cx-form-errors>
  </label>

  <label>
    <span class="label-content">{{ 'preferenceCenter.form.phoneNumber.label' | cxTranslate }}</span>
    <input
      type="tel"
      pattern="[0-9]*"
      class="form-control"
      name="phoneNumber"
      placeholder="{{ 'preferenceCenter.form.phoneNumber.placeholder' | cxTranslate }}"
      formControlName="phoneNumber"
    />
    <cx-form-errors 
      [control]="form.get('phoneNumber')"
      [translationParams]="{
        minLength: form.get('phoneNumber')?.errors?.['minlength']?.requiredLength,
        maxLength: form.get('phoneNumber')?.errors?.['maxlengthWithNumber']?.requiredLength
      }"
    ></cx-form-errors>
  </label>

  <label>
    <span class="label-content">{{ 'preferenceCenter.form.language.label' | cxTranslate }}</span>
    <ng-select
      formControlName="language"
      [searchable]="true"
      [clearable]="false"
      [items]="languages"
      bindLabel="name"
      bindValue="isocode"
      placeholder="{{ 'preferenceCenter.form.language.placeholder' | cxTranslate }}"
    >
    </ng-select>
    <cx-form-errors [control]="form.get('province')"></cx-form-errors>
  </label>

  <label for="unsubscribed">
    <span class="label-content unsubscribe">{{ 'preferenceCenter.form.unsubscribe.title' | cxTranslate }}</span>
    <div class="unsubscribe-wrapper">
      <div class="toggle-button">
        <input type="checkbox" id="toggle-button-checkbox" formControlName="subscribed">
        <label class="toggle-button-switch" for="toggle-button-checkbox"></label>
        <div class="toggle-button-body">
          <div class="toggle-button-handle"></div>
        </div>
      </div>

      <p>{{ 'preferenceCenter.form.unsubscribe.placeholder' | cxTranslate }}</p>
    </div>

    <cx-form-errors [control]="form.get('unsubscribed')"></cx-form-errors>
  </label>
  
  <button type="submit" class="btn btn-primary" [disabled]="!form.valid">
    {{ 'preferenceCenter.form.update' | cxTranslate }}
  </button>
</form>
