import { Component } from '@angular/core';
import { ICON_TYPE, LaunchDialogService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CjProductAttributesDialogData } from '../product-attributes.model';

@Component({
  selector: 'cj-product-attributes-dialog',
  templateUrl: './product-attributes-dialog.component.html',
  styleUrls: ['./product-attributes-dialog.component.scss'],
})
export class CjProductAttributesDialogComponent {
  iconTypes = ICON_TYPE;

  data$: Observable<CjProductAttributesDialogData> = this.launchDialogService.data$;

  constructor(
    private readonly launchDialogService: LaunchDialogService,
  ) { }

  dismissModal(reason: string) {
    this.launchDialogService.closeDialog(reason);
  }
}
