import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { OrderEntry } from '@spartacus/cart/base/root';
import { OrderAmendService } from '@spartacus/order/components';
import { ICON_TYPE, LaunchDialogService } from '@spartacus/storefront';
import { Observable, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'cj-order-detail-cancel-dialog',
  templateUrl: './order-detail-cancel-dialog.component.html',
  styleUrl: './order-detail-cancel-dialog.component.scss',
})
export class CjOrderDetailCancelDialogComponent {
  iconTypes = ICON_TYPE;
  orderCode: string | undefined;

  private destroy$ = new Subject<void>();

  constructor(
    protected orderAmendService: OrderAmendService,
    private readonly dialogService: LaunchDialogService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  form$: Observable<UntypedFormGroup> = this.orderAmendService.getForm().pipe(tap((form) => (this.orderCode = form.value.orderCode)));

  entries$: Observable<OrderEntry[]> = this.orderAmendService.getEntries();

  dismissModal(reason: string) {
    this.dialogService.closeDialog(reason);
  }

  getControl(form: UntypedFormGroup, entry: OrderEntry): UntypedFormControl {
    const control = <UntypedFormControl>form.get('entries')?.get(entry.entryNumber?.toString() ?? '');
    return control;
  }

  setAll(form: UntypedFormGroup): void {
    this.entries$.pipe(takeUntil(this.destroy$)).subscribe((entries) => {
      entries.forEach((entry) => this.getControl(form, entry).setValue(this.orderAmendService.getMaxAmendQuantity(entry)));
    });
  }

  submit(form: UntypedFormGroup) {
    this.setAll(form);
    if (form.valid) {
      this.orderAmendService.save();
      this.dialogService.closeDialog('button click');
    } else {
      form.markAllAsTouched();
    }
  }
}
