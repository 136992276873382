export enum BusinessFriends {
  EMPLOYEE = 'osborneemployeecustomergroup',
  SHAREHOLDER = 'osborneshareholdercustomergroup',
  FRIENDS = 'osbornefriendscustomergroup',
  MARKETPLACE = 'osbornemarketplacecustomergroup',
  COMPANY = 'osbornecompanycustomergroup',
  RETIRED = 'osborneretiredcustomergroup',
  COLLECTIVE = 'osbornecollectivecustomergroup',
  EMPLOYEE_CREDIT_CARD = 'osborneemployecreditcardcustomergroup',
  PUBLIC = 'osbornepubliccustomergroup',
  RIOFRIO = 'riofriocustomergroup',
}
