import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { CjNotFoundPageMarkerComponent } from './not-found-page-marker.component';

@NgModule({
  imports: [CommonModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        NotFoundPageMarkerComponent: {
          component: () => import('./not-found-page-marker.component').then((m) => m.CjNotFoundPageMarkerComponent),
        },
      },
    }),
  ],
  declarations: [CjNotFoundPageMarkerComponent],
  exports: [CjNotFoundPageMarkerComponent],
})
export class CjNotFoundPageMarkerModule {}
