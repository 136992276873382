import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { CjOrderDetailModifyDialogComponent } from './order-detail-modify-dialog.component';

export const ORDER_DETAIL_MODIFY_DIALOG = 'ORDER_DETAIL_MODIFY';

export const cjOrderDetailModifyLayoutConfig: LayoutConfig = {
  launch: {
    ORDER_DETAIL_MODIFY: {
      inlineRoot: true,
      component: CjOrderDetailModifyDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};
