import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CmsService, ProductSearchPage } from '@spartacus/core';
import { ProductListComponentService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { CjPage } from 'src/app/core/cms/model/page.model';
import { CjFilteredProductSearch } from 'src/app/shared/models/product.model';
import { CjProductGridElement } from '../../product-list/product-list.model';
import { ProductGridActions } from '../store/actions';
import { CjProductGridElements, CjProductGridElementsExtraData, StateWithProductGrid } from '../store/product-grid-state';
import { ProductGridSelectors } from '../store/selectors';

@Injectable({
  providedIn: 'root',
})
export class CjProductGridService {
  filteredProducts$: Observable<CjFilteredProductSearch> = this.cmsService.getCurrentPage().pipe(
    map((page: CjPage) => page.plpCategoryCode || ''),
    filter((categoryCode) => !!categoryCode),
    switchMap((categoryCode) =>
      this.productListComponentService.model$.pipe(
        filter((model: ProductSearchPage) => categoryCode === model.currentQuery?.query?.value?.split(':').reverse()[0]),
        switchMap((model: ProductSearchPage) =>
          this.getFilterProducts(categoryCode!).pipe(
            map((filterProducts) => ({
              products: (model.products || []).filter((product) => {
                return product.code && !filterProducts.includes(product.code);
              }),
              moreProducts:
                model.pagination?.totalPages && model.pagination?.currentPage
                  ? model.pagination?.currentPage + 1 !== model.pagination?.totalPages
                  : true,
              page: model.pagination?.currentPage || 0,
            })),
          ),
        ),
      ),
    ),
  );

  constructor(
    protected store: Store<StateWithProductGrid>,
    private readonly productListComponentService: ProductListComponentService,
    private readonly cmsService: CmsService,
  ) {}

  addProductToFilter(category: string, ...products: string[]): void {
    if (category && products.length) {
      products.forEach((product) => this.store.dispatch(new ProductGridActions.AddFilteredProduct({ category, product })));
    }
  }
  setOneColumnBannerList(category: string, banners: string): void {
    if (category && banners) {
      this.store.dispatch(new ProductGridActions.SetOneColumnBanner({ category, banners }));
    }
  }
  setThreeColumnBannerList(category: string, banners: string): void {
    if (category && banners) {
      this.store.dispatch(new ProductGridActions.SetThreeColumnBanner({ category, banners }));
    }
  }
  saveElements(category: string, elements: CjProductGridElement[], extraData: CjProductGridElementsExtraData): void {
    if (category && elements && extraData) {
      this.store.dispatch(new ProductGridActions.SaveElements({ category, elements, extraData }));
    }
  }

  getFilterProducts(category: string): Observable<string[]> {
    return this.store.pipe(select(ProductGridSelectors.getFilterProductList(category)));
  }
  getOneColumnBannerList(category: string): Observable<string[]> {
    return this.store.pipe(select(ProductGridSelectors.getOneColumnBannerList(category)));
  }
  getThreeColumnBannerList(category: string): Observable<string[]> {
    return this.store.pipe(select(ProductGridSelectors.getThreeColumnBannerList(category)));
  }
  getElements(category: string): Observable<CjProductGridElements> {
    return this.store.pipe(select(ProductGridSelectors.getElements(category)));
  }
  getLastPage(category: string): Observable<number> {
    return this.store.pipe(select(ProductGridSelectors.getLastPage(category)));
  }
}
