import { NgModule } from '@angular/core';
import { UserAddressAdapter } from '@spartacus/core';
import { CjOccUserAddressAdapter } from './occ-user-address.adapter';

@NgModule({
  providers: [
    {
      provide: UserAddressAdapter,
      useClass: CjOccUserAddressAdapter,
    },
  ],
})
export class CjOccUserModule {}
