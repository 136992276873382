import { NgModule } from '@angular/core';
import { provideConfig } from '@spartacus/core';
import { cjCartConfig } from './configuration/cart-config';
import { cjCheckoutConfig } from './configuration/checkout-config';
import { cjOccConfig } from './configuration/endpoint-config';
import { cjI18nConfig } from './configuration/i18n-config';
import { cjLayoutConfig } from './configuration/layout-config';
import { cjMediaConfig } from './configuration/media-config';
import { cjPageMetaConfig } from './configuration/page-meta-config';
import { cjPwaConfig } from './configuration/pwa-config';
import { cjRoutesConfig } from './configuration/routes-config';
import { cjSeoConfig } from './configuration/seo-config';
import { cjViewConfig } from './configuration/view-config';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    provideConfig(cjLayoutConfig),
    provideConfig(cjI18nConfig),
    provideConfig(cjViewConfig),
    provideConfig(cjOccConfig),
    provideConfig(cjMediaConfig),
    provideConfig(cjRoutesConfig),
    provideConfig(cjCheckoutConfig),
    provideConfig(cjCartConfig),
    provideConfig(cjPwaConfig),
    provideConfig(cjSeoConfig),
    provideConfig(cjPageMetaConfig),
  ],
})
export class CjConfigurationModule {}
