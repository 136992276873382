import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { CjPopupDialogComponent } from './popup-dialog.component';

export const POPUP_DIALOG = 'POPUP_DIALOG';

export const cjPopupLayoutConfig: LayoutConfig = {
  launch: {
    POPUP_DIALOG: {
      inline: true,
      component: CjPopupDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};
