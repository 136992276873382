export const myAccount = {
  myAccountCommon: {
    hello: 'Hola, ',
    myAccount: 'Mi cuenta',

  },
  updatePasswordForm: {
    passwordInfo: 'Contraseña',
    oldPassword: {
      label: 'Contraseña anterior',
      placeholder: 'Contraseña anterior',
    },
    oldPasswordIsRequired: 'Se requiere contraseña anterior.',
    newPassword: {
      label: 'Nueva contraseña',
      placeholder: 'Nueva contraseña',
    },
    passwordMinRequirements: 'La contraseña debe tener un mínimo de seis caracteres, con una letra mayúscula, un número y un símbolo.',
    confirmPassword: {
      label: 'Confirmar nueva contraseña',
      placeholder: 'Confirmar nueva contraseña',
    },
    bothPasswordMustMatch: 'Ambas contraseñas deben coincidir',
    passwordUpdateSuccess: 'Contraseña actualizada con éxito',
    accessDeniedError: 'Acceso denegado',
  },
  updateProfileForm: {
    title: 'Tratamiento',
    firstName: {
      label: 'Nombre y apellidos',
      placeholder: 'Nombre y apellidos',
    },
    firstNameIsRequired: 'Se requiere el nombre.',
    contactInfo: 'Contacto',
    addressInfo: 'Dirección de facturación',
    email: 'Correo electrónico',
    documentIdentifier: 'NIF',
    phone: 'Número de teléfono (para transportista)',
    address: 'Dirección',
    postalCode: 'Código Postal',
    town: 'Ciudad',
    country: {
      label: 'País',
      placeholder: 'Elegir un país',
    },
    region: {
      label: 'Provincia',
      placeholder: 'Provincia',
    },
    profileUpdateSuccess: 'Datos personales actualizados con éxito',
    updateEmailText: 'Para modificaciones del email, por favor ponte en contacto con sac_web@osborne.es',
    placeholder: {
      phone: '+34XXXXXXXXX',
      address1: 'Nombre y número de la calle',
      address2: 'Apartamento, escalera, piso...',
    },
  },
};
