import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { Subscription, map } from 'rxjs';
import { CjPage } from 'src/app/core/cms/model/page.model';

export const DEFAULT_HEADER_ACCENT_COLOR = 'var(--cx-color-primary)';

/**
 * Directive that adds a --header-accent-color css var to the html element style. Value is obtained from the pages ThemeColor attribute.
 */
@Directive({
  selector: '[cjHeaderThemeColor]',
})
export class CjHeaderThemeColorDirective implements OnInit, OnDestroy {
  sub$ = new Subscription();
  headerAccentColor$ = this.cmsService
    .getCurrentPage()
    .pipe(map((page) => (page as CjPage)?.headerAccentColor || DEFAULT_HEADER_ACCENT_COLOR));

  constructor(
    private readonly renderer: Renderer2,
    private readonly element: ElementRef,
    private readonly cmsService: CmsService,
  ) {}

  ngOnInit(): void {
    this.sub$.add(
      this.headerAccentColor$.subscribe((color) =>
        this.renderer.setProperty(this.element.nativeElement, 'style', `--header-accent-color: ${color}`),
      ),
    );
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
