import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { GenericLinkModule, MediaModule } from '@spartacus/storefront';
import { CjHtmlSanitizerPipeModule } from 'src/app/shared/pipes/html-sanitizer/html-sanitizer-pipe.module';
import { CjLinkComponent } from './link.component';

@NgModule({
  imports: [CommonModule, RouterModule, GenericLinkModule, MediaModule, CjHtmlSanitizerPipeModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CMSLinkComponent: {
          component: () => import('./link.component').then((m) => m.CjLinkComponent),
        },
        EnrichedCMSLinkComponent: {
          component: () => import('./link.component').then((m) => m.CjLinkComponent),
        },
      },
    }),
  ],
  declarations: [CjLinkComponent],
  exports: [CjLinkComponent],
})
export class CjLinkModule {}
