import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { MediaModule, PageComponentModule } from '@spartacus/storefront';
import { CjContactMapComponent } from './contact-map.component';

@NgModule({
  imports: [CommonModule, PageComponentModule, MediaModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsborneContactMapComponent: {
          component: () =>
            import('./contact-map.component').then(
              (m) => m.CjContactMapComponent,
            ),
        },
      },
    }),
  ],
  declarations: [CjContactMapComponent],
  exports: [CjContactMapComponent],
})
export class CjContactMapModule {}
