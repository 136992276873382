<div class="register-guest">
  <h3 class="register-guest__title">
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="13.5" fill="#00263F" stroke="#00263F" />
      <path
        d="M13.2994 20.2567C12.4892 20.2567 11.6928 19.9743 11.0353 19.4515C10.7351 19.2111 10.428 18.8772 10.1764 18.4994L10.8097 17.9957C10.9954 18.2629 11.2573 18.5643 11.4933 18.7513C12.0659 19.2073 12.772 19.4324 13.4799 19.3866C14.3005 19.3332 15.0518 18.9306 15.5983 18.2552C16.1431 17.5798 16.4172 16.7098 16.3686 15.8073C16.32 14.9049 15.954 14.0806 15.3398 13.4796C14.7256 12.8805 13.9344 12.581 13.1155 12.6363C12.8744 12.6516 12.6419 12.6993 12.4198 12.7718C12.1994 12.8424 11.986 12.9435 11.7987 13.0618C11.7536 13.0904 11.7137 13.1152 11.6876 13.1343C11.3614 13.3537 11.0596 13.39 10.7889 13.245C10.5078 13.0923 10.3309 12.7756 10.3291 12.4169V9.11044H16.4814V9.97283H11.1133V12.3139C11.1133 12.3539 11.1255 12.3807 11.1498 12.4016C11.1741 12.4245 11.2192 12.4379 11.2608 12.4093C11.2938 12.3864 11.3424 12.3539 11.4014 12.3158C11.6477 12.1574 11.9305 12.0296 12.2116 11.9399C12.4909 11.8503 12.7737 11.7949 13.0739 11.7758C14.1027 11.7091 15.0899 12.0792 15.862 12.8328C16.6341 13.5865 17.0921 14.6244 17.1546 15.7558C17.2153 16.8891 16.8735 17.9786 16.1882 18.8276C15.5029 19.6766 14.559 20.1803 13.5302 20.249C13.4521 20.2528 13.3758 20.2567 13.2994 20.2567ZM17.9509 21.3995C19.6773 19.5812 20.0399 17.4157 20.0399 16.0573V6.10352H16.4831V6.96591H19.2539L19.2557 16.0573C19.2557 18.322 18.1401 19.9743 17.4096 20.7489C16.3374 21.886 14.8141 22.5557 13.3515 22.5557C10.2025 22.5557 8.66354 20.1155 8.48136 19.8484L7.85156 20.3502C8.04068 20.6592 9.8416 23.4086 13.3445 23.4086C15.05 23.4086 16.7312 22.6836 17.9509 21.3995Z"
        fill="white"
      />
    </svg>
    {{ 'checkoutOrderConfirmation.createYourAccount' | cxTranslate }}
  </h3>

  <p class="register-guest__text">{{ 'checkoutOrderConfirmation.createAccountRequirements.requirements' | cxTranslate }}</p>
  <ul class="register-guest__list">
    <li>{{ 'checkoutOrderConfirmation.createAccountRequirements.followOrder' | cxTranslate }}</li>
    <li>{{ 'checkoutOrderConfirmation.createAccountRequirements.downloadInvoices' | cxTranslate }}</li>
    <li>{{ 'checkoutOrderConfirmation.createAccountRequirements.buyQuicker' | cxTranslate }}</li>
  </ul>

  <button (click)="openDialog()" class="register-guest__btn">{{ 'checkoutOrderConfirmation.createAccount' | cxTranslate }}</button>
</div>
