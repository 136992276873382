import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UpdateProfileComponentService } from '@spartacus/user/profile/components';
import { Subscription } from 'rxjs';
import { CustomValidators } from 'src/app/shared/utils/validators/custom-validators';

@Injectable()
export class CjUpdateProfileComponentService extends UpdateProfileComponentService {
  subscription: Subscription = new Subscription();
  isDocumentIdentifierReadOnly: boolean = false;

  override form: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    uid: new FormControl(''),
    exclusiveRole: new FormControl(''),
    supportedOnBehalfOfExclusiveRole: new FormControl(''),
    documentIdentifier: new FormControl('', [Validators.required]),
    defaultAddress: new FormGroup({
      phone: new FormControl('', [Validators.required, CustomValidators.phoneValidator]),
      line1: new FormControl('', [Validators.required, Validators.maxLength(35)]),
      line2: new FormControl('', Validators.maxLength(35)),
      town: new FormControl('', [Validators.required, Validators.maxLength(25)]),
      postalCode: new FormControl('', [Validators.required, Validators.maxLength(12), CustomValidators.postalCode('country.isocode')]),
      country: new FormGroup({
        isocode: new FormControl(null, Validators.required),
      }),
      region: new FormGroup({
        isocode: new FormControl(null, Validators.required),
      }),
    }),
  });

  /**
   * Updates the user's details and handles the UI.
   */
  newUpdateProfile(callback: any): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.busy$.next(true);

    const updateSubscription = this.userProfile
      .update({
        ...this.form.value,
        defaultAddress: {
          ...this.form.get('defaultAddress')?.value,
          firstName: this.form.get('name')?.value,
          lastName: '',
          email: this.form.get('uid')?.value,
        },
      })
      .subscribe({
        next: () => {
          this.onSuccess();
          this.isDocumentIdentifierReadOnly = true;
          callback();
        },
        error: (error: Error) => {
          this.onError(error);
          this.form.get('documentIdentifier')?.setErrors({
            nifError: true,
          });
          return;
        },
      });

    this.subscription.add(updateSubscription);
  }
}
