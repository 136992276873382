import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { MediaModule, StructuredDataModule } from '@spartacus/storefront';
import { CjMediaModule } from 'src/app/shared/components/media/media.module';
import { CjGsapAnimationDirectiveModule } from 'src/app/shared/directives/directives.module';
import { CjCategoryBannerComponent } from './category-banner.component';

@NgModule({
  imports: [CommonModule, MediaModule, CjMediaModule, StructuredDataModule, CjGsapAnimationDirectiveModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsborneCategoryBannerComponent: {
          component: () => import('./category-banner.component').then((m) => m.CjCategoryBannerComponent),
        },
      },
    }),
  ],
  declarations: [CjCategoryBannerComponent],
  exports: [CjCategoryBannerComponent],
})
export class CjCategoryBannerModule {}
