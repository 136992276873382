import { NgModule } from '@angular/core';
import { BaseStorefrontModule } from '@spartacus/storefront';
import { CjConfigurationModule } from './cj-configuration.module';
import { SpartacusConfigurationModule } from './spartacus-configuration.module';
import { SpartacusFeaturesModule } from './spartacus-features.module';
import { TagManagerFeatureModule } from './features/tracking/tag-manager-feature.module';

@NgModule({
  declarations: [],
  imports: [BaseStorefrontModule, SpartacusFeaturesModule, SpartacusConfigurationModule, CjConfigurationModule, TagManagerFeatureModule],
  exports: [BaseStorefrontModule],
})
export class SpartacusModule {}
