import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { MediaModule, PageComponentModule, ProductListComponentService, ProductListModule } from '@spartacus/storefront';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CjProductCardModule } from '../card/product-card.module';
import { CjFilteredProductsGuard } from '../core/guards/filtered-products.guard';
import { CjProductListComponentService } from './product-list-component.service';
import { CjProductListComponent } from './product-list.component';
import { CjProductScrollComponentService } from './product-scroll/product-scroll-component.service';
import { CjProductScrollComponent } from './product-scroll/product-scroll.component';

@NgModule({
  imports: [
    CommonModule,
    MediaModule,
    UrlModule,
    RouterModule,
    I18nModule,
    InfiniteScrollModule,
    PageComponentModule,
    ProductListModule,
    CjProductCardModule,
  ],
  providers: [
    provideConfig({
      cmsComponents: {
        CMSProductListComponent: {
          component: () => import('./product-list.component').then((m) => m.CjProductListComponent),
          guards: [CjFilteredProductsGuard],
        },
      },
    }),

    CjProductScrollComponentService,
    { provide: ProductListComponentService, useClass: CjProductListComponentService },
  ],
  declarations: [CjProductListComponent, CjProductScrollComponent],
  exports: [CjProductListComponent, CjProductScrollComponent],
})
export class CjProductListModule {}
