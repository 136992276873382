import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, take, tap } from 'rxjs';
import { CjBaseStoreActions } from '../store/actions';
import { CjBaseStoreSelectors } from '../store/selector';
import { StateWithBaseStores } from '../store/base-store-state';
import { UserIdService, isNotUndefined } from '@spartacus/core';
import { CjBaseStore } from 'src/app/core/model/misc.mode';

@Injectable({
  providedIn: 'root',
})
export class CjBaseStoreService {
  constructor(
    private readonly store: Store<StateWithBaseStores>,
    private userIdService: UserIdService,
  ) {}

  loadBaseStores() {
    this.userIdService
      .getUserId()
      .pipe(take(1))
      .subscribe((userId: string) => {
        this.store.dispatch(new CjBaseStoreActions.LoadBaseStores({ userId }));
      });
  }

  getBaseStores() {
    return this.store.select(CjBaseStoreSelectors.getBaseStores).pipe(
      tap((baseStores) => baseStores === undefined && this.loadBaseStores()),
      map((baseStores) => baseStores || []),
    );
  }

  getActive() {
    return this.store.select(CjBaseStoreSelectors.getActiveBaseStore);
  }

  setActive(code: CjBaseStore) {
    if (code) {
      this.store.dispatch(new CjBaseStoreActions.SetActiveBaseStore(code));
    }
  }

  isInitialized(): boolean {
    let valueInitialized = false;
    this.getActive()
      .pipe(filter(isNotUndefined), take(1))
      .subscribe(() => (valueInitialized = true));

    return valueInitialized;
  }
}
