import { StateUtils } from '@spartacus/core';
import { CjGiftCard } from '../model/gift-card.model';

export const GIFT_CARD_FEATURE = 'giftCards';
export const GIFT_CARD_LOAD_PROCESS_ID = 'loadGiftCards';

export interface StateWithGiftCards {
  [GIFT_CARD_FEATURE]: StateUtils.EntityLoaderState<CjGiftCard[]>;
}

export interface CjGiftCardLoadSuccessPayload {
  cartId: string;
  giftCards: CjGiftCard[];
}

export interface CjGiftCardSetPayload {
  userId: string;
  cartId: string;
  giftCardCode: string;
  giftCardsMessage: string;
}
