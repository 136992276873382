import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { PageMetaService } from '@spartacus/core';
import { SeoMetaService } from '@spartacus/storefront';
import { CjHreflangMetas, CjPageMeta } from 'src/app/core/cms/model/page.model';
import { CjPageMetaLinkService } from './page-meta-link.service';

@Injectable({
  providedIn: 'root',
})
export class CjSeoMetaService extends SeoMetaService {
  constructor(
    protected override ngTitle: Title,
    protected override ngMeta: Meta,
    protected override pageMetaService: PageMetaService,
    protected override pageMetaLinkService?: CjPageMetaLinkService,
  ) {
    super(ngTitle, ngMeta, pageMetaService, pageMetaLinkService);
  }

  protected override set meta(meta: CjPageMeta) {
    this.title = meta.title;
    this.description = meta.description;
    this.image = meta.image;
    this.robots = meta.robots;
    this.canonicalUrl = meta.canonicalUrl;
    this.hreflang = meta.hreflang;
  }

  protected set hreflang(value: CjHreflangMetas | undefined) {
    if (value) {
      Object.keys(value).forEach((lang) => this.pageMetaLinkService?.setHreflangLink(lang, value[lang]));
    }
  }
}
