import { Component, OnInit } from '@angular/core';
import { OrderDetailsService } from '@spartacus/order/components';
import { ICON_TYPE, LaunchDialogService } from '@spartacus/storefront';
import { Subject, take, tap } from 'rxjs';
import { CjModifyOrderService } from 'src/app/core/order/facade/modify-order.service';

@Component({
  selector: 'cj-order-detail-modify-dialog',
  templateUrl: './order-detail-modify-dialog.component.html',
  styleUrl: './order-detail-modify-dialog.component.scss',
})
export class CjOrderDetailModifyDialogComponent implements OnInit {
  iconTypes = ICON_TYPE;
  orderCode: string | undefined;

  private destroy$ = new Subject<void>();

  constructor(
    private readonly orderDetailsService: OrderDetailsService,
    private readonly modifyOrderService: CjModifyOrderService,
    private readonly dialogService: LaunchDialogService,
  ) {}

  ngOnInit(): void {
    this.orderDetailsService
      .getOrderDetails()
      .pipe(
        take(1),
        tap((order) => (this.orderCode = order.code)),
      )
      .subscribe();
  }

  dismissModal(reason: string) {
    this.dialogService.closeDialog(reason);
  }

  confirmModify() {
    if (this.orderCode) {
      this.modifyOrderService
        .modify(this.orderCode)
        .pipe(take(1))
        .subscribe(() => this.dismissModal('success'));
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
