import { Component, Input } from '@angular/core';
import { CjOrder } from 'src/app/cms-components/order/order.model';

@Component({
  selector: 'cj-order-info',
  templateUrl: './order-info.component.html',
})
export class CjOrderInfoComponent {
  @Input() cart?: CjOrder;
}
