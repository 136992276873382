import { Injectable } from '@angular/core';
import { createFrom, EventService, FeatureConfigService, ProductSearchService, ProductService, StateEventService } from '@spartacus/core';
import { NavigationEvent } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { CleanEcommerceEvent } from '../common/common.events';
import { EcommerceType } from '../tag-manager-feature.model';
import { getProductDetailSelectedUnit } from './product-detail.utils';
import { ProductDetailsPageEvent } from './product-page.events';

@Injectable({
  providedIn: 'root',
})
export class ProductPageEventBuilder {
  constructor(
    protected eventService: EventService,
    protected productService: ProductService,
    protected productSearchService: ProductSearchService,
    protected stateEventService?: StateEventService,
    // TODO: #10896 - remove this
    /** @deprecated @since 3.1 - this will be removed in 4.0 */
    protected featureConfigService?: FeatureConfigService,
  ) {
    this.register();
  }

  protected register(): void {
    this.eventService.register(ProductDetailsPageEvent, this.buildProductDetailsPageEvent());
  }

  protected buildProductDetailsPageEvent(): Observable<ProductDetailsPageEvent> {
    return this.eventService.get(NavigationEvent).pipe(
      filter((navigationEvent) => navigationEvent.semanticRoute === 'product'),
      switchMap((navigationEvent) =>
        this.productService.get(navigationEvent.context.id).pipe(
          filter((product) => Boolean(product)),
          take(1),
          tap(() => this.eventService.dispatch(<CleanEcommerceEvent>{ ecommerce: null }, CleanEcommerceEvent)),
          map((product: any) => {
            return createFrom(ProductDetailsPageEvent, {
              event: EcommerceType.VIEW_ITEM,
              ecommerce: {
                items: [
                  {
                    item_name: product?.name! || product?.code!,
                    item_id: product?.code!,
                    price: product?.price?.value?.toString() || '',
                    item_brand: product?.brands ? product.brands[0].name : '',
                    item_category: product.categories ? product.categories[0]?.name || '' : '',
                    item_category2: product.categories ? product.categories[1]?.name || '' : '',
                    item_category3: product.categories ? product.categories[2]?.name || '' : '',
                    item_variant: getProductDetailSelectedUnit(product),
                    quantity: 1,
                  },
                ],
              },
            });
          }),
        ),
      ),
    );
  }
}
