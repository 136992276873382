import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { I18nModule, provideConfig } from '@spartacus/core';
import { FormErrorsModule, IconModule, KeyboardFocusModule, NgSelectA11yModule } from '@spartacus/storefront';
import { CjAddressFormDialogComponent } from './address-form-dialog/address-form-dialog.component';
import { cjAddressFormLayoutConfig } from './address-form-dialog/address-form-layout.config';
import { CjAddressFormComponent } from './address-form.component';

@NgModule({
  imports: [
    NgSelectA11yModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgSelectModule,
    IconModule,
    I18nModule,
    FormErrorsModule,
    KeyboardFocusModule,
  ],
  providers: [provideConfig(cjAddressFormLayoutConfig)],
  declarations: [CjAddressFormComponent, CjAddressFormDialogComponent],
  exports: [CjAddressFormComponent, CjAddressFormDialogComponent],
})
export class CjAddressFormModule {}
