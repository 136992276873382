import { Injectable } from '@angular/core';
import { select } from '@ngrx/store';
import { Address, Country, UserAddressService } from '@spartacus/core';
import { map, Observable } from 'rxjs';
import { CjUserActions } from '../store/actions';
import { CjUsersSelectors } from '../store/selectors';

@Injectable({
  providedIn: 'root',
})
export class CjUserAddressService extends UserAddressService {
  /**
   * Loads user's addresses of basestore
   */
  loadAddressesOfBasestore(basestore: string): void {
    this.userIdService.takeUserId().subscribe((userId) => {
      this.store.dispatch(
        new CjUserActions.GetAllBasestoreAddresses({
          userId,
          basestore,
        }),
      );
    });
  }

  /**
   * Retrieves user's addresses of basestore
   */
  getAddressesOfBasestore(): Observable<Address[]> {
    return this.store.pipe(
      select(CjUsersSelectors.getAddressesOfBasestore),
      map((addresses) => addresses.filter((address) => address.visibleInAddressBook == true)),
    );
  }

  /**
   * Sets user address as default
   * @param addressId a user address ID
   */
  override setAddressAsDefault(addressId: string): void {
    this.userIdService.takeUserId().subscribe((userId) => {
      this.store.dispatch(
        new CjUserActions.UpdateDefaultUserAddress({
          userId,
          addressId,
          address: { defaultAddress: true },
        }),
      );
    });
  }

  /**
   * Retrieves delivery countries of the current base store.
   */
  loadBaseStoreDeliveryCountries(): void {
    this.store.dispatch(new CjUserActions.LoadBaseSiteDeliveryCountries());
  }

  /**
   * Returns all delivery countries of the current base store.
   */
  getBaseStoreDeliveryCountries(): Observable<Country[]> {
    return this.store.pipe(select(CjUsersSelectors.getAllBaseSiteCountries));
  }
}
