import { Component } from '@angular/core';
import { CmsVideoComponent } from '@spartacus/core';
import { ICON_TYPE, LaunchDialogService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CjCmsMediaService } from 'src/app/shared/utils/media-utils.service';

@Component({
  selector: 'cj-product-video-dialog',
  templateUrl: './product-video-dialog.component.html',
  styleUrls: ['./product-video-dialog.component.scss'],
})
export class CjProductVideoDialogComponent {
  iconTypes = ICON_TYPE;

  data$: Observable<CmsVideoComponent> = this.launchDialogService.data$;

  constructor(
    private readonly launchDialogService: LaunchDialogService,
    protected cjCmsMediaService: CjCmsMediaService,
  ) {}

  dismissModal(reason: string) {
    this.launchDialogService.closeDialog(reason);
  }
}
