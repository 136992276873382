<div class="cx-modal-container" (esc)="dismissModal('Escape pressed')">
  <div class="cx-modal-content" *ngIf="data$ | async as data">
    <!-- Modal Header -->
    <div class="cx-dialog-header modal-header">
      <div class="cx-dialog-title modal-title">
        <cx-media *ngIf="data.iconWarranty" [container]="data.iconWarranty"></cx-media>
        {{ data.title }}
      </div>
      <button
        type="button"
        class="close"
        attr.aria-label="{{ 'addToCart.closeModal' | cxTranslate }}"
        (click)="dismissModal('Cross click')"
      >
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </button>
    </div>

    <!-- Modal Body -->
    <div class="cx-dialog-body modal-body">
      <div class="cj-product-vertical-dialog__data">
        <cx-media *ngIf="data.image" [container]="data.image"></cx-media>
        <div class="cj-product-vertical-dialog__info">
          <p *ngIf="data.longDescription" [innerHTML]="data.longDescription | sanitizeHtml" class="cj-product-vertical-dialog__text"></p>
          <div class="cj-product-vertical-dialog__icons">
            <ng-container *ngFor="let item$ of items$ | async">
              <div *ngIf="item$ | async as item" class="cj-product-vertical-dialog__icon">
                <cx-media *ngIf="item.icon" [container]="item.icon"></cx-media>
                <span class="cj-product-vertical-dialog__icon-text" *ngIf="item.title">{{ item.title }}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="cj-product-vertical-dialog__bottom">
        <p *ngIf="data.bottomText" [innerHTML]="data.bottomText | sanitizeHtml" class="cj-product-vertical-dialog__bottom-text"></p>
      </div>
    </div>
  </div>
</div>
