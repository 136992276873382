export const address = {
  addressForm: {
    alias: 'Alias',
    firstName: {
      label: 'First name',
      placeholder: 'First Name',
    },
    lastName: {
      label: 'Last name',
      placeholder: 'Last Name',
    },
    email: {
      label: 'Email (shipping notification)',
      placeholder: 'Email',
    },
    phoneNumber: {
      label: 'Phone number (shipping notification)',
      placeholder: 'Phone number',
    },
    address1: 'Street and number',
    address2: 'Apt/Staircase (Optional)',
    zipCode: {
      label: 'Zip code',
      placeholder: 'Postal Code/Zip',
    },
    city: {
      label: 'City',
      placeholder: 'City',
    },
    nif: {
      label: 'TIF',
      placeholder: 'TIF',
    },
    country: {
      label: 'Country',
      placeholder: 'Country',
    },
    region: {
      label: 'Region',
      placeholder: 'Region',
    },
    saveAsDefault: 'Save as default',
    chooseAddress: 'Choose address',
    streetAddress: 'Street Address',
    aptSuite: 'Apt, Suite',
    setAsDefault: 'Set as default delivery address',
    titleRequired: 'Title is required.',
    requestBilling: 'Request billing?',
    sameAsDelivery: 'Same as delivery address',
    userAddressAddSuccess: 'New address was added successfully!',
    userAddressUpdateSuccess: 'Address updated successfully!',
    userAddressDeleteSuccess: 'Address deleted successfully!',
    invalidAddress: 'Invalid Address',
  },
  addressBook: {
    addNewDeliveryAddress: 'Add a new delivery address',
    editDeliveryAddress: 'Edit delivery address',
    areYouSureToDeleteAddress: 'Are you sure you want to delete this address?',
    addNewAddress: 'Add new address',
    addAddress: 'Add address',
    updateAddress: 'Update address',
    backToAddressList: 'Back to address list',
    defaultDeliveryAddress: 'Default Delivery Address',
    additionalDeliveryAddress: 'Additional Delivery Address {{number}}',
  },
  addressCard: {
    default: 'Default shipping address',
    selected: 'Selected',
    setAsDefault: 'Set as default',
  },
};
