import { Component } from '@angular/core';
import { AuthService } from '@spartacus/core';
import { OrderHistoryService } from '@spartacus/order/core';
import { Order } from '@spartacus/order/root';
import { LaunchDialogService } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'cj-order-confirmation-actions',
  templateUrl: './order-confirmation-actions.component.html',
  styleUrl: './order-confirmation-actions.component.scss',
})
export class CjOrderConfirmationActionsComponent {
  order$: Observable<Order> = this.orderHistoryService.getOrderDetails();
  loggedIn$: Observable<boolean> = this.authService.isUserLoggedIn();

  constructor(
    private readonly orderHistoryService: OrderHistoryService,
    private readonly launchDialogService: LaunchDialogService,
    private readonly authService: AuthService,
  ) {}
}
