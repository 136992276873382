import { NgModule } from '@angular/core';
import {
  CartAdapter,
  CartConnector,
  CartValidationAdapter,
  CartValidationConnector,
  CartValidationService,
} from '@spartacus/cart/base/core';
import { CjCartValidationAdapter } from './connectors/cart-validation.adapter';
import { CjCartValidationConnector } from './connectors/cart-validation.connector';
import { CjCartAdapter } from './connectors/cart.adapter';
import { CjCartConnector } from './connectors/cart.connector';
import { CjCartValidationService } from './facade/cart-validation.service';

@NgModule({
  providers: [
    { provide: CartAdapter, useExisting: CjCartAdapter },
    { provide: CartConnector, useClass: CjCartConnector },
    { provide: CartValidationAdapter, useExisting: CjCartValidationAdapter },
    { provide: CartValidationConnector, useClass: CjCartValidationConnector },
    { provide: CartValidationService, useClass: CjCartValidationService },
  ],
})
export class CjCartModule {}
