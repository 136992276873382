import { Injectable } from '@angular/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutPageMetaResolver } from '@spartacus/checkout/base/core';
import { BreadcrumbMeta, CanonicalPageResolver, PageBreadcrumbResolver, SemanticPathService, TranslationService } from '@spartacus/core';
import { Observable, combineLatest, map, switchMap } from 'rxjs';
import { CjPageHreflangResolver } from 'src/app/core/cms/page/page.resolvers';
import { CjBasePageMetaResolver } from './base-page-meta.resolver';

@Injectable({
  providedIn: 'root',
})
export class CjCheckoutPageMetaResolver
  extends CheckoutPageMetaResolver
  implements CanonicalPageResolver, PageBreadcrumbResolver, CjPageHreflangResolver
{
  constructor(
    protected override translationService: TranslationService,
    protected override activeCartFacade: ActiveCartFacade,
    protected override basePageMetaResolver: CjBasePageMetaResolver,
    private readonly checkoutStepService: CheckoutStepService,
    private readonly semanticPathService: SemanticPathService,
    private readonly translation: TranslationService,
  ) {
    super(translationService, activeCartFacade, basePageMetaResolver);
  }

  override resolveHeading(): Observable<string> {
    return this.basePageMetaResolver.resolveTitle().pipe(map((title) => title || ''));
  }

  // resolveTitle uses this.basePageMetaResolver.resolveTitle() (page.title)
  // resolveDescription uses this.basePageMetaResolver.resolveDescription() (page.description)
  // resolveImage not used

  resolveCanonicalUrl(): Observable<string | undefined> {
    return this.basePageMetaResolver.resolveCanonicalUrl();
  }

  resolveBreadcrumbs(): Observable<BreadcrumbMeta[] | undefined> {
    return combineLatest([this.checkoutStepService.steps$, this.checkoutStepService.activeStepIndex$]).pipe(
      switchMap(([steps, activeStepIndex]) => {
        const stepsWithCart = [{ name: 'common.home', routeName: '/' }, { name: 'checkoutProgress.cart', routeName: 'cart' }, ...steps];
        return combineLatest(stepsWithCart.slice(0, activeStepIndex + 3).map((step) => this.translation.translate(step.name))).pipe(
          map((stepNames) =>
            stepNames.map((stepName, i) => ({
              label: stepName,
              link: this.semanticPathService.get(stepsWithCart[i].routeName),
            })),
          ),
        );
      }),
    );
  }

  // resolveRobots uses this.basePageMetaResolver.resolveRobots() (page.robots)

  resolveHreflang() {
    return this.basePageMetaResolver.resolveHreflang();
  }
}
