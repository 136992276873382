import { Observable } from 'rxjs';
import { CsTicketCauseList, CsTicketContactForm } from '../contact-form.model';

export abstract class ContactFormAdapter {
  /**
   * Abstract method used to load Ticket Causes.
   */
  abstract loadCsTicketCauses(templateConfigurations: string): Observable<CsTicketCauseList>;
  /**
   * Abstract method used to send contact form.
   *
   * @param form The `form` to send
   * @param templateConfiguration The template configuration value for fields param in url
   */
  abstract sendContactForm(form: CsTicketContactForm, templateConfiguration: string): Observable<any>;
}
