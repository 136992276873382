import { NgModule } from '@angular/core';
import { BasePageMetaResolver, CategoryPageMetaResolver, ContentPageMetaResolver, ProductPageMetaResolver } from '@spartacus/core';
import { PageMetaLinkService, SeoMetaService } from '@spartacus/storefront';
import { CjBasePageMetaResolver } from './meta-resolvers/base-page-meta.resolver';
import { CjCategoryPageMetaResolver } from './meta-resolvers/category-page-meta.resolver';
import { CjContentPageMetaResolver } from './meta-resolvers/content-page-meta.resolver';
import { CjProductPageMetaResolver } from './meta-resolvers/product-page-meta.resolver';
import { CjPageMetaLinkService } from './page-meta-link.service';
import { CjSeoMetaService } from './seo-meta.service';

@NgModule({
  imports: [],
  providers: [
    { provide: BasePageMetaResolver, useExisting: CjBasePageMetaResolver },
    { provide: ProductPageMetaResolver, useExisting: CjProductPageMetaResolver },
    { provide: CategoryPageMetaResolver, useExisting: CjCategoryPageMetaResolver },
    { provide: ContentPageMetaResolver, useExisting: CjContentPageMetaResolver },
    // CheckoutPageMetaResolver is provided in CjCheckoutModule due to context
    // { provide: CheckoutPageMetaResolver, useExisting: CjCheckoutPageMetaResolver },

    { provide: PageMetaLinkService, useExisting: CjPageMetaLinkService },
    { provide: SeoMetaService, useExisting: CjSeoMetaService },
  ],
})
export class CjMetaTagsModule {}
