import { NgModule } from '@angular/core';
import { CjContactMapModule } from './contact-map/contact-map.module';
import { CjExperiencesBannerModule } from './experiences-banner/experiences-banner.module';
import { CjExperiencesCardListModule } from './experiences-card-list/experiences-card-list.module';
import { CjExperiencesCardSliderModule } from './experiences-card-slider/experiences-card-slider.module';
import { CjRestaurantBannerModule } from './restaurant-banner/restaurant-banner.module';
import { CjRestaurantBookingModule } from './restaurant-booking/restaurant-booking.module';

@NgModule({
  imports: [
    CjContactMapModule,
    CjExperiencesBannerModule,
    CjExperiencesCardSliderModule,
    CjExperiencesCardListModule,
    CjRestaurantBannerModule,
    CjRestaurantBookingModule,
  ],
})
export class CjExperiencesModule {}
