export const product = {
  productDetail: {
    common: {
      showMore: 'Voir plus',
      showLess: 'Voir moins',
    },
    specification: {
      slider: {
        title: 'Aussi délicieux que sain',
      }
    },
  },
  productCard: {
    stock: {
      inStock: 'Disponible',
      lowStock: 'Seulement {{ count }} disponible',
      lowStock_other: 'Seulement {{ count }} disponibles',
      outOfStock: 'Non disponible',
    },
  },
  addToCart: {
    title: 'Panier',
    subtitle: {
      products: 'Vos produits sauvegardés dans le panier.',
      empty: "Vous n'avez aucun article dans votre panier.",
    },
    checkoutButton: 'Commander',
    cartButton: 'Voir et modifier le panier',
    total: 'Total estimé: ',
    stock: {
      lowStock: 'Derniers articles en stock',
      outOfStock: 'Plus en stock',
    },
    quantity: 'Quantité: ',
    addToCart: 'Ajouter au panier',
    buyItAgain: 'Achète le encore',
    notifyMe: 'Prévenez-moi quand il sera disponible',
    inStock: 'Disponible',
    lowStock: 'Seulement {{ count }} disponible',
    lowStock_other: 'Seulement {{ count }} disponibles',
    outOfStock: 'Non disponible',
  },
  cartItems: {
    itemRemoved: "L'article sélectionné a été retiré. Le total du panier a été mis à jour",
  },
  webAvailability: {
    WEB_NOT_AVAILABLE: 'Non disponible',
    WEB_AVAILABLE: 'Disponible',
    WEB_LAST_UNITS: 'Dernières unités disponibles',
    WEB_ONLINE_ONLY: 'En ligne seulement',
    WEB_TEMPORARILY_OUT_OF_STOCK: 'Temporairement absent',
  },
  stockNotification: {
    notifyMe: 'ENVOYEZ-MOI UNE NOTIFICATION',
    stopNotify: 'ANNULER LA NOTIFICATION',
    getNotify: 'Recevez une notification lorsque ce produit sera disponible.',
    getNotifySuffix: 'pour recevoir une notification lorsque ce produit sera disponible.',
    activateChannelsPrefix: 'Pour recevoir des notifications, vous devez activer les',
    channelsLink: 'Canaux de notification',
    activateChannelsSuffix: '.',
    notified: 'Vous recevrez une notification lorsque ce produit sera de nouveau disponible.',
    getNotified: 'Recevez une notification lorsque ce produit sera de nouveau disponible.',
    unsubscribeSuccess: 'Vous ne recevrez plus de notifications de disponibilité pour ce produit.',
    subscriptionDialog: {
      header: 'INSCRIPTION HORS STOCK',
      notifiedPrefix: 'Vous recevez une notification dans',
      notifiedSuffix: 'dès que ce produit sera de nouveau disponible.',
      manageChannelsPrefix: 'Gérez vos canaux de notification préférés dans la section ',
      manageChannelsLink: 'Préférence de notification',
      manageChannelsSuffix: ' (préférences de notification).',
      manageSubscriptionsPrefix: 'Vous pouvez gérer vos abonnements à la page ',
      manageSubscriptionsLink: 'Mes intérêts',
      manageSubscriptionsSuffix: " à la page Mes centres d'intérêt.",
      okBtn: 'OK',
      subscribing: 'Inscription aux notifications de disponibilité de stock de ce produit',
    },
  },
};
