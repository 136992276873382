import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { SEND_CONTACT_FORM_PROCESS_ID } from '../contact-form-state';
import { CsTicketCauseList, CsTicketContactForm } from '../../contact-form.model';

export const CONTACT_FORM = '[Contact Form] Contact Form';
export const LOAD_CONTACT_FORM = '[Contact Form] Load Contact Form';
export const LOAD_CONTACT_FORM_FAIL = '[Contact Form] Load Contact Form Fail';
export const LOAD_CONTACT_FORM_SUCCESS = '[Contact Form] Load Contact Form Success';
export const CLEAR_CONTACT_FORM = '[Contact Form] Clear Contact Form';

export const SEND_CONTACT_FORM = '[Contact Form] Send Contact Form';
export const SEND_CONTACT_FORM_FAIL = '[Contact Form] Send Contact Form Fail';
export const SEND_CONTACT_FORM_SUCCESS = '[Contact Form] Send Contact Form Success';
export const CLEAR_SEND_CONTACT_FORM = '[Contact Form] Clear Send Contact Form';

export class LoadContactForm extends StateUtils.LoaderLoadAction {
  override readonly type = LOAD_CONTACT_FORM;
  constructor(public payload: string) {
    super(CONTACT_FORM);
  }
}

export class LoadContactFormFail extends StateUtils.LoaderFailAction {
  override readonly type = LOAD_CONTACT_FORM_FAIL;
  constructor(public payload: any) {
    super(CONTACT_FORM, payload);
  }
}

export class LoadContactFormSuccess extends StateUtils.LoaderSuccessAction {
  override readonly type = LOAD_CONTACT_FORM_SUCCESS;
  constructor(public payload: CsTicketCauseList) {
    super(CONTACT_FORM);
  }
}

export class ClearContactForm extends StateUtils.LoaderResetAction {
  override readonly type = CLEAR_CONTACT_FORM;
  constructor() {
    super(CONTACT_FORM);
  }
}

export class SendContactForm extends StateUtils.EntityLoadAction {
  override readonly type = SEND_CONTACT_FORM;
  constructor(public payload: { form: CsTicketContactForm; templateConfiguration: string }) {
    super(PROCESS_FEATURE, SEND_CONTACT_FORM_PROCESS_ID);
  }
}

export class SendContactFormFail extends StateUtils.EntityFailAction {
  override readonly type = SEND_CONTACT_FORM_FAIL;
  constructor(public payload: any) {
    super(PROCESS_FEATURE, SEND_CONTACT_FORM_PROCESS_ID, payload);
  }
}

export class SendContactFormSuccess extends StateUtils.EntitySuccessAction {
  override readonly type = SEND_CONTACT_FORM_SUCCESS;
  constructor(public override payload: any) {
    super(PROCESS_FEATURE, SEND_CONTACT_FORM_PROCESS_ID, payload);
  }
}

export class ClearSendContactForm extends StateUtils.EntityLoaderResetAction {
  override readonly type = CLEAR_SEND_CONTACT_FORM;
  constructor() {
    super(PROCESS_FEATURE, SEND_CONTACT_FORM_PROCESS_ID);
  }
}

export type ContactFormAction =
  | LoadContactForm
  | LoadContactFormFail
  | LoadContactFormSuccess
  | ClearContactForm
  | SendContactForm
  | SendContactFormFail
  | SendContactFormSuccess
  | ClearSendContactForm;
