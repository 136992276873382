export const user = {
  checkoutLogin: {
    signIn: 'Se connecter',
    emailAddress: {
      label: 'Êtes-vous inscrit ?',
      placeholder: 'Votre adresse e-mail',
    },
    emailIsRequired: "Format d'email invalide",
  },
  authMessages: {
    signedOutSuccessfully: 'Déconnexion réussie',
  },
};
