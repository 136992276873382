import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { CjGsapAnimationDirectiveModule } from 'src/app/shared/directives/directives.module';
import { CjHtmlSanitizerPipeModule } from 'src/app/shared/pipes/html-sanitizer/html-sanitizer-pipe.module';
import { CjBannerComponent } from './banner.component';

@NgModule({
  imports: [CommonModule, CjHtmlSanitizerPipeModule, CjGsapAnimationDirectiveModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsborneBannerComponent: {
          component: () => import('./banner.component').then((m) => m.CjBannerComponent),
        },
      },
    }),
  ],
  declarations: [CjBannerComponent],
  exports: [CjBannerComponent],
})
export class CjBannerModule {}
