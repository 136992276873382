import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CartActions } from '@spartacus/cart/base/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CjGiftCardConnector } from '../../adapter/gift-card.connector';
import { CjGiftCard } from '../../model/gift-card.model';
import { CjGiftCardsActions } from '../actions';
import { CjGiftCardSetPayload } from '../gift-card-state';

@Injectable()
export class CjGiftCardsEffects {
  constructor(
    private actions$: Actions,
    private giftCardConnector: CjGiftCardConnector,
  ) {}

  loadGiftCards$: Observable<CjGiftCardsActions.LoadGiftCardsSuccess | CjGiftCardsActions.LoadGiftCardsFail> = createEffect(() =>
    this.actions$.pipe(
      ofType(CjGiftCardsActions.LOAD_GIFT_CARDS),
      map((action: CjGiftCardsActions.LoadGiftCards) => action.payload),
      mergeMap((payload: { userId: string; cartId: string }) =>
        this.giftCardConnector.getAll(payload.userId, payload.cartId).pipe(
          map((giftCards: CjGiftCard[]) => new CjGiftCardsActions.LoadGiftCardsSuccess({ cartId: payload.cartId, giftCards })),
          catchError((error) => of(new CjGiftCardsActions.LoadGiftCardsFail(error))),
        ),
      ),
    ),
  );

  selectGiftCard$: Observable<CartActions.LoadCart | CjGiftCardsActions.SelectGiftCardFail> = createEffect(() =>
    this.actions$.pipe(
      ofType(CjGiftCardsActions.SELECT_GIFT_CARD),
      map((action: CjGiftCardsActions.SelectGiftCard) => action.payload),
      mergeMap((payload: CjGiftCardSetPayload) =>
        this.giftCardConnector.set(payload.userId, payload.cartId, payload.giftCardCode, payload.giftCardsMessage).pipe(
          map(() => new CartActions.LoadCart({ userId: payload.userId, cartId: payload.cartId })),
          catchError((error) => of(new CjGiftCardsActions.SelectGiftCardFail(error))),
        ),
      ),
    ),
  );

  removeGiftCard$: Observable<CartActions.LoadCart | CjGiftCardsActions.RemoveGiftCardFail> = createEffect(() =>
    this.actions$.pipe(
      ofType(CjGiftCardsActions.REMOVE_GIFT_CARD),
      map((action: CjGiftCardsActions.RemoveGiftCard) => action.payload),
      mergeMap((payload: { userId: string; cartId: string }) =>
        this.giftCardConnector.delete(payload.userId, payload.cartId).pipe(
          map(() => new CartActions.LoadCart({ userId: payload.userId, cartId: payload.cartId })),
          catchError((error) => of(new CjGiftCardsActions.RemoveGiftCardFail(error))),
        ),
      ),
    ),
  );
}

export const cjEffectsGiftCard: any[] = [CjGiftCardsEffects];
