import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CsTicketCauseList, CsTicketContactForm } from '../contact-form.model';
import { ContactFormAdapter } from './contact-form.adapter';

@Injectable({
  providedIn: 'root',
})
export class ContactFormConnector {
  constructor(protected adapter: ContactFormAdapter) {}

  public loadCsTicketCauses(templateConfigurations: string): Observable<CsTicketCauseList> {
    return this.adapter.loadCsTicketCauses(templateConfigurations);
  }

  public sendContactForm(form: CsTicketContactForm, templateConfiguration: string): Observable<any> {
    return this.adapter.sendContactForm(form, templateConfiguration);
  }
}
