import { Injectable } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { ProductListComponentService } from '@spartacus/storefront';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, distinctUntilKeyChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { CjPage } from 'src/app/core/cms/model/page.model';
import { CjProductGridService } from '../../core/facade/product-grid.service';
import { CjProductGridBannerType, CjProductGridElement } from '../product-list.model';

@Injectable()
export class CjProductScrollComponentService {
  category$: Observable<string> = this.cmsService.getCurrentPage().pipe(
    map((page: CjPage) => page.plpCategoryCode || ''),
    filter((category) => !!category),
  );

  elements$: Observable<CjProductGridElement[]> = this.category$.pipe(
    switchMap((category) =>
      combineLatest([
        this.productGridService.getElements(category),
        this.productGridService.filteredProducts$.pipe(distinctUntilKeyChanged('page')),
      ]).pipe(
        switchMap(
          ([
            { lastPage, nextRow, nextOneColumnBanner, nextThreeColumnBanner, lastIteration, extraRowProducts, entities },
            { products, moreProducts, page },
          ]) =>
            combineLatest([
              this.productGridService.getOneColumnBannerList(category).pipe(take(1)),
              this.productGridService.getThreeColumnBannerList(category).pipe(take(1)),
            ]).pipe(
              tap(([oneColumnBanners, threeColumnBanners]) => {
                // Cuando emitimos un nuevo valor se vuelve a entrar aquí ya que estamos suscritos a "nosotros mismos"
                // Descartamos las páginas anteriores a la de la última iteración
                if (lastPage !== undefined && page <= lastPage) {
                  return;
                }

                const newElements: CjProductGridElement[] = [];
                let nextProduct = 0;

                // La lógica está dividida en 3 partes:
                //    Generar los elementos siguiendo el patrón estandar (4p + 1b + 1p)
                //    Añadir una fila extra de productos en la fila 5 (sin contar banners grandes)
                //    Añadir los banners grandes cada 6 elementos, teniendo en cuenta que hay 1 excepción:
                //        Entre el primer y el segundo banner grande solo hay 3 productos
                // Con el infinite scroll recibimos 12 productos cada vez. Iteramos hasta que se acaben, teniendo en
                // cuenta que podemos quedarnos sin productos en mitad de la iteración pero no podemos dejar espacios en blanco
                while (products.length - nextProduct) {
                  // Queremos añadir una fila de productos extra en la quinta fila. Es una excepción.
                  // Si es la cuarta fila añadimos 3 productos, creando la quinta fila
                  // Si nos hemos quedado sin productos en la iteración anterior, añadimos los que queden en la variable extraRowProducts
                  if (nextRow == 4 || extraRowProducts) {
                    for (extraRowProducts = extraRowProducts || 3; extraRowProducts > 0; extraRowProducts--) {
                      if (products[nextProduct]) {
                        // Si quedan productos añadir uno
                        newElements.push(products[nextProduct++]);
                      } else {
                        // Si no, la cantidad de productos pendientes queda guardada en la variable extraRowProducts
                        // y se añadirán en la siguiente iteración
                        break;
                      }
                    }
                  }

                  // Ignorando los banners de tres columnas y la fila extra, el diseño sigue el patrón 4 productos + 1 banner pequeño + 1 producto
                  // Iteramos en grupos de 6 elementos para generar ese patrón
                  // Si en la iteración anterior nos quedamos sin productos, continuamos desde la última posición
                  let i = lastIteration > 6 ? 1 : lastIteration;
                  lastIteration = 1;
                  for (i; i <= 6; i++) {
                    // Cada 3 elementos (cada fila) verificamos si hay que añadir un banner grande
                    if (i == 1 || i == 4) {
                      // Si es una fila impar (excepto la primera) o si es la fila dos (se añade un banner extra)
                      if ((nextRow != 1 && nextRow % 2) || nextRow == 2) {
                        // Si quedan banners lo añadimos
                        if (threeColumnBanners[nextThreeColumnBanner]) {
                          newElements.push({
                            type: CjProductGridBannerType.THREE_COLUMN,
                            banner: threeColumnBanners[nextThreeColumnBanner++],
                          });
                        }
                      }
                      nextRow++;
                    }

                    // En la quinta posición añadimos un banner pequeño
                    if (i == 5 && oneColumnBanners[nextOneColumnBanner]) {
                      // Si quedan banners lo añadimos
                      newElements.push({ type: CjProductGridBannerType.ONE_COLUMN, banner: oneColumnBanners[nextOneColumnBanner++] });
                    } else {
                      // Si no, añadimos un producto en su lugar
                      if (products[nextProduct]) {
                        newElements.push(products[nextProduct++]);
                        // Si es el último producto tenemos que continuar desde la posición siguiente
                        lastIteration = i + 1;
                      } else {
                        // Si nos quedamos sin productos guardamos la posición en la que nos hemos quedado para no dejar espacios en blanco
                        lastIteration = i;
                        break;
                      }
                    }
                  }
                }

                // Si no quedan productos que mostrar (en toda la PLP) pintamos el resto de banners grandes
                if (!moreProducts) {
                  for (nextThreeColumnBanner; nextThreeColumnBanner < threeColumnBanners.length; nextThreeColumnBanner++) {
                    newElements.push({ type: CjProductGridBannerType.THREE_COLUMN, banner: threeColumnBanners[nextThreeColumnBanner] });
                  }
                }

                // Guardar el resultado en la store
                if (entities.length || lastPage === undefined) {
                  this.productGridService.saveElements(category, [...entities, ...newElements], {
                    lastPage: page,
                    nextRow,
                    nextOneColumnBanner,
                    nextThreeColumnBanner,
                    lastIteration,
                    extraRowProducts,
                  });
                }
              }),
              switchMap(() => this.productGridService.getElements(category).pipe(map((elements) => elements.entities))),
              distinctUntilChanged(),
            ),
        ),
      ),
    ),
  );

  constructor(
    private readonly productGridService: CjProductGridService,
    private readonly productListComponentService: ProductListComponentService,
    private readonly cmsService: CmsService,
  ) {}

  scrollPage(pageLimit: number | undefined): void {
    this.category$
      .pipe(
        switchMap((category) => this.productGridService.getLastPage(category)),
        take(1),
      )
      .subscribe((page) => {
        if (!pageLimit || page <= pageLimit) {
          this.productListComponentService.getPageItems(page + 1);
        }
      });
  }
}
