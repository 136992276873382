import { Action } from '@ngrx/store';
import { Country } from '@spartacus/core';

export const LOAD_BASE_SITE_COUNTRIES = '[User] Load Base Site Delivery Countries';
export const LOAD_BASE_SITE_COUNTRIES_SUCCESS = '[User] Load Base Site Countries Success';
export const LOAD_BASE_SITE_COUNTRIES_FAIL = '[User] Load Base Site Countries Fail';

export class LoadBaseSiteDeliveryCountries implements Action {
  readonly type = LOAD_BASE_SITE_COUNTRIES;
}

export class LoadBaseSiteCountriesSuccess implements Action {
  readonly type = LOAD_BASE_SITE_COUNTRIES_SUCCESS;
  constructor(public payload: Country[]) {}
}

export class LoadBaseSiteCountriesFail implements Action {
  readonly type = LOAD_BASE_SITE_COUNTRIES_FAIL;
  constructor(public payload: any) {}
}
export type CjDeliveryCountriesAction = LoadBaseSiteDeliveryCountries|LoadBaseSiteCountriesSuccess |LoadBaseSiteCountriesFail;
