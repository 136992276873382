import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { CmsConfig, I18nModule, SiteContextModule, provideConfig } from '@spartacus/core';
import { IconModule, MediaModule, SiteContextComponentService } from '@spartacus/storefront';
import { CjLanguageCurrencyComponent } from './language-currency/language-currency.component';
import { CjSiteContextSelectorDialogComponent } from './language-currency/site-context-selector-dialog/site-context-selector-dialog.component';
import { cjSiteContextSelectorConfig } from './language-currency/site-context-selector-dialog/site-context-selector-layout.config';
import { CjBaseStoreModule } from './store/base-store.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SiteContextModule,
    IconModule,
    I18nModule,
    NgSelectModule,
    MediaModule,
    CjBaseStoreModule,
  ],
  providers: [
    provideConfig(cjSiteContextSelectorConfig),
    provideConfig(<CmsConfig>{
      cmsComponents: {
        LanguageCurrencyComponent: {
          component: () => import('./language-currency/language-currency.component').then((m) => m.CjLanguageCurrencyComponent),
        },
      },
    }),
    SiteContextComponentService,
  ],
  declarations: [CjLanguageCurrencyComponent, CjSiteContextSelectorDialogComponent],
  exports: [CjLanguageCurrencyComponent, CjSiteContextSelectorDialogComponent],
})
export class CjSiteContextSelectorModule {}
