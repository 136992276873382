import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { I18nModule, provideConfig } from '@spartacus/core';
import { IconModule, MediaModule, PageComponentModule, ProductReferencesModule, StructuredDataModule } from '@spartacus/storefront';

import { CjMediaModule } from 'src/app/shared/components/media/media.module';
import { CjGsapAnimationDirectiveModule } from 'src/app/shared/directives/directives.module';
import { CjHtmlSanitizerPipeModule } from 'src/app/shared/pipes/html-sanitizer/html-sanitizer-pipe.module';
import { CjProductCardModule } from '../card/product-card.module';
import { CjProductCharacteristicsComponent } from './product-characteristics/product-characteristics.component';
import { CjProductComparativeBannerComponent } from './product-comparative-banner/product-comparative-banner.component';
import { CjProductCutterBannerComponent } from './product-cutter-banner/product-cutter-banner.component';
import { CjProductFaqBannerComponent } from './product-faq-banner/product-faq-banner.component';
import { CjProductHealthyDialogComponent } from './product-healthy-slider/product-healthy-dialog/product-healthy-dialog.component';
import { cjProductHealthyLayoutConfig } from './product-healthy-slider/product-healthy-dialog/product-healthy-dialog.config';
import { CjProductHealthyItemComponent } from './product-healthy-slider/product-healthy-item/product-healthy-item.component';
import { CjProductHealthySliderComponent } from './product-healthy-slider/product-healthy-slider.component';
import { CjProductMultimediaBannerComponent } from './product-multimedia-banner/product-multimedia-banner.component';
import { CjProductPartsBannerComponent } from './product-parts-banner/product-parts-banner.component';
import { CjProductReferencesSliderComponent } from './product-references-slider/product-references-slider.component';
import { CjProductSimpleMultimediaBannerComponent } from './product-simple-multimedia-banner/product-simple-multimedia-banner.component';
import { CjProductTastingBannerComponent } from './product-tasting-banner/product-tasting-banner.component';
import { CjProductVideoDialogComponent } from './product-video-dialog/product-video-dialog.component';
import { cjProductVideoLayoutConfig } from './product-video-dialog/product-video-dialog.config';
import { CjProductVideoComponent } from './product-video/product-video.component';

@NgModule({
  imports: [
    CommonModule,
    MediaModule,
    CjMediaModule,
    PageComponentModule,
    CjHtmlSanitizerPipeModule,
    I18nModule,
    ProductReferencesModule,
    CjProductCardModule,
    IconModule,
    CjGsapAnimationDirectiveModule,
    StructuredDataModule,
  ],
  providers: [
    provideConfig(cjProductVideoLayoutConfig),
    provideConfig(cjProductHealthyLayoutConfig),
    provideConfig({
      cmsComponents: {
        OsborneProductTastingBannerComponent: {
          component: () =>
            import('./product-tasting-banner/product-tasting-banner.component').then((m) => m.CjProductTastingBannerComponent),
        },
        // OsborneProductHealthySliderComponent: {
        //   component: () =>
        //     import('./product-healthy-slider/product-healthy-slider.component').then((m) => m.CjProductHealthySliderComponent),
        // },
        OsborneProductCutterBannerComponent: {
          component: () => import('./product-cutter-banner/product-cutter-banner.component').then((m) => m.CjProductCutterBannerComponent),
        },
        OsborneProductMultimediaBannerComponent: {
          component: () =>
            import('./product-multimedia-banner/product-multimedia-banner.component').then((m) => m.CjProductMultimediaBannerComponent),
        },
        OsborneComparativeBannerComponent: {
          component: () =>
            import('./product-comparative-banner/product-comparative-banner.component').then((m) => m.CjProductComparativeBannerComponent),
        },
        OsborneFAQComponent: {
          component: () => import('./product-faq-banner/product-faq-banner.component').then((m) => m.CjProductFaqBannerComponent),
        },
        OsborneProductSimpleMultimediaBannerComponent: {
          component: () =>
            import('./product-simple-multimedia-banner/product-simple-multimedia-banner.component').then(
              (m) => m.CjProductSimpleMultimediaBannerComponent,
            ),
        },
        OsborneProductReferencesSliderComponent: {
          component: () =>
            import('./product-references-slider/product-references-slider.component').then((m) => m.CjProductReferencesSliderComponent),
        },
        OsborneVideoComponent: {
          component: () => import('./product-video/product-video.component').then((m) => m.CjProductVideoComponent),
        },
        OsborneHamPartsBannerComponent: {
          component: () => import('./product-parts-banner/product-parts-banner.component').then((m) => m.CjProductPartsBannerComponent),
        },
      },
    }),
  ],
  declarations: [
    CjProductTastingBannerComponent,
    CjProductMultimediaBannerComponent,
    CjProductComparativeBannerComponent,
    CjProductFaqBannerComponent,
    CjProductSimpleMultimediaBannerComponent,
    CjProductCharacteristicsComponent,
    CjProductCutterBannerComponent,
    CjProductVideoDialogComponent,
    CjProductHealthySliderComponent,
    CjProductHealthyItemComponent,
    CjProductHealthyDialogComponent,
    CjProductReferencesSliderComponent,
    CjProductVideoComponent,
    CjProductPartsBannerComponent,
  ],
  exports: [
    CjProductTastingBannerComponent,
    CjProductMultimediaBannerComponent,
    CjProductComparativeBannerComponent,
    CjProductFaqBannerComponent,
    CjProductSimpleMultimediaBannerComponent,
    CjProductCharacteristicsComponent,
    CjProductCutterBannerComponent,
    CjProductVideoDialogComponent,
    CjProductHealthySliderComponent,
    CjProductHealthyItemComponent,
    CjProductHealthyDialogComponent,
    CjProductReferencesSliderComponent,
    CjProductVideoComponent,
    CjProductPartsBannerComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CjProductBannersModule {}
