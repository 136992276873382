import { NgModule } from '@angular/core';
import { OrderHistoryService, ReorderOrderConnector, ReorderOrderService } from '@spartacus/order/core';
import { ReorderOrderFacade } from '@spartacus/order/root';
import { CjOccReorderOrderAdapter } from '../occ/adapters/order/occ-reorder-order.adapter';
import { CjModifyOrderConnector } from './connectors/modify-order.connector';
import { CjReorderOrderAdapter } from './connectors/reorder-order.adapter';
import { CjReorderOrderConnector } from './connectors/reorder-order.connector';
import { CjOrderHistoryService } from './facade/order-history.service';
import { CjReorderOrderService } from './facade/reorder-order.service';

@NgModule({
  imports: [],
  providers: [
    CjOrderHistoryService,
    { provide: OrderHistoryService, useClass: CjOrderHistoryService },
    { provide: ReorderOrderFacade, useClass: CjReorderOrderService },
    { provide: ReorderOrderService, useClass: CjReorderOrderService },
    CjReorderOrderConnector,
    { provide: ReorderOrderConnector, useClass: CjReorderOrderConnector },
    { provide: CjReorderOrderAdapter, useClass: CjOccReorderOrderAdapter },
    CjModifyOrderConnector,
  ],
})
export class CjOrderModule {}
