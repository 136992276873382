import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { CjSecurePageWarningComponent } from './secure-page-warning.component';

@NgModule({
  imports: [CommonModule, I18nModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        SecurePageWarningComponent: {
          component: () => import('./secure-page-warning.component').then((m) => m.CjSecurePageWarningComponent),
        },
      },
    }),
  ],
  declarations: [CjSecurePageWarningComponent],
  exports: [CjSecurePageWarningComponent],
})
export class CjSecurePageWarningModule {}
