import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { CjDeliveryBannerDialogComponent } from './delivery-banner-dialog.component';

export const DELIVERY_BANNER_DIALOG = 'DELIVERY_BANNER';

export const cjDeliveryBannerLayoutConfig: LayoutConfig = {
  launch: {
    DELIVERY_BANNER: {
      inlineRoot: true,
      component: CjDeliveryBannerDialogComponent,
      dialogType: DIALOG_TYPE.SIDEBAR_END,
    },
  },
};
