import { Injectable } from '@angular/core';
import { GlobalMessageService, ScriptLoader, ScriptPlacement, WindowRef } from '@spartacus/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class CookieproService {
  constructor(
    protected scriptLoader: ScriptLoader,
    private readonly winRef: WindowRef,
    protected globalMessageService: GlobalMessageService,
  ) {}

  loadScript(): void {
    if (!this.hasScript() && environment.cookiepro.enabled && environment.cookiepro.src && environment.cookiepro.id) {
      this.scriptLoader.embedScript({
        src: environment.cookiepro.src,
        attributes: {
          'data-domain-script': environment.cookiepro.id,
          charset: environment.cookiepro.charset,
        },
        errorCallback: () => this.handleLoadError(),
        placement: ScriptPlacement.BODY,
      });
    }
  }

  protected hasScript(): boolean {
    if (this.winRef.isBrowser()) {
      return !!this.winRef.document.querySelector(`script[src^="${environment.cookiepro.src}"]`);
    }
    return false;
  }

  protected handleLoadError(): void {
    // Remove script to be able to load it again later
    if (this.winRef.isBrowser()) {
      const script = this.winRef.document.querySelector('script[src^="' + environment.cookiepro.src + '"]');
      script?.parentElement?.removeChild(script);
      console.error('Error loading cookiepro script');
    }
  }
}
