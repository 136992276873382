import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { I18nModule, StateModule, UrlModule } from '@spartacus/core';
import { FormErrorsModule, MediaModule, SpinnerModule } from '@spartacus/storefront';
import { CjGiftCardAdapter } from './adapter/gift-card.adapters';
import { CjOccGiftCardAdapter } from './adapter/occ-gift-card.adapter';
import { CjGiftCardFormComponent } from './gift-card-form.component';
import { cjEffectsGiftCard } from './store/effects/gift-cards.effect';
import { GIFT_CARD_FEATURE } from './store/gift-card-state';
import { cjReducerGiftCardProvider, cjReducerGiftCardToken } from './store/reducers/gift-card.reducer';

@NgModule({
  declarations: [CjGiftCardFormComponent],
  exports: [CjGiftCardFormComponent],
  imports: [
    CommonModule,
    StateModule,
    RouterModule,
    ReactiveFormsModule,
    I18nModule,
    MediaModule,
    UrlModule,
    RouterModule,
    SpinnerModule,
    EffectsModule.forFeature(cjEffectsGiftCard),
    StoreModule.forFeature(GIFT_CARD_FEATURE, cjReducerGiftCardToken),
    FormErrorsModule,
  ],
  providers: [cjReducerGiftCardProvider, { provide: CjGiftCardAdapter, useClass: CjOccGiftCardAdapter }],
})
export class CjGiftCardModule {}
