export const order = {
  orderDetails: {
    actions: {
      cancellationAndReturn: {
        cancelTitle: 'Cancelar Pedido',
        cancelAction: 'Cancelar Pedido',
        cancelParagraph:
          '¿Seguro que deseas cancelar el pedido? Se procederá al abono en tu cuenta durante los siguientes días en caso de haber pagado con Tarjeta Bancaria.',
        cancellationSuccess: 'Orden {{orderCode}} cancelada correctamente',
        cancellationFail: 'Ha ocurrido un error al intentar cancelar la orden {{orderCode}}',
      },
      modify: {
        modifyTitle: 'Modificar pedido',
        modifyAction: 'Modificar pedido',
        modifyParagraph:
          'Para modificar el pedido se procederá a la cancelación del actual. Se procederá al abono de su cuenta durante los siguientes días en caso de haber pagado con Tarjeta Bancaria. Automáticamente se cargará todo el pedido anterior para que pueda modificarlo, ¿Está  de acuerdo?',
        modifySuccess: 'Orden {{orderCode}} restaurada correctamente',
        modifyFail: 'Ha ocurrido un error al intentar restaurar la orden {{orderCode}}',
      },
      reorder: {
        reorderTitle: 'Volver a pedir',
        reorderAction: 'Volver a pedir',
        reorderParagraph: '¿Seguro que deseas realizar este pedido de nuevo?',
        reorderSuccess: 'Productos de la orden {{orderCode}} añadidos al carrito correctamente',
        reorderFail: 'Ha ocurrido un error al intentar volver a pedir la orden {{orderCode}}',
      },
      downloadInvoice: {
        invoiceAction: 'Descargar factura',
      },
    },
    orderInfo: {
      shippingAddress: 'Dirección de envío',
      shippingMethod: 'Método de envío',
      paymentMethod: 'Método de pago',
    },
  },
  orderHistory: {
    orderHistory: 'Mis pedidos',
    orderId: 'Nº pedido',
    emptyPurchaseOrderId: 'Ninguno',
    sendTo: 'Enviado a',
    date: 'Fecha creación',
    status: 'Estado',
    total: 'Importe',
    noOrders: 'No tenemos registros de pedidos para esta cuenta.',
    noReplenishmentOrders: 'No tenemos registros de pedidos de reabastecimiento para esta cuenta.',
    startShopping: 'Empieza a comprar',
    replenishmentOrderHistory: 'Historial de pedidos de reabastecimiento',
    replenishmentOrderId: 'Reposición #',
    purchaseOrderNumber: 'PO #',
    costCenter: 'Centro de costos',
    startOn: 'Comienza en',
    frequency: 'Frecuencia',
    nextOrderDate: 'Fecha del próximo pedido',
    cancel: 'Cancelar',
    cancelled: 'Cancelado',
    replenishmentHistory: 'Historial de reabastecimiento',
    notFound: 'No se encontraron pedidos',
    actions: 'Acciones',
    noOrdersFound: 'No tienes ningún pedido pendiente.',
    reorder: 'Repetir pedido',
  },
};
