import { Injectable } from '@angular/core';
import { CmsStructureModel, OccCmsPageNormalizer } from '@spartacus/core';
import { CjOccCMSPage, CjPage } from 'src/app/core/cms/model/page.model';

@Injectable({ providedIn: 'root' })
export class CjOccCmsPageNormalizer extends OccCmsPageNormalizer {
  /**
   * Converts the OCC cms page model to the `Page` in the `CmsStructureModel`.
   */
  protected override normalizePageData(source: CjOccCMSPage, target: CmsStructureModel): void {
    if (!source) {
      return;
    }

    super.normalizePageData(source, target);

    if (target.page && source.plpCategoryCode) {
      (target.page as CjPage).plpCategoryCode = source.plpCategoryCode;
    }

    if (target.page && source.ThemeColor) {
      (target.page as CjPage).headerAccentColor = source.ThemeColor;
    }
  }
}
