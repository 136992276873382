import { Observable } from 'rxjs';
import { EmarsysContactInfo } from '../../model/preference-center.model';

export abstract class CustomPreferenceCenterAdapter {
  abstract loadPreferenceCenterDetails(
    uid: string,
    storeId: string,
  ): Observable<EmarsysContactInfo>;

  abstract updatePreferenceCenterDetails(
    uid: string,
    cid: string,
    llid: string,
    details: EmarsysContactInfo,
  ): Observable<EmarsysContactInfo>;
}
