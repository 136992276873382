import { Injectable } from '@angular/core';
import { CheckoutConnector } from '@spartacus/checkout/base/core';
import { Address } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CjCheckoutAdapter } from './custom-checkout.adapter';

@Injectable({
  providedIn: 'root',
})
export class CjCheckoutConnector extends CheckoutConnector {
  constructor(protected override adapter: CjCheckoutAdapter) {
    super(adapter);
  }

  public createBillingAddress(userId: string, cartId: string, billingAddress: Address): Observable<any> {
    return this.adapter.createBillingAddress(userId, cartId, billingAddress);
  }

  public setBillingAddress(userId: string, cartId: string, billingAddressId: string): Observable<any> {
    return this.adapter.setBillingAddress(userId, cartId, billingAddressId);
  }

  public unsetBillingAddress(userId: string, cartId: string): Observable<any> {
    return this.adapter.unsetBillingAddress(userId, cartId);
  }
}
