import { Injectable } from '@angular/core';
import { CmsService, LanguageService } from '@spartacus/core';
import { CurrentProductService, SchemaBuilder } from '@spartacus/storefront';
import { Observable, combineLatest, map, switchMap } from 'rxjs';
import { CjStructuredDataUtilsService } from '../../structured-data-utils-service';

@Injectable({
  providedIn: 'root',
})
export class CjWebPageSchemaBuilder implements SchemaBuilder {
  constructor(
    private readonly utils: CjStructuredDataUtilsService,
    private readonly cmsService: CmsService,
    private readonly currentProduct: CurrentProductService,
    private readonly languageService: LanguageService,
  ) {}

  build(): Observable<any> {
    // Common to all pages excepting FAQ and contact pague
    // Using currentProductService ensures we are not in a ProductPage
    return combineLatest([this.cmsService.getCurrentPage(), this.languageService.getActive()]).pipe(
      switchMap(([page]) =>
        this.currentProduct.getProduct().pipe(
          map((product) =>
            page?.pageId != 'contact' && page?.pageId != 'faq' && !product
              ? {
                  '@type': 'WebPage',
                  '@id': this.utils.getCurrentUrl('#webpage'),
                  url: this.utils.getCurrentUrl(),
                  breadcrumb: { '@id': this.utils.getCurrentUrl('#breadcrumb') },
                  image: { '@id': this.utils.getCurrentUrl('#primaryimage') },
                  hasPart: { '@id': this.utils.getCurrentUrl('#faq') },
                  speakable: {
                    '@type': 'SpeakableSpecification',
                    cssSelector: ['speakable-title', 'speakable-description'],
                  },
                  publisher: { '@id': this.utils.getFullUrl('#organization') },
                  isPartOf: { '@id': this.utils.getFullUrl('#website') },
                }
              : {},
          ),
        ),
      ),
    );
  }
}
