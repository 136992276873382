import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { MultiCartService } from '@spartacus/cart/base/core';
import { User } from '@spartacus/user/account/root';
import { Observable, map, tap } from 'rxjs';
import { CjGiftCard } from 'src/app/cms-components/checkout/checkout-gift-card/model/gift-card.model';
import { CjGiftCardsActions } from 'src/app/cms-components/checkout/checkout-gift-card/store/actions';
import { StateWithGiftCards } from 'src/app/cms-components/checkout/checkout-gift-card/store/gift-card-state';
import { getGiftCardsEntitySelectorFactory } from 'src/app/cms-components/checkout/checkout-gift-card/store/selectors/gift-card.selectors';
import { CjCartConnector } from 'src/app/core/cart/connectors/cart.connector';
import { CjCart } from '../../models/cart.model';

@Injectable({
  providedIn: 'root',
})
export class CjMultiCartService extends MultiCartService {
  private customCartConnector: CjCartConnector = inject(CjCartConnector);
  private giftCardStore: Store<StateWithGiftCards> = inject(Store<StateWithGiftCards>);

  /**
   * Set document identifier to customer
   *
   * @param cartId cart ID
   * @param userId User ID
   * @param user User data
   */
  assignDocumentIdentifier(cartId: string, userId: string, user: User): Observable<any> {
    return this.customCartConnector.setDocumentIdentifier(userId, cartId, user);
  }

  loadAvailableGiftCards(userId: string, cartId: string): void {
    this.giftCardStore.dispatch(new CjGiftCardsActions.LoadGiftCards({ userId, cartId }));
  }
  getAvailableGiftCards(userId: string, cartId: string): Observable<CjGiftCard[]> {
    return this.giftCardStore.pipe(
      select(getGiftCardsEntitySelectorFactory(cartId)),
      tap(
        (giftCardsEntity) =>
          !giftCardsEntity.loading && !giftCardsEntity.error && !giftCardsEntity.success && this.loadAvailableGiftCards(userId, cartId),
      ),
      map((giftCardsEntity) => giftCardsEntity.value || []),
    );
  }

  getGiftCard(cartId: string): Observable<CjGiftCard | undefined> {
    return this.getCart(cartId).pipe(map((cart) => (cart as CjCart)?.giftCardProduct));
  }
  setGiftCard(userId: string, cartId: string, giftCardCode: string, giftCardsMessage = ''): void {
    this.giftCardStore.dispatch(new CjGiftCardsActions.SelectGiftCard({ userId, cartId, giftCardCode, giftCardsMessage }));
  }
  removeGiftCard(userId: string, cartId: string): void {
    this.giftCardStore.dispatch(new CjGiftCardsActions.RemoveGiftCard({ userId, cartId }));
  }
}
