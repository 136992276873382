import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { IconModule, MediaModule, PageComponentModule } from '@spartacus/storefront';
import { CjDeliveryBannerDialogComponent } from './delivery-banner-dialog/delivery-banner-dialog.component';
import { cjDeliveryBannerLayoutConfig } from './delivery-banner-dialog/delivery-banner-dialog.config';
import { CjDeliveryBannerComponent } from './delivery-banner.component';

@NgModule({
  imports: [CommonModule, PageComponentModule, MediaModule, IconModule, I18nModule],
  providers: [
    provideConfig(cjDeliveryBannerLayoutConfig),
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsborneDeliveryComponent: {
          component: () => import('./delivery-banner.component').then((m) => m.CjDeliveryBannerComponent),
        },
      },
    }),
  ],
  declarations: [CjDeliveryBannerComponent, CjDeliveryBannerDialogComponent],
  exports: [CjDeliveryBannerComponent, CjDeliveryBannerDialogComponent],
})
export class CjDeliveryBannerModule {}
