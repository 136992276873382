import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { CjCartSharedModule } from '../cart-shared/cart-shared.module';
import { CjCartTotalsComponent } from './cart-totals.component';

@NgModule({
  imports: [CommonModule, CjCartSharedModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CartTotalsComponent: {
          component: () => import('./cart-totals.component').then((m) => m.CjCartTotalsComponent),
        },
        CheckoutOrderSummary: {
          component: () => import('./cart-totals.component').then((m) => m.CjCartTotalsComponent),
        },
      },
    }),
  ],
  declarations: [CjCartTotalsComponent],
  exports: [CjCartTotalsComponent],
})
export class CjCartTotalsModule {}
