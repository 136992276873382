import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { PageComponentModule } from '@spartacus/storefront';
import { CjNavTabsComponent } from './nav-tabs/nav-tabs.component';
import { CjUserGreetingComponent } from './user-greeting/user-greeting.component';

@NgModule({
  imports: [CommonModule, PageComponentModule, I18nModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsborneMyAccountHeaderBanner: {
          component: () => import('./nav-tabs/nav-tabs.component').then((m) => m.CjNavTabsComponent),
        },
        UserGreetingComponent: {
          component: () => import('./user-greeting/user-greeting.component').then((m) => m.CjUserGreetingComponent),
        },
      },
    }),
  ],
  declarations: [CjNavTabsComponent, CjUserGreetingComponent],
  exports: [CjNavTabsComponent, CjUserGreetingComponent],
})
export class CjBottomHeaderNavModule {}
