import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccCheckoutAdapter } from '@spartacus/checkout/base/occ';
import { ADDRESS_SERIALIZER, Address } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CjCheckoutAdapter } from '../../../checkout/connectors/custom-checkout.adapter';

@Injectable()
export class CjOccCheckoutAdapter extends OccCheckoutAdapter implements CjCheckoutAdapter {
  public createBillingAddress(userId: string, cartId: string, billingAddress: Address): Observable<any> {
    billingAddress = this.converter.convert(billingAddress, ADDRESS_SERIALIZER);
    const url = this.occEndpoints.buildUrl('billingAddress', {
      urlParams: { userId, cartId },
    });
    return this.http.post<any>(url, billingAddress, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    });
  }

  public setBillingAddress(userId: string, cartId: string, billingAddressId: string): Observable<any> {
    const params = new HttpParams().set('addressId', billingAddressId);
    const url = this.occEndpoints.buildUrl('billingAddress', {
      urlParams: { userId, cartId },
    });
    return this.http.put(url, {}, { params });
  }

  public unsetBillingAddress(userId: string, cartId: string): Observable<any> {
    const url = this.occEndpoints.buildUrl('billingAddress', {
      urlParams: { userId, cartId },
    });
    return this.http.delete<any>(url, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    });
  }
}
