import { Component } from '@angular/core';
import { CmsBannerComponentMedia, CmsResponsiveBannerComponentMedia, Image, ImageGroup } from '@spartacus/core';
import { ICON_TYPE, LaunchDialogService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CjCmsMediaService } from 'src/app/shared/utils/media-utils.service';
import { CjCmsAnimationSlide } from '../animation-slider.model';

@Component({
  selector: 'cj-animation-slider-dialog',
  templateUrl: './animation-slider-dialog.component.html',
  styleUrls: ['./animation-slider-dialog.component.scss'],
})
export class CjAnimationSliderDialogComponent {
  iconTypes = ICON_TYPE;

  data$: Observable<CjCmsAnimationSlide> = this.launchDialogService.data$;

  constructor(
    private readonly launchDialogService: LaunchDialogService,
    protected cjCmsMediaService: CjCmsMediaService,
  ) { }

  dismissModal(reason: string) {
    this.launchDialogService.closeDialog(reason);
  }

  getMedia(media: CmsBannerComponentMedia | CmsResponsiveBannerComponentMedia): Image | ImageGroup {
    return this.cjCmsMediaService.getMedia(media);
  }
}
