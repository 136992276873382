import { CxEvent } from '@spartacus/core';

export class UserLoginEvent extends CxEvent {
  static override readonly type = 'UserLoginEvent';
  event!: string;
  method!: string;
  uid!: string;
}

export class UserRegisterEvent extends CxEvent {
  static override readonly type = 'UserRegisterEvent';
  event!: string;
  method!: string;
  uid!: string;
}

export class UserAddProductInterestEvent extends CxEvent {
  static override readonly type = 'UserAddProductInterestEvent';
  event!: string;
  item_name!: string;
}
