import { CxEvent } from '@spartacus/core';
import { EcommercePurchase } from './checkout-events.model';

/**
 * Indicates that a user has successfully placed an order
 */
export class OrderPlacedEvent extends CxEvent {
  /**
   * Event's type
   */
  static override readonly type = 'OrderPlacedEvent';
  event!: string;
  ecommerce!: EcommercePurchase;
}
