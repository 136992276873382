import { Injectable } from '@angular/core';
import { CheckoutPaymentTypeFacade } from '@spartacus/checkout/b2b/root';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export abstract class CjCheckoutPaymentTypeFacade extends CheckoutPaymentTypeFacade {
  abstract getSavePaymentCard(): Observable<boolean>;
  abstract setSavePaymentCard(save: boolean): void;
}
