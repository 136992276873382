import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CartNotEmptyGuard, CheckoutStepsSetGuard } from '@spartacus/checkout/base/components';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { CjCheckoutProgressMobileTopComponent } from './checkout-progress-mobile-top.component';

@NgModule({
  imports: [CommonModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutProgressMobileTop: {
          component: CjCheckoutProgressMobileTopComponent,
          guards: [CartNotEmptyGuard, CheckoutStepsSetGuard],
        },
      },
    }),
  ],
  declarations: [CjCheckoutProgressMobileTopComponent],
  exports: [CjCheckoutProgressMobileTopComponent],
})
export class CjCheckoutProgressMobileTopModule {}
