import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { CjAddressFormDialogComponent } from './address-form-dialog.component';

export const ADDRESS_FORM_DIALOG = 'ADDRESS_FORM';

export const cjAddressFormLayoutConfig: LayoutConfig = {
  launch: {
    ADDRESS_FORM: {
      inline: true,
      component: CjAddressFormDialogComponent,
      dialogType: DIALOG_TYPE.POPOVER_CENTER,
    },
  },
};
