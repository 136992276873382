import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BugherdService } from './bugherd.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [BugherdService],
})
export class BugherdModule {}
