import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { CjProductVideoDialogComponent } from './product-video-dialog.component';

export const VIDEO_DIALOG = 'VIDEO';

export const cjProductVideoLayoutConfig: LayoutConfig = {
  launch: {
    VIDEO: {
      inlineRoot: true,
      component: CjProductVideoDialogComponent,
      dialogType: DIALOG_TYPE.POPOVER_CENTER_BACKDROP,
    },
  },
};
