<nav [attr.aria-label]="'common.breadcrumbs' | cxTranslate" tabindex="-1" class="cj-breadcrumb">
  <ol [attr.aria-label]="'common.breadcrumbs' | cxTranslate" class="cj-breadcrumb__list">
    <li *ngFor="let crumb of crumbs$ | async">
      <a
        [attr.aria-label]="'navigation.navigateTo' | cxTranslate: { nav: crumb.label }"
        [routerLink]="crumb.link"
        [innerHTML]="crumb.label"
        class="cj-breadcrumb__link"
      ></a>
    </li>
  </ol>
</nav>
