import { CommonModule } from '@angular/common';
import { ComponentFactoryResolver, NgModule, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AddToCartModule } from '@spartacus/cart/base/components/add-to-cart';
import { CmsConfig, FeaturesConfigModule, I18nModule, MODULE_INITIALIZER, UrlModule, provideConfig } from '@spartacus/core';
import {
  MyAccountV2ConsignmentTrackingComponent,
  OrderAmendService,
  OrderCancellationService,
  OrderDetailsModule,
} from '@spartacus/order/components';
import { OrderOutlets, USE_MY_ACCOUNT_V2_ORDER } from '@spartacus/order/root';
import {
  CardModule,
  IconModule,
  KeyboardFocusModule,
  OutletModule,
  OutletPosition,
  OutletService,
  PromotionsModule,
  ProvideOutletOptions,
  SpinnerModule,
} from '@spartacus/storefront';
import { CjCartSharedModule } from 'src/app/cms-components/cart/base/components/cart-shared/cart-shared.module';
import { CjModifyOrderService } from 'src/app/core/order/facade/modify-order.service';
import { CjReorderOrderService } from 'src/app/core/order/facade/reorder-order.service';
import { CjOrderDetailActionsComponent } from './order-detail-actions/order-detail-actions.component';
import { CjOrderDetailCancelDialogComponent } from './order-detail-actions/order-detail-cancel-dialog/order-detail-cancel-dialog.component';
import { cjOrderDetailCancelLayoutConfig } from './order-detail-actions/order-detail-cancel-dialog/order-detail-cancel-layout.config';
import { CjOrderDetailModifyDialogComponent } from './order-detail-actions/order-detail-modify-dialog/order-detail-modify-dialog.component';
import { cjOrderDetailModifyLayoutConfig } from './order-detail-actions/order-detail-modify-dialog/order-detail-modify-layout.config';
import { CjOrderDetailReorderDialogComponent } from './order-detail-actions/order-detail-reorder-dialog/order-detail-reorder-dialog.component';
import { cjOrderDetailReorderLayoutConfig } from './order-detail-actions/order-detail-reorder-dialog/order-detail-reorder-layout-config';
import { CjOrderDetailItemsComponent } from './order-detail-items/order-detail-items.component';
import { CjOrderDetailShippingComponent } from './order-detail-shipping/order-detail-shipping.component';
import { CjOrderDetailTotalsComponent } from './order-detail-totals/order-detail-totals.component';
import { CjOrderOverviewComponent } from './order-overview/order-overview.component';
import { CjOrderDetailTicketDownloadComponent } from './order-detail-actions/order-detail-ticket-download/order-detail-ticket-download.component';

function registerOrderOutletFactory(): () => void {
  const isMyAccountV2 = inject(USE_MY_ACCOUNT_V2_ORDER);
  const outletService = inject(OutletService);
  const componentFactoryResolver = inject(ComponentFactoryResolver);
  return () => {
    const config: ProvideOutletOptions = {
      component: MyAccountV2ConsignmentTrackingComponent,
      id: OrderOutlets.ORDER_CONSIGNMENT,
      position: OutletPosition.REPLACE,
    };
    if (isMyAccountV2) {
      const template = componentFactoryResolver.resolveComponentFactory(config.component);
      outletService.add(config.id, template, config.position);
    }
  };
}

@NgModule({
  imports: [
    CommonModule,
    CardModule,
    I18nModule,
    IconModule,
    FeaturesConfigModule,
    PromotionsModule,
    UrlModule,
    SpinnerModule,
    RouterModule,
    OutletModule,
    AddToCartModule,
    KeyboardFocusModule,
    IconModule,
    OrderDetailsModule,
    CjCartSharedModule,
    ReactiveFormsModule,
  ],
  providers: [
    provideConfig(cjOrderDetailReorderLayoutConfig),
    provideConfig(cjOrderDetailCancelLayoutConfig),
    provideConfig(cjOrderDetailModifyLayoutConfig),
    provideConfig(<CmsConfig>{
      cmsComponents: {
        AccountOrderDetailsOverviewComponent: {
          component: () => import('./order-overview/order-overview.component').then((m) => m.CjOrderOverviewComponent),
        },
        AccountOrderDetailsShippingComponent: {
          component: () => import('./order-detail-shipping/order-detail-shipping.component').then((m) => m.CjOrderDetailShippingComponent),
        },
        AccountOrderDetailsTotalsComponent: {
          component: () => import('./order-detail-totals/order-detail-totals.component').then((m) => m.CjOrderDetailTotalsComponent),
        },
        AccountOrderDetailsItemsComponent: {
          component: () => import('./order-detail-items/order-detail-items.component').then((m) => m.CjOrderDetailItemsComponent),
        },
        AccountOrderDetailsActionsComponent: {
          component: () => import('./order-detail-actions/order-detail-actions.component').then((m) => m.CjOrderDetailActionsComponent),
        },
      },
    }),
    {
      provide: MODULE_INITIALIZER,
      useFactory: registerOrderOutletFactory,
      multi: true,
    },
    {
      provide: OrderAmendService,
      useExisting: OrderCancellationService,
    },
    CjModifyOrderService,
    CjReorderOrderService,
  ],
  declarations: [
    CjOrderOverviewComponent,
    CjOrderDetailShippingComponent,
    CjOrderDetailTotalsComponent,
    CjOrderDetailItemsComponent,
    CjOrderDetailActionsComponent,
    CjOrderDetailCancelDialogComponent,
    CjOrderDetailModifyDialogComponent,
    CjOrderDetailReorderDialogComponent,
    CjOrderDetailTicketDownloadComponent,
  ],
  exports: [
    CjOrderOverviewComponent,
    CjOrderDetailShippingComponent,
    CjOrderDetailTotalsComponent,
    CjOrderDetailItemsComponent,
    CjOrderDetailActionsComponent,
    CjOrderDetailCancelDialogComponent,
    CjOrderDetailModifyDialogComponent,
    CjOrderDetailReorderDialogComponent,
    CjOrderDetailTicketDownloadComponent,
  ],
})
export class CjOrderDetailsModule {}
