import { CxEvent } from '@spartacus/core';
import { PageEvent } from '@spartacus/storefront';
import { EcommerceProductDetail, EcommerceProductDetailSelect } from './product-events.model';

/**
 * Indicates that a user visited a product detail page.
 */
export class ProductDetailsPageEvent extends CxEvent {
  /** event's type */
  static override readonly type = 'ProductDetailsPageEvent';
  event!: string;
  ecommerce!: EcommerceProductDetail;
}

/**
 * Indicates that a user selected a product for details.
 */
export class ProductDetailsSelectEvent extends CxEvent {
  static override readonly type = 'ProductDetailsSelectEvent';
  event!: string;
  ecommerce!: EcommerceProductDetailSelect;
}

/**
 * Indicates that a user visited a category page.
 */
export class CategoryPageResultsEvent extends PageEvent {
  /** event's type */
  static override readonly type = 'CategoryPageResultsEvent';
  categoryCode!: string;
  categoryName?: string;
  numberOfResults!: number;
}

/**
 * Indicates that a user visited the search results page,
 * and that the search results have been retrieved.
 */
export class SearchPageResultsEvent extends PageEvent {
  /** event's type */
  static override readonly type = 'SearchPageResultsEvent';
  searchTerm!: string;
  numberOfResults!: number;
}

/**
 * Indicates that the creation of order return failed.
 */
export class CreateOrderReturnFailEvent extends CxEvent {
  /** event's type */
  static override readonly type = 'CreateOrderReturnFailEvent';
}
