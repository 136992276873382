import { ModuleWithProviders, NgModule } from '@angular/core';
import { contextInitializerProviders } from './services/providers/context-initializer-providers';

@NgModule({
  imports: [],
})
export class CjSiteContextModule {
  static forRoot(): ModuleWithProviders<CjSiteContextModule> {
    return {
      ngModule: CjSiteContextModule,
      providers: [...contextInitializerProviders],
    };
  }
}
