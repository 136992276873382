import { InjectionToken, Provider } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { PaymentDetails, StateUtils } from '@spartacus/core';
import { CONTACT_FORM } from 'src/app/cms-components/atc/contact/contact-form/store/actions/contact-form.action';
import { CjRedsysActions } from '../actions';
import { RedsysState } from '../redsys-state';

export const initialState = undefined;
export function cjRedsysCardReducer(state: PaymentDetails[] | undefined = initialState, action: StateUtils.LoaderAction) {
  switch (action.type) {
    case CjRedsysActions.LOAD_CARDS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export function getReducers(): ActionReducerMap<RedsysState> {
  return {
    cards: StateUtils.loaderReducer<PaymentDetails[]>(CONTACT_FORM, cjRedsysCardReducer),
  };
}

export const cjRedsysReducerToken: InjectionToken<ActionReducerMap<RedsysState>> = new InjectionToken<ActionReducerMap<RedsysState>>(
  'CjRedsysReducers',
);

export const cjRedsysReducerProvider: Provider = {
  provide: cjRedsysReducerToken,
  useFactory: getReducers,
};
