/**
 * Indicates that a user has successfully placed an order
 */
import { CxEvent } from '@spartacus/core';
import { CheckoutDeliveryModeCompleteModel, CheckoutPaymentModeCompleteModel, CheckoutStepsModel } from './checkout-steps.model';

/**
 * Indicates that a user visited a checkout step page.
 */
export class CheckoutStepsEvent extends CxEvent {
  /** event's type */
  static override readonly type = 'CheckoutStepsPageEvent';
  event!: string;
  ecommerce!: CheckoutStepsModel;
}

/**
 * Indicates that a user change a checkout delivery mode.
 */
export class CheckoutDeliveryModeEvent extends CxEvent {
  /** event's type */
  static override readonly type = 'CheckoutDeliveryModeEvent';
  event!: string;
  ecommerce!: CheckoutStepsModel;
}

/**
 * Indicates that a user completed setting up checkout delivery mode.
 */
export class CheckoutDeliveryModeCompleteEvent extends CxEvent {
  /** event's type */
  static override readonly type = 'CheckoutDeliveryModeCompleteEvent';
  event!: string;
  ecommerce!: CheckoutDeliveryModeCompleteModel;
}

/**
 * Indicates that a user change a payment mode.
 */
export class CheckoutPaymentModeEvent extends CxEvent {
  /** event's type */
  static override readonly type = 'CheckoutPaymentModeEvent';
  event!: string;
  ecommerce!: CheckoutStepsModel;
}

/**
 * Indicates that a user change a payment mode.
 */
export class CheckoutPaymentModeCompleteEvent extends CxEvent {
  /** event's type */
  static override readonly type = 'CheckoutPaymentModeCompleteEvent';
  event!: string;
  ecommerce!: CheckoutPaymentModeCompleteModel;
}
