import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { CheckoutAuthGuard } from '@spartacus/checkout/base/components';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CjCheckoutAuthGuard extends CheckoutAuthGuard {
  override canActivate(): Observable<boolean | UrlTree> {
    return combineLatest([this.authService.isUserLoggedIn(), this.activeCartFacade.isGuestCart(), this.activeCartFacade.isStable()]).pipe(
      map(([isLoggedIn, isGuestCart, isStable]) => ({
        isLoggedIn,
        isGuestCart,
        isStable,
      })),
      filter((data) => data.isStable),
      map((data) => {
        console.log('CheckoutAuthGuard | If !loggedIn or !guestCart go /login | [isLoggedIn, isGuestCart]', [
          data.isLoggedIn,
          data.isGuestCart,
        ]);

        if (!data.isLoggedIn) {
          return data.isGuestCart ? true : this.handleAnonymousUser();
        }
        return data.isLoggedIn;
      }),
    );
  }
}
