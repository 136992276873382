import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { FormErrorsModule, NgSelectA11yModule, PasswordVisibilityToggleModule, SpinnerModule } from '@spartacus/storefront';
import { CjRegisterComponent } from './register.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    SpinnerModule,
    FormErrorsModule,
    NgSelectModule,
    NgSelectA11yModule,
    PasswordVisibilityToggleModule,
  ],
  providers: [
    provideConfig({
      cmsComponents: {
        RegisterCustomerComponent: {
          component: () => import('./register.component').then((m) => m.CjRegisterComponent),
        },
      },
    }),
  ],
  declarations: [CjRegisterComponent],
  exports: [CjRegisterComponent],
})
export class CjRegisterModule {}
