import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService, PaymentDetails } from '@spartacus/core';
import { Observable, map, take } from 'rxjs';
import { CjOccPaymentDetails } from '../redsys-model';
import { CjRedsysAdapter } from './redsys.adapter';

@Injectable({
  providedIn: 'root',
})
export class CjOccRedsysAdapter implements CjRedsysAdapter {
  constructor(
    private readonly http: HttpClient,
    private readonly occEndpoints: OccEndpointsService,
  ) {}

  loadCards(userId: string): Observable<PaymentDetails[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http
      .get<CjOccPaymentDetails>(
        this.occEndpoints.buildUrl('redsysCards', {
          urlParams: { userId },
        }),
        { headers },
      )
      .pipe(
        take(1),
        map((res) => res?.payments /*.filter((card) => card.saved)*/),
      );
  }
}
