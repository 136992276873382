import { Injectable } from '@angular/core';
import { PaymentDetails } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export abstract class CjRedsysAdapter {
  abstract loadCards(userId: string): Observable<PaymentDetails[]>;
}
