<div class="cx-modal-container cj-animation-slider-dialog" (esc)="dismissModal('Escape pressed')">
  <div class="cx-modal-content" *ngIf="data$ | async as data">
    <!-- Modal Header -->
    <div class="cx-dialog-header modal-header">
      <button type="button" class="close" (click)="dismissModal('Cross click')">
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </button>
    </div>
    <!-- Modal Body -->
    <div class="cx-dialog-body modal-body">
      <cj-media
        *ngIf="data.media?.media as media"
        [container]="getMedia(media)"
        [muted]="false"
        [ngClass]="data.mediaMobile ? 'slider-media-desktop' : ''">
      </cj-media>
      <cj-media
        *ngIf="data.mediaMobile?.media as media"
        [container]="getMedia(media)"
        [muted]="false"
        class="slider-media-mobile">
      </cj-media>
    </div>
  </div>
</div>
