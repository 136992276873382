<div class="cx-modal-container cj-address-form-dialog" (esc)="dismissModal('Escape pressed')">
  <!-- Modal Header -->
  <div class="cx-dialog-header modal-header">
    <h2>{{ 'checkoutAddress.addNewAddress' | cxTranslate }}</h2>
    <button type="button" class="close" attr.aria-label="{{ 'addToCart.closeModal' | cxTranslate }}" (click)="dismissModal('Cross click')">
      <span aria-hidden="true">
        <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
      </span>
    </button>
  </div>

  <div class="cx-dialog-body modal-body">
    <cj-address-form
      #addressForm
      (submitAddress)="dismissModal($event)"
      [showActionBtns]="false"
      [loadAllCountries]="false"
    ></cj-address-form>

    <div class="cx-address-form-btns row">
      <div class="col-md-13 col-lg-6">
        <button (click)="forceSubmit()" class="btn btn-block btn-primary" type="submit">
          {{ 'common.save' | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
</div>
