import { DeliveryCountryEntities } from '@spartacus/core';

export const CJ_USER_FEATURE = 'cjUser';

export interface CjUserState {
  baseSiteCountries: CjBaseSiteCountriesState;
}

export interface CjBaseSiteCountriesState {
  entities: DeliveryCountryEntities;
}
