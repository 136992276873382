import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CookieproModule } from './cookiepro/cookiepro.module';
import { CookieproService } from './cookiepro/cookiepro.service';
import { BugherdService } from './bugherd/bugherd.service';
import { BugherdModule } from './bugherd/bugherd.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, CookieproModule, BugherdModule],
})
export class ScriptsModule {
  static forRoot(): ModuleWithProviders<ScriptsModule> {
    return {
      ngModule: ScriptsModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: (service: CookieproService) => {
            return () => service.loadScript();
          },
          deps: [CookieproService],
          multi: true,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: (service: BugherdService) => {
            return () => service.loadScript();
          },
          deps: [BugherdService],
          multi: true,
        },
      ],
    };
  }
}
