import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CartValidationGuard } from '@spartacus/cart/base/core';
import { CartNotEmptyGuard, CheckoutAuthGuard } from '@spartacus/checkout/base/components';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { OutletModule, PageComponentModule, SpinnerModule } from '@spartacus/storefront';
import { CartSimulationGuard } from '../../cart/base/core/guards/cart-simulation.guard';
import { CjGiftCardModule } from '../checkout-gift-card/gift-card.module';
import { CjCheckoutDeliveryModeComponent } from './checkout-delivery-mode.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, I18nModule, SpinnerModule, OutletModule, PageComponentModule, CjGiftCardModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutDeliveryMode: {
          component: () => import('./checkout-delivery-mode.component').then((m) => m.CjCheckoutDeliveryModeComponent),
          guards: [CheckoutAuthGuard, CartNotEmptyGuard, CartSimulationGuard, CartValidationGuard],
        },
      },
    }),
  ],
  declarations: [CjCheckoutDeliveryModeComponent],
  exports: [CjCheckoutDeliveryModeComponent],
})
export class CjCheckoutDeliveryModeModule {}
