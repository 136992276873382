import { Injectable } from '@angular/core';
import { OccCartValidationAdapter } from '@spartacus/cart/base/occ';
import { CART_NORMALIZER, Cart } from '@spartacus/cart/base/root';
import { normalizeHttpError } from '@spartacus/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CjCartValidationAdapter } from '../../../cart/connectors/cart-validation.adapter';

@Injectable()
export class CjOccCartValidationAdapter extends OccCartValidationAdapter implements CjCartValidationAdapter {
  simulate(cartId: string, userId: string): Observable<Cart> {
    const url = this.occEndpoints.buildUrl('simulate', {
      urlParams: { cartId, userId },
    });

    return this.http.put<Cart>(url, null).pipe(
      catchError((error) => {
        throw normalizeHttpError(error, this.logger);
      }),
      this.converter.pipeable(CART_NORMALIZER),
    );
  }
}
