import { Injectable } from '@angular/core';
import {
  BreadcrumbMeta,
  CanonicalPageResolver,
  ContentPageMetaResolver,
  PageDescriptionResolver,
  PageRobotsMeta
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { CjHreflangMetas } from 'src/app/core/cms/model/page.model';
import { CjPageHreflangResolver } from 'src/app/core/cms/page/page.resolvers';
import { CjBasePageMetaResolver } from './base-page-meta.resolver';

@Injectable({
  providedIn: 'root',
})
export class CjContentPageMetaResolver extends ContentPageMetaResolver implements PageDescriptionResolver, CanonicalPageResolver, CjPageHreflangResolver {
  constructor(protected override basePageMetaResolver: CjBasePageMetaResolver) {
    super(basePageMetaResolver);
  }

  override resolveTitle(): Observable<string | undefined> {
    return this.basePageMetaResolver.resolveTitle();
  }

  override resolveDescription(): Observable<string | undefined> {
    return this.basePageMetaResolver.resolveDescription();
  }

  override resolveBreadcrumbs(): Observable<BreadcrumbMeta[] | undefined> {
    return this.basePageMetaResolver.resolveBreadcrumbs();
  }

  override resolveRobots(): Observable<PageRobotsMeta[]> {
    return this.basePageMetaResolver.resolveRobots();
  }

  override resolveCanonicalUrl(): Observable<string | undefined> {
    return this.basePageMetaResolver.resolveCanonicalUrl();
  }

  resolveHreflang(): Observable<CjHreflangMetas | undefined> {
    return this.basePageMetaResolver.resolveHreflang();
  }
}
