import { APP_INITIALIZER, Provider } from '@angular/core';
import { CjBaseStoreInitializer } from '../base-store-initializer';

export function initializeBaseStore(baseStoreInitializer: CjBaseStoreInitializer): () => void {
  const result = () => {
    baseStoreInitializer.initialize();
  };
  return result;
}

export const contextInitializerProviders: Provider[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: initializeBaseStore,
    deps: [CjBaseStoreInitializer],
    multi: true,
  },
];
