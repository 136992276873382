import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { CjProductVerticalDialogComponent } from './product-vertical-dialog.component';

export const VERTICAL_BANNER_DIALOG = 'VERTICAL_BANNER';

export const cjVerticalBannerSelectorConfig: LayoutConfig = {
  launch: {
    VERTICAL_BANNER: {
      inlineRoot: true,
      component: CjProductVerticalDialogComponent,
      dialogType: DIALOG_TYPE.SIDEBAR_END,
    },
  },
};
