import { Injectable } from '@angular/core';
import {
  BasePageMetaResolver,
  BaseSiteService,
  BreadcrumbMeta,
  CanonicalPageResolver,
  CategoryPageMetaResolver,
  CmsService,
  LanguageService,
  PageDescriptionResolver,
  ProductSearchService,
  RoutingService,
  SemanticPathService,
  TranslationService
} from '@spartacus/core';
import { combineLatest, map, Observable, of, startWith, switchMap } from 'rxjs';
import { CjBaseStoreService } from 'src/app/cms-components/content/misc/site-context-selector/facades/base-store.service';
import { CjHreflangMetas, CjPage } from 'src/app/core/cms/model/page.model';
import { CjPageHreflangResolver } from 'src/app/core/cms/page/page.resolvers';
import { CjStructuredDataUtilsService } from '../../structured-data/structured-data-utils-service';

@Injectable({
  providedIn: 'root',
})
export class CjCategoryPageMetaResolver
  extends CategoryPageMetaResolver
  implements PageDescriptionResolver, CanonicalPageResolver, CjPageHreflangResolver
{
  page$ = this.cms.getCurrentPage();

  constructor(
    protected override productSearchService: ProductSearchService,
    protected override cms: CmsService,
    protected override translation: TranslationService,
    protected override basePageMetaResolver: BasePageMetaResolver,
    private readonly routingService: RoutingService,
    private readonly semanticPathService: SemanticPathService,
    private readonly utils: CjStructuredDataUtilsService,
    private readonly languageService: LanguageService,
    private readonly basestoreService: CjBaseStoreService,
    private readonly basesiteService: BaseSiteService,
  ) {
    super(productSearchService, cms, translation, basePageMetaResolver);
  }

  // resolveHeading is defined by a component (CjCategoryHeaderBannerComponent) in SmartEdit

  override resolveTitle(): Observable<string> {
    this.resolveHreflang();
    return this.page$.pipe(switchMap((page) => (page.title ? of(page.title) : super.resolveTitle())));
  }

  resolveDescription(): Observable<string | undefined> {
    return this.page$.pipe(map((page) => page.description));
  }

  // resolveImage is defined by a component (CjCategoryHeaderBannerComponent or CjCategoryBannerComponent) in SmartEdit
  // resolveCanonical uses this.basePageMetaResolver.resolveCanonical() (autoreferenced)
  override resolveCanonicalUrl(): Observable<string> {
    return super.resolveCanonicalUrl();
  }

  override resolveBreadcrumbs(): Observable<BreadcrumbMeta[]> {
    return combineLatest([this.translation.translate('common.home'), this.resolveTitle(), this.page$]).pipe(
      map(([home, pageTitle, page]) => {
        return [
          { label: home, link: '/' },
          {
            label: pageTitle,
            link: this.routingService.getUrl(
              this.semanticPathService.transform({ cxRoute: 'category', params: { code: (page as CjPage).plpCategoryCode } }),
            ),
          },
        ];
      }),
      startWith([]),
    );
  }

  // resolveRobots uses this.basePageMetaResolver.resolveRobots() (page.robots)

  resolveHreflang(): Observable<CjHreflangMetas | undefined> {
    return combineLatest([
      this.routingService.getParams(),
      this.languageService.getActive(),
      this.basestoreService.getActive(),
      this.basesiteService.getActive().pipe(switchMap((active) => this.basesiteService.get(active))),
    ]).pipe(
      map(([params, activeLang, activeBasestore, activeBasesite]) => {
        const hreflangs: CjHreflangMetas = {};
        const commands = this.semanticPathService.transform({ cxRoute: 'category', params: { code: params['categoryCode'] } });

        hreflangs['x-default'] =
          `${this.utils.getHost()}/${commands.map((val) => (val == activeLang || val == '/' ? activeBasesite?.defaultLanguage?.isocode : val)).join('/')}`;
        activeBasestore?.languages?.forEach((lang) => {
          if (lang.isocode) {
            const path = commands.map((val) => (val == activeLang || val == '/' ? lang.isocode : val)).join('/');
            hreflangs[lang.isocode] = `${this.utils.getHost()}/${path}`;
          }
        });

        return hreflangs;
      }),
    );
  }
}
