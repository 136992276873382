import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StateWithMultiCart } from '@spartacus/cart/base/core';
import { ActiveCartFacade, PaymentType } from '@spartacus/cart/base/root';
import { CHECKOUT_PAYMENT_TYPE_NORMALIZER } from '@spartacus/checkout/b2b/core';
import { OccCheckoutPaymentTypeAdapter } from '@spartacus/checkout/b2b/occ';
import { ConverterService, OccEndpointsService, UserIdService } from '@spartacus/core';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { CjOcc } from 'src/app/core/occ/occ-models/occ.models';

@Injectable()
export class CjOccCheckoutPaymentTypeAdapter extends OccCheckoutPaymentTypeAdapter {
  cartId!: string;
  userId!: string;

  constructor(
    protected override http: HttpClient,
    protected override occEndpoints: OccEndpointsService,
    protected override converter: ConverterService,
    protected store: Store<StateWithMultiCart>,
    protected userIdService: UserIdService,
    protected activeCartFacade: ActiveCartFacade,
  ) {
    super(http, occEndpoints, converter);
  }

  override getPaymentTypes(): Observable<PaymentType[]> {
    return combineLatest([this.userIdService.getUserId(), this.activeCartFacade.takeActiveCartId()]).pipe(
      take(1),
      switchMap(([userId, cartId]) => {
        const url = this.occEndpoints.buildUrl('paymentMethods', {
          urlParams: { userId: userId, cartId: cartId },
        });
        return this.http.get<CjOcc.PaymentTypeList>(url).pipe(
          map((paymentTypeList) => paymentTypeList?.paymentModes),
          // Makes sure that payment modes are not repeated
          map(
            (paymentModes) =>
              paymentModes?.filter(
                (paymentType, index, paymentTypes) => paymentTypes.findIndex((item) => item.code === paymentType.code) === index,
              ) ?? [],
          ),
          this.converter.pipeableMany(CHECKOUT_PAYMENT_TYPE_NORMALIZER),
        );
      }),
    );
  }

  override setPaymentType(userId: string, cartId: string, paymentType: string, purchaseOrderNumber?: string): Observable<PaymentType> {
    let httpParams = new HttpParams().set('code', paymentType);

    if (purchaseOrderNumber !== undefined) {
      httpParams = httpParams.set('purchaseOrderNumber', purchaseOrderNumber);
    }

    return this.http.put(this.occEndpoints.buildUrl('paymentMode', { urlParams: { userId, cartId } }), {}, { params: httpParams }).pipe(
      take(1),
      tap(() => this.activeCartFacade.reloadActiveCart()),
    );
  }
}
