import { NgModule } from '@angular/core';
import { CjProductStoreModule } from './core/store/product-grid-store.module';
import { CjProductBannersModule } from './product-banners/product-banners.module';
import { CjProductDetailModule } from './product-detail/product-detail.module';
import { CjProductListBannerModule } from './product-list-banner/product-list-banner.module';
import { CjProductListModule } from './product-list/product-list.module';
import { CjProductAttributesModule } from './product-tabs/product-attributes/product-attributes.module';

@NgModule({
  imports: [
    CjProductStoreModule,
    CjProductBannersModule,
    CjProductDetailModule,
    CjProductListBannerModule,
    CjProductListModule,
    CjProductAttributesModule,
  ],
})
export class CjProductModule { }
