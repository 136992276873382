import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { CjWarrantyBannerDialogComponent } from './warranty-banner-dialog.component';

export const WARRANTY_BANNER_DIALOG = 'WARRANTY_BANNER';

export const cjWarrantyBanneLayoutConfig: LayoutConfig = {
  launch: {
    WARRANTY_BANNER: {
      inlineRoot: true,
      component: CjWarrantyBannerDialogComponent,
      dialogType: DIALOG_TYPE.SIDEBAR_END,
    },
  },
};
