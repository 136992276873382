import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BasePageMetaResolver,
  BaseSiteService,
  BreadcrumbMeta,
  CmsService,
  LanguageService,
  PageLinkService,
  RoutingPageMetaResolver,
  RoutingService,
  TranslationService
} from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { CjBaseStoreService } from 'src/app/cms-components/content/misc/site-context-selector/facades/base-store.service';
import { CjHreflangMetas } from 'src/app/core/cms/model/page.model';
import { CjPageHreflangResolver } from 'src/app/core/cms/page/page.resolvers';
import { CjCapitalizePipe } from 'src/app/shared/pipes/capitalize/capitalize-pipe';
import { CjStructuredDataUtilsService } from '../../structured-data/structured-data-utils-service';

@Injectable({
  providedIn: 'root',
})
export class CjBasePageMetaResolver extends BasePageMetaResolver implements CjPageHreflangResolver {
  constructor(
    protected override cmsService: CmsService,
    protected override translation: TranslationService,
    protected override routingPageMetaResolver: RoutingPageMetaResolver,
    protected override router: Router,
    protected override pageLinkService: PageLinkService,
    private readonly utils: CjStructuredDataUtilsService,
    private readonly basesiteService: BaseSiteService,
    private readonly languageService: LanguageService,
    private readonly basestoreService: CjBaseStoreService,
    private readonly routingService: RoutingService,
    private readonly capitalizePipe: CjCapitalizePipe,
  ) {
    super(cmsService, translation, routingPageMetaResolver, router, pageLinkService);
  }

  resolveHreflang(): Observable<CjHreflangMetas> {
    return combineLatest([
      this.languageService.getActive(),
      this.basestoreService.getActive(),
      this.basesiteService.getActive().pipe(switchMap((active) => this.basesiteService.get(active))),
    ]).pipe(
      map(([activeLang, activeBasestore, activeBasesite]) => {
        const hreflangs: CjHreflangMetas = {};

        if (activeBasesite?.defaultLanguage?.isocode) {
          hreflangs['x-default'] = this.getHreflangCommand(activeLang, activeBasesite?.defaultLanguage?.isocode);
        }

        activeBasestore?.languages?.forEach((lang) => {
          if (lang.isocode) hreflangs[lang.isocode] = this.getHreflangCommand(activeLang, lang.isocode);
        });

        return hreflangs;
      }),
    );
  }

  getHreflangCommand(activeLang: string, lang: string) {
    return this.utils.getCurrentUrl().replace(`/${activeLang}/`, `/${lang}/`);
  }

  override resolveBreadcrumbs(): Observable<BreadcrumbMeta[] | undefined> {
    return combineLatest([
      this.homeBreadcrumb$,
      this.routingService.getPageContext().pipe(
        filter((context) => !!context.id),
        map((context) => (this.router.parseUrl(context.id)?.root?.children || {})['primary']?.segments),
        map((segments) => segments.map((segment) => segment.path || '')),
        withLatestFrom(this.page$),
        map(([paths, page]) =>
          paths.map(
            (path, i, arr) =>
              ({
                label: i + 1 < arr.length ? this.getLabel(path) : page.title || this.getLabel(path),
                link: this.getPath(arr, i),
              }) as BreadcrumbMeta,
          ),
        ),
      ),
    ]).pipe(
      map(([home, breadcrumbs]) => [...home, ...breadcrumbs]),
    );
  }

  getLabel(path: string): string {
    const label = path.replaceAll(/[-_]/g, ' ');
    return this.capitalizePipe.transform(label);
  }

  getPath(arr: any[], i: number): string {
    return '/' + arr.slice(0, i + 1).join('/');
  }
}
