import { CjBaseStore } from 'src/app/core/model/misc.mode';

export const BASE_STORES_FEATURE = 'baseStores';

export interface BaseStoreState {
  baseStores?: CjBaseStore[];
  selected?: CjBaseStore;
}
export interface StateWithBaseStores {
  [BASE_STORES_FEATURE]: BaseStoreState;
}
