import { Action } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { CjGiftCardLoadSuccessPayload, CjGiftCardSetPayload, GIFT_CARD_FEATURE } from '../gift-card-state';

export const LOAD_GIFT_CARDS = '[Gift Cards] Load Gift Cards ';
export const LOAD_GIFT_CARDS_SUCCESS = '[Gift Cards] Load Gift Cards Success';
export const LOAD_GIFT_CARDS_FAIL = '[Gift Cards] Load Gift Cards Fail';

export const LOAD_GIFT_CARD = '[Gift Card] Load Gift Card';
export const LOAD_GIFT_CARD_SUCCESS = '[Gift Card] Load Gift Card Success';
export const LOAD_GIFT_CARD_FAIL = '[Gift Card] Load Gift Card Fail';

export const SELECT_GIFT_CARD = '[Gift Card] Select Gift Card';
export const SELECT_GIFT_CARD_FAIL = '[Gift Card] SelectGift Card Fail';
export const SELECT_GIFT_CARD_SUCCESS = '[Gift Card] Select Gift Card Success';

export const UPDATE_GIFT_CARD = '[Gift Card] Update Gift Card';
export const UPDATE_GIFT_CARD_FAIL = '[Gift Card] Update Gift Card Fail';
export const UPDATE_GIFT_CARD_SUCCESS = '[Gift Card] Update Gift Card Success';

export const REMOVE_GIFT_CARD = '[Gift Card] Remove Gift Card';
export const REMOVE_GIFT_CARD_FAIL = '[Gift Card] Remove Gift Card Fail';
export const REMOVE_GIFT_CARD_SUCCESS = '[Gift Card] Remove OGift Card Success';

export class LoadGiftCards extends StateUtils.EntityLoadAction {
  override readonly type = LOAD_GIFT_CARDS;
  constructor(public payload: { userId: string; cartId: string }) {
    super(GIFT_CARD_FEATURE, payload.cartId);
  }
}

export class LoadGiftCardsSuccess extends StateUtils.EntitySuccessAction {
  override readonly type = LOAD_GIFT_CARDS_SUCCESS;
  constructor(public override payload: CjGiftCardLoadSuccessPayload) {
    super(GIFT_CARD_FEATURE, payload.cartId, payload);
  }
}

export class LoadGiftCardsFail extends StateUtils.EntityFailAction {
  override readonly type = LOAD_GIFT_CARDS_FAIL;
  constructor(public payload: any) {
    super(GIFT_CARD_FEATURE, payload.cartId, payload);
  }
}

export class SelectGiftCard implements Action {
  readonly type = SELECT_GIFT_CARD;
  constructor(public payload: CjGiftCardSetPayload) {}
}

export class SelectGiftCardFail implements Action {
  readonly type = SELECT_GIFT_CARD_FAIL;
  constructor(public payload: any) {}
}

export class RemoveGiftCard implements Action {
  readonly type = REMOVE_GIFT_CARD;
  constructor(public payload: { userId: string; cartId: string }) {}
}

export class RemoveGiftCardFail implements Action {
  readonly type = REMOVE_GIFT_CARD_FAIL;
  constructor(public payload: any) {}
}

// action types
export type giftCardsListActions =
  | LoadGiftCards
  | LoadGiftCardsFail
  | LoadGiftCardsSuccess
  | SelectGiftCard
  | SelectGiftCardFail
  | RemoveGiftCard
  | RemoveGiftCardFail;
