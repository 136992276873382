import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CjBaseStoreEffect } from './effects/base-store.effect';
import { reducerBaseStoreProvider, reducerBaseStoreToken, metaReducers } from './reducer/base-store.reducer';
import { BASE_STORES_FEATURE } from './base-store-state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(BASE_STORES_FEATURE, reducerBaseStoreToken, { metaReducers }),
    EffectsModule.forFeature(CjBaseStoreEffect),
  ],
  providers: [reducerBaseStoreProvider],
})
export class CjBaseStoreModule {}
