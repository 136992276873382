import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { createFrom, EventService, StateWithProcess } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ContactFormActions } from 'src/app/cms-components/atc/contact/contact-form/store/actions';
import { StateWithContactForm } from 'src/app/cms-components/atc/contact/contact-form/store/contact-form-state';
import { ContactFormSelectors } from 'src/app/cms-components/atc/contact/contact-form/store/selector';
import { EcommerceType } from '../tag-manager-feature.model';
import { SendContactFromSuccessEvent } from './contact.events';

@Injectable({ providedIn: 'root' })
export class ContactEventBuilder {
  constructor(
    protected actionsSubject: ActionsSubject,
    protected event: EventService,
    protected store: Store<StateWithContactForm>,
  ) {
    this.register();
  }

  protected register(): void {
    this.registerSendContactFromSuccessEvents();
  }

  protected registerSendContactFromSuccessEvents(): void {
    this.event.register(SendContactFromSuccessEvent, this.registerSendContactFormSuccess());
  }

  protected registerSendContactFormSuccess(): Observable<SendContactFromSuccessEvent> {
    return this.actionsSubject
      .pipe(
        ofType(ContactFormActions.SEND_CONTACT_FORM_SUCCESS),
        map((action: any) => action.payload),
        withLatestFrom(
          this.store.pipe(
            select(ContactFormSelectors.getContactFormLoaderState),
            map((customContactFormListState: any): any[] => customContactFormListState.value),
          ),
        ),
        switchMap(([action, csTicketCauseList]): Observable<string> => {
          let selectedOption = action.templateCode;

          csTicketCauseList.map((ticket) =>
            ticket.templates.map((template: any) => {
              if (template.code === action.templateCode) selectedOption = template.name;
            }),
          );

          return of(selectedOption);
        }),
      )
      .pipe(
        map((causeName) => {
          return createFrom(SendContactFromSuccessEvent, {
            event: EcommerceType.FORM,
            form_option: causeName || '',
          });
        }),
      );
  }
}
