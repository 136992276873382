import { Injectable } from '@angular/core';
import { Cart } from '@spartacus/cart/base/root';
import { Observable } from 'rxjs';
import { CjModifyOrderAdapter } from './modify-order.adapter';

@Injectable()
export class CjModifyOrderConnector {
  constructor(protected adapter: CjModifyOrderAdapter) {}

  public modify(orderId: string, userId: string): Observable<Cart> {
    return this.adapter.modify(orderId, userId);
  }
}
