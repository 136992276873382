import { ProductGridActions } from '../actions';
import { ProductGridAction } from '../actions/product-grid.action';
import { CjProductGridCategoryState, CjProductGridState } from '../product-grid-state';

export const initialState: CjProductGridState = {};

export function reducer(state = initialState, action: ProductGridAction): CjProductGridState {
  const categoryState = ('payload' in action && state[action.payload?.category]) || ({} as CjProductGridCategoryState);

  switch (action.type) {
    case ProductGridActions.ADD_FILTERED_PRODUCT: {
      const filteredProductsList = categoryState?.filteredProductsList || [];
      return filteredProductsList.includes(action.payload.product)
        ? state
        : {
            ...state,
            [action.payload.category]: {
              ...categoryState,
              filteredProductsList: [...filteredProductsList, action.payload.product],
            },
          };
    }
    case ProductGridActions.SET_ONE_COLUMN_BANNERS: {
      return {
        ...state,
        [action.payload.category]: {
          ...categoryState,
          oneColumnBannerList: action.payload.banners,
        },
      };
    }
    case ProductGridActions.SET_THREE_COLUMN_BANNERS: {
      return {
        ...state,
        [action.payload.category]: {
          ...categoryState,
          threeColumnBannerList: action.payload.banners,
        },
      };
    }
    case ProductGridActions.SAVE_ELEMENTS: {
      return {
        ...state,
        [action.payload.category]: {
          ...categoryState,
          elements: {
            ...action.payload.extraData,
            entities: action.payload.elements,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
}
