import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';
import { CjHtmlSanitizerPipeModule } from 'src/app/shared/pipes/html-sanitizer/html-sanitizer-pipe.module';
import { CjIconsComponent } from './icons.component';
@NgModule({
  imports: [CommonModule, MediaModule, CjHtmlSanitizerPipeModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsborneIconsComponent: {
          component: () => import('./icons.component').then((m) => m.CjIconsComponent),
        },
      },
    }),
  ],
  declarations: [CjIconsComponent],
  exports: [CjIconsComponent],
})
export class CjIconsModule {}
