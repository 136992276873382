import { InjectionToken, Provider } from '@angular/core';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { AuthActions } from '@spartacus/core';
import { CjUserState } from '../user-state';
import * as frombaseSiteCountries from './base-site-countries.reducer';


export function getReducers(): ActionReducerMap<CjUserState, any> {
  return {
    // addresses: loaderReducer<Address[], any>(USER_ADDRESSES, fromAddressesReducer.reducer),
    // billingCountries: fromBillingCountriesReducer.reducer,
    // consents: loaderReducer<ConsentTemplate[], any>(USER_CONSENTS, fromUserConsentsReducer.reducer),
    // payments: loaderReducer<PaymentDetails[], any>(USER_PAYMENT_METHODS, fromPaymentReducer.reducer),
    // countries: frombaseSiteCountries.reducer,
    // regions: loaderReducer<RegionsState, any>(REGIONS, fromRegionsReducer.reducer),
    // customerCoupons: loaderReducer<CustomerCouponSearchResult, any>(CUSTOMER_COUPONS, fromCustomerCouponReducer.reducer),
    // notificationPreferences: loaderReducer<NotificationPreference[], any>(
    //   NOTIFICATION_PREFERENCES,
    //   fromNotificationPreferenceReducer.reducer,
    // ),
    // productInterests: loaderReducer<ProductInterestSearchResult, any>(PRODUCT_INTERESTS, fromInterestsReducer.reducer),
    // costCenters: loaderReducer<CostCenter[], any>(USER_COST_CENTERS, fromCostCenterReducer.reducer),
    baseSiteCountries: frombaseSiteCountries.reducer,
  };
}

export const reducerToken: InjectionToken<ActionReducerMap<CjUserState>> = new InjectionToken<ActionReducerMap<CjUserState>>('UserReducers');

export const reducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getReducers,
};

export function clearUserState(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action.type === AuthActions.LOGOUT) {
      state = undefined;
    }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [clearUserState];
