import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { CjProductHealthyDialogComponent } from './product-healthy-dialog.component';

export const PRODUCT_HEALTHY_DIALOG = 'PRODUCT_HEALTHY';

export const cjProductHealthyLayoutConfig: LayoutConfig = {
  launch: {
    PRODUCT_HEALTHY: {
      inlineRoot: true,
      component: CjProductHealthyDialogComponent,
      dialogType: DIALOG_TYPE.POPOVER_CENTER,
    },
  },
};
