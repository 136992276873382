import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { MediaModule, PageComponentModule } from '@spartacus/storefront';
import { CjExperiencesCardListComponent } from './experiences-card-list.component';

@NgModule({
  imports: [CommonModule, PageComponentModule, MediaModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsborneExperienceCardListComponent: {
          component: () =>
            import('./experiences-card-list.component').then(
              (m) => m.CjExperiencesCardListComponent,
            ),
        },
      },
    }),
  ],
  declarations: [CjExperiencesCardListComponent],
  exports: [CjExperiencesCardListComponent],
})
export class CjExperiencesCardListModule {}
