import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccCartAdapter } from '@spartacus/cart/base/occ';
import { User } from '@spartacus/user/account/root';
import { Observable } from 'rxjs';
import { CjCartAdapter } from 'src/app/core/cart/connectors/cart.adapter';

@Injectable()
export class CjOccCartAdapter extends OccCartAdapter implements CjCartAdapter {
  setDocumentIdentifier(userId: string, cartId: string, user: User): Observable<{}> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const url = this.occEndpointsService.buildUrl('documentIdentifier', {
      urlParams: { userId, cartId },
    });
    return this.http.put(url, user, { headers });
  }
}
