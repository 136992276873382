import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import {
  MediaModule,
  PageComponentModule
} from '@spartacus/storefront';
import { CjRestaurantBookingComponent } from './restaurant-booking.component';

@NgModule({
  imports: [CommonModule, PageComponentModule, MediaModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsborneBookingComponent: {
          component: () =>
            import('./restaurant-booking.component').then(
              (m) => m.CjRestaurantBookingComponent,
            ),
        },
      },
    }),
  ],
  declarations: [CjRestaurantBookingComponent],
  exports: [CjRestaurantBookingComponent],
})
export class CjRestaurantBookingModule {}
