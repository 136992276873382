import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService, OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CjOcc } from 'src/app/core/occ/occ-models/occ.models';
import { CsTicketCauseList, CsTicketContactForm } from '../contact-form.model';
import { CS_TICKET_CAUSES_NORMALIZER } from './contact-form-converters';
import { ContactFormAdapter } from './contact-form.adapter';

@Injectable()
export class OccContactFormAdapter implements ContactFormAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
  ) {}

  public loadCsTicketCauses(templateConfigurations: string): Observable<CsTicketCauseList> {
    templateConfigurations = templateConfigurations ?? '';
    const url = this.occEndpoints.buildUrl('csTicketCauses', {
      urlParams: { templateConfigurations },
    });
    return this.http.get<CjOcc.CsTicketCauseList>(url).pipe(this.converter.pipeable(CS_TICKET_CAUSES_NORMALIZER));
  }

  public sendContactForm(form: CsTicketContactForm, templateConfiguration: string): Observable<any> {
    const url = this.occEndpoints.buildUrl('csTicket', {
      urlParams: { templateConfiguration },
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(url, form, { headers });
  }
}
