import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CartItemListComponent } from '@spartacus/cart/base/components';
import { CjCartItemDisplayType } from '../cart.model';

@Component({
  selector: 'cj-cart-item-list',
  templateUrl: './cart-item-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjCartItemListComponent extends CartItemListComponent {
  @Input() displayType: CjCartItemDisplayType = CjCartItemDisplayType.CART;
}
