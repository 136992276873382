import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CartCouponModule } from '@spartacus/cart/base/components';
import { AddToCartModule } from '@spartacus/cart/base/components/add-to-cart';
import { I18nModule, UrlModule } from '@spartacus/core';
import { AtMessageModule, IconModule, ItemCounterModule, MediaModule, OutletModule, PromotionsModule } from '@spartacus/storefront';
import { CjCartItemListComponent } from './cart-item-list/cart-item-list.component';
import { CjCartItemComponent } from './cart-item/cart-item.component';
import { CjOrderInfoComponent } from './order-info/order-info.component';
import { CjOrderSummaryComponent } from './order-summary/order-summary.component';

@NgModule({
  imports: [
    AtMessageModule,
    CartCouponModule,
    CommonModule,
    I18nModule,
    IconModule,
    ItemCounterModule,
    MediaModule,
    OutletModule,
    PromotionsModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    AddToCartModule,
  ],
  declarations: [CjCartItemListComponent, CjCartItemComponent, CjOrderSummaryComponent, CjOrderInfoComponent],
  exports: [CjCartItemListComponent, CjCartItemComponent, CjOrderSummaryComponent, CjOrderInfoComponent],
})
export class CjCartSharedModule {}
