import { ChangeDetectionStrategy, Component, ElementRef, OnInit } from '@angular/core';
import { AddedToCartDialogComponent } from '@spartacus/cart/base/components';
import { ActiveCartFacade, PromotionResult } from '@spartacus/cart/base/root';
import { RoutingService, isNotUndefined } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CjCartItemDisplayType } from '../cart-shared/cart.model';

@Component({
  selector: 'cj-added-to-cart-dialog',
  templateUrl: './added-to-cart-dialog.component.html',
  styleUrls: ['./added-to-cart-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjAddedToCartDialogComponent extends AddedToCartDialogComponent implements OnInit {

  cartItemDisplayTypes = CjCartItemDisplayType;
  orderPromotions$: Observable<PromotionResult[]> | undefined;
  orderAppliedPromotions$: Observable<PromotionResult[]> | undefined;

  constructor(
    protected override activeCartFacade: ActiveCartFacade,
    protected override launchDialogService: LaunchDialogService,
    protected override routingService: RoutingService,
    protected override el: ElementRef,
  ) {
    super(activeCartFacade, launchDialogService, routingService, el);

    this.subscription.add(
      this.activeCartFacade.getActive().pipe(
        filter(isNotUndefined)
      ).subscribe((cart) => {
        this.orderPromotions$ = new Observable<PromotionResult[]>((obs) => {
          obs.next(cart.potentialOrderPromotions);
        });
        this.orderAppliedPromotions$ = new Observable<PromotionResult[]>((obs) => {
          obs.next(cart.appliedOrderPromotions);
        });
      })
    );
  }

  override ngOnInit(): void {
    this.subscription.add(
      this.routingService
        .getRouterState()
        .pipe(filter((state) => !!state.nextState))
        .subscribe(() => this.dismissModal('dismiss')),
    );
  }
}
