import { NgModule } from '@angular/core';
import { CheckoutAdapter, CheckoutPaymentAdapter } from '@spartacus/checkout/base/core';
import { OccCheckoutPaymentAdapter } from '@spartacus/checkout/base/occ';
import { CjCheckoutAdapter } from 'src/app/core/checkout/connectors/custom-checkout.adapter';
import { CjOccCheckoutAdapter } from './occ-checkout.adapter';

@NgModule({
  providers: [
    { provide: CheckoutAdapter, useExisting: CjCheckoutAdapter },
    { provide: CjCheckoutAdapter, useClass: CjOccCheckoutAdapter },
    { provide: CheckoutPaymentAdapter, useClass: OccCheckoutPaymentAdapter },
  ],
})
export class CjCheckoutOccModule {}
