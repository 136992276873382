import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { CjHtmlSanitizerPipeModule } from 'src/app/shared/pipes/html-sanitizer/html-sanitizer-pipe.module';
import { CjRectangularBannerComponent } from './rectangular-banner.component';

@NgModule({
  imports: [CommonModule, CjHtmlSanitizerPipeModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsborneRectangularBannerComponent: {
          component: () => import('./rectangular-banner.component').then((m) => m.CjRectangularBannerComponent),
        },
      },
    }),
  ],
  declarations: [CjRectangularBannerComponent],
  exports: [CjRectangularBannerComponent],
})
export class CjRectangularBannerModule {}
