import { NgModule } from '@angular/core';
import { UserAddressService } from '@spartacus/core';
import { CjOccUserAddressAdapter } from 'src/app/core/occ/adapters/user/occ-user-address.adapter';
import { CjLoginModule } from './account/login/login.module';
import { CjUserAccountModule } from './account/user-account.module';
import { CjUserAddressAdapter } from './connectors/address/user-address.adapter';
import { CjUserAddressService } from './facade/user-address.service';
import { CjUserProfileComponentsModule } from './profile/user-profile-components.module';
import { CjUserProfileModule } from './profile/user-profile.module';
import { CjUserStoreModule } from './store/user-store.module';

@NgModule({
  imports: [CjLoginModule, CjUserProfileComponentsModule, CjUserAccountModule, CjUserProfileModule, CjUserStoreModule],
  providers: [
    { provide: CjUserAddressAdapter, useClass: CjOccUserAddressAdapter },
    { provide: UserAddressService, useClass: CjUserAddressService },
  ],
})
export class CjUserModule {}
