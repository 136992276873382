import { RoutingConfig } from '@spartacus/core';

export const cjRoutesConfig: RoutingConfig = {
  routing: {
    routes: {
      shop: { paths: ['category/todos'] },
      addressBook: { paths: ['/my-account/address-book'] },
      product: {
        paths: ['product/:productCode/:slug', 'product/:productCode/:name', 'product/:productCode'],
        paramsMapping: { name: 'slug' },
      },
      orderConfirmation: { paths: ['order-confirmation/:orderCode'], paramsMapping: { orderCode: 'code' } },
    },
  },
};
