import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomPreferenceCenterAdapter } from './custom-preference-center.adapter';
import { EmarsysContactInfo } from '../../model/preference-center.model';

@Injectable()
export class CustomPreferenceCenterConnector {

  constructor(protected adapter: CustomPreferenceCenterAdapter) {}

  loadPreferenceCenterDetails(
    uid: string,
    storeId: string,
  ): Observable<EmarsysContactInfo> {
    return this.adapter.loadPreferenceCenterDetails(uid, storeId);
  }

  updatePreferenceCenterDetails(
    uid: string,
    cid: string,
    llid: string,
    details: EmarsysContactInfo,
  ): Observable<EmarsysContactInfo> {
    return this.adapter.updatePreferenceCenterDetails(uid, cid, llid, details);
  }
}
