/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthGuard, CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { CardModule, SpinnerModule } from '@spartacus/storefront';
import { AddressBookComponentService } from '@spartacus/user/profile/components';
import { CjUserAddressService } from '../../user/facade/user-address.service';
import { CjAddressBookComponent } from './address-book.component';
import { CjAddressBookComponentService } from './address-book.component.service';
import { CjAddressFormModule } from './address-form/address-form.module';

@NgModule({
  imports: [CommonModule, CardModule, CjAddressFormModule, SpinnerModule, I18nModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        AccountAddressBookComponent: {
          component: () => import('./address-book.component').then((m) => m.CjAddressBookComponent),
          guards: [AuthGuard],
        },
      },
    }),
    CjUserAddressService,
    {
      provide: AddressBookComponentService,
      useExisting: CjAddressBookComponentService,
    },
  ],
  declarations: [CjAddressBookComponent],
  exports: [CjAddressBookComponent],
})
export class CjAddressBookModule {}
