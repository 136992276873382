import { Injectable } from '@angular/core';
import { Price } from '@spartacus/core';
import { CjGiftCard } from 'src/app/cms-components/checkout/checkout-gift-card/model/gift-card.model';
import { CjOrderEntry } from 'src/app/cms-components/order/order.model';
import { CjProduct } from 'src/app/shared/models/product.model';
import { ProductDetailItem } from './product/product-events.model';
import { ProductItem } from './tag-manager-feature.model';
import { Unit } from 'src/app/shared/models/unit.model';

@Injectable({
  providedIn: 'root',
})
export class TagManagerFeatureService {
  getOrderEntries(entries: Array<CjOrderEntry>, giftCardProduct: CjGiftCard): ProductItem[] {
    const entryList: any[] = entries.map((entry: CjOrderEntry) => {
      const gtmProduct = this.getProductItem(entry.product!, entry.basePrice, entry.unit!);
      return {
        item_name: gtmProduct.name,
        item_id: gtmProduct.id,
        item_brand: gtmProduct.brand,
        price: gtmProduct.price,
        item_category: gtmProduct.category,
        item_variant: gtmProduct.variant,
        quantity: entry.quantity,
      };
    });

    if (giftCardProduct?.code) {
      entryList.push({
        item_name: giftCardProduct.name,
        item_id: giftCardProduct.code,
        price: giftCardProduct.price?.value?.toString(),
        item_brand: '',
        item_category: 'Gift Card',
        item_variant: giftCardProduct.giftCardMessage,
        quantity: 1,
      });
    }
    return entryList;
  }

  getProductItem(product: CjProduct, basePrice: Price | undefined, unit: Unit): ProductDetailItem {
    return {
      name: product.name!,
      id: product.code!,
      price: basePrice?.value?.toString()!,
      brand: product?.brands ? this.getEntryBrands(product.brands) : '',
      category: product?.categories ? this.getEntryCategories(product.categories) : '',
      variant: '',
      unitCode: unit?.code ? unit.code : '',
    };
  }

  cartActions(entry: any, modifiedQuantity: any): ProductItem {
    return {
      item_name: entry.product.name,
      item_id: entry.product.code,
      item_brand: entry.product.brands ? entry.product.brands[0].name : '',
      price: entry.basePrice.value.toString(),
      item_category: entry.product.categories[0]?.name || '',
      item_category2: entry.product.categories[1]?.name || '',
      item_category3: entry.product.categories[2]?.name || '',
      item_variant: entry.unit?.name ? entry.unit.name : '',
      quantity: modifiedQuantity,
    };
  }

  getEntryCategories(categories: any[]): string {
    const categoryList = categories.filter((category) => category.code.includes('nav_'));
    return categoryList.map((category) => category.name).join(',');
  }

  getEntryBrands(brands: any[]): string {
    return brands.map((brand) => brand.name).join(',');
  }
}
