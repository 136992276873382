import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GlobalMessageActions, GlobalMessageType, normalizeHttpError, SiteContextActions } from '@spartacus/core';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ContactFormConnector } from '../../connectors/contact-form.connector';
import { ContactFormActions } from '../actions';
import { CsTicketCauseList } from '../../contact-form.model';
import { LoggerService } from '@spartacus/core';

@Injectable()
export class ContactFormEffect {
  protected logger = inject(LoggerService);

  constructor(
    private actions$: Actions,
    private contactFormConnector: ContactFormConnector,
  ) {}

  loadContactForm$: Observable<ContactFormActions.ContactFormAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactFormActions.LOAD_CONTACT_FORM),
      map((action: any) => action.payload),
      switchMap((payload) => {
        return this.contactFormConnector.loadCsTicketCauses(payload).pipe(
          map((csTicketCauses: CsTicketCauseList) => {
            return new ContactFormActions.LoadContactFormSuccess(csTicketCauses);
          }),
          catchError((error) => of(new ContactFormActions.LoadContactFormFail(normalizeHttpError(error, this.logger)))),
        );
      }),
    ),
  );

  resetContactForm$: Observable<ContactFormActions.ClearContactForm> = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteContextActions.LANGUAGE_CHANGE),
      map(() => {
        return new ContactFormActions.ClearContactForm();
      }),
    ),
  );

  sendContactForm$: Observable<
    ContactFormActions.SendContactFormSuccess | GlobalMessageActions.AddMessage | ContactFormActions.SendContactFormFail
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactFormActions.SEND_CONTACT_FORM),
      map((action: any) => action.payload),
      mergeMap((payload) => {
        return this.contactFormConnector.sendContactForm(payload.form, payload.templateConfiguration).pipe(
          switchMap((data) => [
            new ContactFormActions.SendContactFormSuccess(data),
            new GlobalMessageActions.AddMessage({
              text: { key: 'contactForm.updateSuccess' },
              type: GlobalMessageType.MSG_TYPE_CONFIRMATION,
              timeout: 7000,
            }),
          ]),
          catchError((error) => {
            const actions: Array<ContactFormActions.SendContactFormFail | GlobalMessageActions.AddMessage> = [
              new ContactFormActions.SendContactFormFail(normalizeHttpError(error, this.logger)),
            ];
            if (this.hasModelNotFoundError(error)) {
              actions.push(
                new GlobalMessageActions.AddMessage({
                  text: { key: 'contactForm.modelNotFoundError' },
                  type: GlobalMessageType.MSG_TYPE_ERROR,
                }),
              );
            } else if (!this.hasErrors) {
              actions.push(
                new GlobalMessageActions.AddMessage({
                  text: { key: 'contactForm.updateError' },
                  type: GlobalMessageType.MSG_TYPE_ERROR,
                }),
              );
            }
            return from(actions);
          }),
        );
      }),
    ),
  );

  private hasModelNotFoundError(errorData: any): string {
    return errorData?.error?.errors?.some((errorResponse: any) => errorResponse.type === 'ModelNotFoundError');
  }

  private hasErrors(errorData: any): boolean {
    return Boolean(errorData?.error?.errors?.length);
  }
}
