import { NgModule } from '@angular/core';
import { CjAnimationSliderModule } from './animation-slider/animation-slider.module';
import { CjBannerModule } from './banner/banner.module';
import { CjCategoryBannerModule } from './category-banner/category-banner.module';
import { CjCategoryHeaderBannerModule } from './category-header-banner/category-header-banner.module';
import { CjDeliveryBannerModule } from './delivery-banner/delivery-banner.module';
import { CjIconsModule } from './icons/icons.module';
import { CjInstagramSliderModule } from './instagram-slider/instagram-slider.module';
import { CjLinkModule } from './link/link.module';
import { CjSiteContextSelectorModule } from './misc/site-context-selector/site-context-selector.module';
import { CjNewsletterModule } from './newsletter/newsletter.module';
import { CjProductBannerModule } from './product-banner/product-banner.module';
import { CjProductDescriptionBannerModule } from './product-description-banner/product-description-banner.module';
import { CjProductSliderModule } from './product-slider/product-slider.module';
import { CjProductTwoColumnsModule } from './product-two-columns/product-two-columns.module';
import { CjRectangularBannerModule } from './rectangular-banner/rectangular-banner.module';
import { CjWarrantyBannerModule } from './warranty-banner/warranty-banner.module';

@NgModule({
  imports: [
    CjLinkModule,
    CjDeliveryBannerModule,
    CjSiteContextSelectorModule,
    CjCategoryHeaderBannerModule,
    CjBannerModule,
    CjProductDescriptionBannerModule,
    CjProductSliderModule,
    CjProductBannerModule,
    CjInstagramSliderModule,
    CjAnimationSliderModule,
    CjNewsletterModule,
    CjWarrantyBannerModule,
    CjProductTwoColumnsModule,
    CjIconsModule,
    CjCategoryBannerModule,
    CjRectangularBannerModule,
  ],
})
export class CjContentModule {}
