import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule, provideConfig } from '@spartacus/core';
import { IconModule, MediaModule, PageComponentModule } from '@spartacus/storefront';
import { CjProductListBannerComponent } from './product-list-banner.component';
import { CjProductVerticalBannerComponent } from './product-vertical-banner/product-vertical-banner.component';
import { cjVerticalBannerSelectorConfig } from './product-vertical-banner/product-vertical-dialog/product-vertical-dialog.config';
import { CjProductVerticalDialogComponent } from './product-vertical-banner/product-vertical-dialog/product-vertical-dialog.component';
import { CjHtmlSanitizerPipeModule } from 'src/app/shared/pipes/html-sanitizer/html-sanitizer-pipe.module';

@NgModule({
  imports: [CommonModule, PageComponentModule, MediaModule, IconModule, I18nModule, CjHtmlSanitizerPipeModule],
  providers: [
    provideConfig(cjVerticalBannerSelectorConfig),
    provideConfig({
      cmsComponents: {
        OsborneProductGridBannerComponent: {
          component: () => import('./product-list-banner.component').then((m) => m.CjProductListBannerComponent),
        },
        OsborneVerticalBannerComponent: {
          component: () =>
            import('./product-vertical-banner/product-vertical-banner.component').then((m) => m.CjProductVerticalBannerComponent),
        },
      },
    }),
  ],
  declarations: [CjProductListBannerComponent, CjProductVerticalBannerComponent, CjProductVerticalDialogComponent],
  exports: [CjProductListBannerComponent, CjProductVerticalBannerComponent, CjProductVerticalDialogComponent],
})
export class CjProductListBannerModule {}
