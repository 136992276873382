import { ProductItem } from '../../tag-manager-feature.model';

export function getProductItems(entries: any[], giftCardProduct?: any): ProductItem[] {
  const entryList: ProductItem[] =
    entries?.map((entry: any): ProductItem => {
      return {
        item_name: entry.product.name || entry.product.code,
        item_id: entry.product.code,
        price: entry.basePrice.value.toString(),
        item_brand: entry.product.brands ? entry.product.brands[0].name : '',
        item_category: entry.product.categories ? entry.product.categories[0]?.name || '' : '',
        item_category2: entry.product.categories ? entry.product.categories[1]?.name || '' : '',
        item_category3: entry.product.categories ? entry.product.categories[2]?.name || '' : '',
        item_variant: entry.unit?.name ? entry.unit.name : '',
        quantity: entry.quantity,
      };
    }) || [];

  if (giftCardProduct?.code) {
    entryList.push({
      item_name: giftCardProduct.name,
      item_id: giftCardProduct.code,
      price: giftCardProduct.price.value.toString(),
      item_brand: '',
      item_category: 'Gift Card',
      item_variant: '',
      quantity: 1,
    });
  }

  return entryList;
}
