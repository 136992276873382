import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService, RoutingService } from '@spartacus/core';
import { OrderGuestRegisterFormComponent } from '@spartacus/order/components';
import { CustomFormValidators, ICON_TYPE, LaunchDialogService } from '@spartacus/storefront';
import { UserRegisterFacade } from '@spartacus/user/profile/root';
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'cj-guest-register-form-dialog',
  templateUrl: './order-guest-register-form-dialog.component.html',
})
export class CjOrderGuestRegisterFormDialogComponent extends OrderGuestRegisterFormComponent {
  iconTypes = ICON_TYPE;
  protected data$?: Observable<{ guid: string; email: string }>;
  override guestRegisterForm: FormGroup = this.fb.group({
    password: ['', [Validators.required, CustomFormValidators.passwordValidator]],
    newsletter: [false],
  });

  constructor(
    protected override userRegisterFacade: UserRegisterFacade,
    protected override routingService: RoutingService,
    protected override authService: AuthService,
    protected override fb: FormBuilder,
    private readonly dialogService: LaunchDialogService,
  ) {
    super(userRegisterFacade, routingService, authService, fb);
    this.data$ = this.dialogService.data$.pipe(
      tap((data: { guid: string; email: string }) => {
        this.email = data.email;
        this.guid = data.guid;
      }),
    );
  }

  override submit() {
    if (this.guestRegisterForm.valid) {
      this.userRegisterFacade.registerGuest(this.guid, this.guestRegisterForm.value.password);
      if (!this.subscription) {
        this.subscription = this.authService.isUserLoggedIn().subscribe((isLoggedIn) => {
          if (isLoggedIn) {
            this.routingService.go({ cxRoute: 'home' });
          }
        });
        this.dismissModal('registered');
      }
    } else {
      this.guestRegisterForm.markAllAsTouched();
    }
  }

  dismissModal(reason?: string) {
    this.dialogService.closeDialog(reason);
  }
}
