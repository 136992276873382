import { Injectable } from '@angular/core';
import { CartValidationConnector } from '@spartacus/cart/base/core';
import { Cart } from '@spartacus/cart/base/root';
import { Observable } from 'rxjs';
import { CjCartValidationAdapter } from './cart-validation.adapter';

@Injectable({
  providedIn: 'root',
})
export class CjCartValidationConnector extends CartValidationConnector {
  constructor(protected override adapter: CjCartValidationAdapter) {
    super(adapter);
  }

  simulate(cartId: string, userId: string): Observable<Cart> {
    return this.adapter.simulate(cartId, userId);
  }
}
