import { NgModule } from '@angular/core';
import { CjAtcModule } from './atc/atc.module';
import { CjCartBaseModule } from './cart/cart-base.module';
import { CjCheckoutComponentsModule } from './checkout/checkout-components.module';
import { CjBannerModule } from './content/banner/banner.module';
import { CjContentModule } from './content/content.module';
import { CjExperiencesModule } from './experiences/experiences.module';
import { CjLayoutModule } from './layout/layout.module';
import { CjMiniCartModule } from './mini-cart/mini-cart.module';
import { CjAddressBookModule } from './myaccount/address-book/address-book.module';
import { CjLogoutModule } from './myaccount/logout/logout.module';
import { CjNavigationModule } from './navigation/navigation.module';
import { CjNotFoundPageMarkerModule } from './not-found/not-found-page-marker.module';
import { CjOrderModule } from './order/order.module';
import { CjProductCardModule } from './product/card/product-card.module';
import { CjProductModule } from './product/product.module';
import { CjUserModule } from './user/user.module';

@NgModule({
  declarations: [],
  imports: [
    CjContentModule,
    CjNavigationModule,
    CjMiniCartModule,
    CjCartBaseModule,
    CjProductModule,
    CjBannerModule,
    CjProductCardModule,
    CjUserModule,
    CjAddressBookModule,
    CjOrderModule,
    CjAtcModule,
    CjCheckoutComponentsModule,
    CjLogoutModule,
    CjLayoutModule,
    CjExperiencesModule,
    CjNotFoundPageMarkerModule,
  ],
})
export class CjCustomModule {}
