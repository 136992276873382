import { NgModule } from '@angular/core';
import { CartNotEmptyGuard, CheckoutAuthGuard, CheckoutStepsSetGuard } from '@spartacus/checkout/base/components';
import { AuthGuard } from '@spartacus/core';
import { CjAuthGuard } from './auth.guard';
import { CjCartNotEmptyGuard } from './cart-not-empty.guard';
import { CjCheckoutAuthGuard } from './checkout-auth.guard';
import { CjCheckoutStepsSetGuard } from './checkout-steps-set.guard';

@NgModule({
  providers: [
    { provide: AuthGuard, useClass: CjAuthGuard },
    { provide: CartNotEmptyGuard, useClass: CjCartNotEmptyGuard },
    { provide: CheckoutAuthGuard, useClass: CjCheckoutAuthGuard },
    { provide: CheckoutStepsSetGuard, useClass: CjCheckoutStepsSetGuard },
  ],
})
export class CjCheckoutGuardsModule {}
