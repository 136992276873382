import { CjProductGridElement } from '../../product-list/product-list.model';

export const PRODUCT_GRID_FEATURE = 'productGrid';

export interface StateWithProductGrid {
  [PRODUCT_GRID_FEATURE]: CjProductGridState;
}

export interface CjProductGridState {
  [category: string]: CjProductGridCategoryState;
}

export interface CjProductGridCategoryState {
  filteredProductsList?: string[];
  oneColumnBannerList?: string;
  threeColumnBannerList?: string;
  elements?: CjProductGridElements;
}

export interface CjProductGridElements {
  lastPage?: number;
  nextRow: number;
  nextOneColumnBanner: number;
  nextThreeColumnBanner: number;
  lastIteration: number;
  extraRowProducts: number;
  entities: CjProductGridElement[];
}

export interface CjProductGridBasePayload {
  category: string;
}

export interface CjProductGridFilteredProductPayload extends CjProductGridBasePayload {
  product: string;
}

export interface CjProductGridBannerPayload extends CjProductGridBasePayload {
  banners: string;
}

export interface CjProductGridElementsPayload extends CjProductGridBasePayload {
  extraData: CjProductGridElementsExtraData;
  elements: CjProductGridElement[];
}

export interface CjProductGridElementsExtraData {
  lastPage: number;
  nextRow: number;
  nextOneColumnBanner: number;
  nextThreeColumnBanner: number;
  lastIteration: number;
  extraRowProducts: number;
}
