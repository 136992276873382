/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const payment = {
  paymentOptions: {
    title: 'Opciones de pago',
    paymentText: 'Paga en 3 plazos, sin intereses. <br/> {{ total }} 0% TAE.',
    subTitle: 'Métodos de pago',
  },
};
