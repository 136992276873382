<div class="cx-modal-container cj-delivery-banner" (esc)="dismissModal('Escape pressed')">
  <div class="cx-modal-content" *ngIf="data$ | async as data">
    <!-- Modal Header -->
    <div class="cx-dialog-header modal-header">
      <div class="cx-dialog-title modal-title">
        <cx-media *ngIf="data.iconWarranty" [container]="data.iconWarranty"></cx-media>
        {{ data.title }}
      </div>
      <button
        type="button"
        class="close"
        attr.aria-label="{{ 'addToCart.closeModal' | cxTranslate }}"
        (click)="dismissModal('Cross click')"
      >
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </button>
    </div>

    <!-- Modal Body -->
    <div class="cx-dialog-body modal-body">
      <div class="cj-delivery-banner__item" *ngFor="let item$ of items$ | async">
        <div class="cj-delivery-banner__content" *ngIf="item$ | async as item">
          <div class="cj-delivery-banner__icon">
            <cx-media *ngIf="item.icon" [container]="item.icon"></cx-media>
          </div>
          <div class="cj-delivery-banner__info">
            <p class="cj-delivery-banner__title" *ngIf="item.title">
              {{ item.title }}
            </p>
            <p class="cj-delivery-banner__text" *ngIf="item.text">
              {{ item.text }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
