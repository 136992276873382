<div class="cj-promotions" *ngIf="promotions">
  <ng-container *ngIf="promotions?.length === 1">
    <p class="cj-promotions__text">{{ promotions[0].description }}</p>
  </ng-container>
  <ng-container *ngIf="promotions.length > 1">
    <ul class="cj-promotions__list">
      <li class="cj-promotions__item" *ngFor="let promotion of promotions" [ngClass]="{'d-none': shouldHideDescription(promotion.description ?? '')}">
        {{ promotion.description }}
      </li>
    </ul>
  </ng-container>
</div>
