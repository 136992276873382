import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { StateConfig, StateTransferType, provideConfigFactory } from '@spartacus/core';
import { PRODUCT_GRID_FEATURE } from './product-grid-state';
import { reducerProvider, reducerToken } from './reducers/index';

export function productStoreConfigFactory(): StateConfig {
  // if we want to reuse PRODUCT_GRID_FEATURE const in config, we have to use factory instead of plain object
  const config: StateConfig = {
    state: {
      ssrTransfer: {
        keys: { [PRODUCT_GRID_FEATURE]: StateTransferType.TRANSFER_STATE },
      },
    },
  };
  return config;
}

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(PRODUCT_GRID_FEATURE, reducerToken)],
  providers: [provideConfigFactory(productStoreConfigFactory), reducerProvider],
})
export class CjProductStoreModule {}
