import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CartNotEmptyGuard, CheckoutGuard } from '@spartacus/checkout/base/components';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { CjRedsysGuard } from '../checkout-place-order/redsys/redsys.guard';
@NgModule({
  imports: [CommonModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutOrchestrator: {
          component: () => import('@spartacus/checkout/base/components').then((m) => m.CheckoutOrchestratorComponent),
          guards: [CjRedsysGuard, CartNotEmptyGuard, CheckoutGuard],
        },
      },
    }),
  ],
})
export class CjCheckoutOrchestratorModule {}
