import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { CartConfigService } from '@spartacus/cart/base/core';
import { SemanticPathService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CjCartValidationService } from 'src/app/core/cart/facade/cart-validation.service';

@Injectable({
  providedIn: 'root',
})
export class CartSimulationGuard {
  constructor(
    private readonly cartValidationService: CjCartValidationService,
    private readonly semanticPathService: SemanticPathService,
    private readonly router: Router,
    private readonly cartConfigService: CartConfigService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return !this.cartConfigService.isCartValidationEnabled()
      ? of(true)
      : this.cartValidationService.simulateCart().pipe(
          map(() => true),
          catchError(() => of(this.router.parseUrl(this.semanticPathService.get('cart') ?? ''))),
        );
  }
}
