import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { CjAnimationSliderDialogComponent } from './animation-slider-dialog.component';

export const ANIMATION_SLIDER_DIALOG = 'ANIMATION_SLIDER_DIALOG';

export const cjAnimationSliderDialogLayoutConfig: LayoutConfig = {
  launch: {
    ANIMATION_SLIDER_DIALOG: {
      inlineRoot: true,
      component: CjAnimationSliderDialogComponent,
      dialogType: DIALOG_TYPE.POPOVER_CENTER_BACKDROP,
    },
  },
};
