import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { CjProductAttributesDialogComponent } from './product-attributes-dialog.component';

export const PRODUCT_ATTRIBUTES_DIALOG = 'PRODUCT_ATTRIBUTES_DIALOG';

export const cjProductAttributesLayoutConfig: LayoutConfig = {
  launch: {
    PRODUCT_ATTRIBUTES_DIALOG: {
      inlineRoot: true,
      component: CjProductAttributesDialogComponent,
      dialogType: DIALOG_TYPE.POPOVER_CENTER,
    },
  },
};
