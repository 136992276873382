/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { CheckoutStepsSetGuard } from '@spartacus/checkout/base/components';
import { CheckoutStep, CheckoutStepType } from '@spartacus/checkout/base/root';
import { filter, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CjCheckoutStepsSetGuard extends CheckoutStepsSetGuard {
  protected override isStepSet(step: CheckoutStep): Observable<boolean | UrlTree> {
    if (step && !step.disabled) {
      switch (step.type[0]) {
        case CheckoutStepType.DELIVERY_ADDRESS: {
          return this.isDeliveryAddress(step);
        }
        case CheckoutStepType.DELIVERY_MODE: {
          return this.isDeliveryModeSet(step);
        }
        case CheckoutStepType.PAYMENT_DETAILS: {
          if (this.checkoutStepService.getCheckoutStep(CheckoutStepType.DELIVERY_MODE)?.disabled) {
            this.checkoutDeliveryModesFacade.setDeliveryMode('pickup');
          }
          return this.isPaymentDetailsSet(step);
        }
        case CheckoutStepType.REVIEW_ORDER: {
          break;
        }
      }
    }
    console.log('CheckoutStepsSetGuard true');
    return of(true);
  }

  protected override isDeliveryAddress(step: CheckoutStep): Observable<boolean | UrlTree> {
    return this.checkoutDeliveryAddressFacade.getDeliveryAddressState().pipe(
      filter((state) => !state.loading),
      map((state) => state.data),
      map((deliveryAddress) => {
        console.log(
          'CheckoutStepsSetGuard | this.isDeliveryAddress(step), else redirect to deliveryAddress step | [!!deliveryAddress.length]',
          deliveryAddress && Object.keys(deliveryAddress).length,
        );

        if (deliveryAddress && Object.keys(deliveryAddress).length) {
          return true;
        } else {
          return this.getUrl(step.routeName);
        }
      }),
    );
  }

  protected override isDeliveryModeSet(step: CheckoutStep): Observable<boolean | UrlTree> {
    return this.checkoutDeliveryModesFacade.getSelectedDeliveryModeState().pipe(
      filter((state) => !state.loading),
      map((state) => state.data),
      map((mode) => {
        console.log('CheckoutStepsSetGuard | this.isDeliveryModeSet(step), else redirect to deliveryMode step | [mode]', mode);
        return mode ? true : this.getUrl(step.routeName);
      }),
    );
  }

  protected override isPaymentDetailsSet(step: CheckoutStep): Observable<boolean | UrlTree> {
    return this.checkoutPaymentFacade.getPaymentDetailsState().pipe(
      filter((state) => !state.loading),
      map((state) => state.data),
      map((paymentDetails) => {
        console.log('CheckoutStepsSetGuard | this.isPaymentDetailsSet(step), else redirect to paymentDetails step | [paymentDetails.length !== 0]', paymentDetails && Object.keys(paymentDetails).length !== 0);
        return paymentDetails && Object.keys(paymentDetails).length !== 0 ? true : this.getUrl(step.routeName);
      }),
    );
  }
}
