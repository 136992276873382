import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { CartNotEmptyGuard } from '@spartacus/checkout/base/components';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CjCartNotEmptyGuard extends CartNotEmptyGuard {
  override canActivate(): Observable<boolean | UrlTree> {
    return this.activeCartFacade.takeActive().pipe(
      map((cart) => {
        const empty = cart && !cart.totalItems;
        console.log('CartNotEmptyGuard | If empty (cart && !cart.totalItems) go /home | [cart, isEmpty]', [cart, empty]);
        if (empty) {
          return this.router.parseUrl(this.semanticPathService.get('home') ?? '');
        }
        return true;
      }),
    );
  }
}
