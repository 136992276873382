import { NgModule } from '@angular/core';
import { provideConfig } from '@spartacus/core';
import { BaseTmsModule, TmsConfig } from '@spartacus/tracking/tms/core';
import { GtmModule } from '@spartacus/tracking/tms/gtm';
import { environment } from 'src/environments/environment';
import { CartEventModule } from './events/cart/cart-event.module';
import {
  CartAddEntrySuccessEvent,
  CartRemoveEntrySuccessEvent,
  CartUpdateEntrySuccessEvent,
  NavigateToCartEvent,
} from './events/cart/cart.events';
import { CategoryPageEventModule } from './events/category/category-page-event.module';
import { CategoryPageEvent } from './events/category/category-page.events';
import { CheckoutStepsEventModule } from './events/checkout/checkout-steps/checkout-steps-event.module';
import { CheckoutDeliveryModeEvent, CheckoutPaymentModeCompleteEvent } from './events/checkout/checkout-steps/checkout-steps.events';
import { CheckoutNavigationEventModule } from './events/checkout/navigation/checkout-navigation-event.module';
import { NavigateToCheckoutEvent } from './events/checkout/navigation/checkout-navigation.events';
import { CheckoutEventModule } from './events/checkout/place-order/checkout-event.module';
import { OrderPlacedEvent } from './events/checkout/place-order/checkout.events';
import { ContactEventModule } from './events/contact/contact-event.module';
import { SendContactFromSuccessEvent } from './events/contact/contact.events';
import { UpdateNewsletterEvent } from './events/newsletter/newsletter.events';
import { ReorderEvent } from './events/order/order.events';
import { ProductPageEventModule } from './events/product/product-page-event.module';
import { ProductDetailsPageEvent, ProductDetailsSelectEvent } from './events/product/product-page.events';
import { UserEventModule } from './events/user/user-event.module';
import { UserAddProductInterestEvent, UserLoginEvent, UserRegisterEvent } from './events/user/user.events';

@NgModule({
  imports: [
    BaseTmsModule.forRoot(),
    GtmModule,
    ProductPageEventModule,
    CartEventModule,
    CategoryPageEventModule,
    UserEventModule,
    CheckoutNavigationEventModule,
    CheckoutStepsEventModule,
    ContactEventModule,
    CheckoutEventModule,
  ],
  providers: [
    provideConfig({
      tagManager: {
        gtm: {
          gtmId: environment?.gtmId,
          events: [
            /*Pages Events*/
            CategoryPageEvent,
            /*Page Event*/
            ProductDetailsPageEvent,
            ProductDetailsSelectEvent,
            /*Cart Events*/
            CartAddEntrySuccessEvent,
            CartRemoveEntrySuccessEvent,
            NavigateToCartEvent,
            CartUpdateEntrySuccessEvent,
            /* Checkout Steps Events */
            NavigateToCheckoutEvent,
            /* Checkout Events */
            CheckoutDeliveryModeEvent,
            CheckoutPaymentModeCompleteEvent,
            OrderPlacedEvent,
            /* User Events */
            UserLoginEvent,
            UserRegisterEvent,
            UserAddProductInterestEvent,
            /* Newsletter Events */
            UpdateNewsletterEvent,
            /* Contact Events */
            SendContactFromSuccessEvent,
            /* Reorder Events */
            ReorderEvent,
          ],
          debug: !environment.production,
        },
      },
    } as TmsConfig),
  ],
})
export class TagManagerFeatureModule {}
