import { Injectable } from '@angular/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { CheckoutPaymentTypeConnector, CheckoutPaymentTypeService } from '@spartacus/checkout/b2b/core';
import { CheckoutPaymentTypeFacade } from '@spartacus/checkout/b2b/root';
import { CheckoutPaymentFacade, CheckoutQueryFacade } from '@spartacus/checkout/base/root';
import { CommandService, EventService, QueryService, UserIdService, WindowRef } from '@spartacus/core';
import { BehaviorSubject } from 'rxjs';
import { SAVE_PAYMENT_CARD_STORAGE_KEY } from '../model/payment-types.model';
import { CjCheckoutPaymentTypeFacade } from './checkout-payment-type.facade';

@Injectable({
  providedIn: 'root',
})
export class CjCheckoutPaymentTypeService extends CheckoutPaymentTypeService implements CjCheckoutPaymentTypeFacade {
  private savePaymentCard$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    protected override activeCartFacade: ActiveCartFacade,
    protected override userIdService: UserIdService,
    protected override queryService: QueryService,
    protected override commandService: CommandService,
    protected override paymentTypeConnector: CheckoutPaymentTypeConnector,
    protected override eventService: EventService,
    protected override checkoutQueryFacade: CheckoutQueryFacade,
    protected checkoutPaymentFacade: CheckoutPaymentFacade,
    protected checkoutPaymentTypeFacade: CheckoutPaymentTypeFacade,
    protected winRef: WindowRef,
  ) {
    super(activeCartFacade, userIdService, queryService, commandService, paymentTypeConnector, eventService, checkoutQueryFacade);

    if (this.winRef.isBrowser()) {
      this.savePaymentCard$.next(this.winRef.localStorage?.getItem(SAVE_PAYMENT_CARD_STORAGE_KEY) === 'true');
    }
  }

  getSavePaymentCard() {
    return this.savePaymentCard$;
  }

  setSavePaymentCard(save: boolean) {
    this.savePaymentCard$.next(save);
    if (this.winRef.isBrowser()) {
      this.winRef.localStorage?.setItem(SAVE_PAYMENT_CARD_STORAGE_KEY, save.toString());
    }
  }
}
