import { OccConfig, OccEndpoints } from '@spartacus/core';

const cartFields =
  'DEFAULT,potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),totalUnitCount,deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user,saveTime,name,description,deliveryAddress(FULL),paymentAddress(FULL),paymentMode(FULL)';

const baseStoreFields = 'baseStores(code,name,deliveryCountries(isocode,name),languages(FULL),selectorLanguages(FULL),flag(FULL),defaultLanguage(FULL))';

export const cjOccConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        product: {
          default:
            'products/${productCode}?fields=DEFAULT,shortDescription,averageRating,images(FULL),classifications,manufacturer,numberOfReviews,categories(FULL),baseOptions,baseProduct,variantOptions,variantType,discountPrice(DEFAULT),discountTag,webAvailability,metaDescription5j,metaTitle5j,description5j,secondaryDescription5j',
          list: 'products/${productCode}?fields=code,name,summary,price(formattedValue),images(DEFAULT,galleryIndex),baseProduct,itemtype,stock(DEFAULT),unitPrices(FULL),discountPrice(DEFAULT),discountTag,webAvailability,metaDescription5j,metaTitle5j,description5j,secondaryDescription5j',
        },
        addresses: 'users/${userId}/addresses?fields=FULL',
        addressesBasestore: 'users/${userId}/basestore/addresses?fields=FULL',
        addressDetail: '/users/${userId}/addresses/${addressId}',
        addressDetailBasestore: '/users/${userId}/basestore/addresses/${addressId}',
        user: 'users/${userId}/custom',
        productSearch:
          'products/search?fields=products(url,code,name,summary,configurable,configuratorType,multidimensional,price(FULL),discountPrice(DEFAULT),discountTag,images(DEFAULT),stock(FULL),averageRating,variantOptions,webAvailability),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery',
        carts: 'users/${userId}/carts?fields=carts(' + cartFields + ')',
        cart: 'users/${userId}/carts/${cartId}?fields=' + cartFields,
        reorder: '/users/${userId}/orders/${code}/restoration?fields=' + cartFields,
        simulate: '/users/${userId}/carts/${cartId}/simulate?fields=' + cartFields,
        documentIdentifier: '/users/${userId}/carts/${cartId}/documentIdentifier',
        billingAddress: '/users/${userId}/carts/${cartId}/addresses/payment',
        giftCard: 'users/${userId}/carts/${cartId}/giftCard',
        getGiftCard: 'users/${userId}/carts/${cartId}/giftCard?fields=GIFT_CARD',
        giftCards: 'users/${userId}/carts/${cartId}/giftCards?fields=GIFT_CARD',
        csTicket: 'cstickets?fields=${templateConfiguration}',
        csTicketCauses: 'cstickets/causes?fields=DEFAULT&templateConfigurations=${templateConfigurations}',
        userBaseStores: 'users/${userId}/basestores?fields=' + baseStoreFields,
        baseStores: '/basestores?fields=' + baseStoreFields,
        paymentMethods: '/users/${userId}/carts/${cartId}/availablePaymentModes',
        paymentMode: 'users/${userId}/carts/${cartId}/paymentMode',
        redsysCards: '/users/${userId}/paymentdetails',
        redsysCard: '/users/${userId}/paymentdetails/${paymentDetailsId}',
        redsysPayment: 'users/${userId}/carts/${cartId}/payments/redsys/hop',
        paypalOrderCreate: 'users/${userId}/carts/${cartId}/paypal/order/create',
        paypalOrderCapture: 'users/${userId}/carts/${cartId}/paypal/order/capture',
        countries: '/countries',
        baseStoreCountries: '/basestore/countries',
        orderTicket: '/users/${userId}/orders/${code}/ticket',
      } as OccEndpoints,
    },
  },
};
