import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Promotion } from '@spartacus/core';
import { PromotionsModule } from '@spartacus/storefront';

@Component({
  selector: 'cj-promotions',
  templateUrl: './promotions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjPromotionsComponent extends PromotionsModule {

  @Input()
  promotions: Promotion[] = [];
 
  constructor() {
    super();
  }

  shouldHideDescription(promotionDescription: string): boolean {
    return !promotionDescription || promotionDescription.trim() === '';
  }
}
