import { Injectable } from '@angular/core';
import { Converter, Occ, Product, RoutingService, SemanticPathService } from '@spartacus/core';
@Injectable({ providedIn: 'root' })
export class CjProductUrlNormalizer implements Converter<Occ.Product, Product> {
  constructor(
    private readonly urlService: SemanticPathService,
    private readonly routingService: RoutingService,
  ) {}

  convert(source: Occ.Product, target?: Product): Product {
    target = target ?? { ...(source as unknown as Partial<Product>) };

    // Create a working product url if backend url is empty
    if (!source?.url || source?.url == '/') {
      target.url = this.routingService.getUrl(this.urlService.transform({ cxRoute: 'product', params: target }));
    }

    return target as Product;
  }
}
