import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CartValidationGuard } from '@spartacus/cart/base/core';
import { CartNotEmptyGuard, CheckoutAuthGuard } from '@spartacus/checkout/base/components';
import { CmsConfig, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { AtMessageModule } from '@spartacus/storefront';
import { CjCheckoutPlaceOrderComponent } from './checkout-place-order.component';
import { CjPaypalButtonModule } from './paypal-button/paypal-button.module';
import { CjRedsysModule } from './redsys/redsys.module';

@NgModule({
  imports: [AtMessageModule, CommonModule, RouterModule, UrlModule, I18nModule, ReactiveFormsModule, CjPaypalButtonModule, CjRedsysModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutPlaceOrder: {
          component: () => import('./checkout-place-order.component').then((m) => m.CjCheckoutPlaceOrderComponent),
          guards: [CheckoutAuthGuard, CartNotEmptyGuard, CartValidationGuard],
        },
      },
    }),
  ],
  declarations: [CjCheckoutPlaceOrderComponent],
  exports: [CjCheckoutPlaceOrderComponent],
})
export class CjCheckoutPlaceOrderModule {}
