import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CartValidationGuard } from '@spartacus/cart/base/core';
import { CartNotEmptyGuard } from '@spartacus/checkout/base/components';
import { CmsConfig, FeaturesConfigModule, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { CardModule, SpinnerModule } from '@spartacus/storefront';
import { CjAddressFormModule } from '../../myaccount/address-book/address-form/address-form.module';
import { CjCheckoutBillingAddressModule } from '../checkout-billing-address/checkout-billing-address.module';
import { CjCheckoutLoginModule } from '../checkout-login/checkout-login.module';
import { CjCheckoutDeliveryAddressComponent } from './checkout-delivery-address.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CjAddressFormModule,
    CardModule,
    SpinnerModule,
    I18nModule,
    FeaturesConfigModule,
    UrlModule,
    CjCheckoutLoginModule,
    CjCheckoutBillingAddressModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutDeliveryAddress: {
          component: () => import('./checkout-delivery-address.component').then((m) => m.CjCheckoutDeliveryAddressComponent),
          guards: [CartNotEmptyGuard, CartValidationGuard],
        },
      },
    }),
  ],
  declarations: [CjCheckoutDeliveryAddressComponent],
  exports: [CjCheckoutDeliveryAddressComponent],
})
export class CjCheckoutDeliveryAddressModule {}
