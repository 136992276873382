export const userAccount = {
  loginForm: {
    title: 'Je ai déjà un compte',
    forgotPassword: 'Mot de passe oublié?',
    signIn: 'Se connecter',
    emailAddress: {
      label: 'Adresse e-mail',
      placeholder: 'Adresse e-mail',
    },
    password: {
      label: 'Mot de passe',
      placeholder: 'Mot de passe',
    },
    wrongEmailFormat: 'Ce n`est pas un format de courrier électronique valide.',
  },
  miniLogin: {
    signInRegister: 'Se connecter / Créer un compte',
  },
};
