import { InjectionToken, Provider } from '@angular/core';
import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { CjGiftCard } from '../../model/gift-card.model';
import { CjGiftCardsActions } from '../actions';
import { GIFT_CARD_FEATURE } from '../gift-card-state';

export const initialState = undefined;
export function cjGiftCardsReducer(state: CjGiftCard[] | undefined = initialState, action: StateUtils.LoaderAction) {
  switch (action.type) {
    case CjGiftCardsActions.LOAD_GIFT_CARDS_SUCCESS:
      return action.payload.giftCards;
    case CjGiftCardsActions.LOAD_GIFT_CARDS_FAIL:
    default:
      return state;
  }
}

export const cjReducerGiftCardToken: InjectionToken<ActionReducerMap<StateUtils.EntityLoaderState<CjGiftCard[]>>> = new InjectionToken<
  ActionReducer<StateUtils.EntityLoaderState<CjGiftCard[]>>
>('CjGiftCardsReducers');

export const cjReducerGiftCardProvider: Provider = {
  provide: cjReducerGiftCardToken,
  useValue: StateUtils.entityLoaderReducer<CjGiftCard[]>(GIFT_CARD_FEATURE, cjGiftCardsReducer),
};
