import { NgModule } from '@angular/core';
import { ReorderOrderAdapter } from '@spartacus/order/core';
import { CjModifyOrderAdapter } from 'src/app/core/order/connectors/modify-order.adapter';
import { CjOccModifyOrderAdapter } from './occ-modify-order.adapter';
import { CjOccReorderOrderAdapter } from './occ-reorder-order.adapter';

@NgModule({
  providers: [
    CjOccReorderOrderAdapter,
    CjOccModifyOrderAdapter,
    { provide: ReorderOrderAdapter, useClass: CjOccReorderOrderAdapter },
    { provide: CjModifyOrderAdapter, useClass: CjOccModifyOrderAdapter },
  ],
})
export class CjOccOrderModule {}
