import { Injectable } from '@angular/core';
import { CurrencyService, Price, getLastValueSync } from '@spartacus/core';
import { CurrentProductService, SchemaBuilder } from '@spartacus/storefront';
import { Observable, map } from 'rxjs';
import { CjProduct } from 'src/app/shared/models/product.model';
import { CjStructuredDataUtilsService } from '../../structured-data-utils-service';

@Injectable({
  providedIn: 'root',
})
export class CjProductSchemaBuilder implements SchemaBuilder {
  product?: CjProduct;
  getFullProductUrl(append: string = '') {
    return this.utils.getFullUrl('', this.product?.url) + append;
  }

  constructor(
    private readonly currentProduct: CurrentProductService,
    private readonly structuredDataUtilsService: CjStructuredDataUtilsService,
    private readonly currencyService: CurrencyService,
    private readonly utils: CjStructuredDataUtilsService,
  ) {}

  build(): Observable<any> {
    // Using currentProductService ensures we are in a ProductPage
    return this.currentProduct.getProduct().pipe(
      map((p: CjProduct | null) => {
        if (p) {
          this.product = p;

          let schema: any = { '@type': 'Product' };

          if (p.url) schema['@id'] = this.getFullProductUrl;
          if (p.name) schema.name = p.name;
          if (p.images) schema.image = this.getFullProductUrl('#primaryImage');
          if (p.description5j) schema.description = p.description5j;
          if (p.code) schema.sku = p.code;
          schema.brand = { '@type': 'Brand', name: this.utils.siteName };
          if (p.discountPrice && p.webAvailability) schema.offers = this.getOffers(p.discountPrice, p.webAvailability);
          schema.mainEntityOfPage = { '@id': this.getFullProductUrl('#webpage') };

          return schema;
        }
        return {};
      }),
    );
  }

  getOffers(discountPrice: Price, webAvailability: string) {
    return [
      {
        '@type': 'Offer',
        url: this.getFullProductUrl(),
        priceCurrency: getLastValueSync(this.currencyService.getActive()),
        price: discountPrice.value,
        availability: this.structuredDataUtilsService.parseAvailability(webAvailability),
        seller: { '@id': this.utils.getFullUrl('#organization') },
      },
    ];
  }
}
