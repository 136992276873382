import { Injectable } from '@angular/core';
import { PageMetaLinkService } from '@spartacus/storefront';

@Injectable({
  providedIn: 'root',
})
export class CjPageMetaLinkService extends PageMetaLinkService {
  /**
   * Adds a hreflang link elements to the document head.
   *
   * If an id is provided, the link will be updated.
   * If no url is provided, the link element will be deleted.
   */
  setHreflangLink(lang: string, url: string | undefined): void {
    let link: HTMLLinkElement = this.winRef.document.querySelector(`link[rel="alternate"][hreflang="${lang}"]`) as HTMLLinkElement;

    if (!url) {
      // Removing the link is an edge case, but useful if the canonical url
      // is created in CSR while developing/testing.
      link?.remove();
      return;
    }

    if (!link) {
      link = this.renderer.createElement('link');
      link.rel = 'alternate';
      link.hreflang = lang;
      link.href = url;
      this.renderer.appendChild(this.winRef.document.head, link);
    } else {
      link?.setAttribute('href', url);
    }
  }
}
