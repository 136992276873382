import { Injectable } from '@angular/core';
import { LanguageService } from '@spartacus/core';
import { SchemaBuilder } from '@spartacus/storefront';
import { map, Observable } from 'rxjs';
import { CjStructuredDataUtilsService } from '../../structured-data-utils-service';

@Injectable({
  providedIn: 'root',
})
export class CjWebSiteSchemaBuilder implements SchemaBuilder {
  constructor(
    private readonly utils: CjStructuredDataUtilsService,
    private readonly languageService: LanguageService,
  ) {}

  build(): Observable<any> {
    // Common to all pages
    // this.languageService.getActive() is used to ensure urls contain the site language
    return this.languageService.getActive().pipe(
      map(() => ({
        '@type': 'WebSite',
        '@id': this.utils.getFullUrl('#website'),
        url: this.utils.getFullUrl(),
        name: this.utils.siteName,
      })),
    );
  }
}
