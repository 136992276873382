<ng-container *ngIf="cart as order">
  <div *ngIf="order.deliveryAddress as deliveryAddress" class="item">
    <h3 class="item__title">{{ 'orderDetails.orderInfo.shippingAddress' | cxTranslate }}</h3>
    <ul class="item__list">
      <li>{{ deliveryAddress.firstName }} {{ deliveryAddress.lastName }}</li>
      <li>{{ deliveryAddress.line1 }}</li>
      <li>{{ deliveryAddress.line2 }}</li>
      <li>
        {{ deliveryAddress.town }}, {{ deliveryAddress.country?.name }}
        {{ deliveryAddress.postalCode }}
      </li>
    </ul>
  </div>
  <div *ngIf="order.deliveryMode as deliveryMode" class="item">
    <h3 class="item__title">{{ 'orderDetails.orderInfo.shippingMethod' | cxTranslate }}</h3>
    <ul class="item__list">
      <li>{{ deliveryMode.name }}</li>
      <li>{{ deliveryMode.description }}</li>
    </ul>
  </div>
  <div *ngIf="order.paymentMode as paymentMode" class="item">
    <h3 class="item__title">{{ 'orderDetails.orderInfo.paymentMethod' | cxTranslate }}</h3>
    <ul class="item__list">
      <li>{{ paymentMode.name }}</li>
    </ul>
  </div>
</ng-container>
