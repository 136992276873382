import { I18nConfig } from '@spartacus/core';
import { cjModuleTranslationChunksConfig } from 'src/assets/translations/module-translation-chunks-config';
import { cjTranslations } from 'src/assets/translations/translations';

export const cjI18nConfig: I18nConfig = {
  i18n: {
    resources: cjTranslations,
    chunks: cjModuleTranslationChunksConfig,
    fallbackLang: 'en',
  },
};
