import { translationChunksConfig } from '@spartacus/assets';
import { checkoutTranslationChunksConfig } from '@spartacus/checkout/base/assets';
import { TranslationChunksConfig } from '@spartacus/core';

export const cjModuleTranslationChunksConfig: TranslationChunksConfig = {
  checkout: [...checkoutTranslationChunksConfig['checkout'], 'checkoutGift', 'checkoutPayment', 'paypal', 'redsys'],
  common: [...translationChunksConfig['common'], 'contextSelector', 'newsletter', 'contact', 'contactForm', 'preferenceCenter'],
  product: [...translationChunksConfig['product'], 'productCard', 'productDetail', 'webAvailability'],
  myAccount: [...translationChunksConfig['myAccount'], 'myAccountCommon'],
  payment: [...translationChunksConfig['payment'], 'paymentOptions'],
};
