export const common = {
  common: {
    send: 'Envoyer',
    discover: 'Découvrir',
    knowMore: 'Savoir plus',
    remove: 'Éliminer',
    delete: 'Supprimer',
    edit: 'Modifier',
    save: 'Garder',
    continue: 'Continuer',
    free: 'Gratuit',
    logout: 'Se déconnecter',
    cancel: 'Annuler',
    submit: 'Soumettre',
    back: 'Revenir',
    update: 'Mise à jour',
    home: 'Cinco Jotas',
    reserve: 'Réserve',
    daysOfWeek: {
      0: 'lundi',
      1: 'mardi',
      2: 'mercredi',
      3: 'jeudi',
      4: 'vendredi',
      5: 'samedi',
      6: 'dimanche',
    },
    daysOfWeek_plural: {
      0: 'lundis',
      1: 'mardis',
      2: 'mercredis',
      3: 'jeudis',
      4: 'vendredis',
      5: 'samedis',
      6: 'dimanches',
    },
  },
  httpHandlers: {
    badRequest: {
      bad_credentials: 'Identifiants incorrects. Veuillez vous reconnecter.',
      el_usuario_está_deshabilitado: 'Utilisateur désactivé. Veuillez vous connecter avec un autre e-mail.',
      user_is_disabled: 'Utilisateur désactivé. Veuillez vous connecter avec un autre e-mail.',
      le_compte_utilisateur_est_désactivé: 'Utilisateur désactivé. Veuillez vous connecter avec un autre e-mail.',
      usuário_desabilitado: 'Utilisateur désactivé. Veuillez vous connecter avec un autre e-mail.',
    },
    badGateway: "Une erreur s'est produite sur le serveur. Veuillez réessayer plus tard.",
    badRequestPleaseLoginAgain: 'Identifiants incorrects. Veuillez vous reconnecter.',
    badRequestOldPasswordIncorrect: 'Ancien mot de passe incorrect.',
    badRequestGuestDuplicateEmail: "L'adresse e-mail existe déjà. Veuillez vous connecter ou saisir une autre adresse e-mail.",
    conflict: 'Existe déjà.',
    forbidden:
      "Vous n'êtes pas autorisé à effectuer cette action. Veuillez contacter votre administrateur si vous pensez que cela est une erreur.",
    gatewayTimeout: 'Le serveur ne répond pas, veuillez réessayer plus tard.',
    internalServerError: "Une erreur interne du serveur s'est produite. Veuillez réessayer plus tard.",
    sessionExpired: 'Votre session a expiré. Veuillez vous reconnecter.',
    unknownError: "Une erreur inconnue s'est produite",
    unauthorized: {
      common: "Une erreur d'autorisation inconnue s'est produite",
      invalid_client: 'Identifiants incorrects',
    },
    validationErrors: {
      missing: {
        bankAccountNumber: 'Le champ IBAN est obligatoire',
        card_cardType: "La carte de crédit sélectionnée n'est pas compatible. Veuillez en sélectionner une autre.",
        card_accountNumber: "Le numéro de carte de crédit saisi n'est pas valide.",
        card_cvNumber: "Le code de sécurité saisi n'est pas valide.",
        card_expirationMonth: "La date d'expiration de la carte de crédit saisie n'est pas valide.",
        card_expirationYear: "La date d'expiration de la carte de crédit saisie n'est pas valide.",
        billTo_firstName: "Le prénom saisi n'est pas valide.",
        billTo_lastName: "Le nom saisi n'est pas valide.",
        billTo_street1: "L'adresse saisie n'est pas valide.",
        billTo_street2: "L'adresse saisie n'est pas valide.",
        billTo_city: "La ville saisie n'est pas valide pour cette carte de crédit.",
        billTo_state: "L'état/province saisi n'est pas valide pour cette carte de crédit.",
        billTo_country: "Le pays saisi n'est pas valide pour cette carte de crédit.",
        billTo_postalCode: "Le code postal saisi n'est pas valide pour cette carte de crédit.",
        country: {
          isocode: 'Pays manquant',
        },
        firstName: 'Le prénom est obligatoire',
        lastName: 'Le nom est obligatoire',
        password: 'Le mot de passe est obligatoire',
        firstNameLastName: 'Le prénom et le nom sont obligatoires',
        uid: "L'e-mail est obligatoire",
        code: 'Le code est obligatoire',
        email: "L'e-mail est obligatoire",
        iban: 'Le champ IBAN est obligatoire',
        documentIdentifier: 'Le champ NIF est obligatoire',
        postalCode: 'Le champ code postal est obligatoire',
        defaultPaymentAddress: {
          phone: 'Le champ code postal est obligatoire',
          firstName: 'Le champ prénom est obligatoire',
          lastName: 'Le champ nom est obligatoire',
          password: 'Le champ mot de passe est obligatoire',
          line1: 'Le champ adresse est obligatoire',
          town: 'Le champ ville est obligatoire',
          postalCode: 'Le champ code postal est obligatoire',
          country: {
            isocode: 'Le champ pays est obligatoire',
          },
          toCustomersUid: 'Adresse e-mail du client manquante',
        },
        newUid: "Veuillez vérifier les informations de l'utilisateur",
        message: 'Le message est obligatoire',
        subject: 'Le sujet est obligatoire',
        orderCode: 'Le numéro de commande est obligatoire',
        productsAffected: 'Le champ Produits et Quantités affectés est obligatoire',
        qualityProblem: 'Le champ Problème de qualité est obligatoire',
        requiredAction: 'Le champ Action requise est obligatoire',
      },
      invalid: {
        subject: "Le sujet saisi n'est pas valide.",
        message: "Le message saisi n'est pas valide.",
        bankAccountNumber: "Le champ IBAN n'est pas valide",
        card_expirationMonth: "La date d'expiration de la carte de crédit saisie n'est pas valide.",
        country: {
          isocode: "Le pays n'est pas valide.",
        },
        firstName: "Le prénom saisi n'est pas valide.",
        lastName: "Le nom saisi n'est pas valide.",
        firstNameLastName: 'Le prénom et le nom saisis ne sont pas valides',
        password: "Le mot de passe saisi n'est pas valide, il doit contenir au moins six caractères, une majuscule, un chiffre, un symbole",
        uid: "L'e-mail n'est pas valide.",
        code: "Le code n'est pas valide.",
        email: "L'e-mail n'est pas valide.",
        iban: "Le champ IBAN n'est pas valide",
        sessionCart: 'Les informations de paiement ne sont pas configurées.',
        documentIdentifier: "Le NIF n'est pas valide.",
        postalCode: "Le code postal n'est pas valide",
        defaultPaymentAddress: {
          phone: "Le champ code postal n'est pas valide",
          firstName: "Le champ prénom n'est pas valide",
          lastName: "Le champ nom n'est pas valide",
          password: "Le champ mot de passe n'est pas valide",
          line1: "Le champ adresse n'est pas valide",
          town: "Le champ ville n'est pas valide",
          postalCode: "Le champ code n'est pas valide",
          country: {
            isocode: "Le champ pays n'est pas valide",
          },
        },
      },
    },
    cartModification: {
      giftBoxError: {
        label: "Le nombre minimum de produits pour compléter la boîte cadeau n'a pas été atteint.",
        entries: '',
      },
      lowStock: {
        label: 'Les articles suivants dans votre panier ont été modifiés : ',
        entries: "La quantité du produit {{product}} a été réduite de {{oldQuantity}} à {{quantity}} en raison d'un stock insuffisant.",
      },
      noStock: {
        label: 'Les articles suivants dans votre panier ont été modifiés : ',
        entries: "Le produit {{product}} a été supprimé en raison d'un stock insuffisant.",
      },
      unavailable: {
        label: 'Les articles suivants qui ne sont pas disponibles pour cette destination ont été supprimés : ',
        entries: '{{product}}.',
      },
    },
    cartNotFound: 'Panier non trouvé.',
    invalidCodeProvided: 'Code non valide.',
    notActiveCodeProvided: 'Code actuellement non valide.',
    voucherExceeded: '{{voucherCode}}',
    cartSimulationError: 'Erreur lors de la simulation de la commande',
  },
  contextSelector: {
    title: 'Sélectionner le pays et la langue',
    country: 'Pays',
    language: 'Langue',
  },
  newsletter: {
    emailPlaceholder: 'Ton adresse mail',
    acceptThe: "J'accepte la ",
    termsAndConditions: 'politique de confidentialité.',
    success: 'Merci beaucoup de faire partie de Cinco Jotas',
    error: "Une erreur s'est produite lors de l'inscription à la newsletter",
  },
  formErrors: {
    cjEmailMustNotExist: 'Cet e-mail est déjà enregistré auprès de Cinco Jotas.',
    nifError: 'Le nom et le prénom ne correspondent pas à votre FNI.',
    globalMessage: 'Le formulaire que vous essayez de soumettre contient des erreurs.',
    required: 'Ce champ est obligatoire',
    cxInvalidEmail: "Le format de l'email est incorrect",
    cxInvalidPassword:
      'Le mot de passe doit comporter au minimum six caractères, une lettre majuscule, une lettre minuscule, un chiffre et un symbole.',
    cxPasswordsMustMatch: 'Les champs du mot de passe doivent correspondre',
    cxEmailsMustMatch: "Les champs de l'e-mail doivent correspondre",
    cxStarRatingEmpty: 'Le champ Classement est obligatoire',
    cxNoSelectedItemToCancel: 'Sélectionnez au moins un article',
    cxNegativeAmount: 'Le montant doit être égal ou supérieur à zéro',
    cxContainsSpecialCharacters: 'Le champ ne peut pas contenir de caractères spéciaux',
    cxIban: 'Le champ IBAN est incorrect',
    cxNif: 'Le champ Numéro de TVA est incorrect pour le pays sélectionné.',
    cxCif: 'Le champ NIF est incorrect pour le pays sélectionné. Vous ne pouvez introduire un NIF que pour une personne physique.',
    cxPostalCode: 'Le code postal est incorrect pour la zone - le pays sélectionné',
    email: "Le format de l'email est incorrect",
    maxlength: "Vous avez introduit plus de caractères qu'il n'est permis",
    maxlengthWithNumber: 'Ce champ ne doit pas contenir plus de caractères que {{maxLength}}.',
    combinedMaxlength:
      'La combinaison de {{ firstControl }} et {{ secondControl }} doit contenir au maximum {{maxLength}} caractères (actuellement {{actualLength}}).',
    date: {
      required: 'Ce champ est obligatoire',
      min: 'La date ne peut être antérieure à {{min}}',
      max: 'La date ne peut être postérieure à {{max}}.',
      pattern: 'Utiliser le format de date aaaa-mm-jj',
      invalid: 'Utiliser une date valide',
    },
    minlength: 'Ce champ doit contenir au moins {{{minLength}} caractères',
    min: "N'atteint pas le minimum",
  },
  contact: {
    address: '{{ street }}<br>{{locality}} ({{region}})',
    scheduleDay: '{{ startDay }}, jours ouvrables, de {{ opening }} à {{ closing}}',
    schedulePeriod: 'Du {{ startDay }} au {{ endDay }}, jours ouvrables, de {{ opening }} à {{ closing}}',
    email: '{{ email }}',
    phone: 'T: {{ phone }}',
  },
  contactForm: {
    labels: {
      email: 'Courriel',
      message: 'Traduit avec DeepL.com (version gratuite)',
      subject: 'Objet',
      orderCode: 'Numéro de commande',
      accept: "J'accepte le traitement de mes données personnelles pour les activités suivantes.",
      requiredAction: 'Action demandée',
      productsAffected: {
        label: 'Produits et quantités concernés',
        productDescription: 'Description du produit',
        quantity: 'Quantité',
        add: 'PRODUIT',
        remove: 'Retirer le produit',
      },
      qualityProblem: 'Problème de qualité',
      qualityProblems: {
        excessFat: 'Excès de graisse',
        smellFlavorColorProblems: "Problème d'odeur/de saveur/de couleur",
        vacuumLoss: 'Perte de vide',
        excessLackCuring: 'Durcissement excessif ou insuffisant',
        other: 'Autres raisons',
      },
    },
    placeholders: {
      ticketCauses: 'Sélectionnez une raison',
      ticketTemplates: 'Sélectionnez un cas',
      qualityProblems: 'Sélectionnez un problème',
    },
    formTemplateTitle: 'Formulaire de contact pour {{formTitle}}',
    submit: 'Envoyer',
    updateSuccess: 'Demande soumise avec succès',
    updateError: "Une erreur s'est produite lors de la soumission de votre demande. Veuillez réessayer plus tard.",
    modelNotFoundError:
      "L'adresse électronique ne correspond pas au numéro de commande. Veuillez réessayer après avoir corrigé vos données.",
  },
  preferenceCenter: {
    form: {
      title: 'MES INFORMATIONS PERSONNELLES',
      name: {
        label: 'Prénom',
        placeholder: 'Prénom',
      },
      surnames: {
        label: 'Nom',
        placeholder: 'Nom',
      },
      email: {
        label: 'E-mail',
        placeholder: 'E-mail',
        contact: 'Si vous voulez modifier votre e-mail, veuillez contacter sac_web@osborne.es',
      },
      birthDate: {
        label: 'Date de naissance',
        placeholder: 'jj/mm/aaaa',
      },
      province: {
        label: 'Région',
        placeholder: 'Région',
      },
      phoneNumber: {
        label: 'Téléphone portable',
        placeholder: 'Téléphone portable',
      },
      language: {
        label: 'Langage',
        placeholder: 'Langage',
      },
      unsubscribe: {
        title: 'SE DÉSABONNER',
        placeholder: 'Je ne souhaite plus recevoir de communications.',
      },
      update: 'METTRE À JOUR',
      preferenceUpdateSuccess: 'Données personnelles mises à jour avec succès',
      preferenceUpdateError: 'Impossible de mettre à jour les données',
    },
    languages: {
      en: 'Anglais',
      fr: 'Français',
      gt: 'Galicien',
      de: 'Allemand',
      it: 'Italien',
      pt: 'Portugais',
      es: 'Espagnol',
    },
  },
};
