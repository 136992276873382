import { Injectable } from '@angular/core';
import { Config, LanguageService, RoutingService, UrlCommands, WindowRef } from '@spartacus/core';
import { CjContactSchedule } from 'src/app/cms-components/atc/contact/contact-info/contact-info.model';
import { CjWebAvailabilityType } from 'src/app/cms-components/cart/base/models/web-availability.model';
import { CjBaseStoreService } from 'src/app/cms-components/content/misc/site-context-selector/facades/base-store.service';

@Injectable({
  providedIn: 'root',
})
export class CjStructuredDataUtilsService {
  private readonly daysOfWeek = [
    'https://schema.org/Monday',
    'https://schema.org/Tuesday',
    'https://schema.org/Wednesday',
    'https://schema.org/Thursday',
    'https://schema.org/Friday',
    'https://schema.org/Saturday',
    'https://schema.org/Sunday',
  ];

  constructor(
    private readonly winRef: WindowRef,
    private readonly config: Config,
    private readonly routingService: RoutingService,
    private readonly languageService: LanguageService,
    private readonly basestoreService: CjBaseStoreService,
  ) {}

  get siteName(): string {
    return 'Cinco Jotas';
  }

  get socialNetworkUrls(): string[] {
    return [
      'https://www.facebook.com/CincoJotas',
      'https://twitter.com/cincojotas',
      'https://www.youtube.com/user/5JotasVideo',
      'https://www.instagram.com/cincojotas',
    ];
  }

  parseAvailability(status: string) {
    switch (status) {
      case CjWebAvailabilityType.AVAILABLE:
        return 'https://schema.org/InStock';
      case CjWebAvailabilityType.LAST_UNITS:
        return 'https://schema.org/LimitedAvailability';
      case CjWebAvailabilityType.NOT_AVAILABLE:
        return 'https://schema.org/Discontinued';
      case CjWebAvailabilityType.ONLINE_ONLY:
        return 'https://schema.org/OnlineOnly';
      case CjWebAvailabilityType.TEMPORARILY_OUT_OF_STOCK:
        return 'https://schema.org/OutOfStock';
      default:
        return undefined;
    }
  }

  parseScheduleStructuredData(schedules: CjContactSchedule[]) {
    return schedules.map((schedule) => ({
      opens: schedule.opening,
      closes: schedule.closing,
      dayOfWeek: this.parseDays(schedule.startDay, schedule.endDay),
    }));
  }

  parseDays(startDay: number, endDay?: number): string[] {
    if (endDay) {
      const days = [];
      for (let i = startDay; i < endDay; i++) {
        days.push(this.daysOfWeek[i]);
      }
      return days;
    } else {
      return [this.daysOfWeek[startDay]];
    }
  }

  getHost(): string {
    return this.winRef.document.location.origin;
  }

  getFullUrl(append: string = '', url: string | UrlCommands | any[] = ''): string {
    return this.trimTrailingSlash(this.routingService.getFullUrl(url)) + append;
  }

  getCurrentUrl(append: string = ''): string {
    if (this.winRef.isBrowser() && this.winRef.location.href) {
      return this.trimTrailingSlash(this.winRef.location.href) + append;
    }
    return '';
  }

  getFullMediaUrl(url: string): string {
    return (this.config.backend?.media?.baseUrl ?? this.config.backend?.occ?.baseUrl ?? '') + url;
  }

  private trimTrailingSlash(url: string) {
    return url.substring(url.length - 1) == '/' ? url.slice(0, -1) : url;
  }
}
