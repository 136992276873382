import { NgModule } from '@angular/core';
import { CartAdapter } from '@spartacus/cart/base/core';
import { CjCartValidationAdapter } from 'src/app/core/cart/connectors/cart-validation.adapter';
import { CjCartAdapter } from 'src/app/core/cart/connectors/cart.adapter';
import { CjOccCartValidationAdapter } from './occ-cart-validation.adapter';
import { CjOccCartAdapter } from './occ-cart.adapter';

@NgModule({
  providers: [
    { provide: CartAdapter, useClass: CjOccCartAdapter },
    { provide: CjCartAdapter, useClass: CjOccCartAdapter },
    { provide: CjCartValidationAdapter, useClass: CjOccCartValidationAdapter },
  ],
})
export class CjOccCartModule {}
