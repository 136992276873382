import { PaymentDetails, StateUtils } from '@spartacus/core';

export const REDSYS_CARDS = 'Redsys cards';
export const LOAD_CARDS = '[Redsys] Load cards ';
export const LOAD_CARDS_SUCCESS = '[Redsys] Load Cards Success';
export const LOAD_CARDS_FAIL = '[Redsys] Load Cards Fail';

export class LoadCards extends StateUtils.LoaderLoadAction {
  override readonly type = LOAD_CARDS;
  constructor() {
    super(REDSYS_CARDS);
  }
}

export class LoadCardsSuccess extends StateUtils.LoaderSuccessAction {
  override readonly type = LOAD_CARDS_SUCCESS;
  constructor(public payload: PaymentDetails[]) {
    super(REDSYS_CARDS);
  }
}

export class LoadCardsFail extends StateUtils.LoaderFailAction {
  override readonly type = LOAD_CARDS_FAIL;
  constructor(public payload: any) {
    super(REDSYS_CARDS, payload);
  }
}

// action types
export type RedsysActions = LoadCards | LoadCardsFail | LoadCardsSuccess;
