<div class="cj-product-healthy-dialog">
  <div *ngIf="data$ | async as data" class="cx-modal-container" (esc)="dismissModal('Escape pressed')">
    <div class="cx-modal-content">
      <!-- Modal Header -->
      <div class="cx-dialog-header modal-header">
        <div *ngIf="data.title" class="cx-dialog-title modal-title">
          {{ data.title }}
        </div>
        <button type="button" class="close" (click)="dismissModal('Cross click')">
          <span aria-hidden="true">
            <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
          </span>
        </button>
      </div>

      <!-- Modal Body -->
      <div class="cx-dialog-body modal-body" *ngIf="data.feature as feature">
        <div *ngIf="feature.featureValues" class="cj-product-healthy-dialog__list">
          <div 
            *ngFor="let value of feature.featureValues" 
            class="cj-product-healthy-dialog__item"
            [innerHTML]="value.value | sanitizeHtml" >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>