import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CjGiftCard } from '../model/gift-card.model';
import { CjGiftCardAdapter } from './gift-card.adapters';

@Injectable({
  providedIn: 'root',
})
export class CjGiftCardConnector {
  constructor(protected adapter: CjGiftCardAdapter) {}

  getAll(userId: string, cartId: string): Observable<CjGiftCard[]> {
    return this.adapter.loadAll(userId, cartId);
  }

  set(userId: string, cartId: string, giftCardCode: string, giftCardsMessage: string): Observable<CjGiftCard> {
    return this.adapter.set(userId, cartId, giftCardCode, giftCardsMessage);
  }

  delete(userId: string, cartId: string): Observable<{}> {
    return this.adapter.delete(userId, cartId);
  }
}
