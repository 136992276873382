import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { IconModule, ItemCounterModule, KeyboardFocusModule, MediaModule, PromotionsModule, SpinnerModule } from '@spartacus/storefront';
import { CjCartSharedModule } from '../cart-shared/cart-shared.module';
import { CjAddedToCartDialogComponent } from './added-to-cart-dialog.component';
import { cjAddedToCartLayoutConfig } from './added-to-cart-layout.config';
import { CjPromotionsModule } from "../../../../product/promotions/promotions.module";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    SpinnerModule,
    PromotionsModule,
    UrlModule,
    IconModule,
    I18nModule,
    ItemCounterModule,
    KeyboardFocusModule,
    MediaModule,
    CjCartSharedModule,
    CjPromotionsModule
],
  providers: [provideConfig(cjAddedToCartLayoutConfig)],
  declarations: [CjAddedToCartDialogComponent],
  exports: [CjAddedToCartDialogComponent],
})
export class CjAddedToCartDialogModule {}
