import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CartActions, CartValidationService, CartValidationStateService, StateWithMultiCart } from '@spartacus/cart/base/core';
import { ActiveCartFacade, Cart } from '@spartacus/cart/base/root';
import { Command, CommandService, CommandStrategy, GlobalMessageService, GlobalMessageType, UserIdService } from '@spartacus/core';
import { Observable, combineLatest, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { CjCartValidationConnector } from '../connectors/cart-validation.connector';

@Injectable({
  providedIn: 'root',
})
export class CjCartValidationService extends CartValidationService {
  protected simulateCartCommand: Command<void, Cart> = this.command.create(
    () =>
      combineLatest([this.activeCartFacade.getActiveCartId(), this.userIdService.takeUserId(), this.activeCartFacade.isStable()]).pipe(
        filter(([_, __, loaded]) => loaded),
        take(1),
        switchMap(([cartId, userId]) => this.cartValidationConnector.simulate(cartId, userId)),
      ),
    {
      strategy: CommandStrategy.CancelPrevious,
    },
  );

  constructor(
    protected override cartValidationConnector: CjCartValidationConnector,
    protected override command: CommandService,
    protected override userIdService: UserIdService,
    protected override activeCartFacade: ActiveCartFacade,
    protected override cartValidationStateService: CartValidationStateService,
    private readonly store: Store<StateWithMultiCart>,
    private readonly globalMessageService: GlobalMessageService,
  ) {
    super(cartValidationConnector, command, userIdService, activeCartFacade, cartValidationStateService);
  }

  /**
   * Simulates cart, saves results on store and returns updated cart.
   */
  simulateCart(): Observable<Cart> {
    return this.simulateCartCommand.execute().pipe(
      withLatestFrom(this.userIdService.takeUserId()),
      tap(
        ([cart, userId]) =>
          userId && cart?.code && this.store.dispatch(new CartActions.LoadCartSuccess({ userId, cartId: cart.code, cart })),
      ),
      map(([cart]) => cart),
      catchError((err) => {
        this.globalMessageService.add({ key: 'httpHandlers.cartSimulationError' }, GlobalMessageType.MSG_TYPE_ERROR);
        return throwError(() => err);
      }),
    );
  }
}
