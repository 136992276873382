import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { PageComponentModule } from '@spartacus/storefront';
import { CjHamburgerMenuModule } from './hamburger-menu/hamburger-menu.module';
import { CjLogoComponent } from './logo/logo.component';

@NgModule({
  imports: [CommonModule, PageComponentModule, CjHamburgerMenuModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsborneWebsiteLogosComponent: {
          component: () => import('./logo/logo.component').then((m) => m.CjLogoComponent),
        },
      },
    }),
  ],
  declarations: [CjLogoComponent],
  exports: [CjLogoComponent],
})
export class CjHeaderModule {}
