import { Actions } from '@ngrx/effects';
import { Cart } from '@spartacus/cart/base/root';
import { Order } from '@spartacus/order/root';
import { CjGiftCard } from 'src/app/cms-components/checkout/checkout-gift-card/model/gift-card.model';
import { CjOrderEntry } from 'src/app/cms-components/order/order.model';

export const PLACE_ORDER = '[Checkout] Place Order';
export const LOAD_CHECKOUT_STEP = '[Checkout] Load Checkout Step';
export const LOAD_CHECKOUT_STEP_SUCCESS = '[Checkout] Load Checkout Step Success';
export const SET_DELIVERY_MODE_SUCCESS = '[Checkout] Set Delivery Mode Success'; // Default set delivery mode action

export class LoadCheckoutStep extends Actions {
  readonly type = LOAD_CHECKOUT_STEP;

  constructor(public payload: { step: number; cart: Cart }) {
    super();
  }
}

export class LoadCheckoutStepSuccess extends Actions {
  readonly type = LOAD_CHECKOUT_STEP_SUCCESS;

  constructor(
    public payload: {
      step: number;
      entries: Array<CjOrderEntry>;
      optionInformation: any;
      giftCard: CjGiftCard;
    },
  ) {
    super();
  }
}

export class SetDeliveryModeSuccess extends Actions {
  readonly type = SET_DELIVERY_MODE_SUCCESS;

  constructor(public payload: string) {
    super();
  }
}

export class PlaceOrder extends Actions {
  readonly type = PLACE_ORDER;

  constructor(public payload: Order) {
    super();
  }
}

export type CjCheckoutStepsEventAction = LoadCheckoutStep | LoadCheckoutStepSuccess | SetDeliveryModeSuccess;
