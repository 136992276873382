import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule } from '@spartacus/core';
import { GenericLinkModule, MediaModule, NavigationModule } from '@spartacus/storefront';
import { CjAnouncementsModule } from './announcements/announcements-list.module';
import { CjBottomHeaderNavModule } from './bottom-header-nav/bottom-header-nav.module';
import { CjBreadcrumbModule } from './breadcrumb/breadcrumb.module';
import { CjFooterNavigationModule } from './footer-navigation/footer-navigation.module';
import { CjMenuModule } from './menu/menu.module';
import { CjPageTitleModule } from './page-header/page-title.module';
import { CjSecurePageWarningModule } from './secure-page-warning/secure-page-warning.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    GenericLinkModule,
    MediaModule,
    I18nModule,
    NavigationModule,
    CjMenuModule,
    CjFooterNavigationModule,
    CjPageTitleModule,
    CjSecurePageWarningModule,
    CjBottomHeaderNavModule,
    CjBreadcrumbModule,
    CjAnouncementsModule,
  ],
})
export class CjNavigationModule {}
