import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CartValidationGuard } from '@spartacus/cart/base/core';
import { CheckoutPaymentTypeAdapter, CheckoutPaymentTypeConnector, CheckoutPaymentTypeService } from '@spartacus/checkout/b2b/core';
import { CheckoutPaymentTypeFacade } from '@spartacus/checkout/b2b/root';
import { CartNotEmptyGuard, CheckoutAuthGuard } from '@spartacus/checkout/base/components';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { CardModule, SpinnerModule } from '@spartacus/storefront';
import { CjOccCheckoutPaymentTypeAdapter } from './adapter/occ-checkout-payment-type.adapter';
import { CjCheckoutPaymentTypeComponent } from './checkout-payment-type.component';
import { CjCheckoutPaymentTypeFacade } from './facade/checkout-payment-type.facade';
import { CjCheckoutPaymentTypeService } from './facade/checkout-payment-type.service';

@NgModule({
  imports: [CommonModule, BrowserModule, I18nModule, SpinnerModule, FormsModule, ReactiveFormsModule, CardModule, NgOptimizedImage],
  declarations: [CjCheckoutPaymentTypeComponent],
  exports: [CjCheckoutPaymentTypeComponent],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutPaymentDetails: {
          component: () => import('./checkout-payment-type.component').then((m) => m.CjCheckoutPaymentTypeComponent),
          guards: [CheckoutAuthGuard, CartNotEmptyGuard, CartValidationGuard],
        },
        CheckoutPaymentType: {
          component: () => import('./checkout-payment-type.component').then((m) => m.CjCheckoutPaymentTypeComponent),
          guards: [CheckoutAuthGuard, CartNotEmptyGuard, CartValidationGuard],
        },
      },
    }),
    { provide: CheckoutPaymentTypeFacade, useClass: CheckoutPaymentTypeService },
    { provide: CjCheckoutPaymentTypeFacade, useClass: CjCheckoutPaymentTypeService },
    { provide: CheckoutPaymentTypeService, useClass: CjCheckoutPaymentTypeService },
    { provide: CheckoutPaymentTypeConnector, useClass: CheckoutPaymentTypeConnector },
    { provide: CheckoutPaymentTypeAdapter, useClass: CjOccCheckoutPaymentTypeAdapter },
  ],
})
export class CjCheckoutPaymentTypeModule {}
