import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { CmsConfig, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { ListNavigationModule, MediaModule, SpinnerModule } from '@spartacus/storefront';
import { CjOrderHistoryComponent } from './order-history.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgSelectModule,
    ListNavigationModule,
    UrlModule,
    I18nModule,
    SpinnerModule,
    MediaModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        AccountOrderHistoryComponent: {
          component: () => import('./order-history.component').then((m) => m.CjOrderHistoryComponent),
        },
      },
    }),
  ],
  declarations: [CjOrderHistoryComponent],
  exports: [CjOrderHistoryComponent],
})
export class CjOrderHistoryModule {}
