<div class="cx-modal-container cj-warranty-banner" (esc)="dismissModal('Escape pressed')">
  <div class="cx-modal-content" *ngIf="data$ | async as data">
    <!-- Modal Header -->
    <div class="cx-dialog-header modal-header">
      <div class="cx-dialog-title modal-title">
        <cx-media *ngIf="data.image" [container]="data.image"></cx-media>
        {{ data.title | titlecase }}
      </div>
      <button
        type="button"
        class="close"
        attr.aria-label="{{ 'addToCart.closeModal' | cxTranslate }}"
        (click)="dismissModal('Cross click')"
      >
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </button>
    </div>

    <!-- Modal Body -->
    <div class="cx-dialog-body modal-body">
      <div class="cj-warranty-banner__item" *ngFor="let item$ of items$ | async">
        <div class="cj-warranty-banner__content" *ngIf="item$ | async as item">
          <div class="cj-warranty-banner__icon">
            <cx-media *ngIf="item.icon" [container]="item.icon"></cx-media>
          </div>
          <div class="cj-warranty-banner__info">
            <p class="cj-warranty-banner__title" *ngIf="item.title">{{ item.title }}</p>
            <p class="cj-warranty-banner__text" *ngIf="item.text">{{ item.text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
