import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { CjOrderGuestRegisterFormDialogComponent } from './order-guest-register-form-dialog.component';

export const ORDER_REGISTER_FORM_DIALOG = 'ORDER_REGISTER_FORM_DIALOG';

export const cjOrderRegisterFormLayoutConfig: LayoutConfig = {
  launch: {
    ORDER_REGISTER_FORM_DIALOG: {
      inlineRoot: true,
      component: CjOrderGuestRegisterFormDialogComponent,
      dialogType: DIALOG_TYPE.POPOVER_CENTER_BACKDROP,
    },
  },
};
