import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { Address, StateUtils, StateWithUser, USER_FEATURE, UserState } from '@spartacus/core';
import { LoaderState } from '@spartacus/core/src/state/utils/loader';

export const getUserState: MemoizedSelector<StateWithUser, UserState> = createFeatureSelector<UserState>(USER_FEATURE);

export const getAddressesOfBasestoreLoaderState: MemoizedSelector<StateWithUser, LoaderState<Address[]>> = createSelector(
  getUserState,
  (state: UserState) => state.addresses,
);

export const getAddressesOfBasestore: MemoizedSelector<StateWithUser, Address[]> = createSelector(
  getAddressesOfBasestoreLoaderState,
  (state: LoaderState<Address[]>) => StateUtils.loaderValueSelector(state),
);

export const getAddressesLoading: MemoizedSelector<StateWithUser, boolean> = createSelector(
  getAddressesOfBasestoreLoaderState,
  (state: LoaderState<Address[]>) => StateUtils.loaderLoadingSelector(state),
);

export const getAddressesofBasestoreLoadedSuccess: MemoizedSelector<StateWithUser, boolean> = createSelector(
  getAddressesOfBasestoreLoaderState,
  (state: LoaderState<Address[]>) => StateUtils.loaderSuccessSelector(state) && !StateUtils.loaderLoadingSelector(state),
);
