<div class="cj-order-confirmation-actions">
  <a class="btn btn-primary" [routerLink]="{ cxRoute: 'shop' } | cxUrl">{{ 'checkoutOrderConfirmation.continueBuying' | cxTranslate }}</a>

  <a
    *ngIf="loggedIn$ | async"
    class="btn btn-outline-primary mt-4"
    [routerLink]="{ cxRoute: 'orderDetails', params: order$ | async } | cxUrl"
  >
    {{ 'checkoutOrderConfirmation.orderDetails' | cxTranslate }}
  </a>
</div>
