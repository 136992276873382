/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { AuthGuard } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Checks if there is currently logged in user.
 * Use to protect pages dedicated only for logged in users.
 */
@Injectable({
  providedIn: 'root',
})
export class CjAuthGuard extends AuthGuard {
  override canActivate(): Observable<boolean | UrlTree> {
    return this.authService.isUserLoggedIn().pipe(
      map((isLoggedIn) => {
        console.log('AuthGuard | If !isloggedIn go /login and then back to this route | isLoggedIn', isLoggedIn);

        if (!isLoggedIn) {
          this.authRedirectService.saveCurrentNavigationUrl();
          return this.router.parseUrl(this.semanticPathService.get('login') ?? '');
        }
        return isLoggedIn;
      }),
    );
  }
}
