import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CmsConfig, I18nModule, StateModule, UrlModule, provideConfig } from '@spartacus/core';
import { DatePickerModule, FormErrorsModule, IconModule, ListNavigationModule, SpinnerModule } from '@spartacus/storefront';
import { ContactFormAdapter } from './connectors/contact-form.adapter';
import { OccContactFormAdapter } from './connectors/occ-contact-form.adapter';
import { CONTACT_FORM_FEATURE } from './store/contact-form-state';
import { ContactFormEffect } from './store/effects/contact-form.effect';
import { CjContactFormComponent } from './contact-form.component';
import { reducerContactFormProvider, reducerContactFormToken } from './store/reducer/contact-form.reducer';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormErrorsModule,
    NgSelectModule,
    ListNavigationModule,
    UrlModule,
    I18nModule,
    IconModule,
    DatePickerModule,
    StateModule,
    SpinnerModule,
    StoreModule.forFeature(CONTACT_FORM_FEATURE, reducerContactFormToken),
    EffectsModule.forFeature([ContactFormEffect]),
  ],
  providers: [
    reducerContactFormProvider,
    {
      provide: ContactFormAdapter,
      useClass: OccContactFormAdapter,
    },
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CmsCustomContactFormComponent: {
          component: () => import('./contact-form.component').then((m) => m.CjContactFormComponent),
        },
      },
    }),
  ],
  declarations: [CjContactFormComponent],
  exports: [CjContactFormComponent],
})
export class CjContactFormModule {}
