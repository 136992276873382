import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoggerService, normalizeHttpError } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CjBaseStore } from 'src/app/core/model/misc.mode';
import { CjBaseStoreActions } from '../actions';
import { LoadBaseStores } from '../actions/base-store.action';
import { CjBaseStoreConnector } from 'src/app/core/site-context/connectors/base-store-connector';

@Injectable()
export class CjBaseStoreEffect {
  protected logger = inject(LoggerService);

  constructor(
    private actions$: Actions,
    private baseStoreConnector: CjBaseStoreConnector,
  ) {}

  loadBaseStores$: Observable<CjBaseStoreActions.CjBaseStoreAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(CjBaseStoreActions.LOAD_BASE_STORES),
      map((action: LoadBaseStores) => action.payload),
      switchMap((payload) => {
        return this.baseStoreConnector.getBaseStores(payload.userId).pipe(
          map((baseStores: CjBaseStore[]) => new CjBaseStoreActions.LoadBaseStoresSuccess(baseStores)),
          catchError((error) => of(new CjBaseStoreActions.LoadBaseStoresFail(normalizeHttpError(error, this.logger)))),
        );
      }),
    ),
  );
}
