import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CjBaseStoreInterceptor } from './base-store.interceptor';

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CjBaseStoreInterceptor,
      multi: true,
    },
  ],
})
export class CjBaseStoreInterceptorModule {}
