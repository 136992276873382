export const userProfile = {
  register: {
    registerTitle: 'Je n`ai pas de compte',
    createAccount: 'Créer un compte',
    termsAndConditions: 'Termes et conditions',
    confirmThatRead: 'Je confirme avoir lu et accepté le',
    newsletter: 'Je souhaite recevoir la newsletter',
    firstName: {
      label: 'Prénom',
      placeholder: 'Prénom',
    },
    lastName: {
      label: 'Nom de famille',
      placeholder: 'Nom de famille',
    },
    emailAddress: {
      label: 'Adresse e-mail',
      placeholder: 'Adresse e-mail',
    },
    password: {
      label: 'Mot de passe',
      placeholder: 'Mot de passe',
    },
    confirmPassword: {
      action: 'Confirmez le mot de passe',
      label: 'Confirmez le mot de passe',
      placeholder: 'Confirmez le mot de passe',
    },
    postRegisterMessage: 'Veuillez démarrer la session avec les données fournies.',
    newPassword: 'Nouveau mot de passe',
    passwordMinRequirements:
      'Le mot de passe doit comporter au minimum six caractères, une lettre majuscule, une lettre minuscule, un chiffre et un symbole.',
    resetPassword: 'Changer le mot de passe',
  },
  forgottenPassword: {
    resetPassword: 'Réinitialiser le mot de passe',
    resetButton: 'Réinitialiser',
    sendResetPassword: 'Changer le mot de passe',
    enterEmailAddressAssociatedWithYourAccount: "Entrez l'adresse e-mail associée à votre compte",
    emailAddress: {
      label: 'E-mail',
      placeholder: 'E-mail',
    },
    enterValidEmail: 'Veuillez entrer un e-mail valide.',
    passwordResetEmailSent: "Si un compte existe avec l'e-mail fournie, vous recevrez un e-mail pour réinitialiser votre mot de passe.",
    passwordResetSuccess: 'Succès! Vous pouvez vous connecter avec votre nouveau mot de passe.',
    TokenInvalidatedError:
      "Le lien utilisé n'est pas correct. Si vous avez fait plusieurs tentatives, assurez-vous d'utiliser le lien du dernier e-mail reçu. Sinon, veuillez en faire la demande à nouveau.",
    IllegalArgumentError: 'Le lien a expiré',
  },
};
