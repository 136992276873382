import { CartAdapter } from '@spartacus/cart/base/core';
import { User } from '@spartacus/user/account/root';
import { Observable } from 'rxjs';

export abstract class CjCartAdapter extends CartAdapter {
  /**
   * Abstract method to set document identifier to customer.
   * @param userId
   * @param cartId
   * @param user
   */
  abstract setDocumentIdentifier(userId: string, cartId: string, user: User): Observable<{}>;
}
