import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, provideConfig, UrlModule } from '@spartacus/core';
import { PageSlotModule } from '@spartacus/storefront';
import { CjLoginComponent } from './login.component';

@NgModule({
  imports: [CommonModule, RouterModule, UrlModule, PageSlotModule, I18nModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        LoginComponent: {
          component: () => import('./login.component').then((m) => m.CjLoginComponent),
        },
      },
    }),
  ],
  declarations: [CjLoginComponent],
})
export class CjLoginModule {}
