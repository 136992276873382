import { Action } from '@ngrx/store';
import { CjProductGridBannerPayload, CjProductGridElementsPayload, CjProductGridFilteredProductPayload } from '../product-grid-state';

export const ADD_FILTERED_PRODUCT = '[ProductGrid] Add filtered product';
export const SET_ONE_COLUMN_BANNERS = '[ProductGrid] Set one column banners';
export const SET_THREE_COLUMN_BANNERS = '[ProductGrid] Set three column banners';
export const SAVE_ELEMENTS = '[ProductGrid] Save elements';
export const CLEAR_BANNERS = '[ProductGrid] Clear banners';

export class AddFilteredProduct implements Action {
  readonly type = ADD_FILTERED_PRODUCT;
  constructor(public payload: CjProductGridFilteredProductPayload) {}
}

export class SetOneColumnBanner implements Action {
  readonly type = SET_ONE_COLUMN_BANNERS;
  constructor(public payload: CjProductGridBannerPayload) {}
}

export class SetThreeColumnBanner implements Action {
  readonly type = SET_THREE_COLUMN_BANNERS;
  constructor(public payload: CjProductGridBannerPayload) {}
}

export class SaveElements implements Action {
  readonly type = SAVE_ELEMENTS;
  constructor(public payload: CjProductGridElementsPayload) {}
}

export class ClearBanners implements Action {
  readonly type = CLEAR_BANNERS;
}

// Action types
export type ProductGridAction = AddFilteredProduct | SetOneColumnBanner | SetThreeColumnBanner | SaveElements | ClearBanners;
