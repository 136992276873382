import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { FormErrorsModule, MediaModule, PageComponentModule } from '@spartacus/storefront';
import { CjGsapAnimationDirectiveModule } from 'src/app/shared/directives/directives.module';
import { CjNewsletterComponent } from './newsletter.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    MediaModule,
    ReactiveFormsModule,
    FormErrorsModule,
    I18nModule,
    HttpClientModule,
    PageComponentModule,
    CjGsapAnimationDirectiveModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsborneNewsletterComponent: {
          component: () => import('./newsletter.component').then((m) => m.CjNewsletterComponent),
        },
      },
    }),
  ],
  declarations: [CjNewsletterComponent],
  exports: [CjNewsletterComponent],
})
export class CjNewsletterModule {}
