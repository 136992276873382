import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { CartActions, StateWithMultiCart, getCartIdByUserId } from '@spartacus/cart/base/core';
import { CART_NORMALIZER, Cart } from '@spartacus/cart/base/root';
import {
  ConverterService,
  GlobalMessageService,
  GlobalMessageType,
  LoggerService,
  OccEndpointsService,
  UserIdService,
  normalizeHttpError,
} from '@spartacus/core';
import { Observable, catchError, map, switchMap, take, tap } from 'rxjs';
import { CjModifyOrderAdapter } from 'src/app/core/order/connectors/modify-order.adapter';
import { CjOrderHistoryService } from 'src/app/core/order/facade/order-history.service';

@Injectable()
export class CjOccModifyOrderAdapter implements CjModifyOrderAdapter {
  protected logger = inject(LoggerService);

  constructor(
    private readonly http: HttpClient,
    private readonly occEndpoints: OccEndpointsService,
    private readonly converter: ConverterService,
    private readonly orderHistoryService: CjOrderHistoryService,
    private readonly userIdService: UserIdService,
    private readonly store: Store<StateWithMultiCart>,
    private readonly gms: GlobalMessageService,
  ) {}

  modify(orderId: string, userId: string): Observable<Cart> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(this.getModifyOrderEndpoint(orderId, userId), {}, { headers }).pipe(
      catchError((error) => {
        this.gms.add({ key: 'orderDetails.actions.modify.modifyFail' }, GlobalMessageType.MSG_TYPE_ERROR);
        throw normalizeHttpError(error, this.logger);
      }),
      this.converter.pipeable(CART_NORMALIZER),
      switchMap((cart) =>
        this.userIdService.getUserId().pipe(
          take(1),
          tap((userId) => {
            this.store.dispatch(
              new CartActions.LoadCartSuccess({
                userId,
                cartId: getCartIdByUserId(cart, userId),
                cart,
                extraData: {
                  active: true,
                },
              }),
            );

            this.gms.add({ key: 'orderDetails.actions.modify.modifySuccess' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
          }),
          map(() => cart),
        ),
      ),
    );
  }

  protected getModifyOrderEndpoint(orderCode: string, userId: string): string {
    return this.occEndpoints.buildUrl('reorder', {
      urlParams: {
        userId,
        code: orderCode,
      },
      queryParams: { cancel: true },
    });
  }
}
