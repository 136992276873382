import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoggerService, UserIdService, normalizeHttpError } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { CjRedsysConnector } from '../../connectors/redsys.connector';
import { CjRedsysActions } from '../actions';
import { RedsysActions } from '../actions/redsys.action';

@Injectable()
export class CjRedsysEffect {
  constructor(
    private readonly actions$: Actions,
    private readonly logger: LoggerService,
    private readonly connector: CjRedsysConnector,
    private readonly userIdService: UserIdService,
  ) {}

  loadRedsysCards$: Observable<RedsysActions> = createEffect(() =>
    this.actions$.pipe(
      ofType(CjRedsysActions.LOAD_CARDS),
      switchMap(() => this.userIdService.getUserId().pipe(take(1))),
      mergeMap((userId) => {
        return this.connector.loadCards(userId).pipe(
          map((paymentDetails) => new CjRedsysActions.LoadCardsSuccess(paymentDetails)),
          catchError((error) => of(new CjRedsysActions.LoadCardsFail(normalizeHttpError(error, this.logger)))),
        );
      }),
    ),
  );
}
