import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { CjGiftCard } from '../../model/gift-card.model';
import { GIFT_CARD_FEATURE, StateWithGiftCards } from '../gift-card-state';

export const getGiftCardsStoreState: MemoizedSelector<
  StateWithGiftCards,
  StateUtils.EntityLoaderState<CjGiftCard[]>
> = createFeatureSelector<StateUtils.EntityLoaderState<CjGiftCard[]>>(GIFT_CARD_FEATURE);

export const getGiftCardsEntitySelectorFactory = (
  cartId: string,
): MemoizedSelector<StateWithGiftCards, StateUtils.LoaderState<CjGiftCard[]>> => {
  return createSelector(getGiftCardsStoreState, (state: StateUtils.EntityLoaderState<CjGiftCard[]>) =>
    StateUtils.entityLoaderStateSelector(state, cartId),
  );
};

export const getGiftCardsSelectorFactory = (cartId: string): MemoizedSelector<StateWithGiftCards, CjGiftCard[]> => {
  return createSelector(getGiftCardsStoreState, (state: StateUtils.EntityLoaderState<CjGiftCard[]>) =>
    StateUtils.entityValueSelector(state, cartId),
  );
};
