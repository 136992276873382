export const userAccount = {
  loginForm: {
    title: 'I already have an account',
    forgotPassword: 'Forgot password?',
    signIn: 'Sign In',
    emailAddress: {
      label: 'Email address',
      placeholder: 'Enter email',
    },
    password: {
      label: 'Password',
      placeholder: 'Password',
    },
    wrongEmailFormat: 'This is not a valid email format.',
  },
  miniLogin: {
    signInRegister: 'Log In / Create account',
  },
};
