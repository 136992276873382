import { Injectable } from '@angular/core';
import { PaymentDetails } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CjRedsysAdapter } from './redsys.adapter';

@Injectable({
  providedIn: 'root',
})
export class CjRedsysConnector {
  constructor(protected adapter: CjRedsysAdapter) {}

  loadCards(userId: string): Observable<PaymentDetails[]> {
    return this.adapter.loadCards(userId);
  }
}
